import React, { useEffect, useState } from "react";
import api from "utils/api";
import { Bar, HorizontalBar } from "react-chartjs-2";
import APIConstant from "utils/constant";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
  MenuItem,
  Select,
  Card,
  Grid,
} from "@material-ui/core";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Dashboard = (props) => {
  const [userId, setUserId] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [dashboardList, setDashboardList] = useState([
    {
      Name: "Stock",
      SubList: [
        {
          Name: "Inward",
          Count: 0,
        },

        {
          Name: "Outward",
          Count: 0,
        },
      ],
    },
    {
      Name: "Stock Returned",
      SubList: [
        {
          Name: "Inward Returns",
          Count: 0,
        },
        {
          Name: "Outward Returns",
          Count: 0,
        },
      ],
    },
    {
      Name: "Orders to be placed",
      Count: 0,
    },
    {
      Name: "Items & Goods",
      Count: 0,
    },
    {
      Name: "Total Warehouse",
      Count: 0,
      SubCount: "(Sub-Warehouse: 0)",
    },
  ]);
  const [equipmentList, setEquipmentList] = useState([
    {
      Name: "Total Repairing Costs",
      Count: 0,
    },
    {
      Name: "Total Items Used",
      Count: 0,
    },
    {
      Name: "Equipments",
      Count: 0,
    },
  ]);
  const [majorReasons, setMajorReasons] = useState([]);
  const [mostUsedItems, setMostUsedItems] = useState([]);
  const [itemsReturnedList, setItemsReturnedList] = useState([]);
  const [itemsRefillList, setItemsRefillList] = useState([]);
  const [repairingCost, setRepairingCost] = useState([]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Bar Chart",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");

    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      getMainDashboardAPI(data);
      getEquipmentAPI(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getMainDashboardAPI = async (UserId) => {
    let params = {
      UserId,
    };
    let tempDashboardList = [...dashboardList];
    await api
      .post(APIConstant.path.GetTotalStocks, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempDashboardList.find(
            (data) => data.Name === "Stock"
          );
          tempData.SubList[0].Count =
            response.Data.find((data) => data._id === "Inward")?.Count || 0;
          tempData.SubList[1].Count =
            response.Data.find((data) => data._id === "Outward")?.Count || 0;

          tempDashboardList[0] = tempData;
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetReturnedStocks, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempDashboardList.find(
            (data) => data.Name === "Stock Returned"
          );
          tempData.SubList[0].Count =
            response.Data.find((data) => data._id === "Inward")?.Count || 0;
          tempData.SubList[1].Count =
            response.Data.find((data) => data._id === "Outward")?.Count || 0;
          tempDashboardList[1] = tempData;
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetOrdersToBePlaced, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempDashboardList.find(
            (data) => data.Name === "Orders to be placed"
          );
          tempData.Count = response.Data.Count;
          tempDashboardList[2] = tempData;
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetItemGoodsCount, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempDashboardList.find(
            (data) => data.Name === "Items & Goods"
          );
          tempData.Count = response.Data.Count;
          tempDashboardList[3] = tempData;
        } else {
          console.error(
            "API call was not successful or data is not in the expected format:",
            response
          );
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetTotalWarehouse, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempDashboardList.find(
            (data) => data.Name === "Total Warehouse"
          );
          tempData.Count = response.Data.Count;
          tempData.SubCount = `(Sub-Warehouse: ${response.Data.SubCount})`;
          tempDashboardList[4] = tempData;
        } else {
          console.error("API response is not an object or invalid:", response);
        }
      })
      .catch((err) => { });

    setDashboardList(tempDashboardList);
    api
      .post(APIConstant.path.MostUsedItems, params)
      .then((response) => {
        const chartData = {
          labels: response.Data.map((item) => item.ItemName), // Item names as labels
          datasets: [
            {
              label: "Most Used Items",
              data: response.Data.map((item) => item.Count), // Counts as data
              backgroundColor: "rgba(54, 162, 235, 0.6)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              base: 0,
            },
          ],
        };

        setMostUsedItems(chartData);
      })
      .catch((err) => { });
    api
      .post(APIConstant.path.MajorReasons, params)
      .then((response) => {
        if (response.Success) {
          const chartData = {
            labels: response.Data.map((item) => item.Name),
            datasets: [
              {
                label: "Sell",
                data: response.Data.map((item) => item.SellCount),
                backgroundColor: "rgba(54, 162, 235, 0.6)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
              },
              {
                label: "Machinery",
                data: response.Data.map((item) => item.MachineryCount),
                backgroundColor: "rgba(255, 99, 132, 0.6)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
              },
              {
                label: "Warehouse",
                data: response.Data.map((item) => item.WarehouseCount),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          };
          setMajorReasons(chartData);
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.ItemsReturned, params)
      .then((response) => {
        if (response.Success) {
          setItemsReturnedList(response.Data);
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetRefillItems, params)
      .then((response) => {
        if (response.Success) {
          setItemsRefillList(response.Data);
        }
      })
      .catch((err) => { });
  };

  const getEquipmentAPI = async (UserId) => {
    let params = {
      UserId,
    };
    let tempEquipmentList = [...equipmentList];
    await api
      .post(APIConstant.path.GetTotalRepairingCost, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempEquipmentList.find(
            (data) => data.Name === "Total Repairing Costs"
          );
          tempData.Count = response.Count;

          tempEquipmentList[0] = tempData;
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetTotalItemsUsed, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempEquipmentList.find(
            (data) => data.Name === "Total Items Used"
          );
          tempData.Count = response.Count;
          tempEquipmentList[1] = tempData;
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetTotalEquipments, params)
      .then((response) => {
        if (response.Success === 1) {
          let tempData = tempEquipmentList.find(
            (data) => data.Name === "Equipments"
          );
          tempData.Count = response.Count;

          tempEquipmentList[2] = tempData;
        }
      })
      .catch((err) => { });
    await api
      .post(APIConstant.path.GetRepairingCosts, params)
      .then((response) => {
        if (response.Success === 1) {
          const chartData = {
            labels: response.Data.map((item) => item.Equipment), // Item names as labels
            datasets: [
              {
                label: "Most Used Items",
                data: response.Data.map((item) => item.Price), // Counts as data
                backgroundColor: "rgba(54, 162, 235, 0.6)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
                base: 0,
              },
            ],
          };

          setRepairingCost(chartData);
        }
      })
      .catch((err) => { });

    setEquipmentList(tempEquipmentList);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  return (
    <div className="p-mt-2 e-main-access">
      {/* <div className="heading-area">
        <h2> Dashboard </h2>
      </div> */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="simple tabs example"
      >
        <Tab label="Main Dashboard" {...a11yProps(0)} />

        <Tab label="Equipment Analytics" {...a11yProps(1)} />
      </Tabs>
      <TabPanel className="main-card main-dashboard" value={tabValue} index={0}>
        <div className="wayment-owner-dashboard p-mt-0">
          <div className="p-grid">
            {dashboardList?.length
              ? dashboardList?.map((item, idx) => {
                return (
                  <div>
                  <div className="p-col-6 p-md-4 p-w-100">
                    <div className="wb-counter-box">
                      <div className="p-d-flex p-ai-center">
                        <i className="pi pi-chart-pie"></i>
                        <p>{item.Name}</p>
                      </div>
                      <div className="p-d-flex p-ai-center p-mt-2">
                        {item.SubList ? (
                          item.SubList.map((list, index) => {
                            return (
                              <>
                                <div className="job-d-badge p-ml-2">
                                  <p><b>{list.Name}</b> - {list.Count}</p>
                                  {/* <h2>{list.Count}</h2> */}
                                </div>
                                {/* {index !== item.SubList.length - 1 && (
                                  <span>|</span>
                                )} */}
                              </>
                            );
                          })
                        ) : (
                          <div className="job-d-badge">
                            <p><strong>{item.Count || 0}</strong></p>
                            {item.SubCount && <span>{item.SubCount}</span>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>
                );
              })
              : null}
          </div>

          {/* <div className="p-grid dashboard-counters">
          {dashboardList?.length
            ? dashboardList.map((item, idx) => {
                return (
                  <div key={idx} className="p-col-12 p-md-2 p-lg-2">
                    <div className="counter-box p-shadow-2 p-mb-3 p-p-3">
                      <div className="counter-icon p-mb-2">
                        <i className={item.Icon || "pi pi-box"}></i>
                      </div>
                      <div className="counter-details p-d-flex p-flex-column p-jc-center">
                        <p className="counter-title p-mb-1">{item.Name}</p>
                        <div className="p-d-flex p-ai-center">
                          {item.SubList ? (
                            item.SubList.map((subItem, subIdx) => (
                              <div key={subIdx} className="sub-counter p-mr-2">
                                <p className="sub-counter-title p-m-0">
                                  {subItem.Name}
                                </p>
                                <h2 className="sub-counter-value p-m-0">
                                  {subItem.Count}
                                </h2>
                              </div>
                            ))
                          ) : (
                            <h2 className="counter-value p-m-0">
                              {item.Count || 0}
                            </h2>
                          )}
                        </div>
                        {item.SubCount && (
                          <p className="sub-counter-text p-m-0">
                            {item.SubCount}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div> */}

          <div className="p-d-flex">
            <div className="p-col-12 p-md-6">
              <div className="wb-owner-chart">
                <div className="p-d-flex p-ai-center">
                  <p>Most Used Items</p>
                </div>
                {mostUsedItems ? (
                  <Bar data={mostUsedItems} options={options} />
                ) : (
                  <p>No Data Found...</p>
                )}
              </div>
            </div>

            <div className="p-col-12 p-md-6">
              <div className="wb-owner-chart">
                <div className="p-d-flex p-ai-center">
                  <p>Major Reasons</p>
                </div>
                {majorReasons ? (
                  <HorizontalBar
                    data={majorReasons}
                    options={{
                      ...options,
                      scales: {
                        xAxes: [
                          {
                            stacked: true, // Stacks the data on the x-axis
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            stacked: true, // Stacks the data on the y-axis
                          },
                        ],
                      },
                    }}
                  />
                ) : (
                  <p>No Data Found...</p>
                )}
              </div>
            </div>
          </div>
          <div className="custom-large-wb-table wb-owner-chart p-my-2">
              <p>Returned</p>
              <hr  className="p-my-2"/>
              <DataTable
                className="main-table table-td"
                value={itemsReturnedList}
                editMode="row"
                dataKey="id"
                responsiveLayout="scroll"
                emptyMessage="No records found"
                columnResizeMode="fit"
                scrollable={true}
              >
                <Column
                  field="Index"
                  header="Sr.No"
                  body={actionBodyTemplateSrNo}
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Item"
                  header="Items"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Unit"
                  header="Unit"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="ReturnedFrom"
                  header="Returned From"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="ReturnedTo"
                  header="Returned To"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Quantity"
                  header="Qty"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="CreatedOn"
                  header="DateTime"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Reasons"
                  header="Reasons"
                  style={{ width: "20% !important" }}
                ></Column>
              </DataTable>
            </div>
            <div className="custom-large-wb-table wb-owner-chart p-my-2">
              <p>Refill</p>
              <hr  className="p-my-2"/>
              <DataTable
                className="main-table table-td"
                value={itemsRefillList}
                editMode="row"
                dataKey="id"
                responsiveLayout="scroll"
                emptyMessage="No records found"
                columnResizeMode="fit"
                scrollable={true}
              >
                <Column
                  field="Index"
                  header="Sr.No"
                  body={actionBodyTemplateSrNo}
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Item"
                  header="Item/Stock"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Unit"
                  header="Unit"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Godown"
                  header="Godown"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="IdealLimit"
                  header="Ideal Limit"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="LowLimit"
                  header="Low Limit"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="CurrentQuantity"
                  header="Current Qty"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="OrderQuantity"
                  header="Order Qty"
                  style={{ width: "20% !important" }}
                ></Column>
              </DataTable>
            </div>
        </div>
      </TabPanel>
      <TabPanel
        className="main-card equipment-dashboard"
        value={tabValue}
        index={1}
      >
        <div className="wayment-owner-dashboard p-mt-0">
          <div className="p-grid">
            {equipmentList?.length
              ? equipmentList?.map((item, idx) => {
                return (
                  <div className="p-col-6 p-md-4">
                    <div className="wb-counter-box">
                      <div className="p-mt-2">
                      <div className="p-d-flex p-ai-center">
                        <i className="pi pi-tags"></i>
                        <p>{item.Name}</p>
                        </div>
                        <p className="job-d-badge p-px-3 p-mt-2">{item.Count || 0}</p>
                      </div>

                      {/* <h2>{item.Count || 0}</h2> */}
                    </div>
                  </div>
                );
              })
              : null}
          </div>

          <div className="p-d-flex">
            <div className="p-col-12 p-md-6">
              <div className="wb-owner-chart">
                <div className="p-d-flex p-ai-center">
                  <p>Repairing Costs ( in ₹)</p>
                </div>
                {repairingCost ? (
                  <Bar data={repairingCost} options={options} />
                ) : (
                  <p>No Data Found...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      {/* <div className="wayment-container">
        <div className="heading-area">
          <h2> Dashboard </h2>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
