/*eslint-disable*/
import React from "react";
import APIConstant from "../../utils/constant";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import { Link } from "react-router-dom";
import "primeicons/primeicons.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
// import Button from '@material-ui/core/Button';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import api from "../../utils/api";
import { apiBase } from "../../config";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { CommonConfig } from "../../CommonConfig.js";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import cogoToast from "cogo-toast";
import SimpleBackdrop from "../../utils/general";
import { individual_profile_image_link } from "../../config";
import User from "../../assets/images/user.jpg";
import SearchIcon from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import { InputSwitch } from "primereact/inputswitch";
import groupUser from "../../assets/equator-img/group.png";
import { FALSE } from "sass";
import yes from "../../assets/images/yesmarkblank.png";
import pending from "../../assets/images/pendingicon.png";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tooltip } from "primereact/tooltip";
import Axios from "axios";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox, InputLabel } from "@material-ui/core";
import Loader from "components/Shared/Loader/Loader";
import moment from "moment";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(1),
    bottom: theme.spacing(6),
  },
  vesselheading: {
    backgroundColor: "#499AFF",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "25px",
    padding: "5px",
  },
  vesselheading_btn: {
    backgroundColor: "#499AFF",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "20px",
    padding: "5px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SortableItem = SortableElement(({ value, item, onColumnToggle }) => (
  <li
    className={
      item.isChecked == 1
        ? "p-multiselect-item p-highlight"
        : "p-multiselect-item"
    }
  >
    <div className="p-checkbox p-component">
      <Checkbox
        className="p-checkbox-box p-highlight"
        style={{
          borderColor: "#007ad9",
          background: "transparent",
          color: "#ffffff",
        }}
        checked={item.isChecked == 1}
        onChange={(event) => onColumnToggle(event, item)}
      />
    </div>
    <span>{value}</span>
  </li>
));

const SortableList = SortableContainer(({ items, onColumnToggle }) => {
  return (
    <ul className="p-multiselect-items p-component">
      {items.map((value, index) => (
        <SortableItem
          key={`item-drag-${index}`}
          index={index}
          value={value.header}
          item={value}
          onColumnToggle={onColumnToggle}
        />
      ))}
    </ul>
  );
});

class Network extends React.Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        field: "id",
        header: "Sr.No",
        sortable: true,
        filterMatchMode: "contains",
        id: 0,
      },
      {
        field: "Name",
        body: this.actionTemplate1.bind(this),
        header: "My Network",
        sortable: true,
        filterMatchMode: "contains",
        id: 1,
      },
      { field: "Email", header: "Email", filterMatchMode: "contains", id: 2 },
      {
        field: "PhoneNumber",
        header: "PhoneNumber",
        filterMatchMode: "contains",
        id: 3,
      },
      {
        field: "UpdatedOn",
        header: "Date/Time",
        sortable: true,
        filterMatchMode: "contains",
        id: 4,
      },
      {
        field: "ToggleStatus",
        body: this.actionTemplate2.bind(this),
        header: "Status",
        sortable: false,
        filterMatchMode: "contains",
        id: 5,
      },
    ];
    let columnsNotValid = [
      {
        field: "id",
        header: "Sr.No",
        sortable: true,
        filterMatchMode: "contains",
        id: 0,
      },
      {
        field: "Name",
        body: this.actionTemplate1.bind(this),
        header: "Name",
        sortable: true,
        filterMatchMode: "contains",
        id: 1,
      },
      { field: "Email", header: "Email", filterMatchMode: "contains", id: 2 },
      {
        field: "PhoneNumber",
        header: "PhoneNumber",
        filterMatchMode: "contains",
        id: 3,
      },
      {
        field: "CompanyName",
        header: "Company",
        filterMatchMode: "contains",
        id: 4,
      },
      {
        field: "UserType",
        header: "User Type",
        filterMatchMode: "contains",
        id: 5,
      },
      {
        field: "Address",
        header: "Address",
        filterMatchMode: "contains",
        id: 6,
      },
      {
        field: "UpdatedOn",
        header: "Date/Time",
        sortable: true,
        filterMatchMode: "contains",
        id: 7,
      },
      {
        body: this.actionNonNetwork.bind(this),
        header: "Status",
        sortable: false,
        filterMatchMode: "contains",
        id: 8,
      },
    ];
    let columns1 = [
      {
        field: "id",
        header: "Sr.No",
        sortable: true,
        filterMatchMode: "contains",
        id: 0,
      },
      {
        field: "Name",
        body: this.actionTemplate1.bind(this),
        header: "Loading Person",
        sortable: true,
        filterMatchMode: "contains",
        id: 1,
      },
      {
        field: "UpdatedOn",
        header: "Date/Time",
        sortable: true,
        filterMatchMode: "contains",
        id: 2,
      },
    ];

    this.state = {
      items: [],
      items1: [],
      // colOptions: [],
      PDFFile: [],
      pdf_url: "",
      fileName: "",
      NetorkUserFileModel: false,
      openNetworkUserDeleteModal: false,
      ImporterFirstName: "",
      ImporterFirstNameErr: false,
      ImporterFirstNameErrText: "",
      HandlerImporterMasterID: "",
      openAddImporter: false,
      ImporterLastName: "",
      ID: "",
      ImporterLastNameErr: false,
      ImporterLastNameErrText: "",

      CompanyName: "",
      CompanyNameErr: false,
      CompanyNameErrText: "",
      openUserNetwork: false,
      UserInfo: [],
      EmailId: "",
      EmailIdErr: false,
      EmailIdErrText: "",

      PhoneNo: "",
      PhoneNoErr: false,
      PhoneNoErrText: "",

      address: "",
      addressErr: false,
      addressErrText: "",

      fillData: false,
      cols: columns,
      colsNon: columnsNotValid,
      arr: [],
      rowData: "",
      NonVerifiedSelectedPage: 0,
      selectedPage: 0,
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      NonVerifiedRowsPerPageOptions:
        CommonConfig.dataTableConfig.rowsPerPageOptions,
      str: "",
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      NonVerifiedRowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      NonVerifiedGlobalFilter: null,
      globalFilter: null,
      isEdit: false,
      NonVerifiedStr: "",
      cols1: columns1,
      arr1: [],
      rowData1: "",
      selectedPage1: 0,
      rowsPerPageOptions1: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPageOptionsNonUser:
        CommonConfig.dataTableConfig.rowsPerPageOptions,
      str1: "",
      rowsPerPage1: CommonConfig.dataTableConfig.rowsPerPage,
      globalFilter1: null,
      isEdit1: false,
      NonVerifiedArray: [],
      value: 0,
      setValue: 0,
      UserId: "",
      token: "",
      soldVesselList: [],
      serviceList: [],
      getMyConnectList: [],
      getFirstLastNamelist: [],
      groupUserList: [],
      networkGroup: [],
      openDialogue: false,
      firstName: "",
      secondName: "",
      emailId: "",
      contactNum: "",
      profilePic: "",
      checkuser: 0,
      tags: [],
      tags1: "",
      invite: 0,
      formRequestType: "verified",
      group_name: "",
      uniqueuser: "",
      uniqueuser1: "",
      NetworkGroupList: [],
      NetworkList: "",
      textOperations: [],
      NetworkListErr: false,
      NetworkListhelperText: "",
      GroupId: "",
      open1: false,
      networkitems: [],
      clearText: false,
      name: "",
      type: "",
      opendelete: false,
      clearvisible: 0,
      getdetailedgrouplist: [],
      openUserDialogue: false,
      User_Profile: "",
      User_Name: "",
      User_Role: "",
      User_Company: "",
      User_EmailPhone: "",
      blReleaseReminder: 3,
      blReleaseReminderErr: false,
      blReleaseReminderErrText: "",
      GetNonVerifideUserTypeList: [],
      userType: [],
      userTypeErr: false,
      userTypeErrText: "",
      isUserAttachment: false,
      UserAttachmentTypeList: [],
      UserDocType: "",
      AttachmentUserArray: [],
      UserAttachmentGstFile: [],
      UserAttachmentPanFile: [],
      UserAttachmentIecFile: [],
      UserAttachmentDrawbackDetailsFile: [],
      UserAttachmentADCodeFile: [],
      UserAttachmentStuffingPermissionFile: [],
      UserAttachmentKycFormFile: [],
      GSTNo: "",
      GSTNoErr: false,
      GSTNoErrText: "",
      isGSTVerified: false,
      isPANVerified: false,
      PANNo: "",
      PANNoErr: false,
      PANNoErrText: "",

      IEC: "",
      IECErr: false,
      IECErrText: "",

      AdCode: "",
      AdCodeErr: false,
      AdCodeErrText: "",

      DrawBack: "",
      DrawBackErr: false,
      DrawBackErrText: "",
      isgstVerified: false,

      MasterLabelName: "",
      MasterLabelNameErr: false,
      MasterLabelNameErrText: "",
      AddMasterModal: false,
      MasterID: "",
      loading: false,
      userPageAccess: [],
      dpaDomainUserList: [],
    };
    this.colOptions = [];
    for (let col of columns) {
      this.colOptions.push({ label: col.header, value: col });
      this.state.items.push(col);
    }
    this.colNonOptions = [];
    for (let col of columnsNotValid) {
      this.colNonOptions.push({ label: col.header, value: col });
    }
    this.colOptions1 = [];
    for (let col of columns1) {
      this.colOptions1.push({ label: col.header, value: col });
      this.state.items1.push(col);
    }
    this.onNonColumnToggle = this.onNonColumnToggle.bind(this);
    this.onColumnToggle = this.onColumnToggle.bind(this);
    this.onColumnToggle1 = this.onColumnToggle1.bind(this);
  }

  componentDidMount() {
    let token = localStorage.getItem("Token");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      let UserId = localStorage.getItem("Infodata");
      let userrole = JSON.parse(localStorage.getItem("Userdata"));
      let useraccess = JSON.parse(localStorage.getItem("UserAccessData"));
      let userPageAccess = localStorage.getItem("PageAccess");
      this.setState({
        UserId: UserId,
        userPageAccess: userPageAccess ? userPageAccess : [],
      });
      this.getPhoneEmail();
      this.getNetworkPhoneEmail(UserId, token);
      this.getMyNetwork(UserId, token);
      this.getLoadingPerson(UserId, token);
      this.getMyNetworkGroup(UserId, token);
      this.getNotVerifedNetwork(UserId, token);
      this.GetNonVerifideUserTypeList(UserId);
      this.GetNetworkDataOrder(UserId, "Purchase");
      // }
      this.getUserAttachmentTypeList(UserId);
      // this.getDpaDomailUserList();
    } else {
      this.props.history.push("/login");
    }
    // }
    // else if (lockstatus == null) {
    //     this.props.history.push('/login')
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }

  GetNonVerifideUserTypeList = async (UserId) => {
    let params = {
      ID: UserId,
      Type: "NETWORKUSERTYPE",
    };
    api.post(APIConstant.path.GetMasterListByType, params).then(async (res) => {
      let response = await res;
      if (response.Success === 1) {
        this.setState({
          GetNonVerifideUserTypeList: response.Data.map((data) => ({
            ...data,
            label: data.name,
            value: data._id,
          })),
        });
      }
    });
  };

  getMyNetworkGroup = (UserId, token) => {
    // this.setState({ loading: true })
    var params = {
      UserId: UserId,
      Type: "group",
    };
    if (params !== null) {
      api
        .post(APIConstant.path.GetMyNetwork, params)
        .then((res) => {
          if (res.Success === 1) {
            // this.setState({ loading: false })

            // var getGroupList = [];
            for (let i = 0; i < res.Info.Network.length; i++) {
              var params = {
                UserId: res.Info.Network[i].UserId,
                GroupId: res.Info.Network[i].NetworkGroupId,
              };
              if (params !== null) {
                api
                  .post(APIConstant.path.GetDetailedGroupList, params)
                  .then((detail) => {
                    if (res.Success === 1) {
                      var member = {
                        Member: detail.Info.Network,
                      };
                      res.Info.Network[i] = {
                        ...res.Info.Network[i],
                        ...member,
                      };

                      // this.setState({ opencollapse: index, getdetailedgrouplist: res.Info.Network });
                    }
                  })
                  .catch((err) => {
                    cogoToast.error("some thing Bad!");
                  });
              } else {
                // this.setState({ loading:false});
                cogoToast.error("Empty data");
              }
              // this.handleCollapse(res.Info.Network[i], i, "down")
            }
            this.setState({ NetworkGroupList: res.Info.Network });
            // this.setState({ GroupList: getGroupList });
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          }
        })
        .catch((err) => { });
    } else {
      alert("Id is Not Selected");
    }
  };

  getPhoneEmail() {
    api
      .get(APIConstant.path.GetPhoneEmail)
      .then((res) => {
        if (res.Success === 1) {
          var getGroupUserList = [];
          for (let i = 0; i < res.Data.length; i++) {
            getGroupUserList.push({
              label: res.Data[i]._id,
              value: res.Data[i].Email,
            });
            getGroupUserList.push({
              label: res.Data[i]._id,
              value: res.Data[i].PhoneNumber,
            });
          }
          this.setState({ groupUserList: getGroupUserList });
        }
      })
      .catch((err) => { });
  }

  getNetworkPhoneEmail(UserId) {
    var params = {
      UserId: UserId,
    };

    api
      .post(APIConstant.path.GetNetworkPhoneEmail, params)
      .then((res) => {
        if (res.Success === 1) {
          var getGroupUserList = [];
          var getGroupUserList1 = [];
          for (let i = 0; i < res.Info.List.length; i++) {
            getGroupUserList.push({
              label:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?._id
                  : res.Info.List[i].AcceptedByUser?._id,
              email:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?.Email
                  : res.Info.List[i].AcceptedByUser?.Email,
              phone:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?.PhoneNumber
                  : res.Info.List[i].AcceptedByUser?.PhoneNumber,
              name:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?.Name
                  : res.Info.List[i].AcceptedByUser?.Name,
            });
            getGroupUserList1.push({
              label:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?._id
                  : res.Info.List[i].AcceptedByUser?._id,
              value:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?.Email
                  : res.Info.List[i].AcceptedByUser?.Email,
            });
            getGroupUserList1.push({
              label:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?._id
                  : res.Info.List[i].AcceptedByUser?._id,
              value:
                this.state.UserId === res.Info.List[i].AcceptedByUser?._id
                  ? res.Info.List[i].AddedByUser?.PhoneNumber
                  : res.Info.List[i].AcceptedByUser?.PhoneNumber,
            });
          }
          this.setState({
            networkGroup: getGroupUserList,
            networkGroup1: getGroupUserList1,
          });
        } else if (res.Success == -1) {
          localStorage.clear();
          const { history } = this.props;
          history.push("/login");
        }
      })
      .catch((err) => { });
  }

  toggle = (rowData) => {
    try {
      this.setState({
        openDialogue: true,
        firstName: rowData.Name,
        secondName: rowData.LastName,
        emailId: rowData.Email,
        contactNum: rowData.PhoneNumber,
        profilePic: rowData.ProfilePic,
      });
    } catch (error) { }
  };

  handleCollapse = (item, index, type) => {
    if (type == "down") {
      var params = {
        UserId: item.fkUserId,
        GroupId: item.NetworkGroupId,
      };
      if (params !== null) {
        api
          .post(APIConstant.path.GetDetailedGroupList, params)
          .then((res) => {
            if (res.Success === 1) {
              this.setState({
                opencollapse: index,
                getdetailedgrouplist: res.Info.Network,
              });
            }
          })
          .catch((err) => {
            cogoToast.error("some thing Bad!");
          });
      } else {
        // this.setState({ loading:false});
        cogoToast.error("Empty data");
      }
    } else {
      this.setState({ opencollapse: null });
    }
  };

  confirm = (item, type) => {
    if (type == "cancel") {
      var params = {
        UserId: this.state.UserId,
        NetworkId: this.state.networkitems.NetworkId,
        Type: "reject",
      };
      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(APIConstant.path.AcceptRejectNetwork, params)
          .then((res) => {
            if (res.Success === 1) {
              cogoToast.success(res.Message);
              this.setState({
                loading: false,
                networkitems: [],
                uniqueuser: "",
                checkuser: 0,
                openUserDialogue: false,
              });
              this.getNetworkRequest(this.state.UserId, this.state.token);
              //    window.location.reload();
            } else if (res.Success == -1) {
              localStorage.clear();
              const { history } = this.props;
              history.push("/login");
            }
          })
          .catch((err) => { });
      } else {
        alert("in Else");
      }
    } else {
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: 1,
        },
      });
    }
  };

  delete = async (Id) => {
    var params = {
      UserID: this.state.UserId,
      ID: Id,
    };
    await api
      .post(APIConstant.path.DeleteNonUserNetwork, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          cogoToast.success(res.Message);
          this.setState({ openNetworkUserDeleteModal: false, ID: "" });
          await this.getNotVerifedNetwork(this.state.UserId, this.state.token);
        }
      })
      .catch((err) => { });
  };

  handleClose = (type) => {
    if (type == "group") {
      this.setState({
        open: false,
        group_name: "",
        groupnameErr: false,
        groupnamehelperText: "",
        tags: [],
        emailphoneErr: false,
        emailphonehelperText: "",
      });
    }
    if (type == "NetorkUserFile") {
      this.setState({ NetorkUserFileModel: false });
    } else if (type == "member") {
      this.setState({
        open1: false,
        getFirstLastNamelist: [],
        uniqueuser1: "",
        emailphoneErr: false,
        emailphonehelperText: "",
      });
    } else if (type == "loading") {
      this.setState({
        openloading: false,
        NetworkList: "",
        NetworkListErr: false,
        NetworkListhelperText: "",
      });
    } else if (type == "delete") {
      this.setState({ opendelete: false });
    } else if (type == "openUserDialogue") {
      this.setState({
        openUserDialogue: false,
        User_Profile: "",
        User_Name: "N/A",
        User_Role: "N/A",
        User_CompanyName: "N/A",
        uniqueuser: "",
        fillData: false,
      });
    } else if (type == "NetworkUserDelete") {
      this.setState({ openNetworkUserDeleteModal: false, ID: null });
    } else if (type == "NetworkBlRealeaseReminder") {
      this.setState({
        openNetworkBlRealeaseReminder: false,
        HandlerImporterMasterID: "",
        blReleaseReminder: 3,
        blReleaseReminderErr: false,
        blReleaseReminderErrText: "",
      });
      this.getNotVerifedNetwork();
    } else if (type == "openNoNetworkManual") {
      this.noAddToNetwork();
    } else if (type == "openAddImporter") {
      this.setState({
        openAddImporter: false,
        EmailId: "",
        PhoneNo: "",
        CompanyName: "",
        ImporterLastName: "",
        ImporterFirstName: "",
        userType: [],
        userTypeErr: false,
        userTypeErrText: "",
        isUserAttachment: false,
        UserAttachmentTypeList: [],
        UserDocType: "",
        AttachmentUserArray: [],
        UserAttachmentGstFile: [],
        UserAttachmentPanFile: [],
        UserAttachmentIecFile: [],
        UserAttachmentDrawbackDetailsFile: [],
        UserAttachmentADCodeFile: [],
        UserAttachmentStuffingPermissionFile: [],
        UserAttachmentKycFormFile: [],
        GSTNo: "",
        GSTNoErr: false,
        GSTNoErrText: "",
        isGSTVerified: false,
        isPANVerified: false,
        PANNo: "",
        PANNoErr: false,
        PANNoErrText: "",
        IEC: "",
        IECErr: false,
        IECErrText: "",
        AdCode: "",
        AdCodeErr: false,
        AdCodeErrText: "",
        DrawBack: "",
        DrawBackErr: false,
        DrawBackErrText: "",
        HandlerImporterMasterID: "",
        address: "",
      });
      this.getUserAttachmentTypeList(this.state.UserId);
    } else if (type == "AddMaster") {
      this.setState({
        AddMasterModal: false,
        MasterLabelName: "",
        MasterLabelNameErr: false,
        MasterLabelNameErrText: "",
        MasterID: "",
      });
    } else {
      this.setState({ openDialogue: false });
    }
  };

  handleClickOpen = (type) => {
    if (type == "loading") {
      this.setState({ openloading: true });
    } else {
      this.setState({ open: true, type: "add" });
    }
  };

  selectType(e, rowData, type) {
    e.preventDefault();
    if (type === "toggle") {
      var PIndex = this.state.textOperations.findIndex(
        (x) => x.id === rowData.id[0]
      );
      var NewTextDetails = this.state.textOperations;
      var arr = this.state.arr;
      NewTextDetails[PIndex]["ToggleStatus"] =
        arr[PIndex]["ToggleStatus"] == "Active" ? false : true;
      if (NewTextDetails[PIndex]["ToggleStatus"]) {
        arr[PIndex]["ToggleStatus"] = "Active";
      } else {
        arr[PIndex]["ToggleStatus"] = "Inactive";
      }
      this.setState({ textOperations: NewTextDetails, arr: arr });
      var params = {
        UserId: this.state.UserId,
        NetworkId: rowData.NetworkId,
        ToggleStatus:
          NewTextDetails[PIndex]["ToggleStatus"] == true
            ? "Active"
            : "Inactive",
        Type: "network",
      };
      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(APIConstant.path.UpdateNetworkToggleStatus, params)
          .then((res) => {
            if (res.Success === 1) {
              this.setState({ loading: false });
            }
          });
      }
    }
  }

  modelOpen = (item, type) => {
    if (type == "NetworkUserDelete") {
      this.setState({ openNetworkUserDeleteModal: true, ID: item });
    }
    if (type == "NetworkBlRealeaseReminder") {
      this.setState({
        openNetworkBlRealeaseReminder: true,
        HandlerImporterMasterID: item.HandlerImporterMasterID,
        blReleaseReminder: item.BLReleaseReminder,
      });
    }
    if (type == "NetorkUserFile") {
      this.setState({ NetorkUserFileModel: true });
      this.getNonUserByID(item);
    }
  };

  getNonUserByID = (ID, type) => {
    let params = {
      ID: ID,
    };
    api.post(APIConstant.path.getNonUserByID, params).then((res) => {
      if (res.Success == 1) {
        if (type == "edituser") {
          this.setState({ openAddImporter: true });
        }
        this.setState({
          ImporterFirstName: res.Data.FirstName,
          userType: res.Data.UserType?.length ? res.Data.UserType : [],
          ImporterLastName: res.Data.LastName,
          CompanyName: res.Data.CompanyName,
          PhoneNo: res.Data.PhoneNumber,
          EmailId: res.Data.Email,
          UserId: this.state.UserId,
          HandlerImporterMasterID: res.Data.HandlerImporterMasterID,
          PDFFile: res.Data.Files,
          address: res.Data?.Address,
        });
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  dowloadPdf(FileName) {
    const PDFFile = FileName;
    var pdf_file_link = this.state.pdf_url + PDFFile;
    Axios({
      url: pdf_file_link,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", PDFFile); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
  noAddToNetwork = () => {
    let params = {
      FirstName: this.state.ImporterFirstName,
      LastName: this.state.ImporterLastName,
      CompanyName: this.state.CompanyName,
      PhoneNumber: this.state.PhoneNo,
      Email: this.state.EmailId,
      UserId: this.state.UserId,
      CHAID: this.state.UserId,
      Address: this.state.address,
      HandlerImporterMasterID: this.state.HandlerImporterMasterID,
      Type: this.state.userType.length <= 0 ? ["Stock"] : this.state.userType,
    };
    api.post(APIConstant.path.noAddToNetwork, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          openUserNetwork: false,
          tags: "",
          UserInfo: [],
        });
        this.getNotVerifedNetwork(this.state.UserId);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
    this.handleClose("openAddImporter");
  };

  resentNetworkRequest = (ID) => {
    let params = {
      ID: ID,
    };
    api.post(APIConstant.path.GetResentNetworkRequest, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          openUserNetwork: true,
          loading: false,
          tags: res.Info.User?.UserID,
          UserInfo: res.Info.User,
        });
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  actionNonNetwork(rowData) {
    return (
      <div class="bill-action icon-only-btn">
        {rowData.Type == 1 ? (
          <div
            onClick={(e) =>
              this.resentNetworkRequest(rowData.HandlerImporterMasterID)
            }
          >
            {" "}
            <i className="icon-add-user mainDarkBlue"></i>
          </div>
        ) : (
          <>
            {this.state.userPageAccess.includes("Edit Manual Network") ? (
              <div
                onClick={(e) =>
                  this.getNonUserByID(
                    rowData.HandlerImporterMasterID,
                    "edituser"
                  )
                }
              >
                {" "}
                <i class="icon-editImage"></i>{" "}
              </div>
            ) : null}
            {this.state.userPageAccess.includes("Delete Manual Network") ? (
              <div
                onClick={(e) =>
                  this.modelOpen(
                    rowData.HandlerImporterMasterID,
                    "NetworkUserDelete"
                  )
                }
              >
                {" "}
                <i class="icon-delete"></i>{" "}
              </div>
            ) : null}
            {rowData.UserType.split(",")
              .map((type) => type.trim())
              .includes("Line") ? (
              <>
                <div
                  onClick={(e) =>
                    this.modelOpen(rowData, "NetworkBlRealeaseReminder")
                  }
                >
                  {" "}
                  <i class="icon-add"></i>{" "}
                </div>
              </>
            ) : (
              <></>
            )}
            {rowData.TotalFileCount != "0" ? (
              <>
                <div
                  onClick={(e) =>
                    this.modelOpen(
                      rowData.HandlerImporterMasterID,
                      "NetorkUserFile"
                    )
                  }
                >
                  {" "}
                  <i class="fas fa-file"></i>{" "}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  }

  actionTemplate2(rowData, column) {
    var PIndex = this.state.textOperations.findIndex(
      (x) => x.id === rowData.id[0]
    );
    var NewTextDetails = this.state.textOperations;
    if (this.state.userPageAccess.includes("Cancel/Inactive")) {
      if (NewTextDetails.length !== 0) {
        if (
          rowData.ToggleStatus == "Active" ||
          NewTextDetails[PIndex]["IsAllOtherOperator"] === true
        ) {
          return (
            <div>
              <FormControlLabel
                control={
                  <InputSwitch
                    checked={NewTextDetails[PIndex]["ToggleStatus"]}
                    onClick={(e) => this.selectType(e, rowData, "toggle")}
                    name="toggle"
                    color="primary"
                  />
                }
              />
            </div>
          );
        } else {
          return (
            <div>
              <FormControlLabel
                style={{ color: "red" }}
                control={
                  <InputSwitch
                    checked={NewTextDetails[PIndex]["ToggleStatus"]}
                    onClick={(e) => this.selectType(e, rowData, "toggle")}
                    name="toggle"
                    color="primary"
                  />
                }
                labelPlacement="start"
              />
            </div>
          );
        }
      } else {
        if (rowData.ToggleStatus == "Active") {
          return (
            <div>
              {/* <div style={{ color: "green"}}>
                            Active
                        </div> */}
              <FormControlLabel
                style={{ color: "green" }}
                control={
                  <InputSwitch
                    checked={rowData.ToggleStatus}
                    onClick={(e) => this.selectType(e, rowData, "toggle")}
                    name="toggle"
                    color="primary"
                  />
                }
                // label="Active"
                labelPlacement="start"
              />
            </div>
          );
        } else {
          return (
            <div>
              {/* <div style={{ color: "red" }}>
                            Inactive
                        </div> */}
              <FormControlLabel
                style={{ color: "red" }}
                control={
                  <InputSwitch
                    checked={rowData.ToggleStatus}
                    onClick={(e) => this.selectType(e, rowData, "toggle")}
                    name="toggle"
                    color="primary"
                  />
                }
              // label="Inactive"
              // labelPlacement="start"
              />
            </div>
          );
        }
      }
    } else {
      if (NewTextDetails.length !== 0) {
        if (
          rowData.ToggleStatus == "Active" ||
          NewTextDetails[PIndex]["IsAllOtherOperator"] === true
        ) {
          return <div style={{ width: "150px", color: "green" }}>Active</div>;
        } else {
          return <div style={{ width: "150px", color: "red" }}>Inactive</div>;
        }
      } else {
        if (rowData.ToggleStatus == "Active") {
          return <div style={{ width: "150px", color: "green" }}>Active</div>;
        } else {
          return <div style={{ width: "150px", color: "red" }}>Inactive</div>;
        }
      }
    }
  }

  actionTemplate1(rowData, column) {
    return (
      <div>
        <Link to="#" onClick={() => this.toggle(rowData)}>
          <span>{rowData.Name}</span>
        </Link>
      </div>
    );
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        {/* <Link onClick={() => this.toggle(rowData)}><span>{rowData.Name}</span></Link> */}
      </div>
    );
  }

  getPageString = (e) => {
    let firstPage = e.first + 1;
    let l = e.rows * (e.page + 1);
    let lastPage = l < this.state.arr.length ? l : this.state.arr.length;
    var str =
      firstPage +
      " to " +
      lastPage +
      " out of " +
      this.state.arr.length +
      " records";
    this.setState({ selectedPage: e.first, str: str, rowsPerPage: e.rows });
  };

  getPageNonVerifiedString = (e) => {
    let firstPage = e.first + 1;
    let l = e.rows * (e.page + 1);
    let lastPage =
      l < this.state.NonVerifiedArray.length
        ? l
        : this.state.NonVerifiedArray.length;
    var str =
      firstPage +
      " to " +
      lastPage +
      " out of " +
      this.state.NonVerifiedArray.length +
      " records";
    this.setState({
      NonVerifiedSelectedPage: e.first,
      NonVerifiedStr: str,
      NonVerifiedRowsPerPage: e.rows,
    });
  };

  getPageString1 = (e) => {
    let firstPage = e.first + 1;
    let l = e.rows * (e.page + 1);
    let lastPage = l < this.state.arr1.length ? l : this.state.arr1.length;
    var str =
      firstPage +
      " to " +
      lastPage +
      " out of " +
      this.state.arr1.length +
      " records";
    this.setState({ selectedPage1: e.first, str1: str, rowsPerPage1: e.rows });
  };

  onColumnToggle1(event) {
    let valueInOrder = event.value.sort((x, y) => {
      return x.id - y.id;
    });
    this.setState({ cols1: valueInOrder });
  }

  onTagsChange = (e, values, type) => {
    if (type == "inputchange") {
      if (e.target.value) {
        this.setState({ name: e.target.value });
      } else {
        this.setState({ name: "" });
      }
    } else {
      // var emp = [];
      // for(let p = 0; p < values.length; p++) {
      //     emp.push(values[p].label)
      //     console.log ("emp....",emp)
      // }
      // for(let p in emp) {
      //     console.log (emp[p])
      // }
      // console.log("whatvalue...........",values.label)
      // ;
      // this.setState({ [e.target.name]: e.target.value });

      if (Array.isArray(values) && values.length === 0) {
        this.setState({
          tags: values,
          emailphoneErr: true,
          emailphonehelperText: "Please enter Email/Phone",
        });
      } else {
        this.setState({
          tags: values,
          emailphoneErr: false,
          emailphonehelperText: "",
        });
      }
    }
    // if (values.length > 50) {
    //     alert('You may only select 50');
    // } else {
    //     var list = [];
    //     for (let j = 0; j < values.length; j++) {
    //         list.push({ tag: values[j].label })
    //     }
    //     this.setState({ tags: list },
    //         function () {
    //         });
    // }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChange_value1 = (e) => {
    // ;
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "group_name") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          groupnameErr: true,
          groupnamehelperText: "Please enter group network name",
        });
      } else {
        this.setState({
          group_name: e.target.value,
          groupnameErr: false,
          groupnamehelperText: "",
        });
      }
    }
  };

  onNonColumnToggle(event) {
    let valueInOrder = event.value.sort((x, y) => {
      return x.id - y.id;
    });
    this.setState({ colsNon: valueInOrder });
  }

  onSelectTag = (event, type) => {
    if (type === "member") {
      this.setState({ uniqueuser1: event.target.value });
    } else if (type == "NetworkList") {
      this.setState({ NetworkList: event });
      if (event !== "" && event !== null) {
        this.setState({ NetworkListErr: false, NetworkListhelperText: "" });
      } else {
        this.setState({
          NetworkListErr: true,
          NetworkListhelperText: "Please select email address",
        });
      }
    } else {
      if (event.target.value == "") {
        this.setState({ clearvisible: 0 });
      } else {
        this.setState({ clearvisible: 1 });
      }
      this.setState({ uniqueuser: event.target.value });
    }
  };

  validate(e, type) {
    let formIsValid = true;

    if (type === "member") {
      if (this.state.uniqueuser1 === "" || this.state.uniqueuser1 === null) {
        this.setState({
          emailphoneErr: true,
          emailphonehelperText: "Please enter Email/Mobile Number",
        });
      } else {
        this.setState({ emailphoneErr: false, emailphonehelperText: "" });
      }
    } else if (type === "loading") {
      if (this.state.NetworkList === "" || this.state.NetworkList === null) {
        this.setState({
          NetworkListErr: true,
          NetworkListhelperText: "Please select Email/Mobile Number",
        });
        formIsValid = false;
      } else {
        this.setState({ NetworkListErr: false, NetworkListhelperText: "" });
      }
    } else {
      if (this.state.uniqueuser === "" || this.state.uniqueuser === null) {
        this.setState({
          emailphoneErr: true,
          emailphonehelperText: "Please enter Email/Mobile Number",
        });
      } else {
        this.setState({ emailphoneErr: false, emailphonehelperText: "" });
      }
    }

    return formIsValid;
  }

  AddtoMyNetwork = (type) => {
    if (type === "new") {
      if (this.validate("mynetwork")) {
        if (this.state.tags !== "") {
          this.setState({ loading: true });
          var params = {
            UserId: this.state.UserId,
            SentToUser: this.state.tags,
            Type: "mynetwork",
          };

          api
            .post(APIConstant.path.CreateNetwork, params)
            .then((res) => {
              if (res.Success === 1) {
                this.setState({
                  loading: false,
                  openUserDialogue: false,
                  openUserNetwork: false,
                  UserInfo: [],
                });
                cogoToast.success("Request sent successfully");
              } else if (res.Success == -1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
              } else {
                cogoToast.error("Something happening bad");
              }
            })
            .catch((err) => { });
        }
      }
    } else if (type === "group") {
      if (this.state.group_name !== "" || this.state.tags.length > 0) {
        if (this.state.group_name !== "") {
          if (this.state.group_name.trim() == this.state.group_name) {
            if (this.state.tags.length > 0) {
              var emp = [];
              for (let p = 0; p < this.state.tags.length; p++) {
                emp.push(this.state.tags[p].label);
              }
              this.setState({ loading: true });
              var params = {
                users: emp,
                UserId: this.state.UserId,
                GroupName: this.state.group_name,
                Type: "group",
              };
              api
                .post(APIConstant.path.CreateNetwork, params)
                .then((res) => {
                  if (res.Success == 1) {
                    cogoToast.success("Group created successfully");
                    this.setState({
                      loading: false,
                      group_name: "",
                      groupnameErr: false,
                      groupnamehelperText: "",
                      open: false,
                      tags: [],
                      emailphoneErr: false,
                      emailphonehelperText: "",
                      type: "",
                    });
                    this.getMyNetworkGroup(this.state.UserId, this.state.token);
                    // this.props.history.push('/admin/request');
                  } else if (res.Success == -1) {
                    let data = localStorage.getItem("Infodata");

                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  } else {
                    cogoToast.error("Something happening bad");
                  }
                })
                .catch((err) => { });
            } else {
              this.setState({
                loading: false,
                emailphoneErr: true,
                emailphonehelperText: "Please select user",
              });
            }
          } else {
            this.setState({
              loading: false,
              groupnameErr: true,
              groupnamehelperText: "Please enter valid group name",
            });
          }
        } else {
          this.setState({
            loading: false,
            groupnameErr: true,
            groupnamehelperText: "Please enter network group name",
          });
        }
      } else {
        this.setState({
          loading: false,
          groupnameErr: true,
          groupnamehelperText: "Please enter network group name",
          emailphoneErr: true,
          emailphonehelperText: "Please select user",
        });
      }
    } else if (type === "editgroup") {
      if (this.state.group_name !== "" || this.state.tags.length > 0) {
        if (this.state.group_name !== "") {
          if (this.state.group_name.trim() == this.state.group_name) {
            if (this.state.tags.length > 0) {
              var emp = [];
              for (let p = 0; p < this.state.tags.length; p++) {
                emp.push(this.state.tags[p].label);
              }
              this.setState({ loading: true });
              var params = {
                users: emp,
                GroupId: this.state.GroupId,
                UserId: this.state.UserId,
                GroupName: this.state.group_name,
                Type: "editgroup",
              };

              api
                .post(APIConstant.path.CreateNetwork, params)
                .then((res) => {
                  if (res.Success == 1) {
                    cogoToast.success("Group updated successfully");
                    this.setState({
                      loading: false,
                      group_name: "",
                      groupnameErr: false,
                      groupnamehelperText: "",
                      open: false,
                      tags: [],
                      emailphoneErr: false,
                      emailphonehelperText: "",
                      type: "",
                    });
                    this.getMyNetworkGroup(this.state.UserId, this.state.token);
                    // this.props.history.push('/admin/request');
                  } else if (res.Success == -1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  } else {
                    cogoToast.error("Something happening bad");
                  }
                })
                .catch((err) => { });
            } else {
              this.setState({
                loading: false,
                emailphoneErr: true,
                emailphonehelperText: "Please select user",
              });
            }
          } else {
            this.setState({
              loading: false,
              groupnameErr: true,
              groupnamehelperText: "Please enter valid group name",
            });
          }
        } else {
          this.setState({
            loading: false,
            groupnameErr: true,
            groupnamehelperText: "Please enter network group name",
          });
        }
      } else {
        this.setState({
          loading: false,
          groupnameErr: true,
          groupnamehelperText: "Please enter network group name",
          emailphoneErr: true,
          emailphonehelperText: "Please select user",
        });
      }
    } else if (type === "delete") {
      this.setState({ loading: true });
      var params = {
        // users: emp,
        GroupId: this.state.GroupId,
        UserId: this.state.UserId,
        // GroupName: this.state.group_name,
        Type: "delete",
      };
      api
        .post(APIConstant.path.CreateNetwork, params)
        .then((res) => {
          if (res.Success == 1) {
            cogoToast.success("Group deleted successfully");
            this.setState({
              loading: false,
              group_name: "",
              groupnameErr: false,
              groupnamehelperText: "",
              opendelete: false,
              tags: [],
              emailphoneErr: false,
              emailphonehelperText: "",
              type: "",
            });
            this.getMyNetworkGroup(this.state.UserId, this.state.token);
            // this.props.history.push('/admin/request');
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          } else {
            cogoToast.error("Something happening bad");
          }
        })
        .catch((err) => { });
    } else {
      this.setState({ loading: true });
      var params = {
        UserId: this.state.UserId,
        email_phone: this.state.tags.toLowerCase(),
      };

      api
        .post(APIConstant.path.SendInvite, params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ loading: false, invite: 1 });
            cogoToast.success("Invitation link sent successfully");
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          } else {
            cogoToast.error("Something happening bad");
          }
        })
        .catch((err) => { });
    }
  };

  handleAdd = (e, item, type) => {
    if (type == "add") {
      this.setState({ open1: true, GroupId: item.NetworkGroupId });
    } else if (type == "delete") {
      this.setState({ opendelete: true, GroupId: item.NetworkGroupId });
    } else {
      var GroupMember = item.GroupMemberIds;
      var data = [];
      if (GroupMember.length) {
        var filter = this.state.networkGroup.filter((x) =>
          GroupMember.includes(x.label)
        );
        filter.map((value) => {
          data.push(value);
        });
      }

      this.setState({
        open: true,
        GroupId: item.NetworkGroupId,
        group_name: item.NetworkGroupName,
        tags: data,
        type: "edit",
      });
    }
  };

  AddGroupMember = (event) => {
    if (this.validate(event, "member")) {
      this.setState({ loading: true });
      if (this.state.tags1 !== "") {
        var params = {
          UserId: this.state.UserId,
          SentToUser: this.state.tags1,
          GroupId: this.state.GroupId,
        };

        api
          .post(APIConstant.path.AddNetworkGroupUsers, params)
          .then((res) => {
            if (res.Success === 1) {
              this.setState({
                loading: false,
                open1: false,
                uniqueuser1: "",
                emailphoneErr: false,
                emailphonehelperText: "",
              });
              window.location.reload();
              cogoToast.success("Member added successfully");

              this.getMyNetworkGroup(this.state.UserId);
              // this.props.history.push("/admin/chat");
            } else if (res.Success == 2) {
              this.setState({
                loading: false,
                emailphoneErr: true,
                emailphonehelperText: "Member already in group",
              });
            } else if (res.Success == -1) {
              localStorage.clear();
              const { history } = this.props;
              history.push("/login");
            } else {
              cogoToast.error("Something happening bad");
            }
          })
          .catch((err) => { });
      }
    } else {
      return;
    }
  };

  AddLoadingPerson = (event) => {
    event.preventDefault();
    if (this.validate(event, "loading")) {
      this.setState({ loading: true });

      var params = {
        UserId: this.state.UserId,
        LoadingPersonId: this.state.NetworkList.label,
      };
      api
        .post(APIConstant.path.AddLoadingPerson, params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ loading: false, openloading: false });
            cogoToast.success(res.Message);
            this.getLoadingPerson(this.state.UserId, this.state.token);
            // this.props.history.push("/admin/chat");
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          } else if (res.Success === 2) {
            this.setState({
              loading: false,
              NetworkListErr: true,
              NetworkListhelperText: "Loading person already added",
            });
            cogoToast.error("Loading person already added");
          } else {
            cogoToast.error("Something happening bad");
          }
        })
        .catch((err) => { });
    }
  };

  clearText = () => {
    this.setState({
      uniqueuser: "",
      clearText: true,
      clearvisible: 0,
      getFirstLastNamelist: [],
      fillData: false,
    });
  };

  SearchUser = (e, type) => {
    this.setState({ clearText: false, fillData: true });
    if (type === "member") {
      if (this.state.uniqueuser1 === "" || this.state.uniqueuser1 === null) {
        this.setState({
          emailphoneErr: true,
          emailphonehelperText: "Please enter Email/Mobile Number",
        });
      } else {
        this.setState({ emailphoneErr: false, emailphonehelperText: "" });
        var usrid = this.state.networkGroup1.filter(
          (x) => x.value === this.state.uniqueuser1
        );
        if (usrid.length > 0) {
          this.setState({ emptyfirst1: 0 });
          if (this.state.UserId !== usrid[0].label) {
            this.getFirstLastName(usrid[0].label);

            this.setState({ searchUserID1: usrid[0].label });
            this.setState({ tags1: usrid[0].label });
          } else {
            this.setState({ searchUserID1: usrid[0].label });
            this.setState({ tags1: "" });
          }
        } else {
          this.setState({ emptyfirst1: 1, tags1: this.state.uniqueuser1 });
        }
      }
    } else {
      if (this.state.uniqueuser === "" || this.state.uniqueuser === null) {
        this.setState({
          emailphoneErr: true,
          emailphonehelperText: "Please enter Email/Mobile Number",
        });
      } else {
        this.setState({
          invite: 0,
          emailphoneErr: false,
          emailphonehelperText: "",
        });
        var usrid = this.state.groupUserList.filter(
          (x) => x.value === this.state.uniqueuser
        );

        if (usrid.length > 0) {
          const data = {
            UserId: usrid[0].label,
            token: this.state.token,
          };
          api
            .post(APIConstant.path.GetUserDetailsById, data)
            .then((res) => {
              if (res.success) {
                let userDetail = res.data;
                this.setState({
                  User_Profile: userDetail.ProfilePic || "",
                  User_Name: userDetail.Name || "N/A",
                  User_Role: userDetail.UserRole || "N/A",
                  User_CompanyName: userDetail.CompanyName || "N/A",
                });
              } else if (res.success == -1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
              }
            })
            .catch((err) => { });
          this.setState({ emptyfirst: 0 });
          if (this.state.UserId !== usrid[0].label) {
            this.searchMyNetwork(usrid[0].label);
            this.getFirstLastName(usrid[0].label);
            this.setState({ searchUserID: usrid[0].label });
            this.setState({ tags: usrid[0].label });
          } else {
            this.setState({ searchUserID: usrid[0].label });
            this.setState({ tags: "" });
          }
        } else {
          this.setState({
            emptyfirst: 1,
            tags: this.state.uniqueuser,
            User_Profile: "",
            User_Name: "N/A",
            User_Role: "N/A",
            User_CompanyName: "N/A",
          });
        }

        this.setState({
          openUserDialogue: true,
          User_EmailPhone: this.state.uniqueuser,
        });
      }
    }
  };

  searchMyNetwork = (val) => {
    try {
      var params = {
        UserId: this.state.UserId,
        SentToUser: val,
      };
      api
        .post(APIConstant.path.CheckmyNetwork, params)
        .then((res) => {
          if (res.Success === 1) {
            if (
              res.Info[0].Status == "Pending" &&
              res.Info[0].AcceptedByUserId == this.state.UserId
            ) {
              this.setState({ checkuser: 1, networkitems: res.Info[0] });
            } else if (
              res.Info[0].Status == "Pending" &&
              res.Info[0].AddedByUserId == this.state.UserId
            ) {
              this.setState({ checkuser: 2 });
            } else if (
              res.Info[0].Status == "Accepted" &&
              (res.Info[0].AcceptedByUserId == this.state.UserId ||
                res.Info[0].AddedByUserId == this.state.UserId)
            ) {
              this.setState({ checkuser: 3 });
            }
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          } else {
            this.setState({ checkuser: 0 });
          }
        })
        .catch((err) => { });
    } catch (err) {
      console.log(err);
    }
  };

  getFirstLastName = (val) => {
    try {
      api
        .post(APIConstant.path.GetFirstLastName, [val])
        .then((res) => {
          if (res.Success === 1) {
            var getFirstLastNameList = [];

            res.Data.forEach((data) => {
              getFirstLastNameList.push({
                label: data._id,
                last_name: data.LastName || "",
                Name: data.Name,
              });
              this.setState({
                User_Name:
                  data.Name + (data.LastName ? " " + data.LastName : ""),
              });
            });

            this.setState({ getFirstLastNamelist: getFirstLastNameList });
          }
        })
        .catch((err) => { });
    } catch (err) {
      console.log(err);
    }
  };

  getMyNetwork(UserId, token) {
    var params = {
      UserId: UserId,
      Type: "mynetwork",
    };
    if (params !== null) {
      api
        .post(APIConstant.path.GetMyNetwork, params)
        .then((res) => {
          if (res.Success === 1) {
            var formattedData = [];
            var List = [];
            for (var i = 0; i < res.Info.Network.length; i++) {
              // var approvalRequired = (res.Info.Network[i].IsApprovalRequired.data[0] == 1) ? ("Yes") : ("No");
              var textOperation = this.state.textOperations;
              List.push({
                label:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.UserId
                    : res.Info.Network[i].AcceptedByUser?.UserId,
                email:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.Email
                    : res.Info.Network[i].AcceptedByUser?.Email,
                phone:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.PhoneNumber
                    : res.Info.Network[i].AcceptedByUser?.PhoneNumber,
              });
              formattedData.push({
                id: [i + 1],
                Name:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.Name
                    : res.Info.Network[i].AcceptedByUser?.Name,
                LastName:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.LastName
                    : res.Info.Network[i].AcceptedByUser?.LastName,
                Email:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.Email
                    : res.Info.Network[i].AcceptedByUser?.Email,
                PhoneNumber:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.PhoneNumber
                    : res.Info.Network[i].AcceptedByUser?.PhoneNumber,
                ProfilePic:
                  this.state.UserId === res.Info.Network[i].AcceptedBy
                    ? res.Info.Network[i].AddedByUser?.ProfilePic
                    : res.Info.Network[i].AcceptedByUser?.ProfilePic,
                UpdatedOn: res.Info.Network[i].Date
                  ? moment(res.Info.Network[i].Date).format("DD-MM-YYYY")
                  : "",
                NetworkId: res.Info.Network[i]._id,
                ToggleStatus: res.Info.Network[i].ToggleStatus,
              });
              var temp = {
                id: i + 1,
                // "NetworkList": "",
                IsAllOtherOperator: false,
                isDeleted: false,
                ToggleStatus:
                  res.Info.Network[i].ToggleStatus == "Active" ? true : false,
              };
              textOperation.push(temp);
            }
            let lastPage =
              this.state.rowsPerPage < formattedData.length
                ? this.state.rowsPerPage
                : formattedData.length;
            let str =
              "1 to " +
              lastPage +
              " out of " +
              formattedData.length +
              " records";
            this.setState({
              arr: formattedData,
              str: str,
              getMyConnectList: List,
            });
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          }
        })
        .catch((err) => {
          cogoToast.error("some thing Bad!");
        });
    } else {
      // this.setState({ loading:false});
      cogoToast.error("Empty data");
    }
  }

  getNotVerifedNetwork(UserId, token) {
    var params = {
      UserId: UserId,
    };
    if (params !== null) {
      api
        .post(APIConstant.path.GetNotVerifiedNetwork, params)
        .then((res) => {
          if (res.Success === 1) {
            var formattedData = [];
            var List = [];
            for (var i = 0; i < res.Data.length; i++) {
              formattedData.push({
                id: [i + 1],
                Name: res.Data[i].FirstName,
                LastName: res.Data[i].LastName,
                Email: res.Data[i].Email,
                PhoneNumber: res.Data[i].PhoneNumber,
                CompanyName: res.Data[i].CompanyName,
                UserType: res.Data[i].UserType,
                ProfilePic: null,
                HandlerImporterMasterID: res.Data[i]._id,
                UpdatedOn: moment(res.Data[i].Date).format("DD-MM-YYYY"),
                Type: res.Data[i].Type,
                BLReleaseReminder: res.Data[i].BLReleaseReminder,
                TotalFileCount: res.Data[i].TotalFileCount,
                Address: res.Data[i].Address,
              });
            }
            let lastPage =
              this.state.rowsPerPage < formattedData.length
                ? this.state.rowsPerPage
                : formattedData.length;
            let str =
              "1 to " +
              lastPage +
              " out of " +
              formattedData.length +
              " records";
            this.setState({
              NonVerifiedArray: formattedData,
              NonVerifiedStr: str,
            });
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          }
        })
        .catch((err) => {
          cogoToast.error("some thing Bad!");
        });
    } else {
      // this.setState({ loading:false});
      cogoToast.error("Empty data");
    }
  }

  getLoadingPerson(UserId, token) {
    var params = {
      UserId: UserId,
    };
    // this.setState({ loading : true});

    if (params !== null) {
      api
        .post(APIConstant.path.GetLoadingPerson, params)
        .then((res) => {
          if (res.Success === 1) {
            // this.setState({ loading : false});
            var formattedData1 = [];
            for (var i = 0; i < res.Info.Loading.length; i++) {
              formattedData1.push({
                id: [i + 1],
                LoadingPersonId:
                  this.state.UserId == res.Info.Loading[i].LoadingPersonId
                    ? res.Info.Loading[i].AddedByDetails[0]._id
                    : res.Info.Loading[i].LoadingPersonDetails[0]._id,
                Name:
                  this.state.UserId == res.Info.Loading[i].LoadingPersonId
                    ? res.Info.Loading[i].AddedByDetails[0].Name
                    : res.Info.Loading[i].LoadingPersonDetails[0].Name,
                LastName:
                  this.state.UserId == res.Info.Loading[i].LoadingPersonId
                    ? res.Info.Loading[i].AddedByDetails[0].LastName
                    : res.Info.Loading[i].LoadingPersonDetails[0].LastName,
                Email:
                  this.state.UserId == res.Info.Loading[i].LoadingPersonId
                    ? res.Info.Loading[i].AddedByDetails[0].Email
                    : res.Info.Loading[i].LoadingPersonDetails[0].Email,
                PhoneNumber:
                  this.state.UserId == res.Info.Loading[i].LoadingPersonId
                    ? res.Info.Loading[i].AddedByDetails[0].PhoneNumber
                    : res.Info.Loading[i].LoadingPersonDetails[0].PhoneNumber,
                ProfilePic:
                  this.state.UserId == res.Info.Loading[i].LoadingPersonId
                    ? res.Info.Loading[i].AddedByDetails[0].ProfilePic
                    : res.Info.Loading[i].LoadingPersonDetails[0].ProfilePic,
                UpdatedOn: moment(res.Info.Loading[i].CreatedOn).format(
                  "DD-MM-YYYY"
                ),
              });
            }
            let lastPage =
              this.state.rowsPerPage < formattedData1.length
                ? this.state.rowsPerPage
                : formattedData1.length;
            let str =
              "1 to " +
              lastPage +
              " out of " +
              formattedData1.length +
              " records";
            this.setState({ arr1: formattedData1, str1: str });
            // this.setState({ soldVesselList: res.Info.Loading });
          } else if (res.Success == -1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          }
        })
        .catch((err) => {
          cogoToast.error("some thing Bad!");
        });
    } else {
      // this.setState({ loading : false});
      cogoToast.error("Id is Not Selected");
    }
  }

  handleChangeHandler = (e, type) => {
    if (type === "OwnerCompanyName") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          OwnerCompanyName: e.target.value,
          OwnerCompanyNameErr: true,
          OwnerCompanyNameErrText: `Please enter owner's company name.`,
        });
      } else {
        this.setState({
          OwnerCompanyName: e.target.value,
          OwnerCompanyNameErr: false,
          OwnerCompanyNameErrText: "",
        });
      }
    } else if (type === "ImporterFirstName") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          ImporterFirstName: "",
          ImporterFirstNameErr: true,
          ImporterFirstNameErrText: "First name is required.",
        });
      } else {
        this.setState({
          ImporterFirstName: e.target.value,
          ImporterFirstNameErr: false,
          ImporterFirstNameErrText: "",
        });
      }
    } else if (type === "ImporterLastName") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          ImporterLastName: "",
          ImporterLastNameErr: true,
          ImporterLastNameErrText: "Last name is required.",
        });
      } else {
        this.setState({
          ImporterLastName: e.target.value,
          ImporterLastNameErr: false,
          ImporterLastNameErrText: "",
        });
      }
    } else if (type === "CompanyName") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          CompanyName: "",
          CompanyNameErr: true,
          CompanyNameErrText: "Company name is required.",
        });
      } else {
        this.setState({
          CompanyName: e.target.value,
          CompanyNameErr: false,
          CompanyNameErrText: "",
        });
      }
    } else if (type === "PhoneNo") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          PhoneNo: "",
          PhoneNoErr: true,
          PhoneNoErrText: "Phone number is required.",
        });
      } else if (
        !e.target.value.match(CommonConfig.RegExp.phoneRegExp) ||
        e.target.value.length > 10
      ) {
        this.setState({ PhoneNo: this.state.PhoneNo });
      } else {
        this.setState({
          PhoneNo: e.target.value,
          PhoneNoErr: false,
          PhoneNoErrText: "",
        });
      }
    } else if (type === "EmailId") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          EmailId: "",
          EmailIdErr: true,
          EmailIdErrText: "Email address is required.",
        });
      } else {
        this.setState({
          EmailId: e.target.value,
          EmailIdErr: false,
          EmailIdErrText: "",
        });
      }
    } else if (type === "address") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          address: "",
          addressErr: true,
          addressErrText: "Address is required.",
        });
      } else {
        this.setState({
          address: e.target.value,
          addressErr: false,
          addressErrText: "",
        });
      }
    } else if (type === "userType") {
      if (e.target.value.length <= 0) {
        this.setState({
          userType: "",
          userTypeErr: true,
          userTypeErrText: "User type is required.",
        });
      } else {
        this.setState({
          userType: e.target.value,
          userTypeErr: false,
          userTypeErrText: "",
        });
      }
    } else if (type === "UserDocType") {
      if (CommonConfig.isObjectEmpty(e)) {
        this.setState({ UserDocType: "" });
      } else {
        this.setState({ UserDocType: e.label });
      }
    } else if (type === "GSTNo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          GSTNo: "",
          GSTNoErr: true,
          GSTNoErrText: "GST No is required.",
        });
      } else {
        this.setState({
          GSTNo: e.target.value,
          GSTNoErr: false,
          GSTNoErrText: "",
        });
      }
    } else if (type === "PANNo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PANNo: "",
          PANNoErr: true,
          PANNoErrText: "Pan No is required.",
        });
      } else {
        this.setState({
          PANNo: e.target.value,
          PANNoErr: false,
          PANNoErrText: "",
        });
      }
    } else if (type === "IEC") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          IEC: "",
          IECErr: true,
          IECErrText: "IEC is required.",
        });
      } else {
        this.setState({ IEC: e.target.value, IECErr: false, IECErrText: "" });
      }
    } else if (type === "AdCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          AdCode: "",
          AdCodeErr: true,
          AdCodeErrText: "Ad Code is required.",
        });
      } else {
        this.setState({
          AdCode: e.target.value,
          AdCodeErr: false,
          AdCodeErrText: "",
        });
      }
    } else if (type === "DrawBack") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          DrawBack: "",
          DrawBackErr: true,
          DrawBackErrText: "Draw Back Details is required.",
        });
      } else {
        this.setState({
          DrawBack: e.target.value,
          DrawBackErr: false,
          DrawBackErrText: "",
        });
      }
    }
  };

  validateHandlerImporterForm = () => {
    const { CompanyName, PhoneNo, EmailId, userType } = this.state;
    let formIsValid = true;
    if (CommonConfig.isEmpty(CompanyName)) {
      formIsValid = false;
      this.setState({
        CompanyNameErr: true,
        CompanyNameErrText: "Company name is required.",
      });
    } else {
      this.setState({ CompanyNameErr: false, CompanyNameErrText: "" });
    }
    if (userType.length <= 0) {
      formIsValid = false;
      this.setState({
        userTypeErr: true,
        userTypeErrText: "User type is required.",
      });
    } else {
      this.setState({ userTypeErr: false, userTypeErrText: "" });
    }
    return formIsValid;
  };

  addUpdateHandlerImporter = () => {
    if (this.validateHandlerImporterForm()) {
      Loader.show();
      let params = {
        FirstName: this.state.ImporterFirstName,
        LastName: this.state.ImporterLastName,
        CompanyName: this.state.CompanyName,
        PhoneNumber: this.state.PhoneNo,
        Email: this.state.EmailId,
        UserId: this.state.UserId,
        CHAID: this.state.UserId,
        HandlerImporterMasterID: this.state.HandlerImporterMasterID,
        Type: this.state.userType.length <= 0 ? [] : this.state.userType,
        BLReleaseReminder: this.state.blReleaseReminder,
        GSTNo: this.state.GSTNo,
        PANNo: this.state.PANNo,
        IEC: this.state.IEC,
        AdCode: this.state.AdCode,
        DrawBack: this.state.DrawBack,
        isGSTVerified: this.state.isGSTVerified == true ? 1 : 0,
        isPANVerified: this.state.isPANVerified == true ? 1 : 0,
        Address: this.state.address,
      };
      var formData = new FormData();
      for (let i = 0; i < this.state.UserAttachmentGstFile?.length; i++) {
        let file = this.state.UserAttachmentGstFile[i]?.file;
        formData.append("UserAttachmentGstFile", file);
      }
      for (let i = 0; i < this.state.UserAttachmentPanFile?.length; i++) {
        let file = this.state.UserAttachmentPanFile[i]?.file;
        formData.append("UserAttachmentPanFile", file);
      }
      for (let i = 0; i < this.state.UserAttachmentIecFile?.length; i++) {
        let file = this.state.UserAttachmentIecFile[i]?.file;
        formData.append("UserAttachmentIecFile", file);
      }
      for (
        let i = 0;
        i < this.state.UserAttachmentDrawbackDetailsFile?.length;
        i++
      ) {
        let file = this.state.UserAttachmentDrawbackDetailsFile[i]?.file;
        formData.append("UserAttachmentDrawbackDetailsFile", file);
      }
      for (let i = 0; i < this.state.UserAttachmentADCodeFile?.length; i++) {
        let file = this.state.UserAttachmentADCodeFile[i]?.file;
        formData.append("UserAttachmentADCodeFile", file);
      }
      for (
        let i = 0;
        i < this.state.UserAttachmentStuffingPermissionFile?.length;
        i++
      ) {
        let file = this.state.UserAttachmentStuffingPermissionFile[i]?.file;
        formData.append("UserAttachmentStuffingPermissionFile", file);
      }
      for (let i = 0; i < this.state.UserAttachmentKycFormFile?.length; i++) {
        let file = this.state.UserAttachmentKycFormFile[i]?.file;
        formData.append("UserAttachmentKycFormFile", file);
      }
      formData.append("Data", JSON.stringify(params));

      api
        .post(APIConstant.path.AddNonVerifiedNetworkUser, formData, {
          headers: {
            "Content-Type": "mulitipart/form-data",
          },
        })
        .then((res) => {
          if (res.Success == 1) {
            Loader.hide();
            let StatusID = res.StatusId;
            if (StatusID === 2) {
              // openAddtonetworkPopup
              this.setState({
                loading: false,
                openUserNetwork: true,
                tags: res.UserInfo._id,
                UserInfo: res.UserInfo,
              });
              // this.handleClose("openAddImporter");
              this.getNotVerifedNetwork(this.state.UserId);
              cogoToast.success(res.Message);
            } else if (StatusID === 7 || StatusID === 4 || StatusID === 3) {
              cogoToast.error(res.Message);
            } else {
              this.handleClose("openAddImporter");
              this.getNotVerifedNetwork(this.state.UserId);
              cogoToast.success(res.Message);
            }
          } else {
            Loader.hide();
            this.setState({ loading: false });
            cogoToast.error(res.Message);
          }
        });
    }
  };

  addBLReleaseReminder = () => {
    this.setState({ loading: true });
    let params = {
      HandlerImporterMasterID: this.state.HandlerImporterMasterID,
      BLReleaseReminder: this.state.blReleaseReminder,
      UserId: this.state.UserId,
    };
    api.post(APIConstant.path.AddBLReleaseReminder, params).then((res) => {
      if (res.Success == 1) {
        cogoToast.success(res.Message);
        this.handleClose("NetworkBlRealeaseReminder");
        this.setState({ loading: false });
        this.getNotVerifedNetwork(this.state.UserId);
      } else {
        this.setState({
          loading: false,
          openNetworkBlRealeaseReminder: false,
          ID: "",
        });
        cogoToast.error(res.Message);
      }
    });
  };

  handleBLReminderChange = (e, type) => {
    if (type == "blReleaseReminder") {
      if (e.target.value == "" || e.target.value == null) {
        this.setState({
          blReleaseReminder: e.target.value,
          blReleaseReminderErr: true,
          blReleaseReminderErrText: "Please Enter Bl Release Reminder",
        });
      } else if (e.target.value.length > 3) {
        this.setState({
          blReleaseReminder: e.target.value,
          blReleaseReminderErr: true,
          blReleaseReminderErrText: "Please Enter Only 3 Number",
        });
      } else {
        this.setState({
          blReleaseReminder: e.target.value,
          blReleaseReminderErr: false,
          blReleaseReminderErrText: "",
        });
      }
    }
  };

  getUserAttachmentTypeList = async (UserId) => {
    try {
      let params = {
        Type: "USERATTACHMENT",
        ID: UserId,
      };
      await api
        .post(APIConstant.path.GetMasterListByType, params)
        .then(async (res) => {
          if (res.Success == 1) {
            this.setState({
              UserAttachmentTypeList: res.Data.map((data) => ({
                ...data,
                label: data.name,
              })),
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  AttachmentArrayUserPush = (e, value) => {
    e.preventDefault();

    if (!CommonConfig.isEmpty(value)) {
      let ArrAttach = this.state.AttachmentUserArray;
      ArrAttach.push({ UserDocType: value, isActive: 0 });

      let ArrList = this.state.UserAttachmentTypeList.filter(
        (x) => x.label != value
      ).length
        ? this.state.UserAttachmentTypeList.filter((x) => x.label != value)
        : [];
      this.setState({
        AttachmentUserArray: ArrAttach,
        UserAttachmentTypeList: ArrList,
        UserDocType: "",
      });
    } else {
      cogoToast.info("Please Select User Document Type");
    }
  };

  UserAttachmentFile = (fileItems, type) => {
    if (type === "GST") {
      if (this.state.UserAttachmentGstFile.length < 2) {
        for (let i = 0; i < fileItems.length; i++) {
          let files = fileItems[i];
          if (files.fileSize < 1024000 || files.fileSize == 1024000) {
            this.setState({
              fileErrmsg: "",
              dropzoneDisplay: "none",
            });
          } else {
            cogoToast.error("image size should be less than 1 MB");
            this.setState({
              fileErrmsg: " image size should be less than 1 MB",
              UserAttachmentGstFile: this.state.UserAttachmentGstFile,
            });
          }
          this.setState({
            UserAttachmentGstFile: this.state.fileErrmsg
              ? this.state.UserAttachmentGstFile
              : fileItems,
          });
        }
      } else {
        cogoToast.error("Maximum 1 Pdf are allowed.");
        this.setState({
          UserAttachmentGstFile: this.state.UserAttachmentGstFile,
        });
      }
    } else if (type === "PAN") {
      if (this.state.UserAttachmentPanFile.length < 2) {
        for (let i = 0; i < fileItems.length; i++) {
          let files = fileItems[i];
          if (files.fileSize < 1024000 || files.fileSize == 1024000) {
            this.setState({
              fileErrmsg: "",
              dropzoneDisplay: "none",
            });
          } else {
            cogoToast.error("image size should be less than 1 MB");
            this.setState({
              fileErrmsg: " image size should be less than 1 MB",
              UserAttachmentPanFile: this.state.UserAttachmentPanFile,
            });
          }
          this.setState({
            UserAttachmentPanFile: this.state.fileErrmsg
              ? this.state.UserAttachmentPanFile
              : fileItems,
          });
        }
      } else {
        cogoToast.error("Maximum 1 Pdf are allowed.");
        this.setState({
          UserAttachmentPanFile: this.state.UserAttachmentPanFile,
        });
      }
    } else if (type === "Stuffing Permission") {
      if (this.state.UserAttachmentStuffingPermissionFile.length < 2) {
        for (let i = 0; i < fileItems.length; i++) {
          let files = fileItems[i];
          if (files.fileSize < 1024000 || files.fileSize == 1024000) {
            this.setState({
              fileErrmsg: "",
              dropzoneDisplay: "none",
            });
          } else {
            cogoToast.error("image size should be less than 1 MB");
            this.setState({
              fileErrmsg: " image size should be less than 1 MB",
              UserAttachmentStuffingPermissionFile: this.state
                .UserAttachmentStuffingPermissionFile,
            });
          }
          this.setState({
            UserAttachmentStuffingPermissionFile: this.state.fileErrmsg
              ? this.state.UserAttachmentStuffingPermissionFile
              : fileItems,
          });
        }
      } else {
        cogoToast.error("Maximum 1 Pdf are allowed.");
        this.setState({
          UserAttachmentStuffingPermissionFile: this.state
            .UserAttachmentStuffingPermissionFile,
        });
      }
    } else if (type === "KYC Form") {
      if (this.state.UserAttachmentKycFormFile.length < 2) {
        for (let i = 0; i < fileItems.length; i++) {
          let files = fileItems[i];
          if (files.fileSize < 1024000 || files.fileSize == 1024000) {
            this.setState({
              fileErrmsg: "",
              dropzoneDisplay: "none",
            });
          } else {
            cogoToast.error("image size should be less than 1 MB");
            this.setState({
              fileErrmsg: " image size should be less than 1 MB",
              UserAttachmentKycFormFile: this.state.UserAttachmentKycFormFile,
            });
          }
          this.setState({
            UserAttachmentKycFormFile: this.state.fileErrmsg
              ? this.state.UserAttachmentKycFormFile
              : fileItems,
          });
        }
      } else {
        cogoToast.error("Maximum 1 Pdf are allowed.");
        this.setState({
          UserAttachmentKycFormFile: this.state.UserAttachmentKycFormFile,
        });
      }
    } else if (type === "IEC") {
      if (this.state.UserAttachmentIecFile.length < 2) {
        for (let i = 0; i < fileItems.length; i++) {
          let files = fileItems[i];
          if (files.fileSize < 1024000 || files.fileSize == 1024000) {
            this.setState({
              fileErrmsg: "",
              dropzoneDisplay: "none",
            });
          } else {
            cogoToast.error("image size should be less than 1 MB");
            this.setState({
              fileErrmsg: " image size should be less than 1 MB",
              UserAttachmentIecFile: this.state.UserAttachmentIecFile,
            });
          }
          this.setState({
            UserAttachmentIecFile: this.state.fileErrmsg
              ? this.state.UserAttachmentIecFile
              : fileItems,
          });
        }
      } else {
        cogoToast.error("Maximum 1 Pdf are allowed.");
        this.setState({
          UserAttachmentIecFile: this.state.UserAttachmentIecFile,
        });
      }
    } else if (type === "AD Code") {
      if (this.state.UserAttachmentADCodeFile.length < 2) {
        for (let i = 0; i < fileItems.length; i++) {
          let files = fileItems[i];
          if (files.fileSize < 1024000 || files.fileSize == 1024000) {
            this.setState({
              fileErrmsg: "",
              dropzoneDisplay: "none",
            });
          } else {
            cogoToast.error("image size should be less than 1 MB");
            this.setState({
              fileErrmsg: " image size should be less than 1 MB",
              UserAttachmentADCodeFile: this.state.UserAttachmentADCodeFile,
            });
          }
          this.setState({
            UserAttachmentADCodeFile: this.state.fileErrmsg
              ? this.state.UserAttachmentADCodeFile
              : fileItems,
          });
        }
      } else {
        cogoToast.error("Maximum 1 Pdf are allowed.");
        this.setState({
          UserAttachmentADCodeFile: this.state.UserAttachmentADCodeFile,
        });
      }
    } else if (type === "Drawback Details") {
      if (this.state.UserAttachmentDrawbackDetailsFile.length < 2) {
        for (let i = 0; i < fileItems.length; i++) {
          let files = fileItems[i];
          if (files.fileSize < 1024000 || files.fileSize == 1024000) {
            this.setState({
              fileErrmsg: "",
              dropzoneDisplay: "none",
            });
          } else {
            cogoToast.error("image size should be less than 1 MB");
            this.setState({
              fileErrmsg: " image size should be less than 1 MB",
              UserAttachmentDrawbackDetailsFile: this.state
                .UserAttachmentDrawbackDetailsFile,
            });
          }
          this.setState({
            UserAttachmentDrawbackDetailsFile: this.state.fileErrmsg
              ? this.state.UserAttachmentDrawbackDetailsFile
              : fileItems,
          });
        }
      } else {
        cogoToast.error("Maximum 1 Pdf are allowed.");
        this.setState({
          UserAttachmentDrawbackDetailsFile: this.state
            .UserAttachmentDrawbackDetailsFile,
        });
      }
    }
  };
  getGSTVerifyData = (type) => {
    if (type == "getGSTVerifyData") {
      if (this.state.GSTNo) {
        this.setState({ GSTNoErr: false, GSTNoErrText: "" });
        var params = {
          UserId: this.state.UserId,
          // PhoneNumber:this.state.edit_phonenumber,
          gst: this.state.GSTNo,
          token: this.state.token,
        };

        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(APIConstant.path.GetGSTVerifyData, params)
            .then((res) => {
              if (
                res.Success === 1 &&
                res.Info.VerifyGST &&
                res.Info.VerifyGST.code &&
                res.Info.VerifyGST.code == 200
              ) {
                this.setState({ loading: false, isGSTVerified: true });
                this.setState({
                  loading: false,
                  GSTNo: res.Info.VerifyGST.response.gstin,
                });
                let panNo = this.state.GSTNo.substring(2, 12);
                this.setState({ PANNo: panNo });
                this.getPanVerifyData("getPanVerifyData");
                cogoToast.success(res.Message);
              } else if (
                (res.Info.VerifyGST &&
                  (res.Info.VerifyGST.code &&
                    res.Info.VerifyGST.code != 200)) ||
                res.Info.VerifyGST.code == undefined
              ) {
                // cogoToast.error("Verification failed. Please check GST No.");
                this.setState({ openGstRequest: true });
                this.setState({
                  loading: false,
                });
              } else if (res.Success == -1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch((err) => { });
        }
      } else {
        this.setState({ GSTNoErr: true, GSTNoErrText: "GST No. is required." });
      }
    }
  };
  getPanVerifyData = (type) => {
    if (type == "getPanVerifyData") {
      if (this.state.PANNo) {
        this.setState({ PANNoErr: false, PANNoErrText: "" });
        var params = {
          UserId: this.state.UserId,
          pan: this.state.PANNo,
          token: this.state.token,
        };
        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(APIConstant.path.GetPanVerifyData, params)
            .then((res) => {
              if (
                res.Success === 1 &&
                res.Info.VerifyPAN &&
                res.Info.VerifyPAN.code &&
                res.Info.VerifyPAN.code == 200
              ) {
                this.setState({ loading: false, isPANVerified: true });
                cogoToast.success(res.Message);
              } else if (
                (res.Info.VerifyPAN &&
                  (res.Info.VerifyPAN.code &&
                    res.Info.VerifyPAN.code != 200)) ||
                res.Info.VerifyPAN.code == undefined
              ) {
                cogoToast.error("Verification failed. Please check PAN No.");
                this.setState({
                  loading: false,
                });
              } else if (res.Success == -1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch((err) => { });
        }
      } else {
        this.setState({ PANNoErr: true, PANNoErrText: "PAN No. is required." });
      }
    }
  };
  onColumnToggle = (event, value) => {
    let tempList = this.state.items;
    let cols = this.state.cols;
    let cols1 = this.state.cols1;

    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].header == value.header) {
        if (tempList[i].isChecked == 1) {
          tempList[i].isChecked = 0;
          cols.splice(i, 1);
          cols1.splice(i, 1);
        } else {
          tempList[i].isChecked = 1;
          cols = tempList.filter((x) => x.isChecked == 1);
          cols1 = tempList.filter((x) => x.isChecked == 1);
        }
      }
    }
    this.setState({ items: tempList, cols: cols, cols1: cols1 });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
      cols: arrayMove(items, oldIndex, newIndex),
      cols1: arrayMove(items, oldIndex, newIndex),
    }));
  };
  SaveDataOrder = async () => {
    Loader.show();
    var params = {
      UserId: this.state.UserId,
      DataOrder: this.state.items,
      Type: "Purchase",
    };
    await api
      .post(APIConstant.path.AddUpdateNetworkDataOrder, params)
      .then(async (res) => {
        if (res.Success === 1) {
          Loader.hide();
          cogoToast.success("Field list saved");
          this.setState({});
          this.GetNetworkDataOrder(this.state.UserId, "Purchase");
        } else {
          Loader.hide();
          cogoToast.error("Something went wrong");
        }
      })
      .catch((err) => {
        cogoToast.error(err);
        Loader.hide();
      });
  };
  GetNetworkDataOrder = (ID, type) => {
    let params = {
      UserId: ID,
      Type: "Purchase",
    };
    api
      .post(APIConstant.path.GetNetworkDataOrder, params)
      .then(async (res) => {
        if (res.Success === 1) {
          let response = res.Info.map((x) => {
            return {
              ...this.state.cols.filter((i) => i.header == x.header)[0],
              isChecked: x.IsChecked == 1 ? 1 : 0,
            };
          });
          this.setState({
            items: response,
            cols: response.filter((x) => x.isChecked == 1),
            cols1: response.filter((x) => x.isChecked == 1),
            cols2: response.filter((x) => x.isChecked == 1),
          });
        }
      })
      .catch((err) => { });
  };

  AddMaster = () => {
    if (this.validateMaster()) {
      this.setState({ loading: true });

      let params = {
        Description: this.state.MasterLabelName,
        UserID: this.state.UserId,
        ID: this.state.MasterID,
        StringType: "NETWORKUSERTYPE",
      };
      api.post(APIConstant.path.AddEditMasterByType, params).then((res) => {
        if (res.Success == 1) {
          this.setState({
            loading: false,
            AddMasterModal: false,
            MasterLabelName: "",
            MasterLabelNameErr: false,
            MasterLabelNameErrText: "",
            MasterID: "",
          });
          this.GetNonVerifideUserTypeList(this.state.UserId);
          cogoToast.success(res.Message);
        } else {
          this.setState({ loading: false });
          cogoToast.error(res.Message);
        }
      });
    }
  };
  validateMaster = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.MasterLabelName)) {
      this.setState({
        MasterLabelName: "",
        MasterLabelNameErr: true,
        MasterLabelNameErrText: "Type name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ MasterLabelNameErr: false, MasterLabelNameErrText: "" });
    }
    return formIsValid;
  };

  handleMasterChange = (e, type) => {
    if (type === "MasterLabelName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          MasterLabelName: e.target.value,
          MasterLabelNameErr: true,
          MasterLabelNameErrText: `Please enter Type.`,
        });
      } else {
        this.setState({
          MasterLabelName: e.target.value,
          MasterLabelNameErr: false,
          MasterLabelNameErrText: "",
        });
      }
    }
  };
  render() {
    const {
      userPageAccess,
      NetorkUserFileModel,
      isUserAttachment,
      UserAttachmentTypeList,
      UserDocType,
      AttachmentUserArray,
      UserAttachmentGstFile,
      UserAttachmentPanFile,
      UserAttachmentIecFile,
      UserAttachmentADCodeFile,
      UserAttachmentDrawbackDetailsFile,
      UserAttachmentStuffingPermissionFile,
      UserAttachmentKycFormFile,
      GSTNo,
      GSTNoErr,
      GSTNoErrText,
      PANNo,
      PANNoErr,
      PANNoErrText,
      IEC,
      IECErr,
      IECErrText,
      AdCode,
      AdCodeErr,
      AdCodeErrText,
      DrawBack,
      DrawBackErr,
      DrawBackErrText,
      isGSTVerified,
      isPANVerified,
      fileName,
      PDFFile,
      MasterLabelName,
      MasterLabelNameErr,
      MasterLabelNameErrText,
      AddMasterModal,
      MasterID,
    } = this.state;

    let columnsVerified = this.state.cols.map((col, i) => {
      if (col.header == "Sr.No") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "80px" }}
          />
        );
      } else if (col.header == "My Network" || col.header == "PhoneNumber") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "200px" }}
          />
        );
      } else if (col.header == "Email" || col.header == "Date/Time") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "250px" }}
          />
        );
      } else {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
          />
        );
      }
    });
    let columnsNonVerified = this.state.colsNon.map((col, i) => {
      if (col.header == "Sr.No") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "80px" }}
          />
        );
      } else if (col.header == "My Network" || col.header == "PhoneNumber") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "200px" }}
          />
        );
      } else if (col.header == "Email" || col.header == "Date/Time") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "250px" }}
          />
        );
      } else {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
          />
        );
      }
    });
    let columns1 = this.state.cols1.map((col, i) => {
      if (col.header == "Sr.No") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "80px" }}
          />
        );
      } else if (col.header == "Loading Person") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "200px" }}
          />
        );
      } else if (col.header == "Date/Time") {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
            style={{ width: "250px" }}
          />
        );
      } else {
        return (
          <Column
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={col.sortable}
            filter={col.filter}
            filterMatchMode="contains"
            key={i}
          />
        );
      }
    });

    const Heading = () => {
      const classes = useStyles();
      return <h4 className={classes.vesselheading}>Loading Person List</h4>;
    };

    const SampleFab = () => {
      const classes = useStyles();
      return (
        <>
          {" "}
          {userPageAccess.includes("Add Group Network") ? (
            <div
              className="add-btn1"
              onClick={() => this.handleClickOpen("group")}
            >
              <i className="icon-add"></i> Add Group
            </div>
          ) : null}
        </>
      );
    };

    const SampleFabLoading = () => {
      const classes = useStyles();
      return (
        <div
          className="add-btn1"
          onClick={() => this.handleClickOpen("loading")}
        >
          <i className="icon-add"></i> Add Loading test
        </div>
      );
    };

    return (
      <div className="stock-outer">
        {this.state.loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}

        <AppBar
          position="static"
          className="cs-tabs"
          style={{ flexWrap: "wrap" }}
        >
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="CONTACTS" {...a11yProps(0)} />

            <Tab label="GROUPS" {...a11yProps(1)} />

            <Tab label="LOADING PERSON" {...a11yProps(2)} />
          </Tabs>
          {this.state.value == 0 ? (
            <div className="basic-header network-header">
              <div className="p-buttonset">
                {/* {userPageAccess.includes('Add Verified') ? */}
                <Button
                  className={
                    this.state.formRequestType == "verified"
                      ? ""
                      : "p-button-outlined"
                  }
                  label="Verified"
                  onClick={(e) => {
                    e.preventDefault();

                    this.setState({ formRequestType: "verified" });
                  }}
                />
                {/*  null} */}

                <Button
                  className={
                    this.state.formRequestType == "nonverified"
                      ? ""
                      : "p-button-outlined"
                  }
                  label="Non-Verified"
                  onClick={(e) => {
                    e.preventDefault();

                    this.setState({ formRequestType: "nonverified" });
                  }}
                />
              </div>

              {this.state.formRequestType == "verified" ? (
                <>
                  <div className="global-search searchbar-area">
                    {/* <i className="pi pi-search"></i> */}
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState({ globalFilter: e.target.value })
                      }
                      placeholder="Global Search"
                      size="30"
                      style={{ marginRight: 10 }}
                    />
                    <i className="icon-search"></i>
                  </div>
                  <span>
                    {/* <MultiSelect className="filter-btn" value={this.state.cols} options={this.colOptions}
                                                fixedPlaceholder={true} placeholder={<i className="icon-filter"></i>}
                                                onChange={this.onColumnToggle}
                                            /> */}

                    <div>
                      <div className="filter-btn p-mr-0">
                        <span
                          onClick={(e) => this.opSort.toggle(e)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="icon-filter"></i>
                        </span>
                      </div>

                      <OverlayPanel
                        ref={(el) => (this.opSort = el)}
                        showCloseIcon={false}
                        id="overlay_panel"
                        className="overlaypanel-demo p-multiselect-panel p-component p-connected-overlay-enter-done"
                      >
                        <div className="custom-filter-dragger p-multiselect-items-wrapper">
                          <SortableList
                            items={this.state.items}
                            onSortEnd={this.onSortEnd}
                            onColumnToggle={this.onColumnToggle}
                          />
                        </div>
                        <ul className="noti-footer p-d-flex p-jc-end p-m-0 p-p-1 p-pr-1">
                          <div
                            className="add-btn1"
                            style={{ background: "#007ad9" }}
                            onClick={(e) => this.SaveDataOrder()}
                          >
                            {" "}
                            Save
                          </div>
                        </ul>
                      </OverlayPanel>
                    </div>
                  </span>
                  <span>
                    <Button
                      className="filter-btn"
                      onClick={() => this.setState({ openUserDialogue: true })}
                    >
                      <i className="icon-add"></i>
                    </Button>
                  </span>
                </>
              ) : (
                <>
                  <div className="global-search searchbar-area">
                    {/* <i className="pi pi-search"></i> */}
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState({
                          NonVerifiedGlobalFilter: e.target.value,
                        })
                      }
                      placeholder="Global Search"
                      size="30"
                      style={{ marginRight: 10 }}
                    />
                    <i className="icon-search"></i>
                  </div>
                  <div>
                    <div className="filter-btn p-mr-0">
                      <span
                        onClick={(e) => this.opSort.toggle(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="icon-filter"></i>
                      </span>
                    </div>

                    <OverlayPanel
                      ref={(el) => (this.opSort = el)}
                      showCloseIcon={false}
                      id="overlay_panel"
                      className="overlaypanel-demo p-multiselect-panel p-component p-connected-overlay-enter-done"
                    >
                      <div className="custom-filter-dragger p-multiselect-items-wrapper">
                        <SortableList
                          items={this.state.items}
                          onSortEnd={this.onSortEnd}
                          onColumnToggle={this.onColumnToggle}
                        />
                      </div>
                      <ul className="noti-footer p-d-flex p-jc-end p-m-0 p-p-1 p-pr-1">
                        <div
                          className="add-btn1"
                          style={{ background: "#007ad9" }}
                          onClick={(e) => this.SaveDataOrder()}
                        >
                          {" "}
                          Save
                        </div>
                      </ul>
                    </OverlayPanel>
                  </div>
                  {userPageAccess.includes("Add Manual Network") ? (
                    <Button
                      className="filter-btn"
                      onClick={() => this.setState({ openAddImporter: true })}
                    >
                      {" "}
                      <i className="icon-add"></i>
                    </Button>
                  ) : null}
                </>
              )}
              {/* {this.state.formRequestType == "verified" ? */}
              {/* <span>
                                    <div className="filter-btn" onClick={this.setState({openUserDialogue:true})}
                                    >  <i className="icon-add"></i></div>
                                </span>  */}
              {/* : <span>
                                    <div className="filter-btn" onClick={this.setState({openUserDialogue:true})}
                                    >  <i className="icon-add"></i> Add </div>
                                </span>} */}
              {/* <span>
                                    <SampleFab />
                                </span> */}
            </div>
          ) : this.state.value == 1 ? (
            <div className="p-d-flex p-align-center basic-header">
              <span>
                <SampleFab />
              </span>
            </div>
          ) : this.state.value == 2 ? (
            <div className="p-d-flex p-align-center basic-header">
              <span className="searchbar-area">
                <InputText
                  type="search"
                  onInput={(e) =>
                    this.setState({ globalFilter1: e.target.value })
                  }
                  placeholder="Global Search"
                  size="30"
                  style={{ marginRight: 10 }}
                />
                <i className="icon-search"></i>
              </span>
              {userPageAccess?.includes("Add Loading Network") ? (
                <span>
                  <SampleFabLoading />
                </span>
              ) : null}
              <span>
                <MultiSelect
                  className="filter-btn"
                  value={this.state.cols1}
                  options={this.colOptions1}
                  fixedPlaceholder={true}
                  placeholder={<i className="icon-filter"></i>}
                  tooltip="Col"
                  onChange={this.onColumnToggle1}
                />
              </span>
            </div>
          ) : (
            ""
          )}
        </AppBar>
        {/* @@contact */}
        <TabPanel
          className="main-card e-network-container"
          value={this.state.value}
          index={0}
        >
          {/* <GridContainer style={{ position: "relative", top: "12px" }}>
                        <GridItem xs={12} sm={12} md={12}> */}

          {this.state.formRequestType == "verified" ? (
           <div className="custom-large-table e-network-container">
            <DataTable
              className="main-table"
              ref={(el) => (this.dt = el)}
              value={this.state.arr}
              first={this.state.selectedPage}
              onPage={(e) => this.getPageString(e)}
              // onRowClick={(e) => this.onEditRow(e)}
              paginator={true}
              rows={this.state.rowsPerPage}
              totalRecords={this.state.arr.length}
              exportFilename="Mooring Guide List"
              currentPageReportTemplate={this.state.str}
              // scrollable={true} scrollHeight="250px"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rowsPerPageOptions={this.state.rowsPerPageOptions}
              globalFilter={this.state.globalFilter}
              emptyMessage="No records found"
              responsive={false}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
            >
              {columnsVerified}
            </DataTable>
            </div>
          ) : (
            <div className="custom-large-table e-network-container">
              <DataTable
                className="main-table e-c-table"
                ref={(el) => (this.dt = el)}
                value={this.state.NonVerifiedArray}
                first={this.state.NonVerifiedSelectedPage}
                onPage={(e) => this.getPageNonVerifiedString(e)}
                // onRowClick={(e) => this.onEditRow(e)}
                paginator={true}
                rows={this.state.NonVerifiedRowsPerPage}
                totalRecords={this.state.NonVerifiedArray.length}
                exportFilename="Mooring Guide List"
                currentPageReportTemplate={this.state.NonVerifiedStr}
                // scrollable={true} scrollHeight="250px"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={this.state.rowsPerPageOptionsNonUser}
                globalFilter={this.state.NonVerifiedGlobalFilter}
                emptyMessage="No records found"
                responsive={false}
              // resizableColumns={true}
              // columnResizeMode="fit"
              // scrollable={true}
              // scrollWidth="auto"
              >
                {columnsNonVerified}
              </DataTable>
            </div>
          )}
          {/* </GridItem>
                    </GridContainer> */}
          <Dialog
            className="profile-details-modal"
            open={this.state.openDialogue}
            onClose={() => this.handleClose("old")}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Profile Details</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("old")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <React.Fragment>
              <DialogContent className="profile-content">
                <div className="p-d-flex p-flex-column p-flex-md-row p-pt-2 p-pb-4">
                  <div>
                    <div className="Profile-dialog-img">
                      <img
                        src={
                          this.state.profilePic == "" ||
                            this.state.profilePic == null ||
                            this.state.profilePic == undefined
                            ? User
                            : individual_profile_image_link +
                            this.state.profilePic
                        }
                      />
                    </div>
                  </div>
                  <div className="p-w-100">
                    <table className="profile-table">
                      <tr>
                        <td className="table-label">Name:</td>
                        <td className="table-label-info">
                          {this.state.firstName} {this.state.secondName}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-label">Email ID:</td>
                        <td className="table-label-info">
                          {" "}
                          {this.state.emailId}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-label">Contact No:</td>
                        <td className="table-label-info">
                          {" "}
                          {this.state.contactNum}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </DialogContent>
            </React.Fragment>
          </Dialog>
        </TabPanel>
        {/* @@groups */}
        <TabPanel
          className="main-card e-network-container p-p-3"
          value={this.state.value}
          index={1}
        >
          {this.state.NetworkGroupList.length !== 0 ? (
            this.state.NetworkGroupList.map((item, key) => {
              return (
                <div key={key} className="border-seprate p-pb-2 p-mb-2">
                  <div className="p-d-flex">
                    <div className="group-img">
                      {item.NetworkGroupName.split(" ")
                        .slice(0, 1)
                        .join(" ")
                        .charAt(0)
                        .toUpperCase()}{" "}
                      {item.NetworkGroupName.split(" ")
                        .slice(-1)
                        .join(" ")
                        .charAt(0)
                        .toUpperCase()}
                      {/* <img src={item.profilePic == "" || item.profilePic == null || item.profilePic == undefined ? User : individual_profile_image_link + item.profilePic}/> */}
                    </div>
                    <div className="p-w-100">
                      <div className="p-d-flex p-jc-between p-mb-1">
                        <div>
                          <div className="fs-18 fw-500">
                            {item.NetworkGroupName}
                          </div>
                          <div className="primary-gray">
                            Created By {item.GroupAdmin}
                          </div>
                        </div>

                        <div className="request-status-icon p-ai-start">
                          <div class="icon-btn dark-blue-color">
                            <i
                              className="icon-add-user"
                              onClick={(e) => this.handleAdd(e, item, "add")}
                            ></i>
                          </div>
                          {userPageAccess?.includes("Edit Group Network") ? (
                            <div class="icon-btn dark-blue-color">
                              <i
                                className="icon-editImage"
                                onClick={(e) => this.handleAdd(e, item, "edit")}
                              ></i>
                            </div>
                          ) : null}

                          {userPageAccess?.includes("Delete Group Network") ? (
                            <div class="icon-btn">
                              <i
                                className="icon-cancel seller-red"
                                onClick={(e) =>
                                  this.handleAdd(e, item, "delete")
                                }
                              ></i>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="member-group">
                        {item.Member
                          ? item.Member.map((res, index) => (
                            <div
                              onClick={() => this.toggle(res)}
                              className="member-each"
                            >
                              <div className="member-each-img">
                                <img
                                  src={
                                    res.ProfilePic == "" ||
                                      res.ProfilePic == null ||
                                      res.ProfilePic == undefined
                                      ? User
                                      : individual_profile_image_link +
                                      res.ProfilePic
                                  }
                                />
                              </div>
                              <div className="member-each-name">
                                {res.Name}
                              </div>
                            </div>
                          ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>There are no group added</div>
          )}
          <div>
            <Dialog
              open={this.state.open}
              onClose={() => this.handleClose("group")}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                className="custom-dialog-header"
                id="form-dialog-title"
              >
                <div>{this.state.type == "add" ? "Create" : "Edit"} Group</div>
                <div
                  className="dialog-close"
                  onClick={(e) => this.handleClose("group")}
                >
                  <i className="icon-modal-close"></i>
                </div>
              </DialogTitle>
              <DialogContent className="dialog-content-area">
                <div className="input-control ipt-with-icon">
                  {/* <i className="fas fa-users"></i> */}
                  <CustomInput
                    labelText="Enter Group Name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => this.handleChange_value1(e),
                      type: "text",
                      id: "group_name",
                      name: "group_name",
                      value: this.state.group_name,
                    }}
                    helperText={this.state.groupnamehelperText}
                    id="outlined-required"
                    error={this.state.groupnameErr}
                  />
                </div>
                <div className="input-control select">
                  <FormControl fullWidth>
                    <Autocomplete
                      value={this.state.tags}
                      multiple
                      options={this.state.networkGroup}
                      disableopenonfocus="true"
                      onInputChange={(event, values) =>
                        this.onTagsChange(event, values, "inputchange")
                      }
                      filterSelectedOptions
                      // autoSelect={true}
                      getOptionLabel={(option) =>
                        option.label
                          ? this.state.name &&
                            option.name.includes(this.state.name)
                            ? option.name
                            : option.email + " " + "(" + option.phone + ")"
                          : option
                      }
                      onChange={(event, values) =>
                        this.onTagsChange(event, values, "change")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={this.state.emailphoneErr}
                          helperText={this.state.emailphonehelperText}
                          id="emailphone"
                          name="emailphone"
                          label="Enter Email / Mobile Number"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                  {/* {this.state.getFirstLastNamelist.map((item, i) => {
                                        return (<div><p>{item.Name}{item.last_name}</p></div>)
                                    })
                                    } */}
                </div>
              </DialogContent>
              <DialogActions className="p-p-0 dialog-footer1">
                {this.state.loading === true ? (
                  <div className="loading">
                    <SimpleBackdrop />
                  </div>
                ) : null}

                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose("group")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                {this.state.type == "edit" ? (
                  <Button
                    className="primary-btn"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => this.AddtoMyNetwork("editgroup")}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    className="primary-btn"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => this.AddtoMyNetwork("group")}
                  >
                    Add
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
          <Dialog
            className="profile-details-modal"
            open={this.state.openDialogue}
            onClose={() => this.handleClose("old")}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              className="custom-dialog-header"
              id="form-dialog-title"
            >
              <div>Profile Details</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("old")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <React.Fragment>
              {/* <DialogContent className="profile-content">
                                <div className="vib-header">
                                    <img src={this.state.profilePic == "" || this.state.profilePic == null || this.state.profilePic == undefined ? User : individual_profile_image_link + this.state.profilePic}
                                    />
                                </div>
                                <div className="vib-header">
                                    <b><label>Name: {this.state.firstName} {this.state.secondName}</label></b>
                                </div>
                                <div className="vib-header">
                                    <b><label>Email ID: {this.state.emailId}</label></b>
                                </div>
                                <div className="vib-header">
                                    <b><label>Contact Number: {this.state.contactNum}</label></b>
                                </div>
                            </DialogContent> */}
              <DialogContent className="profile-content">
                <div className="p-d-flex p-flex-column p-flex-md-row p-pt-2 p-pb-4">
                  <div>
                    <div className="Profile-dialog-img">
                      <img
                        src={
                          this.state.profilePic == "" ||
                            this.state.profilePic == null ||
                            this.state.profilePic == undefined
                            ? User
                            : individual_profile_image_link +
                            this.state.profilePic
                        }
                      />
                    </div>
                  </div>
                  <div className="p-w-100">
                    <table className="profile-table">
                      <tr>
                        <td className="table-label">Name:</td>
                        <td className="table-label-info">
                          {this.state.firstName} {this.state.secondName}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-label">Email ID:</td>
                        <td className="table-label-info">
                          {" "}
                          {this.state.emailId}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-label">Contact No:</td>
                        <td className="table-label-info">
                          {" "}
                          {this.state.contactNum}
                        </td>
                      </tr>
                    </table>

                    {/* <div className="vib-header">
                                            <b><label>Name: {this.state.firstName} {this.state.secondName}</label></b>
                                        </div>
                                        <div className="vib-header">
                                            <b><label>Email ID: {this.state.emailId}</label></b>
                                        </div>
                                        <div className="vib-header">
                                            <b><label>Contact Number: {this.state.contactNum}</label></b>
                                        </div> */}
                  </div>
                </div>
              </DialogContent>
              {/* <DialogActions className="p-p-0 dialog-footer1">
                                {this.state.loading === true ? (<div className="loading"><SimpleBackdrop /></div>) : (null)}
                                <Button className="primary-btn1" onClick={(e) => this.handleClose("old")} color="primary" disabled={this.state.loading}>
                                    Cancel
                                </Button>
                            </DialogActions> */}
            </React.Fragment>
          </Dialog>
          <Dialog
            open={this.state.open1}
            onClose={() => this.handleClose("member")}
            aria-labelledby="form-dialog-title"
          >
            {/* <form method="post"> */}
            <DialogTitle
              className="custom-dialog-header"
              id="form-dialog-title"
            >
              <div>Add Member</div>
              <div
                className="dialog-close"
                onClick={() => this.handleClose("member")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="dialog-content-area">
              <GridItem>
                <div className="input-control ipt-with-icon">
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelText="Search Email / Mobile Number"
                    error={this.state.emailphoneErr}
                    helperText={this.state.emailphonehelperText}
                    id="emailphone"
                    name="emailphone"
                    inputProps={{
                      value: this.state.uniqueuser1,
                      onChange: (event) => this.onSelectTag(event, "member"),
                      onKeyPress: (e) => {
                        if (e.key === "Enter") {
                          this.SearchUser(e, "member");
                        }
                      },
                    }}
                  />
                </div>
                <div>
                  <SearchIcon
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "104px",
                    }}
                    onClick={(e) => this.SearchUser(e, "member")}
                  />
                </div>
              </GridItem>
              {this.state.emptyfirst1 === 1 ? (
                <div className="vib-header">
                  <p>No record found</p>
                </div>
              ) : this.state.UserId === this.state.searchUserID1 ? (
                <div className="vib-header">
                  <p>You cannot add yourself</p>
                </div>
              ) : (
                this.state.getFirstLastNamelist.map((item, i) => {
                  return (
                    <div className="vib-header">
                      <p className="p-px-2">
                        {item.Name} {item.last_name}
                      </p>
                      {/* <Button variant="contained" className="primary-btn" onClick={(e) => this.AddGroupMember(e)}>Add</Button> */}
                      {/* {this.state.loading === true ? (<div className="loading"><SimpleBackdrop /></div>) : (null)} */}
                    </div>
                  );
                })
              )}
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={() => this.handleClose("member")}
                color="primary"
              >
                Close
              </Button>
              {this.state.emptyfirst1 === 1
                ? null
                : this.state.UserId === this.state.searchUserID1
                  ? null
                  : this.state.getFirstLastNamelist.map((item, i) => {
                    return (
                      <>
                        <Button
                          variant="contained"
                          className="primary-btn"
                          onClick={(e) => this.AddGroupMember(e)}
                        >
                          Add
                        </Button>
                        {this.state.loading === true ? (
                          <div className="loading">
                            <SimpleBackdrop />
                          </div>
                        ) : null}
                      </>
                    );
                  })}
            </DialogActions>
          </Dialog>
          <div>
            <Dialog
              open={this.state.opendelete}
              onClose={() => this.handleClose("delete")}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                className="custom-dialog-header"
                id="alert-dialog-title"
              >
                <div>{"Delete Network Group"}</div>
                <div
                  className="dialog-close"
                  onClick={(e) => this.handleClose("delete")}
                >
                  <i className="icon-modal-close"></i>
                </div>
              </DialogTitle>
              <DialogContent className="dialog-content-area p-d-flex p-ai-center">
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete group ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className="p-p-0 dialog-footer1">
                {this.state.loading === true ? (
                  <div className="loading">
                    <SimpleBackdrop />
                  </div>
                ) : null}

                <Button
                  className="primary-btn1"
                  onClick={() => this.handleClose("delete")}
                  color="primary"
                >
                  No
                </Button>
                <Button
                  className="primary-btn"
                  onClick={() => this.AddtoMyNetwork("delete")}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </TabPanel>
        {/* @@load */}
        <TabPanel
          className="main-card e-network-container"
          value={this.state.value}
          index={2}
        >
          {/* <Card className="mt0 customize-card"> */}
          {/* <CardHeader color="success" icon>
                            <Heading />
                        </CardHeader> */}
          {/* <CardBody style={{ position: "relative", bottom: "20px" }}> */}
          <div className="custom-large-table e-network-container">
            <DataTable
              className="main-table"
              ref={(el) => (this.dt = el)}
              value={this.state.arr1}
              first={this.state.selectedPage1}
              onPage={(e) => this.getPageString1(e)}
              // onRowClick={(e) => this.onEditRow(e)}
              paginator={true}
              rows={this.state.rowsPerPage1}
              totalRecords={this.state.arr1.length}
              exportFilename="Mooring Guide List"
              currentPageReportTemplate={this.state.str1}
              // scrollable={true} scrollHeight="250px"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rowsPerPageOptions={this.state.rowsPerPageOptions1}
              globalFilter={this.state.globalFilter1}
              emptyMessage="No records found"
              responsive={true}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
            >
              {columns1}
            </DataTable>
          </div>
          {/* </CardBody> */}
          {/* </Card> */}
          <Dialog
            open={this.state.openloading}
            onClose={() => this.handleClose("loading")}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              className="custom-dialog-header"
              id="form-dialog-title"
            >
              <div>Add Loading Person</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("loading")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <React.Fragment>
              <DialogContent className="dialog-content-area p-d-flex p-ai-center">
                <div className="vib-header">
                  <Autocomplete
                    value={this.state.NetworkList}
                    options={this.state.getMyConnectList}
                    onChange={(e, value) =>
                      this.onSelectTag(value, "NetworkList")
                    }
                    getOptionLabel={(option) =>
                      option.label
                        ? option.email + " " + "(" + option.phone + ")"
                        : option
                    }
                    // renderOption={(option) => (
                    //     <React.Fragment>
                    //       <span>{<img height="26" width="26" src={HalfBody}/>}&nbsp;</span>
                    //       {option.label}
                    //     </React.Fragment>
                    //   )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="NetworkList"
                        name="NetworkList"
                        error={this.state.NetworkListErr}
                        helperText={this.state.NetworkListhelperText}
                        label="Select Loading Person"
                        margin="normal"
                      />
                    )}
                  />
                </div>
              </DialogContent>
              <DialogActions className="p-p-0 dialog-footer1">
                {this.state.loading === true ? (
                  <div className="loading">
                    <SimpleBackdrop />
                  </div>
                ) : null}
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose("loading")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn"
                  onClick={(e) => this.AddLoadingPerson(e)}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Add
                </Button>
              </DialogActions>
            </React.Fragment>
          </Dialog>
          <Dialog
            className="profile-details-modal"
            open={this.state.openDialogue}
            onClose={() => this.handleClose("old")}
            aria-labelledby="form-dialog-title"
          >
            {/* <div className="custom-dialog network-dialog"> */}
            <DialogTitle
              className="custom-dialog-header"
              id="form-dialog-title"
            >
              <div>Profile Details</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("old")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <React.Fragment>
              {/* <DialogContent className="dialog-content-area">
                                <div className="vib-header">
                                    <img src={this.state.profilePic == "" || this.state.profilePic == null || this.state.profilePic == undefined ? User : individual_profile_image_link + this.state.profilePic} style={{ width: "100px", height: "100px", alignItems: 'center', alignSelf: 'center' }}
                                    />
                                </div>
                                <div className="vib-header">
                                    <b><label>Name: {this.state.firstName} {this.state.secondName}</label></b>
                                </div>
                                <div className="vib-header">
                                    <b><label>Email ID: {this.state.emailId}</label></b>
                                </div>
                                <div className="vib-header">
                                    <b><label>Contact Number: {this.state.contactNum}</label></b>
                                </div>
                            </DialogContent> */}
              <DialogContent className="profile-content">
                <div className="p-d-flex p-flex-column p-flex-md-row p-pt-2 p-pb-4">
                  <div>
                    <div className="Profile-dialog-img">
                      <img
                        src={
                          this.state.profilePic == "" ||
                            this.state.profilePic == null ||
                            this.state.profilePic == undefined
                            ? User
                            : individual_profile_image_link +
                            this.state.profilePic
                        }
                      />
                    </div>
                  </div>
                  <div className="p-w-100">
                    <table className="profile-table">
                      <tr>
                        <td className="table-label">Name:</td>
                        <td className="table-label-info">
                          {this.state.firstName} {this.state.secondName}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-label">Email ID:</td>
                        <td className="table-label-info">
                          {" "}
                          {this.state.emailId}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-label">Contact No:</td>
                        <td className="table-label-info">
                          {" "}
                          {this.state.contactNum}
                        </td>
                      </tr>
                    </table>

                    {/* <div className="vib-header">
                                            <b><label>Name: {this.state.firstName} {this.state.secondName}</label></b>
                                        </div>
                                        <div className="vib-header">
                                            <b><label>Email ID: {this.state.emailId}</label></b>
                                        </div>
                                        <div className="vib-header">
                                            <b><label>Contact Number: {this.state.contactNum}</label></b>
                                        </div> */}
                  </div>
                </div>
              </DialogContent>
              {/* <DialogActions className="p-p-0 dialog-footer1">
                                {this.state.loading === true ? (<div className="loading"><SimpleBackdrop /></div>) : (null)}
                                <Button className="primary-btn1" onClick={(e) => this.handleClose("old")} color="primary" disabled={this.state.loading}>
                                    Cancel
                                </Button>
                            </DialogActions> */}
            </React.Fragment>
            {/* </div> */}
          </Dialog>
        </TabPanel>

        <Dialog
          open={this.state.openUserDialogue}
          onClose={() => this.handleClose("openUserDialogue")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>User Details</div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose("openUserDialogue")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent>
              <div className="">
                <TextField
                  className="searchbar-area"
                  formcontrolprops={{
                    fullWidth: true,
                  }}
                  // label="Search Email / Mobile Number"
                  variant="outlined"
                  error={this.state.emailphoneErr}
                  helperText={this.state.emailphonehelperText}
                  id="emailphone"
                  name="emailphone"
                  InputProps={{
                    placeholder: "Search Email / Mobile Number",
                    maxLength: 50,
                    endAdornment: (
                      <InputAdornment position="end">
                        {this.state.clearvisible == 1 ? (
                          <CloseIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => this.clearText()}
                          />
                        ) : null}
                        <i
                          onClick={(e) => this.SearchUser(e, "mynetwork")}
                          className="icon-search cursor-pointer"
                        ></i>
                      </InputAdornment>
                    ),
                    value: this.state.uniqueuser,
                    onChange: (event) => this.onSelectTag(event, "mynetwork"),
                    onKeyPress: (e) => {
                      if (e.key === "Enter") {
                        this.SearchUser(e, "mynetwork");
                      }
                    },
                  }}
                />
              </div>
              <div className="dialog-content-area1 p-d-flex">
                <div className="dialog-user-img">
                  <img
                    src={
                      this.state.User_Profile == "" ||
                        this.state.User_Profile == null ||
                        this.state.User_Profile == undefined
                        ? User
                        : individual_profile_image_link +
                        this.state.User_Profile
                    }
                  />
                </div>
                {this.state.fillData ? (
                  <div className="network-details">
                    <h3>{this.state.User_Name}</h3>
                    <h2>{this.state.User_Role}</h2>
                    <h2>{this.state.User_EmailPhone}</h2>
                    <h2>{this.state.User_CompanyName}</h2>
                  </div>
                ) : (
                  <div className="network-details">
                    {" "}
                    <h3>Please Search Contact</h3>
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.fillData ? (
                <>
                  {this.state.clearText !== true ? (
                    this.state.emptyfirst === 1 ? (
                      this.state.invite === 0 ? (
                        <div className="p-d-flex p-jc-end">
                          <Button
                            className="primary-btn1 p-mr-2"
                            onClick={(e) =>
                              this.handleClose("openUserDialogue")
                            }
                            color="primary"
                            disabled={this.state.loading}
                          >
                            Cancel
                          </Button>

                          <Button
                            variant="contained"
                            className="primary-btn"
                            color="primary"
                            onClick={() => this.AddtoMyNetwork("invite")}
                          >
                            Invite
                          </Button>
                        </div>
                      ) : (
                        <div className="dialog-footer">
                          <span className="primary-color">
                            <p className="p-m-0">Invited</p>
                          </span>

                          <Button
                            className="primary-btn1"
                            onClick={(e) =>
                              this.handleClose("openUserDialogue")
                            }
                            color="primary"
                            disabled={this.state.loading}
                          >
                            Cancel
                          </Button>
                        </div>
                      )
                    ) : this.state.UserId === this.state.searchUserID ? (
                      <span className="primary-color">
                        <p className="p-m-0">User It Self</p>
                      </span>
                    ) : this.state.checkuser === 1 ? (
                      <>
                        <div className="dialog-footer">
                          <Button
                            variant="contained"
                            className="primary-btn p-mr-2"
                            color="primary"
                            onClick={(e) => this.confirm(e, "confirm")}
                          >
                            Waiting for confirmation
                          </Button>

                          <Button
                            variant="contained"
                            className="primary-btn"
                            color="primary"
                            onClick={(e) => this.confirm(e, "cancel")}
                          >
                            Cancel Request
                          </Button>
                        </div>
                      </>
                    ) : this.state.checkuser === 2 ? (
                      <div className="dialog-footer">
                        <span className="primary-color">
                          <p className="p-m-0">Request sent</p>
                        </span>

                        <Button
                          className="primary-btn1"
                          onClick={(e) => this.handleClose("openUserDialogue")}
                          color="primary"
                          disabled={this.state.loading}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : this.state.checkuser === 3 ? (
                      <div className="dialog-footer">
                        <span className="primary-color">
                          <p className="p-m-0">User already in network</p>
                        </span>

                        <Button
                          className="primary-btn1"
                          onClick={(e) => this.handleClose("openUserDialogue")}
                          color="primary"
                          disabled={this.state.loading}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div className="dialog-footer">
                        <Button
                          className="primary-btn1 p-mr-2"
                          onClick={(e) => this.handleClose("openUserDialogue")}
                          color="primary"
                          disabled={this.state.loading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="primary-btn"
                          color="primary"
                          type="submit"
                          onClick={() => this.AddtoMyNetwork("new")}
                        >
                          Submit
                        </Button>
                      </div>
                    )
                  ) : null}
                  {this.state.loading === true ? (
                    <div className="loading">
                      <SimpleBackdrop />
                    </div>
                  ) : null}{" "}
                </>
              ) : null}
            </DialogActions>
          </React.Fragment>
        </Dialog>
        <Dialog
          open={AddMasterModal}
          onClose={() => this.handleClose("AddMaster")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>{MasterID !== "" ? "Edit" : "Add"} User Type </div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose("AddMaster")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <GridContainer>
              <GridItem md={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="User Type"
                  // id="chaNo"
                  label="User Type*"
                  type="text"
                  value={MasterLabelName}
                  onChange={(e) =>
                    this.handleMasterChange(e, "MasterLabelName")
                  }
                  error={MasterLabelNameErr}
                  helperText={MasterLabelNameErrText}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => this.handleClose("AddMaster")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => this.AddMaster(e)}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openAddImporter}
          onClose={() => this.handleClose("openAddImporter")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>
              Add User - {isUserAttachment ? "KYC Documents" : "Information"}
            </div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose("openAddImporter")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            {!isUserAttachment ? (
              <GridContainer>
                <GridItem md={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="ImporterFirstName"
                    // id="chaNo"
                    label="First Name *"
                    type="text"
                    value={this.state.ImporterFirstName}
                    onChange={(e) =>
                      this.handleChangeHandler(e, "ImporterFirstName")
                    }
                    error={this.state.ImporterFirstNameErr}
                    helperText={this.state.ImporterFirstNameErrText}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </GridItem>

                <GridItem md={6}>
                  <TextField
                    margin="dense"
                    name="ImporterLastName"
                    // id="chaNo"
                    label="Last Name *"
                    type="text"
                    value={this.state.ImporterLastName}
                    onChange={(e) =>
                      this.handleChangeHandler(e, "ImporterLastName")
                    }
                    error={this.state.ImporterLastNameErr}
                    helperText={this.state.ImporterLastNameErrText}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </GridItem>

                <GridItem md={6}>
                  <TextField
                    margin="dense"
                    name="CompanyName"
                    // id="chaNo"
                    label="Company Name *"
                    type="text"
                    value={this.state.CompanyName}
                    onChange={(e) => this.handleChangeHandler(e, "CompanyName")}
                    error={this.state.CompanyNameErr}
                    helperText={this.state.CompanyNameErrText}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </GridItem>
                <GridItem md={6}>
                  <TextField
                    margin="dense"
                    name="PhoneNo"
                    // id="chaNo"
                    label="Phone No *"
                    type="text"
                    value={this.state.PhoneNo}
                    onChange={(e) => this.handleChangeHandler(e, "PhoneNo")}
                    error={this.state.PhoneNoErr}
                    helperText={this.state.PhoneNoErrText}
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                  />
                </GridItem>

                <GridItem md={6}>
                  <TextField
                    margin="dense"
                    name="EmailId"
                    // id="chaNo"
                    label="Email address *"
                    type="text"
                    value={this.state.EmailId}
                    onChange={(e) => this.handleChangeHandler(e, "EmailId")}
                    error={this.state.EmailIdErr}
                    helperText={this.state.EmailIdErrText}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </GridItem>

                <GridItem md={6}>
                  <TextField
                    margin="dense"
                    name="address"
                    label="Address "
                    type="text"
                    value={this.state.address}
                    onChange={(e) => this.handleChangeHandler(e, "address")}
                    error={this.state.addressErr}
                    helperText={this.state.addressErrText}
                    fullWidth
                  />
                </GridItem>

                <GridItem md={6}>
                  <div className="input-control select p-d-flex p-ai-end">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="simple-select">
                        Select User Type
                      </InputLabel>

                      <MultiSelect
                        value={this.state.userType}
                        onChange={(e) =>
                          this.handleChangeHandler(e, "userType")
                        }
                        options={this.state.GetNonVerifideUserTypeList}
                        optionLabel="label"
                        placeholder="Select User Type"
                        className="w-full md:w-20rem"
                      />

                      <span className="error-msg" style={{ color: "red" }}>
                        {" "}
                        {this.state.userTypeErr
                          ? this.state.userTypeErrText
                          : null}{" "}
                      </span>
                    </FormControl>
                    <div className="p-d-flex p-pt-3">
                      <button
                        className="filter-btn cursor-pointer"
                        onClick={(e) => {
                          this.setState({
                            AddMasterModal: true,
                            MasterLabel: "User Type",
                          });
                        }}
                      >
                        <i className="icon-add"></i>
                      </button>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            ) : (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="input-control select p-d-flex">
                      <FormControl fullWidth>
                        <Autocomplete
                          id="tags-standard"
                          value={
                            UserDocType && UserAttachmentTypeList.length
                              ? UserAttachmentTypeList.filter(
                                (x) => x.label == UserDocType
                              )[0]
                              : ""
                          }
                          options={UserAttachmentTypeList}
                          getOptionLabel={(option) =>
                            option.label ? option.label : option
                          }
                          onChange={(e, value) => {
                            e.preventDefault();
                            this.handleChangeHandler(value, "UserDocType");
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Select User Document type"
                              label="Select User Document type"
                            />
                          )}
                        />
                      </FormControl>
                      <div>
                        <button
                          className="filter-btn cursor-pointer"
                          onClick={(e) => {
                            this.AttachmentArrayUserPush(e, UserDocType);
                          }}
                        >
                          {" "}
                          <i className="icon-add"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <Accordion
                  className="accordion-custom border-ac-left-green p-px-2"
                  activeIndex={0}
                >
                  {AttachmentUserArray.length != 0 ? (
                    AttachmentUserArray.map((x, idx) => {
                      return (
                        <AccordionTab
                          key={idx}
                          header={
                            <>
                              <div className="p-d-flex p-jc-between p-ai-center">
                                <div classname="ac-header">{x.UserDocType}</div>
                              </div>
                            </>
                          }
                        >
                          <>
                            {x.UserDocType == "GST" ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div className="input-control p-position-relative p-pt-2">
                                  <CustomInput
                                    labelText="GST No"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: (e) =>
                                        this.handleChangeHandler(e, "GSTNo"),
                                      type: "text",
                                      id: "GSTNo",
                                      name: "GSTNo",
                                      value: GSTNo,
                                      disabled: isGSTVerified ? true : false,

                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {this.state.isGSTVerified ? (
                                            <img
                                              style={{
                                                height: "20px",
                                                width: "20px",
                                                marginInlineEnd: "2px",
                                              }}
                                              src={yes}
                                            />
                                          ) : (
                                            <span>
                                              <img
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  marginInlineEnd: "2px",
                                                }}
                                                src={pending}
                                              />
                                              &nbsp;
                                              <Button
                                                style={{
                                                  height: "20px",
                                                  width: "60px",
                                                  color: "white",
                                                  fontSize: "12px",
                                                }}
                                                onClick={() =>
                                                  this.getGSTVerifyData(
                                                    "getGSTVerifyData"
                                                  )
                                                }
                                                className="buyVessel-btn"
                                              >
                                                verify
                                              </Button>
                                            </span>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    helperText={GSTNoErrText}
                                    id="outlined-required"
                                    error={GSTNoErr}
                                  />

                                  <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={UserAttachmentGstFile}
                                    allowReorder={true}
                                    credits={""}
                                    instantUpload={true}
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={
                                      'PDF (max size 1MB) <span class="block filepond--label-action">Browse</span>'
                                    }
                                    onupdatefiles={(fileItems) => {
                                      this.UserAttachmentFile(
                                        fileItems,
                                        x.UserDocType
                                      );
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ) : x.UserDocType == "PAN" ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div className="input-control p-position-relative p-pt-2">
                                  <CustomInput
                                    labelText="PAN No"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: (e) =>
                                        this.handleChangeHandler(e, "PANNo"),
                                      type: "text",
                                      id: "PANNo",
                                      name: "PANNo",
                                      value: PANNo,
                                      disabled: isPANVerified ? true : false,

                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {this.state.isPANVerified ? (
                                            <img
                                              style={{
                                                height: "20px",
                                                width: "20px",
                                                marginInlineEnd: "2px",
                                              }}
                                              src={yes}
                                            />
                                          ) : (
                                            <span>
                                              <img
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  marginInlineEnd: "2px",
                                                }}
                                                src={pending}
                                              />
                                              &nbsp;
                                              <Button
                                                style={{
                                                  height: "20px",
                                                  width: "60px",
                                                  color: "white",
                                                  fontSize: "12px",
                                                }}
                                                onClick={() =>
                                                  this.getPanVerifyData(
                                                    "getPanVerifyData"
                                                  )
                                                }
                                                className="buyVessel-btn"
                                              >
                                                verify
                                              </Button>
                                            </span>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    helperText={PANNoErrText}
                                    id="outlined-required"
                                    error={PANNoErr}
                                  />
                                  <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={UserAttachmentPanFile}
                                    allowReorder={true}
                                    credits={""}
                                    instantUpload={true}
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={
                                      'PDF (max size 1MB) <span class="block filepond--label-action">Browse</span>'
                                    }
                                    onupdatefiles={(fileItems) => {
                                      this.UserAttachmentFile(
                                        fileItems,
                                        x.UserDocType
                                      );
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ) : x.UserDocType == "IEC" ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div className="input-control p-position-relative p-pt-2">
                                  <CustomInput
                                    labelText="IEC"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: (e) =>
                                        this.handleChangeHandler(e, "IEC"),
                                      type: "text",
                                      id: "IEC",
                                      name: "IEC",
                                      value: IEC,
                                    }}
                                    helperText={IECErrText}
                                    id="outlined-required"
                                    error={IECErr}
                                  />
                                  <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={UserAttachmentIecFile}
                                    allowReorder={true}
                                    credits={""}
                                    instantUpload={true}
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={
                                      'PDF (max size 1MB) <span class="block filepond--label-action">Browse</span>'
                                    }
                                    onupdatefiles={(fileItems) => {
                                      this.UserAttachmentFile(
                                        fileItems,
                                        x.UserDocType
                                      );
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ) : x.UserDocType == "Stuffing Permission" ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div className="input-control p-position-relative p-pt-2">
                                  <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={UserAttachmentStuffingPermissionFile}
                                    allowReorder={true}
                                    credits={""}
                                    instantUpload={true}
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={
                                      'PDF (max size 1MB) <span class="block filepond--label-action">Browse</span>'
                                    }
                                    onupdatefiles={(fileItems) => {
                                      this.UserAttachmentFile(
                                        fileItems,
                                        x.UserDocType
                                      );
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ) : x.UserDocType == "KYC Form" ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div className="input-control p-position-relative p-pt-2">
                                  <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={UserAttachmentKycFormFile}
                                    allowReorder={true}
                                    credits={""}
                                    instantUpload={true}
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={
                                      'PDF (max size 1MB) <span class="block filepond--label-action">Browse</span>'
                                    }
                                    onupdatefiles={(fileItems) => {
                                      this.UserAttachmentFile(
                                        fileItems,
                                        x.UserDocType
                                      );
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ) : x.UserDocType == "Drawback Details" ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div className="input-control p-position-relative p-pt-2">
                                  <CustomInput
                                    labelText="Draw Back Details"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: (e) =>
                                        this.handleChangeHandler(e, "DrawBack"),
                                      type: "text",
                                      id: "DrawBack",
                                      name: "DrawBack",
                                      value: DrawBack,
                                    }}
                                    helperText={DrawBackErrText}
                                    id="outlined-required"
                                    error={DrawBackErr}
                                  />

                                  <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={UserAttachmentDrawbackDetailsFile}
                                    allowReorder={true}
                                    credits={""}
                                    instantUpload={true}
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={
                                      'PDF (max size 1MB) <span class="block filepond--label-action">Browse</span>'
                                    }
                                    onupdatefiles={(fileItems) => {
                                      this.UserAttachmentFile(
                                        fileItems,
                                        x.UserDocType
                                      );
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ) : x.UserDocType == "AD Code" ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div className="input-control p-position-relative p-pt-2">
                                  <CustomInput
                                    labelText="Ad Code"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: (e) =>
                                        this.handleChangeHandler(e, "AdCode"),
                                      type: "text",
                                      id: "AdCode",
                                      name: "AdCode",
                                      value: AdCode,
                                    }}
                                    helperText={AdCodeErrText}
                                    id="outlined-required"
                                    error={AdCodeErr}
                                  />

                                  <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={UserAttachmentADCodeFile}
                                    allowReorder={true}
                                    credits={""}
                                    instantUpload={true}
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={
                                      'PDF (max size 1MB) <span class="block filepond--label-action">Browse</span>'
                                    }
                                    onupdatefiles={(fileItems) => {
                                      this.UserAttachmentFile(
                                        fileItems,
                                        x.UserDocType
                                      );
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ) : null}
                          </>
                        </AccordionTab>
                      );
                    })
                  ) : (
                    <div>Please select document type to upload document</div>
                  )}
                </Accordion>
              </>
            )}
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) =>
                this.setState({
                  isUserAttachment: !this.state.isUserAttachment,
                })
              }
            >
              {!isUserAttachment ? (
                "KYC Documents"
              ) : (
                <>
                  <i className="icon-add-user mainDarkBlue"></i>{" "}
                  <span>Information</span>
                </>
              )}
            </Button>
            <Button
              className="primary-btn1"
              onClick={(e) => this.handleClose("openAddImporter")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => this.addUpdateHandlerImporter(e)}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openUserNetwork}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>User Details</div>
            {/* <div className="dialog-close" onClick={(e) => this.handleClose("openUserNetwork")}>
                            <i className="icon-modal-close"></i>
                        </div> */}
          </DialogTitle>
          <React.Fragment>
            <DialogContent>
              <h2>
                User already registered in portlink. Would you like to add this
                user in your network ?
              </h2>
              <div className="dialog-content-area1 p-d-flex">
                <div className="dialog-user-img">
                  <img
                    src={
                      this.state.UserInfo.ProfilePic == "" ||
                        this.state.UserInfo.ProfilePic == null ||
                        this.state.UserInfo.ProfilePic == undefined
                        ? User
                        : individual_profile_image_link +
                        this.state.UserInfo.ProfilePic
                    }
                  />
                </div>
                <div className="network-details">
                  <h3>{this.state.UserInfo.Name}</h3>
                  <h2>{this.state.UserInfo.UserRole}</h2>
                  <h2>{this.state.UserInfo.PhoneNumber}</h2>
                  <h2>{this.state.UserInfo.CompanyName}</h2>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <div className="p-d-flex p-jc-end">
                <Button
                  className="primary-btn1 p-mr-2"
                  onClick={(e) => this.handleClose("openNoNetworkManual")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  className="primary-btn"
                  color="primary"
                  onClick={() => this.AddtoMyNetwork("new")}
                >
                  Sent Request
                </Button>
              </div>
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
            </DialogActions>
          </React.Fragment>
        </Dialog>
        <Dialog
          open={this.state.openNetworkUserDeleteModal}
          onClose={() => this.handleClose("NetworkUserDelete")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Bill Of Entry</div>

            <div
              className="dialog-close"
              onClick={() => this.handleClose("NetworkUserDelete")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <div>Are you sure you want to delete ?</div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={() => this.handleClose("NetworkUserDelete")}
                color="primary"
              >
                No
              </Button>

              <Button
                className="primary-btn"
                color="primary"
                onClick={() => this.delete(this.state.ID)}
              >
                Yes
              </Button>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={this.state.openNetworkBlRealeaseReminder}
          onClose={() => this.handleClose("NetworkBlRealeaseReminder")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>BL Release Reminder "In Days"</div>

            <div
              className="dialog-close"
              onClick={() => this.handleClose("NetworkBlRealeaseReminder")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <GridItem sm={6}>
                <div className="input-control ipt-with-icon">
                  <CustomInput
                    labelText="BL Release Reminder"
                    helperText={this.state.blReleaseReminderErrText}
                    error={this.state.blReleaseReminderErr}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) =>
                        this.handleBLReminderChange(e, "blReleaseReminder"),
                      type: "number",
                      id: "blReleaseReminder",
                      name: "blReleaseReminder",
                      value: this.state.blReleaseReminder,
                    }}
                  />
                </div>
              </GridItem>
            </DialogContent>

            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={() => this.handleClose("NetworkBlRealeaseReminder")}
                color="primary"
              >
                Cancel
              </Button>

              <Button
                className="primary-btn"
                color="primary"
                onClick={() => this.addBLReleaseReminder()}
              >
                Add
              </Button>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={NetorkUserFileModel}
          onClose={() => this.handleClose("NetorkUserFile")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div className="attachment-title">
              <h5>Attachments / Documents</h5>
            </div>
            <div
              className="dialog-close"
              onClick={() => this.handleClose("NetorkUserFile")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <div className="attachment-info">
                <GridContainer>
                  {PDFFile.length != 0
                    ? PDFFile.map((x, key) => {
                      return (
                        <GridItem xs={12} sm={12} md={6}>
                          <div className="attachment-container">
                            {x.FileName?.split(".")
                              .pop()
                              .toLowerCase() == "pdf" ? (
                              <a
                                href={apiBase + x.FilePath}
                                className="attachment-box"
                                target="_blank"
                              >
                                <i className="icon-pdf"></i>
                              </a>
                            ) : x.FileName.split(".")
                              .pop()
                              .toLowerCase() == "png" ||
                              x.FileName.split(".")
                                .pop()
                                .toLowerCase() == "jpeg" ||
                              x.FileName.split(".")
                                .pop()
                                .toLowerCase() == "jpg" ? (
                              <div
                                className="attachment-box-img"
                                onClick={() => this.showImgPreview(key)}
                              >
                                <img
                                  id={"attachImg-"}
                                  src={apiBase + x.FilePath}
                                />
                              </div>
                            ) : null}
                            {this.state.showImg != null ? (
                              <Lightbox
                                images={
                                  PDFFile.length
                                    ? PDFFile.filter(
                                      (i) =>
                                        i.FileName.split(".")
                                          .pop()
                                          .toLowerCase() == "png" ||
                                        i.FileName.split(".")
                                          .pop()
                                          .toLowerCase() == "jpeg" ||
                                        i.FileName.split(".")
                                          .pop()
                                          .toLowerCase() == "jpg"
                                    ).map((img) => ({
                                      url: apiBase + x.FilePath,
                                    }))
                                    : null
                                }
                                startIndex={this.state.showImg}
                                onClose={this.hideImgPreview}
                                allowRotate={true}
                                allowReset={true}
                              />
                            ) : null}
                            <div className="attachment-name">
                              <p>{x.DocumentType}</p>
                            </div>
                          </div>
                        </GridItem>
                      );
                    })
                    : null}
                </GridContainer>
              </div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={() => this.handleClose("NetorkUserFile")}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </React.Fragment>
        </Dialog>
      </div>
    );
  }
}
export default Network;
