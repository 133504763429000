import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../../CommonConfig.js";

import SimpleBackdrop from "../../../utils/general.js";
import api from "../../../utils/api.js";
import cogoToast from "cogo-toast";
import APIConstant from "../../../utils/constant.js";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { Dialog } from "primereact/dialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import CustomInput from "components/CustomInput/CustomInput";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "react-datetime";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { MultiSelect } from "primereact/multiselect";
import "date-fns";
import { apiBase } from "config.js";
import { ColorPicker } from "primereact/colorpicker";
import Checkbox from "@material-ui/core/Checkbox";
import { Calendar } from "primereact/calendar";

const Warehouse = (props) => {
  let [userId, setUserId] = useState("");
  let [search, setSearch] = useState("");
  let [wareHouseId, setWareHouseId] = useState("");
  let [wareHouseList, setWareHouseList] = useState([]);
  let [userPageAccess, setUserPageAccess] = useState([]);
  let [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess") || [];
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      getWareHouseList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getWareHouseList = async (UserId) => {
    try {
      // Loader.show();
      let data = {
        ID: UserId,
      };

      await api
        .post(APIConstant.path.GetWareHouseList, data)
        .then((response) => {
          if (response.Success == 1) {
            Loader.hide();
            setWareHouseList(response?.Data);
          } else {
            Loader.hide();
            setWareHouseList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteWareHouse = async () => {
    try {
      Loader.show();
      let data = {
        ID: wareHouseId,
        UserId: userId,
      };

      await api
        .post(APIConstant.path.DeleteWareHouse, data)
        .then((response) => {
          handleClose("deleteNodal");
          if (response.Success == 1) {
            Loader.hide();
            getWareHouseList(userId);
          } else {
            Loader.hide();
            getWareHouseList(userId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = (type) => {
    if (type == "deleteNodal") {
      setDeleteModal(false);
      setWareHouseId("");
    }
  };

  const filterWarehouseList = () => {
    let filterGetMasterList = [];
    if (wareHouseList.length) {
      filterGetMasterList = wareHouseList;
      if (search) {
        filterGetMasterList = filterGetMasterList.filter((x) => {
          return (
            x.WareHouseName.toLowerCase().indexOf(search.toLowerCase()) !==
            -1 ||
            x.EDI.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.PolicyNo.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.InsuranceCompanyName.toLowerCase().indexOf(
              search.toLowerCase()
            ) !== -1
          );
        });
      }
    }
    return filterGetMasterList;
  };

  return (
    <div className="wayment-owner-dashboard e-weighment-dashoard">
      <div className="heading-area p-d-flex p-ai-center p-jc-between p-mb-2">
        <div className="p-d-flex p-ai-center">
          <i className="pi pi-align-center"></i>
          <h2>Warehouse Master</h2>
        </div>
        <div className="p-d-flex p-ai-center wb-filter-btn">
          <div className="searchbar-area p-as-center">
            <InputText
              type="search"
              onInput={(e) => setSearch(e.target.value)}
              placeholder="Global Search"
              size="30"
              style={{ marginRight: 10 }}
            />
            <i className="icon-search"></i>
          </div>
          {userPageAccess.includes("Add Warehouse") ? (
            <div>
              <div
                className="e-add-btn"
                onClick={(e) => props.history.push("add-ware-house")}
              >
                <i className="icon-add"></i>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <hr />
      <div>
        {/* {loading === true ?
                    <div className="loading">
                        <SimpleBackdrop />
                    </div>
                    : null} */}

        {filterWarehouseList()?.length ? (
          filterWarehouseList()?.map((x, i) => {
            return (
              <div className="ship-item p-pt-3" key={i}>
                <div className="daily-dashboard p-h-100">
                  <div className="card p-p-4 p-m-0">

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={3}>
                        <div className="e-card-section"
                        // style={{ width: "25%" }}
                        // onClick={(e) => this.viewContainerDetails(e, x)}
                        >
                          <div className="p-d-flex p-ai-center u-detail">
                            <div className="icon-box u-i-box bg-f">
                              <i className="icon-vessel"></i>
                            </div>
                            <div className="item-info u-i-text">

                              <p className="title">EDI Code</p>
                              <p className="sub-title">
                                {CommonConfig.isEmpty(x.EDI) ? "-" : x.EDI}{" "}
                              </p>
                              <div className="badge p-mt-2">
                                <div className="single-badge bg">
                                  <p className="s-badge-text color" style={{ color: "#3A588F" }}>
                                    {CommonConfig.isEmpty(x.WareHouseTypeName)
                                      ? "-"
                                      : x.WareHouseTypeName}
                                  </p>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <div className="e-card-section"
                        // style={{ width: "25%" }}
                        //  onClick={(e) => this.viewContainerDetails(e, x)}
                        >
                          <div className="p-d-flex p-ai-center  u-detail">
                            <div className="icon-box u-i-box bg-t">
                              <i className="icon-Container"></i>
                            </div>
                            <div className="item-info u-i-text">
                              <p className="title">
                                {CommonConfig.isEmpty(x.Address) ? "-" : x.Address}
                              </p>

                              <p className="title">
                                Policy No{" "}
                                <p className="sub-title">
                                  {CommonConfig.isEmpty(x.PolicyNo)
                                    ? "-"
                                    : x.PolicyNo}
                                </p>
                              </p>

                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <div className="e-card-section"
                        // style={{ width: "25%" }}
                        //  onClick={(e) => this.viewContainerDetails(e, x)}
                        >
                          <div className="p-d-flex p-ai-center  u-detail">
                            <div className="icon-box u-i-box bg-t">
                              <i className="icon-Container"></i>
                            </div>
                            <div className="item-info u-i-text">
                              <p className="title">
                                Company{" "}
                                <p className="sub-title">
                                  {CommonConfig.isEmpty(x.InsuranceCompanyName)
                                    ? "-"
                                    : x.InsuranceCompanyName}
                                </p>
                              </p>
                              <div className="badge">
                                <div className="single-badge">
                                  <p className="s-badge-text">
                                    Amount - {" "}

                                    {CommonConfig.isEmpty(x.InsuranceAmount)
                                      ? "-"
                                      : x.InsuranceAmount}

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <div className="e-card-section"
                        // style={{ width: "30%", gap: "10px" }}
                        >
                          <div className="p-d-flex p-ai-center">
                            {userPageAccess?.includes("Edit Warehouse") ? (
                              <div
                                className="filter-btn p-p-0"
                                onClick={(e) =>
                                  props.history.push({
                                    pathname: "/admin/add-ware-house",
                                    state: {
                                      WareHouseId: x.WareHouseMasterId,
                                      IsEdit: true,
                                    },
                                  })
                                }
                              >
                                <i className="icon-editImage"></i>
                              </div>
                            ) : null}
                            {userPageAccess?.includes("Delete Warehouse") ? (
                              <div
                                className="filter-btn p-p-0"
                                onClick={(e) => {
                                  setWareHouseId(x?.WareHouseMasterId);
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="icon-delete"></i>
                              </div>
                            ) : null}
                            {userPageAccess?.includes("View Warehouse") ? (
                              <div
                                className="filter-btn p-p-0"
                                onClick={(e) =>
                                  props.history.push({
                                    pathname: "/admin/view-ware-house",
                                    state: {
                                      WareHouseId: x.WareHouseMasterId,
                                    },
                                  })
                                }
                              >
                                <i className="icon-view"></i>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </GridItem>

                    </GridContainer>

                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No record found</div>
        )}

        {/* Modal For Delete */}
        <Dialog
          visible={deleteModal}
          onHide={() => handleClose("deleteNodal")}
          aria-labelledby="cha-form-dialog-title"
          disableEscapeKeyDown
          closable={false}
        // closeOnEscape={false}
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Delete Warehouse</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("deleteNodal")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <h6>Are you sure want to delete?</h6>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("deleteNodal")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={deleteWareHouse}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Warehouse;
