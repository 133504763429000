import React, { useEffect, useLayoutEffect, useState } from 'react';
import APIConstant from "../../utils/constant";
import api from '../../utils/api';
import { apiBase } from '../../config';
import Loader from 'components/Shared/Loader/Loader';
import cogoToast from 'cogo-toast';
import { CommonConfig } from "../../CommonConfig.js";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';
import SimpleBackdrop from 'utils/general';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const UpgradeUserPlan = (props) => {
    const isPlanExpiring = localStorage.getItem('IsPlanAboutExpired');
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [isExpiring, setIsExpiring] = useState(isPlanExpiring == "1" ? true : false);
    const [isExpiringStatus, setIsExpiringStatus] = useState('');
    const [isExpiringDays, setIsExpiringDays] = useState(0);
    const [isExpiringDate, setIsExpiringDate] = useState('');
    const [showIsRenewModel, setShowIsRenewModel] = useState(false);
    const [planList, setPlanList] = useState([]);
    const [plan, setPlan] = useState('');
    const [planErr, setPlanErr] = useState(false);
    const [planErrText, setPlanErrText] = useState('');
    const [discRate, setDiscRate] = useState('');
    const [planDesc, setPlanDesc] = useState('');
    const [planRate, setPlanRate] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isSubmitEnable, setIsSubmitEnable] = useState(false);

    useLayoutEffect(() => {
        // debugger
        const isPlanExpiring = localStorage.getItem('IsPlanAboutExpired');
        const isPlanExpiringDate = localStorage.getItem('IsPlanEndDate');
        const isPlanStatus = localStorage.getItem('IsPlanStatus');
        const isPlanRenewDays = localStorage.getItem('IsPlanRenewDays');
        const Userdata = JSON.parse(localStorage.getItem('Userdata'));
        const data = localStorage.getItem('Infodata');
        setIsExpiringDays(isPlanRenewDays)
        setIsExpiring(isPlanExpiring == "1" ? true : false);
        setIsExpiringStatus(isPlanStatus);
        setIsExpiringDate(isPlanExpiringDate);
        setUserId(data);
        getPlanList(Userdata?.fkRoleId, Userdata?.AccountType);

    }, [isPlanExpiring])

    useEffect(() => {
        // debugger
        // const isPlanExpiring = localStorage.getItem('IsPlanAboutExpired');
        // const isPlanExpiringDate = localStorage.getItem('IsPlanEndDate');
        // const isPlanStatus = localStorage.getItem('IsPlanStatus');
        // const isPlanRenewDays = localStorage.getItem('IsPlanRenewDays');
        const Userdata = JSON.parse(localStorage.getItem('Userdata'));
        // const data = JSON.parse(localStorage.getItem('Infodata'));
        // setIsExpiringDays(isPlanRenewDays)
        // setIsExpiring(isPlanExpiring == "1" ? true : false);
        // setIsExpiringStatus(isPlanStatus);
        // setIsExpiringDate(isPlanExpiringDate);
        // setUserId(data);
        getPlanList(Userdata?.fkRoleId, Userdata?.AccountType);

    }, [])

    const getPlanList = async (UserType, AccountType) => {
        var params = {
            AccountType: AccountType,
            UserType: UserType
        }

        // await api.post(APIConstant.path.getUserPlanByAccountAndUserType, params).then(res => {
        //     if (res.Success === 1) {
        //         setPlanList(res.Info.Data);
        //     }
        // }).catch(err => {
        //     console.log("Err: ", err);
        // });
    }

    const handleChange = async (e, type) => {
        const value = e.value
        if (type === 'plan') {
            if (CommonConfig.isEmpty(value)) {
                setPlan('');
                setPlanErr(true);
                setPlanErrText('Plan is required');
            } else {
                const planData = planList.find((x) => x.PlanId == value);
                setPlan(value);
                setPlanErr(false);
                setPlanErrText('');

                if (planData) {
                    const numVal1 = Number(planData.PlanTotalPrice);
                    const numVal2 = Number(planData.PlanDiscPrice);
                    const discountPercentage = parseFloat(100 - (numVal2 / numVal1) * 100).toFixed(2);
                    const planDuration = parseInt(planData.PlanDuration.match(/\d+/)[0], 10);

                    setDiscRate(planData.PlanDiscPrice);
                    setPlanDesc(planData.PlanDesc);
                    setPlanRate(planData.PlanTotalPrice);
                    setDiscountPercentage(discountPercentage);
                    setStartDate(moment().format('YYYY-MM-DD HH:mm:ss'));
                    setEndDate(moment().add(planDuration, 'months').format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        }
    }

    const handleClose = (type) => {
        if (type === 'showIsRenewModel') {
            setShowIsRenewModel(false);
            setPlan('');
            setPlanErr(false);
            setPlanErrText('');
            setDiscRate('');
            setPlanDesc('');
            setPlanRate('');
            setDiscountPercentage('');
            setStartDate('');
            setEndDate('');
        }
    }

    const handleRenewClick = async (e) => {
        e.preventDefault()
        setShowIsRenewModel(true);
    };

    const handleSubmitRenewPlan = async (e) => {
        e.preventDefault();

        if (CommonConfig.isEmpty(plan)) {
            cogoToast.error('Select plan for upgrade!');
            return;
        }

        setIsSubmitEnable(true);
        await displayRazorpay(e)

    }

    const UpgradeUserPlanFaildLogs = async (payloads) => {
        await api.post(APIConstant.path.UpgradeUserPlanFaildLogs, payloads).then((result) => {
            if (result.Success === 1) {
                cogoToast.success(result?.Message);
                setIsSubmitEnable(false);
                setLoading(false);
                handleClose(showIsRenewModel);
                window.location.reload();
            } else {
                cogoToast.error(result?.Message);
                setIsSubmitEnable(false);
                setLoading(false);
            }
        }).catch((err) => {
            console.log("Error: ", err);
        });
    }

    const displayRazorpay = async (e) => {
        e.preventDefault()
        try {
            // Load Razorpay SDK
            const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK failed to load. Please check your internet connection.");
                setIsSubmitEnable(false);
                setLoading(false);
                return;
            }

            // Fetch payment details from the server
            const result = await api.post(apiBase + '/ajax/player.php?action=RazorPayCheckOut', {
                Amount: discRate,
                Currency: 'INR'
            });

            if (!result || result.Success !== 1) {
                console.log("Server error. Please try again later.");
                setIsSubmitEnable(false);
                setLoading(false);
                return;
            }

            const { amount, id: order_id, currency } = result.Info.PaymentDetails;

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEYID, // Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                name: "PortLinksIndia Pvt. Ltd.",
                description: "Renew Pay",
                order_id: order_id,
                handler: async (response) => {

                    if (response) {
                        cogoToast.success('Payment successful and upgrade plan process is in progress...');

                        var arrUserDeatil = {
                            UserId: userId,
                            PlanName: plan,
                            OrderCreationId: order_id,
                            RazorpayPaymentId: response.razorpay_payment_id,
                            RazorpayOrderId: response.razorpay_order_id,
                            RazorpaySignature: response.razorpay_signature,
                            PurchaseAmount: amount,
                            Currency: currency
                        }

                        const result = await api.post(APIConstant.path.UpgradeUserPlan, arrUserDeatil);
                        console.log("result", result);
                        if (result.Success === 1) {
                            cogoToast.success(result.Message);
                            setIsSubmitEnable(false);
                            setLoading(false);
                            handleClose(showIsRenewModel);
                            window.location.reload();
                        } else if (result.Success === 0) {
                            cogoToast.error(result.Message);
                            await UpgradeUserPlanFaildLogs(arrUserDeatil)
                            setIsSubmitEnable(false);
                            setLoading(false);
                        }
                        else {
                            cogoToast.error("There is a system error. We are fixing it asap");
                            await UpgradeUserPlanFaildLogs(arrUserDeatil);
                            setIsSubmitEnable(false);
                            setLoading(false);
                        }
                    }
                },
                prefill: {
                    name: "PortLinksIndia Pvt. Ltd.",
                    email: "info@portlinksinida.com",
                    contact: "+91 8160-6275-81",
                },
                readonly: {
                    email: true,
                    contact: true
                },
                notes: {
                    address: "AHMEDABAD, GUJARAT",
                },
                theme: {
                    // color: "#61dafb",
                    color: "#1da1f2"
                },
                modal: {
                    ondismiss: function () {
                        // Handle Razorpay modal dismissal (e.g., when user closes the window)
                        setIsSubmitEnable(false);
                        setLoading(false);
                    }
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error("Error in displayRazorpay:", error);
            alert("An unexpected error occurred. Please try again.");
            setIsSubmitEnable(false);
        }
    }



    return (
        <div>
            {isExpiring && (
                <div className="warning-message">
                    {
                        isExpiringStatus === 'WR' ?
                            <p>Your plan will expire in {isExpiringDays} days. </p>
                            :
                            <p>Your plan is expired.</p>
                    }
                    <button type="button" onClick={(e) => handleRenewClick(e)}>Renew Now</button>
                </div>
            )}

            <Dialog open={showIsRenewModel} onClose={() => handleClose("showIsRenewModel")} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle className="custom-dialog-header" id="form-dialog-title">
                    <div>Renew Plan</div>
                    <div className="dialog-close" onClick={() => handleClose("showIsRenewModel")}>
                        <i className="icon-modal-close"></i>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content-area">
                    <div className="p-col-12 p-md-12 p-pt-2">
                        <div className="p-w-100 ">
                            <div className="custom-inputs">
                                <label htmlFor="plan">Select Plan</label>
                                <Dropdown
                                    className="p-w-100 p-mt-2"
                                    inputId="plan"
                                    placeholder="Select Plan"
                                    value={plan}
                                    onChange={(e) => handleChange(e, 'plan')}
                                    options={planList}
                                    optionLabel="label"
                                    filter
                                />
                            </div>
                            <span className="error-msg">
                                {planErr ? planErrText : null}
                            </span>
                        </div>
                        {!CommonConfig.isEmpty(plan) ?
                            <>
                                <div className="login-input">
                                    <div>
                                        <div className="p-grid p-ai-center p-jc-between">

                                            <div className='p-mb-3'>Membership Period</div>
                                            <div className="p-grid primary-border p-ai-center b-radius-10 p-p-1">
                                                <div className="p-pr-2 border-right p-py-1">
                                                    Price: <strong>₹ {discRate}</strong>
                                                </div>

                                                <div className="p-pl-2">
                                                    <strike>₹ {planRate}</strike>
                                                    <br />
                                                    <label>
                                                        {discountPercentage} % off
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="p-grid primary-border p-ai-center b-radius-10 p-p-1">
                                            <div className="p-pr-2 p-py-1">
                                                Plan Description: <strong>₹ {planDesc}</strong>
                                            </div>
                                        </div>

                                        <div className="p-d-flex p-jc-between p-mt-3">
                                            <div>
                                                <div>
                                                    <strong>Starts</strong>
                                                </div><div>
                                                    <strong>{moment(startDate).format("DD MMMM YYYY hh:mm A")} </strong>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <strong>Ends</strong>
                                                </div><div>
                                                    <strong>{moment(endDate).format("DD MMMM YYYY hh:mm A")} </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null
                        }
                    </div>

                </DialogContent>
                <DialogActions className="p-p-0 dialog-footer1">
                    <div style={{ alignItems: "center", justifyContent: "center", width: "100%", textAlign: "center" }}>
                        {loading === true ? (<div className="loading"><SimpleBackdrop /></div>) : (null)}
                    </div>

                    <Button className="primary-btn1" onClick={() => handleClose("showIsRenewModel")} color="primary">Close</Button>
                    <Button className="primary-btn" variant="contained" onClick={(e) => handleSubmitRenewPlan(e)} disabled={loading}>Submit</Button>


                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UpgradeUserPlan