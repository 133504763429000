import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  DialogActions,
  Box,
  Typography,
  DialogContent,
} from "@material-ui/core";
import { CommonConfig } from "CommonConfig";
import GridContainer from "components/Grid/GridContainer";
import { InputText } from "primereact/inputtext";
import api from "utils/api";
import APIConstant from "utils/constant";
import GridItem from "components/Grid/GridItem";
import { Button } from "primereact/button";
import { Autocomplete } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import cogoToast from "cogo-toast";
import { MultiSelect } from "primereact/multiselect";

const initialNameState = {
  name: "",
  error: false,
  errorText: "",
};

const initialNameArrayState = {
  name: [],
  error: false,
  errorText: "",
};

const Configuration = (props) => {
  const [userId, setUserId] = useState("");
  const [configId, setConfigId] = useState("");
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [addConfigurationDialog, setAddConfigurationDialog] = useState(false);
  const [deleteConfigurationDialog, setDeleteConfigurationDialog] = useState(
    false
  );
  const [readOnly, setReadOnly] = useState(false);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState(initialNameState);
  const [lowLimit, setLowLimit] = useState(initialNameState);
  const [idealLimit, setIdealLimit] = useState(initialNameState);
  const [highLimit, setHighLimit] = useState(initialNameState);
  const [itemList, setItemList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [unit, setUnit] = useState(initialNameState);
  const [configurationList, setConfigurationList] = useState([]);
  const [subWareHouse, setSubWareHouse] = useState(initialNameArrayState);
  const [subWareHouseList, setSubWareHouseList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [isApplyToAll, setIsApplyToAll] = useState(false);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    CommonConfig.dataTableConfig.rowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonConfig.dataTableConfig.rowsPerPage
  );

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);

      getItemList(data);
      getUnitList(data);
      getConfigurationList();
      getEquipmentList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getItemList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetItemMasterList, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({ ...data, label: data.chapter_name, value: data.value })
          );
          setItemList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getUnitList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetUnitMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data;

          setUnitList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getSubWareHouseList = async (ItemId) => {
    let params = {
      UserId: userId,
      ItemId,
    };
    api
      .post(APIConstant.path.GetSubwarehouseItemList, params)
      .then((response) => {
        if (response.Success === 1) {
          setSubWareHouseList(response.Data);
        }
      })
      .catch((err) => {});
  };

  const getEquipmentList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetEquipmentMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          setEquipmentList(
            response.Data.map((equipment) => ({
              label: equipment.EquipmentName,
              value: equipment._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const getConfigurationList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetConfigurationList, params)
      .then((response) => {
        if (response.Success === 1) {
          setConfigurationList(response.Data);
        }
      })
      .catch((err) => {});
  };

  const addUpdateConfiguration = async () => {
    let params = {
      ConfigId: configId,
      UserId: userId,
      Type: "Item",
      Item: item.name,
      SubWarehouse: subWareHouse.name,
      Unit: unit.name,
      LowLimit: lowLimit.name,
      IdealLimit: idealLimit.name,
      HighLimit: highLimit.name,
    };
    api
      .post(APIConstant.path.AddUpdateConfiguration, params)
      .then((response) => {
        if (response.Success === 1) {
          handleClose("AddAlert");
          getConfigurationList();
          cogoToast.success(response.Message);
        } else {
          cogoToast.error(response.Message);
        }
      })
      .catch((err) => {});
  };

  const getConfigurationById = async (id) => {
    let params = {
      ConfigId: id,
      UserId: userId,
    };
    api
      .post(APIConstant.path.GetConfigurationById, params)
      .then((response) => {
        if (response.Success === 1) {
          let data = response.Data;
          setItem({
            name: data.Item,
            error: false,
            errorText: "",
          });
          setUnit({
            name: data.Unit,
            error: false,
            errorText: "",
          });
          setSubWareHouse({
            name: data.SubWarehouse,
            error: false,
            errorText: "",
          });
          setLowLimit({
            name: data.LowLimit,
            error: false,
            errorText: "",
          });
          setIdealLimit({
            name: data.IdealLimit,
            error: false,
            errorText: "",
          });
          setHighLimit({
            name: data.HighLimit,
            error: false,
            errorText: "",
          });
        }
      })
      .catch((err) => {});
  };

  const deleteConfiguration = async () => {
    let params = {
      ConfigId: configId,
      UserId: userId,
    };
    api
      .post(APIConstant.path.DeleteConfiguration, params)
      .then((response) => {
        if (response.Success === 1) {
          handleClose("DeleteMasterType");
          cogoToast.success(response.Message);
          getConfigurationList();
        }
      })
      .catch((err) => {});
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  const actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {userPageAccess.includes("View Config") ? (
          <i
            class="icon-view"
            onClick={(e) => handleOpen(rowData, "ViewMasterType")}
          ></i>
        ) : null}
        {userPageAccess.includes("Edit Config") ? (
          <i
            onClick={(e) => handleOpen(rowData, "EditMasterType")}
            class="icon-editImage"
          ></i>
        ) : null}
        {userPageAccess.includes("Delete Config") ? (
          <i
            onClick={(e) => handleOpen(rowData, "DeleteMasterType")}
            class="icon-delete"
          ></i>
        ) : null}
      </div>
    );
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const handleOpen = async (e, type) => {
    if (type === "AddAlert") {
      setAddConfigurationDialog(true);
    } else if (type === "ViewMasterType") {
      setConfigId(e._id);
      await getConfigurationById(e._id);
      setReadOnly(true);
      setAddConfigurationDialog(true);
    } else if (type === "EditMasterType") {
      setConfigId(e._id);
      await getSubWareHouseList(e.ItemId);
      await getConfigurationById(e._id);
      setAddConfigurationDialog(true);
    } else if (type === "DeleteMasterType") {
      setConfigId(e._id);
      setDeleteConfigurationDialog(true);
    }
  };

  const handleChange = async (e, type) => {
    if (type === "item") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setItem({
          name: "",
          error: true,
          errorText: "Please enter item",
        });
      } else {
        setItem({
          name: e.value,
          error: false,
          errorText: "",
        });
        let unitId =
          itemList.find((item) => item.value === e.value)?.unitId || "";
        setUnit({
          name: unitId,
          error: false,
          errorText: "",
        });
        await getSubWareHouseList(e.value);
      }
    } else if (type === "unit") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setUnit({
          name: "",
          error: true,
          errorText: "Please enter unit",
        });
      } else {
        setUnit({
          name: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "subWareHouse") {
      if (e.target.value.length <= 0) {
        setSubWareHouse({
          name: [],
          error: true,
          errorText: `Please select Sub Warehouse`,
        });
      } else {
        setSubWareHouse({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "applyAll") {
      setIsApplyToAll((prevState) => !prevState);
      if (e.target.checked) {
        let tempData = [];
        subWareHouseList.map((warehouse) => {
          tempData.push(warehouse.value);
        });
        setSubWareHouse({
          name: tempData,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "lowLimit") {
      {
        if (CommonConfig.isObjectEmpty(e.target.value)) {
          setLowLimit({
            name: "",
            error: true,
            errorText: `Please select Low Limit`,
          });
        } else {
          setLowLimit({
            name: e.target.value,
            error: false,
            errorText: "",
          });
        }
      }
    } else if (type === "idealLimit") {
      {
        if (CommonConfig.isObjectEmpty(e.target.value)) {
          setIdealLimit({
            name: "",
            error: true,
            errorText: `Please select Ideal Limit`,
          });
        } else {
          setIdealLimit({
            name: e.target.value,
            error: false,
            errorText: "",
          });
        }
      }
    } else if (type === "highLimit") {
      {
        if (CommonConfig.isObjectEmpty(e.target.value)) {
          setHighLimit({
            name: "",
            error: true,
            errorText: `Please select High Limit`,
          });
        } else {
          setHighLimit({
            name: e.target.value,
            error: false,
            errorText: "",
          });
        }
      }
    }
  };

  const handleClose = (type) => {
    if (type === "AddAlert") {
      setAddConfigurationDialog(false);
      setItem(initialNameState);
      setUnit(initialNameState);
      setSubWareHouse(initialNameArrayState);
      setLowLimit("");
      setHighLimit("");
      setIdealLimit("");
      setReadOnly(false);
      setConfigId("");
    } else if (type === "DeleteMasterType") {
      setDeleteConfigurationDialog(false);
      setConfigId("");
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <div className="main-access-contianer e-main-access">
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label="Items" {...a11yProps(0)} />

          <Tab label="Equipment" {...a11yProps(1)} />
        </Tabs>
        <div className="heading-area">
          <div className="card-header">
            <div className="card-title p-d-lg-flex p-ai-center p-w-100">
              <div className="heading-area-right">
                <div className="searchbar-area p-as-center">
                  <InputText
                    type="search"
                    onInput={(e) => setSearch(e.target.value)}
                    placeholder="Global Search"
                    size="30"
                    style={{ marginRight: 10 }}
                  />
                  <i className="icon-search"></i>
                </div>
                {userPageAccess.includes("Add Config Alert") ? (
                  <div>
                    <div
                      className="e-add-btn"
                      onClick={(e) => handleOpen(e, "AddAlert")}
                    >
                      <i className="icon-add"></i>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <TabPanel
        className="main-card inward-container"
        value={tabValue}
        index={0}
      >
        <div className="main-access-card-master e-card-master">
          <DataTable
            className="main-table table-td"
            // style={{ height: "calc(100vh - 196px)" }}
            value={configurationList}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={true}
            rows={rowsPerPage}
            totalRecords={configurationList.length}
            rowsPerPageOptions={rowsPerPageOptions}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="ItemName"
              header="Items Name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="SubWarehouseName"
              header="Sub Warehouse"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="UnitName"
              header="Unit"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="LowLimit"
              header="Low Limit"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="HighLimit"
              header="High Limit"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="IdealLimit"
              header="Ideal Limit"
              style={{ width: "20%" }}
            ></Column>

            <Column
              field="Action"
              header="Action"
              body={actionTemplate}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
          </DataTable>
        </div>
      </TabPanel>
      <Dialog
        className="stock-modal"
        open={addConfigurationDialog}
        onClose={() => handleClose("AddAlert")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Item Configuration</div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose("AddAlert")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <GridContainer>
          <GridItem xs={12}>
            <div className="input-control select">
              <FormControl fullWidth>
                <Autocomplete
                  id="item"
                  name="item"
                  value={
                    item.name && itemList.length
                      ? itemList.filter((y) => y.value == item.name)[0]
                      : ""
                  }
                  options={itemList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  onChange={(e, value) => handleChange(value, "item")}
                  disabled={readOnly}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Item Name"
                      error={item.error}
                      helperText={item.errorText}
                      label="Item Name"
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>

          <GridItem xs={12}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <label htmlFor="simple-select">Sub-Warehouse</label>

                <MultiSelect
                  value={subWareHouse.name}
                  onChange={(e) => handleChange(e, "subWareHouse")}
                  options={subWareHouseList}
                  optionLabel="label"
                  placeholder="Select Sub Warehouse"
                  className="w-full md:w-20rem"
                  disabled={readOnly}
                />

                <span className="error-msg" style={{ color: "red" }}>
                  {subWareHouse.error ? subWareHouse.errorText : null}
                </span>
              </FormControl>
            </div>
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name={"ApplyAll"}
                  checked={isApplyToAll}
                  onChange={(e) => handleChange(e, "applyAll")}
                  disabled={readOnly}
                />
              }
              label="Apply to all"
            />
          </GridItem>
          <GridItem xs={12}>
            <div className="input-control select">
              <FormControl fullWidth>
                <Autocomplete
                  id="unit"
                  name="unit"
                  value={
                    unit.name && unitList.length
                      ? unitList.filter((y) => y.value == unit.name)[0]
                      : ""
                  }
                  options={unitList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  onChange={(e, value) => handleChange(value, "unit")}
                  disabled={readOnly}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Unit Name"
                      error={unit.error}
                      helperText={unit.errorText}
                      label="Unit Name"
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>

          <GridItem md={12}>
            <TextField
              autoFocus
              margin="dense"
              name="Low Limit"
              label="Low Limit"
              type="text"
              value={lowLimit.name}
              onChange={(e) => handleChange(e, "lowLimit")}
              error={lowLimit.error}
              helperText={lowLimit.errorText}
              fullWidth
              disabled={readOnly}
            />
          </GridItem>
          <GridItem md={12}>
            <TextField
              autoFocus
              margin="dense"
              name="Discount"
              label="Ideal Limit"
              type="text"
              value={idealLimit.name}
              onChange={(e) => handleChange(e, "idealLimit")}
              error={idealLimit.error}
              helperText={idealLimit.errorText}
              fullWidth
              disabled={readOnly}
            />
          </GridItem>
          <GridItem md={12}>
            <TextField
              autoFocus
              margin="dense"
              name="Discount"
              label="High Limit"
              type="text"
              value={highLimit.name}
              onChange={(e) => handleChange(e, "highLimit")}
              error={highLimit.error}
              helperText={highLimit.errorText}
              fullWidth
              disabled={readOnly}
            />
          </GridItem>
        </GridContainer>
        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("AddAlert")}
          >
            Cancel
          </Button>
          {!readOnly && (
            <Button
              className="primary-btn"
              onClick={(e) => addUpdateConfiguration(e)}
              type="submit"
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfigurationDialog}
        onClose={() => handleClose("DeleteMasterType")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Delete Item</div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose("DeleteMasterType")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="CHA-dialog-content-area">
          <h6>Are you sure want to delete?</h6>
        </DialogContent>
        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("DeleteMasterType")}
          >
            Close
          </Button>
          <Button
            className="primary-btn"
            onClick={(e) => deleteConfiguration(e)}
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Configuration;
