/*eslint-disable*/
import React, { Component } from "react";
import logo from "../../assets/equator-img/logo/portlinkLogo.svg";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import api from "../../utils/api";
import { apiBase } from "../../config";
import cogoToast from "cogo-toast";
//import 'firebase/auth';
import spinners from "../../assets/Spinner.gif";
import { Link } from "react-router-dom";
import "primeflex/primeflex.css";

class otplogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resend: false,
      otp: "",
      userid: "",
      phoneNumber: "",
      otpErr: false,
      otphelperText: "",
      helperText: "",
      error: false,
      loading: false,
      isotpsent: false,
      count: 15,
      count1: 30,
      message: "",
      message1: "",
      incorrect: 0,
    };
    this.handleChange_2 = this.handleChange_2.bind(this);
  }

  componentDidMount = () => {
    let data1 = localStorage.getItem("PhoneNumber");
    let data2 = localStorage.getItem("Email");
    let data3 = localStorage.getItem("UserID");

    var regexEmail = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/;
    var regexPhone = /^([0-9]+\s?)*$/g;

    if (data2 !== "" && data2 !== null && data2 !== undefined) {
      if (data2.match(regexEmail)) {
        this.setState({ phoneNumber: data2, userid: data3 });
      } else {
      }
    }
    if (data1 !== "" && data1 !== null && data1 !== undefined) {
      if (data1.match(regexPhone)) {
        this.setState({ phoneNumber: data1, userid: data3 });
      } else {
      }
    }
    let userdata = JSON.parse(localStorage.getItem("Userdata"));
    if (userdata != null) {
      this.setState({
        userid: userdata.UserId,
        phoneNumber: userdata.PhoneNumber,
      });

      let data1 = localStorage.getItem("PhoneNumber");
      let data2 = localStorage.getItem("Email");
      let data3 = localStorage.getItem("UserID");

      if (data2 !== "" && data2 !== null && data2 !== undefined) {
        if (data2.match(regexEmail)) {
          this.setState({ phoneNumber: data2, userid: data3 });
        } else {
        }
      }
      if (data1 !== "" && data1 !== null && data1 !== undefined) {
        if (data1.match(regexPhone)) {
          this.setState({ phoneNumber: data1, userid: data3 });
        } else {
        }
      }
    } else {
    }
    this.inter = setInterval(() => {
      if (this.state.count <= 0) {
        clearInterval(this.inter);
        this.setState({
          message: "Resend code",
        });
      } else {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      }
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.inter, this.inter1);
  }

  verifyPhone = (userid, phoneNumber) => {
    let UserId = userid;
    let Number = phoneNumber;

    var params = {
      UserId: UserId,
      Number: Number,
    };

    api
      .post(APIConstant.path.VerifiedPhone, params)
      .then((res) => {
        if (res.Success === 1) {
          cogoToast.success("Your phone number is verified successfully");
          this.props.history.push("/");
        }
      })
      .catch((err) => {
        cogoToast.error("Someting bad heppend");
      });
  };

  handleChange_2 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let val = e.target.value;
    let regExp = /^([0-9]+\s?)*$/g;

    if (e.target.name === "otp") {
      if (e.target.value === "" && e.target.value === null) {
        this.setState({ otpErr: true, otphelperText: "Please enter OTP" });
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(regExp)
      ) {
        this.setState({ otp: this.state.otp });
      } else {
        this.setState({ otpErr: false, otphelperText: "" });
      }
    }
  };

  handleOTPLoginSubmit = (event) => {
    event.preventDefault();
    // if(this.validate(event)){
    this.setState({ loading: true });
    if (!this.state.otp) {
      this.setState({
        loading: false,
        otpErr: true,
        otphelperText: "Please enter OTP",
      });
    } else if (this.state.otp.length < 4) {
      this.setState({
        loading: false,
        otpErr: true,
        otphelperText: "Please enter valid OTP",
      });
    } else {
      var params = {
        otp: this.state.otp,
        userid: this.state.userid,
      };
      api
        .post(apiBase + "/ajax/player.php?action=verifiedOTP", params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ loading: false });
            // cogoToast.success("OTP has verified successfully");
            this.verifyPhone(this.state.userid, this.state.phoneNumber);
            this.setState({ otp: "" });
            this.props.history.push("/");
          } else {
            if (res.Info == 3) {
              this.setState({ incorrect: 1 });
            }
            this.setState({ loading: false });
            cogoToast.error("OTP is incorrect");
          }
        })
        .catch((err) => {});
    }
    // }else{
    //     return;
    // }
  };

  handleSendOtp = () => {
    let Number = this.state.phoneNumber;
    this.setState({ loading: true });
    var params = {
      email_phone: Number,
    };
    api
      .post(APIConstant.path.SendOTPInMailForLogin, params)
      .then((res) => {
        if (res.Success === 1) {
          this.setState({
            loading: false,
            resend: true,
            resendcode: res.Info.resendcode,
          });
          cogoToast.success("OTP is sent");

          this.inter1 = setInterval(() => {
            if (this.state.count1 <= 0) {
              clearInterval(this.inter1);
              this.setState({
                message1: "Resend code",
              });
            } else {
              this.setState((prevState) => ({ count1: prevState.count1 - 1 }));
            }
          }, 1000);
          if (this.state.count1 === 0) {
            this.setState({ resend: false });
          }
        } else if (res.Success == 2) {
          this.setState({
            loading: false,
            resend: true,
            resendcode: res.Info.resendcode,
          });
        } else {
          if (res.Info == 3) {
            this.setState({ loading: false, incorrect: 1 });
          } else {
            this.setState({ loading: false });
            cogoToast.error("Invalid Mobile or Email");
          }
        }
      })
      .catch((err) => {
        cogoToast.error("Something went wrong");
      });
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  render() {
    // if(this.state.count1 ===0){
    //     this.setState({resend:false});
    // }
    const { loading, phoneNumber } = this.state;
    return this.state.incorrect == 0 ? (
      <div className="container-fluid">
        <div className="p-grid p-m-0">
          <div className="main-header p-w-50">
            <div className="p-grid p-jc-center p-m-0 p-position-relative">
              <div className="header-name">OTP Verification</div>
            </div>
          </div>
          <div className="p-col p-p-0 p-as-center">
            <div className="login-form">
              <form
                method="post"
                onSubmit={(e) => this.handleOTPLoginSubmit(e)}
              >
                <p>
                  Your requested OTP has been sent to : {phoneNumber}{" "}
                  <Link to="/" style={{ color: "#4f4f4f", fontWeight: "bold" }}>
                    {" "}
                    Back{" "}
                  </Link>
                </p>
                <div className="loginform-inner">
                  <div className="login-input">
                    <span className="input-icn">
                      <i class="fa fa-key" aria-hidden="true"></i>
                    </span>
                    <TextField
                      autoFocus
                      label="Enter Your OTP"
                      name="otp"
                      id="otp"
                      className=""
                      value={this.state.otp}
                      onChange={(e) => this.handleChange_2(e)}
                      helperText={this.state.otphelperText}
                      error={this.state.otpErr}
                      // onKeyDown={(e)=>this.handleEnter(e)}
                      inputProps={{ maxLength: 6 }}
                    />
                  </div>
                  <div className="login-btn">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => this.handleOTPLoginSubmit(e)}
                      disabled={loading}
                    >
                      Submit OTP
                    </Button>
                  </div>
                  <div>
                    <TextField
                      type="hidden"
                      className="input-with-icon-grid"
                      name="userid"
                      id="userid"
                      value={this.state.userid}
                      onChange={this.handleChange_2}
                    />
                  </div>

                  <div className="login-snap1">
                    {this.state.resendcode != 3 ? (
                      <p style={{ textAlign: "center" }}>
                        Not received your code?{" "}
                        <span>
                          {this.state.resend ? (
                            this.state.message1 ? (
                              <Link onClick={() => this.handleSendOtp()}>
                                {" "}
                                {this.state.message1}{" "}
                              </Link>
                            ) : (
                              this.state.count1
                            )
                          ) : this.state.message ? (
                            <Link onClick={() => this.handleSendOtp()}>
                              {" "}
                              {this.state.message}{" "}
                            </Link>
                          ) : (
                            this.state.count
                          )}
                        </span>
                      </p>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        You have already resend OTP three times.Please try again
                        after 1 hour.
                      </p>
                    )}
                  </div>

                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "65px",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    {this.state.loading === true ? (
                      <img
                        src={spinners}
                        alt="loading..."
                        width="10%"
                        height="10%"
                      />
                    ) : null}
                  </div>

                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {loading && <i className="fa fa-refresh fa-spin" />}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="p-col p-p-0 login-outer3 p-d-flex p-jc-center p-ai-center">
            <div>
              <div id="logo" style={{ textAlign: "center" }}>
                <div className="col text-center login-logo">
                  <img
                    src={logo}
                    className="img-thumbnail rounded-circle"
                    style={{ borderRadius: "50%" }}
                    alt=""
                    width="10%"
                    height="10%"
                  />
                </div>
              </div>
              <h2
                className="p-m-0"
                style={{ textAlign: "center", color: "#fff" }}
              >
                SIGN IN
              </h2>
              <h5
                className="sub-heading p-mb-3 p-mt-0"
                style={{ textAlign: "center", color: "#ffffff" }}
              >
                By Authentication Process
              </h5>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="container-fluid">
        <div className="login-outer">
          <div id="logo" style={{ textAlign: "center" }}>
            <div className="col login-logo text-center">
              <img
                src={logo}
                className="img-thumbnail rounded-circle"
                style={{ borderRadius: "50%" }}
                alt=""
                width="10%"
                height="10%"
              />
            </div>
          </div>
          <br />
          <div className="login-form">
            <h2 style={{ textAlign: "center" }}>OTP Verification</h2>
            <br />
            {/* <p style={{ textAlign: "center" }}>Your requested OTP has been sent to : {email_phone} <Link to="/loginwithotp" style={{ color: "#4f4f4f", fontWeight: 'bold' }}> Back </Link></p> */}
            <form method="post">
              <div className="loginform-inner">
                <p>
                  You have made 3 unsuccessful attempt of wrong OTP,&nbsp;OTP
                  Verification is blocked for next 1 hour
                  {/* {this.state.message ? this.state.message : this.state.count}
                      <Link onClick={() => this.onLogin()}></Link> */}
                </p>

                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {this.state.loading === true ? (
                    <img
                      src={spinners}
                      alt="loading..."
                      width="10%"
                      height="10%"
                    />
                  ) : null}
                </div>

                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {loading && <i className="fa fa-refresh fa-spin" />}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default otplogin;
