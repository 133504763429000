//=============PATH=================//
import { apiBase, nodeApiBase } from "../config";
const SERVER_PATH = apiBase;
const NODE_SERVER_PATH = nodeApiBase;
export default class APIConstant {
  static path = {
    //Auth
    login: `${SERVER_PATH}/authentication/Login`,
    AppLogout: `${SERVER_PATH}/authentication/AppLogout`,
    AddUpdateUser: `${SERVER_PATH}/Auth.php?action=register`,
    getUserListByAccountType: `${SERVER_PATH}/Auth.php?action=getUserListByAccountType`,
    getUserPlanByAccountAndUserType: `${SERVER_PATH}/Auth.php?action=getUserPlanByAccountAndUserType`,
    sendOTPPhoneAndEmail: `${SERVER_PATH}/Auth.php?action=sendOTPPhoneAndEmail`,
    sendOTPOnCompanyID: `${SERVER_PATH}/Auth.php?action=sendOTPOnCompanyID`,
    ForgotPassword: `${SERVER_PATH}/Auth.php?action=ForgotPassword`,
    GetRedirectPath: `${SERVER_PATH}/authentication/GetRedirectPath`,
    verifyOTP: `${SERVER_PATH}/Auth.php?action=verifyOTP`,
    AddUpdateMemberUser: `${SERVER_PATH}/Auth.php?action=AddUpdateMemberUser`,

    // User
    GetUserPlanStatus: `${SERVER_PATH}/user/GetUserPlanStatus`,
    // Profile
    verifyAadhaar: `${SERVER_PATH}/profile/VerifyAadhaar`,
    verifyAadhaarOTP: `${SERVER_PATH}/profile/VerifyAadhaarOTP`,
    AddUpdateUserKyc: `${SERVER_PATH}/profile/AddUpdateUserKyc`,
    GetUserKyc: `${SERVER_PATH}/profile/GetUserKyc`,
    SendOTPPhoneAndEmail: `${SERVER_PATH}/profile/SendOTPPhoneAndEmail`,
    VerifyOTP: `${SERVER_PATH}/profile/VerifyOTP`,
    UpgradeUserPlan: `${SERVER_PATH}/Profile.php?action=UpgradeUserPlan`,
    UpgradeUserPlanFaildLogs: `${SERVER_PATH}/Profile.php?action=UpgradeUserPlanFaildLogs`,
    AddUpdateNetworkRequest: `${SERVER_PATH}/Profile.php?action=AddUpdateNetworkRequest`,
    // CHA API
    AddUpdateCHA: `${SERVER_PATH}/CHA.php?action=AddUpdateCHA`,

    // Handler Vessel request
    AddUpdateHandlerVesselRequest: `${SERVER_PATH}/HandlerVesselRequest.php?action=AddUpdateHandlerVesselRequest`,
    CancelDeleteHandlerVesselRequest: `${SERVER_PATH}/HandlerVesselRequest.php?action=CancelDeleteHandlerVesselRequest`,
    GetUnitName: `${SERVER_PATH}/HandlerVesselRequest.php?action=GetUnitName`,
    GetVesselDetail: `${SERVER_PATH}/HandlerVesselRequest.php?action=GetVesselDetail`,
    GetCommodityTypeList: `${SERVER_PATH}/HandlerVesselRequest.php?action=GetCommodityTypeList`,
    // Get Handler  vessel list API

    // Get Handler Dashboard  vessel list API
    GetHandlerVesselList: `${SERVER_PATH}/HandlerDashboard.php?action=GetHandlerVesselList`,
    GetHandlerVesselByID: `${SERVER_PATH}/HandlerDashboard.php?action=GetHandlerVesselByID`,
    GetSoldToPartyList: `${SERVER_PATH}/HandlerDashboard.php?action=GetSoldToPartyList`,
    GetSelectBEList: `${SERVER_PATH}/HandlerDashboard.php?action=GetSelectBEList`,
    GetSelectBEListByPartyID: `${SERVER_PATH}/HandlerDashboard.php?action=GetSelectBEListByPartyID`,

    AddUpdateHandlerImporterMaster: `${SERVER_PATH}/HandlerImporterMaster.php?action=AddUpdateHandlerImporterMaster`,
    GetUserListByType: `${SERVER_PATH}/HandlerImporterMaster.php?action=GetUserListByType`,
    GetHandlerImporterMaster: `${SERVER_PATH}/HandlerImporterMaster.php?action=GetHandlerImporterMaster`,
    AddLineShipperMaster: `${SERVER_PATH}/HandlerImporterMaster.php?action=AddLineShipperMaster`,

    //Stocks
    GetVesselDetailById: `${SERVER_PATH}/Stock.php?action=GetVesselDetailById`,
    AddUpdateMannualPurchaseEntry: `${SERVER_PATH}/Stock.php?action=AddUpdateMannualPurchaseEntry`,
    AddUpdateMannualSoldEntry: `${SERVER_PATH}/Stock.php?action=AddUpdateMannualSoldEntry`,
    AddUpdateStockDataOrder: `${SERVER_PATH}/Stock.php?action=AddUpdateStockDataOrder`,
    GetStockDataOrder: `${SERVER_PATH}/Stock.php?action=GetStockDataOrder`,

    //Network
    GetNetworkPhoneEmail: `${SERVER_PATH}/network/GetNetworkPhoneEmail`,
    SendInvite: `${SERVER_PATH}/network/SendInvite`,
    CheckmyNetwork: `${SERVER_PATH}/network/CheckmyNetwork`,
    GetNotVerifiedNetwork: `${SERVER_PATH}/network/GetNotVerifiedNetwork`,
    AddNonVerifiedNetworkUser: `${SERVER_PATH}/network/AddNonVerifiedNetworkUser`,
    getNonUserByID: `${SERVER_PATH}/network/GetNonUserByID`,
    DeleteNonUserNetwork: `${SERVER_PATH}/network/DeleteNonUserNetwork`,
    GetDetailedGroupList: `${SERVER_PATH}/network/GetDetailedGroupList`,
    AddNetworkGroupUsers: `${SERVER_PATH}/network/AddNetworkGroupUsers`,
    GetNetworkRequest: `${SERVER_PATH}/network/GetNetworkRequest`,
    AcceptRejectNetwork: `${SERVER_PATH}/network/AcceptRejectNetwork`,
    GetResentNetworkRequest: `${SERVER_PATH}/network/GetResentNetworkRequest`,
    noAddToNetwork: `${SERVER_PATH}/network/NoAddToNetwork`,
    AddBLReleaseReminder: `${SERVER_PATH}/network/AddBLReleaseReminder`,
    AddUpdateNetworkDataOrder: `${SERVER_PATH}/network/AddUpdateNetworkDataOrder`,
    GetNetworkDataOrder: `${SERVER_PATH}/network/GetNetworkDataOrder`,
    GetNotVerifiedNetworkByUserType: `${SERVER_PATH}/network/GetNotVerifiedNetworkByUserType`,

    //new api structure
    GetFirstLastName: `${SERVER_PATH}/network/GetFirstLastName`,
    CreateNetwork: `${SERVER_PATH}/network/CreateNetwork`,
    GetMyNetwork: `${SERVER_PATH}/network/GetMyNetwork`,
    AddLoadingPerson: `${SERVER_PATH}/network/AddLoadingPerson`,
    GetLoadingPerson: `${SERVER_PATH}/network/GetLoadingPerson`,
    UpdateNetworkToggleStatus: `${SERVER_PATH}/network/UpdateNetworkToggleStatus`,

    //Common API
    getStringMap: `${SERVER_PATH}/Common.php?action=getStringMap`,
    getRoleTypeList: `${SERVER_PATH}/Common.php?action=getRoleTypeList`,
    getCompanyList: `${SERVER_PATH}/Common.php?action=getCompanyList`,

    // // Container
    // GetMasterCompanyName: `${SERVER_PATH}/Container.php?action=GetMasterCompanyName`,
    // AddMasterCompanyName: `${SERVER_PATH}/Container.php?action=AddMasterCompanyName`,
    // AddContainerType: `${SERVER_PATH}/Container.php?action=AddContainerType`,
    // GetContainerType: `${SERVER_PATH}/Container.php?action=GetContainerType`,
    // AddEditContainer: `${SERVER_PATH}/Container.php?action=AddEditContainer`,
    // DeleteContainerType: `${SERVER_PATH}/Container.php?action=DeleteContainerType`,
    // DeleteMasterCompany: `${SERVER_PATH}/Container.php?action=DeleteMasterCompany`,
    // GetMasterContainerList: `${SERVER_PATH}/Container.php?action=GetMasterContainerList`,
    // GetMasterContainerByID: `${SERVER_PATH}/Container.php?action=GetMasterContainerByID`,
    // GetSubContainerListByID: `${SERVER_PATH}/Container.php?action=GetSubContainerListByID`,
    // DeleteBLType: `${SERVER_PATH}/Container.php?action=DeleteBLType`,
    // GetBLType: `${SERVER_PATH}/Container.php?action=GetBLType`,
    // AddEditBLType: `${SERVER_PATH}/Container.php?action=AddEditBLType`,
    DeleteContainerJobStatus: `${SERVER_PATH}/Container.php?action=DeleteContainerJobStatus`,
    GetContainerJobStatus: `${SERVER_PATH}/Container.php?action=GetContainerJobStatus`,
    AddEditContainerJobStatus: `${SERVER_PATH}/Container.php?action=AddEditContainerJobStatus`,
    // AddEditCommodityType: `${SERVER_PATH}/Container.php?action=AddEditCommodityType`,
    // DeleteCommodityType: `${SERVER_PATH}/Container.php?action=DeleteCommodityType`,
    // GetComoditytType: `${SERVER_PATH}/Container.php?action=GetComoditytType`,
    // DeleteStuffingType: `${SERVER_PATH}/Container.php?action=DeleteStuffingType`,
    // GetStaffDashBoardList: `${SERVER_PATH}/Container.php?action=GetStaffDashBoardList`,
    // AddEditStuffingType: `${SERVER_PATH}/Container.php?action=AddEditStuffingType`,
    // GetContainerEditLogByID: `${SERVER_PATH}/Container.php?action=GetContainerEditLogByID`,
    // UpdateDateContainer: `${SERVER_PATH}/Container.php?action=UpdateDateContainer`,
    // GetContainerDashboard: `${SERVER_PATH}/Container.php?action=GetContainerDashboard`,
    // UpdateJobStatus: `${SERVER_PATH}/Container.php?action=UpdateJobStatus`,
    // DeleteContainer: `${SERVER_PATH}/Container.php?action=DeleteContainer`,
    ActiveInactiveJobStatus: `${SERVER_PATH}/Container.php?action=ActiveInactiveJobStatus`,
    // updateBLReleasedStatus: `${SERVER_PATH}/Container.php?action=updateBLReleasedStatus`,
    // GetJobStaffContainerList: `${SERVER_PATH}/Container.php?action=GetJobStaffContainerList`,
    // AddUpdateBLDraft: `${SERVER_PATH}/Container.php?action=AddUpdateBLDraft`,
    // GetBLDraftDataByID: `${SERVER_PATH}/Container.php?action=GetBLDraftDataByID`,
    // GetBLprintpdf: `${SERVER_PATH}/Container.php?action=GetBLprintpdf`,

    //User Management
    GetCompanyMembersList: `${SERVER_PATH}/UserManagement.php?action=GetCompanyMembersList`,
    GetCompanyMemberById: `${SERVER_PATH}/UserManagement.php?action=GetCompanyMemberById`,
    UpdateCompanyMember: `${SERVER_PATH}/UserManagement.php?action=UpdateCompanyMember`,
    GetContainerUserAccessList: `${SERVER_PATH}/UserManagement.php?action=GetContainerUserAccessList`,
    GetContainerRoleAccessList: `${SERVER_PATH}/UserManagement.php?action=GetContainerRoleAccessList`,
    UpdateCompanyRoleAccess: `${SERVER_PATH}/UserManagement.php?action=UpdateCompanyRoleAccess`,
    UpdateUserAccess: `${SERVER_PATH}/UserManagement.php?action=UpdateUserAccess`,
    GetRoleType: `${SERVER_PATH}/UserManagement.php?action=GetRoleType`,
    DeleteRoleType: `${SERVER_PATH}/UserManagement.php?action=DeleteRoleType`,
    AddRoleType: `${SERVER_PATH}/UserManagement.php?action=AddRoleType`,
    GetCompanyRole: `${SERVER_PATH}/UserManagement.php?action=GetCompanyRole`,
    UpdateShiftByUser: `${SERVER_PATH}/UserManagement.php?action=UpdateShiftByUser`,
    UpdateUserStatus: `${SERVER_PATH}/UserManagement.php?action=UpdateUserStatus`,

    // Quotation
    // AddUpdateQuotation: `${SERVER_PATH}/Quotation.php?action=AddUpdateQuotation`,
    // GetQuotationList: `${SERVER_PATH}/Quotation.php?action=GetQuotationList`,
    // GetQuotationByID: `${SERVER_PATH}/Quotation.php?action=GetQuotationByID`,
    // GetDutyStructureList: `${SERVER_PATH}/Quotation.php?action=GetDutyStructureList`,
    // DeleteQuotation: `${SERVER_PATH}/Quotation.php?action=DeleteQuotation`,

    // Master Api
    AddEditMasterByType: `${SERVER_PATH}/Master/AddEditMasterByType`,
    DeleteMasterByType: `${SERVER_PATH}/Master/DeleteMasterByType`,
    GetMasterListByType: `${SERVER_PATH}/Master/GetMasterListByType`,
    GetHSCodeList: `${SERVER_PATH}/Master.php?action=GetHSCodeList`,
    AddEditHSCode: `${SERVER_PATH}/Master.php?action=AddEditHSCode`,
    DeleteHSCode: `${SERVER_PATH}/Master.php?action=DeleteHSCode`,
    GetSearchHSCode: `${SERVER_PATH}/Master.php?action=GetSearchHSCode`,
    GetItemHSCodeSearchList: `${SERVER_PATH}/Master/GetItemHSCodeSearchList`,
    GetItemMasterList: `${SERVER_PATH}/Master/GetItemMasterList`,
    AddUpdateMasterItem: `${SERVER_PATH}/Master/AddUpdateMasterItem`,
    DeleteItemMaster: `${SERVER_PATH}/Master/DeleteItemMaster`,
    GetServiceMasterList: `${SERVER_PATH}/Master.php?action=GetServiceMasterList`,
    AddUpdateServiceMaster: `${SERVER_PATH}/Master.php?action=AddUpdateServiceMaster`,
    DeleteServiceMaster: `${SERVER_PATH}/Master.php?action=DeleteServiceMaster`,
    AddUpdateVesselMaster: `${SERVER_PATH}/Master.php?action=AddUpdateVesselMaster`,
    DeleteVesselMaster: `${SERVER_PATH}/Master.php?action=DeleteVesselMaster`,
    GetVesselMaster: `${SERVER_PATH}/Master.php?action=GetVesselMaster`,
    GetVesselMasterById: `${SERVER_PATH}/Master.php?action=GetVesselMasterById`,
    AddUpdateVesselStatus: `${SERVER_PATH}/Master.php?action=AddUpdateVesselStatus`,
    DeleteVesselStatus: `${SERVER_PATH}/Master.php?action=DeleteVesselStatus`,
    GetVesselStatusList: `${SERVER_PATH}/Master.php?action=GetVesselStatusList`,
    ActiveInactiveVesselStatus: `${SERVER_PATH}/Master.php?action=ActiveInactiveVesselStatus`,

    AddUpdatePanelMaster: `${SERVER_PATH}/Master.php?action=AddUpdatePanelMaster`,
    DeletePanelMaster: `${SERVER_PATH}/Master.php?action=DeletePanelMaster`,
    GetPanelMaster: `${SERVER_PATH}/Master.php?action=GetPanelMaster`,
    GetPanelMasterById: `${SERVER_PATH}/Master.php?action=GetPanelMasterById`,

    GetShiftFlagMaster: `${SERVER_PATH}/Master.php?action=GetShiftFlagMaster`,
    AddUpdateShiftFlagMaster: `${SERVER_PATH}/Master.php?action=AddUpdateShiftFlagMaster`,
    DeleteShiftFlagMaster: `${SERVER_PATH}/Master.php?action=DeleteShiftFlagMaster`,
    ActiveInactiveShiftFlag: `${SERVER_PATH}/Master.php?action=ActiveInactiveShiftFlag`,
    GetCargoTypeListByVesselName: `${SERVER_PATH}/Master.php?action=GetCargoTypeListByVesselName`,
    GetShiftMasterList: `${SERVER_PATH}/Master.php?action=GetShiftMasterList`,
    AddUpdateShiftMaster: `${SERVER_PATH}/Master.php?action=AddUpdateShiftMaster`,
    DeleteShiftMaster: `${SERVER_PATH}/Master.php?action=DeleteShiftMaster`,
    getShiftMasterListById: `${SERVER_PATH}/Master.php?action=getShiftMasterListById`,

    // FinalJob

    // AddEditMasterFinalJob: `${SERVER_PATH}/FinalJob.php?action=AddEditMasterFinalJob`,
    // GetMasterFinalJobByID: `${SERVER_PATH}/FinalJob.php?action=GetMasterFinalJobByID`,
    // GetMasterFinalJobList: `${SERVER_PATH}/FinalJob.php?action=GetMasterFinalJobList`,
    // AddEditFinalJobBL: `${SERVER_PATH}/FinalJob.php?action=AddEditFinalJobBL`,
    // GetFinalJobBLlist: `${SERVER_PATH}/FinalJob.php?action=GetFinalJobBLlist`,
    // AddEditFinalJobCargo: `${SERVER_PATH}/FinalJob.php?action=AddEditFinalJobCargo`,
    // GetFinalJobCargolist: `${SERVER_PATH}/FinalJob.php?action=GetFinalJobCargolist`,
    // FinalJobAddUpdateContainer: `${SERVER_PATH}/FinalJob.php?action=FinalJobAddUpdateContainer`,
    // FinalJobGetContainer: `${SERVER_PATH}/FinalJob.php?action=FinalJobGetContainer`,
    // FinalJobAddUpdateDispatch: `${SERVER_PATH}/FinalJob.php?action=FinalJobAddUpdateDispatch`,
    // FinalJobGetDispatch: `${SERVER_PATH}/FinalJob.php?action=FinalJobGetDispatch`,
    // FinalJobGetOperationByID: `${SERVER_PATH}/FinalJob.php?action=FinalJobGetOperationByID`,
    // FinalJobAddUpdateOperation: `${SERVER_PATH}/FinalJob.php?action=FinalJobAddUpdateOperation`,
    // DeleteFinalJob: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJob`,

    // DeleteFinalJobBL: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJobBL`,
    // DeleteFinalJobBE: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJobBE`,
    // DeleteFinalJobShipperInvoice: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJobShipperInvoice`,
    // DeleteFinalJobReferenceSoftware: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJobReferenceSoftware`,
    // DeleteFinalJobCargo: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJobCargo`,
    // DeleteFinalJobContainer: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJobContainer`,
    // DeleteFinalJobDispatch: `${SERVER_PATH}/FinalJob.php?action=DeleteFinalJobDispatch`,

    // FinalJobGetReferenceSoftware: `${SERVER_PATH}/FinalJob.php?action=FinalJobGetReferenceSoftware`,
    // FinalJobAddUpdateReferenceSoftware: `${SERVER_PATH}/FinalJob.php?action=FinalJobAddUpdateReferenceSoftware`,

    // AddUpdateFinalJobFillingBE: `${SERVER_PATH}/FinalJob.php?action=AddUpdateFinalJobFillingBE`,
    // FinalJobGetBE: `${SERVER_PATH}/FinalJob.php?action=FinalJobGetBE`,
    // AddUpdateFinalJobShipperInvoice: `${SERVER_PATH}/FinalJob.php?action=AddUpdateFinalJobShipperInvoice`,
    // FinalJobGetShipperInvoice: `${SERVER_PATH}/FinalJob.php?action=FinalJobGetShipperInvoice`,

    // Comapny routes
    AddUpdateCompanyDetail: `${SERVER_PATH}/Company.php?action=AddUpdateCompanyDetail`,
    AddUpdateNewCompany: `${SERVER_PATH}/Company.php?action=AddUpdateNewCompany`,
    GetCompanyDetail: `${SERVER_PATH}/Company.php?action=GetCompanyDetail`,
    GetCompany: `${SERVER_PATH}/Company.php?action=GetCompany`,
    GetCompanyByID: `${SERVER_PATH}/Company.php?action=GetCompanyByID`,
    UpdateCompanyGstData: `${SERVER_PATH}/Company.php?action=UpdateCompanyGstData`,
    updateCompanyPan: `${SERVER_PATH}/Company.php?action=updateCompanyPan`,
    getUserDetailByID: `${SERVER_PATH}/Company.php?action=getUserDetailByID`,
    ApproveRejectCompany: `${SERVER_PATH}/Company.php?action=ApproveRejectCompany`,
    updateCompanyRequest: `${SERVER_PATH}/Company.php?action=updateCompanyRequest`,

    // // Tracking routes
    // AddTracking: `${SERVER_PATH}/Tracking.php?action=AddTracking`,
    // GetTrackingByID: `${SERVER_PATH}/Tracking.php?action=GetTrackingByID`,
    // GetTracking: `${SERVER_PATH}/Tracking.php?action=GetTracking`,
    // GetTrackCount: `${SERVER_PATH}/Tracking.php?action=GetTrackCount`,
    // GetLiveUsers: `${SERVER_PATH}/Tracking.php?action=GetLiveUsers`,

    // //Shift
    // AddUpdateShift: `${SERVER_PATH}/Shift.php?action=AddUpdateShift`,
    // GetShift: `${SERVER_PATH}/Shift.php?action=GetShift`,
    // GetShiftByID: `${SERVER_PATH}/Shift.php?action=GetShiftByID`,
    // DeleteShift: `${SERVER_PATH}/Shift.php?action=DeleteShift`,
    // GetShiftList: `${SERVER_PATH}/Shift.php?action=GetShiftList`,

    //WorkingDays
    // GetWorkingDays: `${SERVER_PATH}/WorkingDays.php?action=GetWorkingDays`,
    // addUpdateWorkingDays: `${SERVER_PATH}/WorkingDays.php?action=addUpdateWorkingDays`,
    // GetTotalWorkingHours: `${SERVER_PATH}/WorkingDays.php?action=GetTotalWorkingHours`,
    // GetUserList: `${SERVER_PATH}/WorkingDays.php?action=GetUserList`,
    // GetAttendanceTypeCount: `${SERVER_PATH}/WorkingDays.php?action=GetAttendanceTypeCount`,

    //Attendance
    // GetAttendance: `${SERVER_PATH}/Attendance.php?action=GetAttendance`,
    // addUpdateAttendance: `${SERVER_PATH}/Attendance.php?action=addUpdateAttendance`,
    // GetCurrentMonth: `${SERVER_PATH}/Attendance.php?action=GetCurrentMonth`,
    // GetAttendanceReport: `${SERVER_PATH}/Attendance.php?action=GetAttendanceReport`,

    //Holiday

    // AddUpdateHoliday: `${SERVER_PATH}/Holiday.php?action=AddUpdateHoliday`,
    // GetHoliday: `${SERVER_PATH}/Holiday.php?action=GetHoliday`,
    // GetHolidayByID: `${SERVER_PATH}/Holiday.php?action=GetHolidayByID`,
    // DeleteHoliday: `${SERVER_PATH}/Holiday.php?action=DeleteHoliday`,

    AddUpdatePortMaster: `${SERVER_PATH}/portmaster/AddUpdatePortMaster`,
    GetPortMaster: `${SERVER_PATH}/portmaster/GetPortMaster`,
    GetPortMasterByID: `${SERVER_PATH}/portmaster/GetPortMasterByID`,
    DeletePortMaster: `${SERVER_PATH}/portmaster/DeletePortMaster`,

    AddUpdateProductQuality: `${SERVER_PATH}/ProductQuality.php?action=AddUpdateProductQuality`,
    GetProductQuality: `${SERVER_PATH}/ProductQuality.php?action=GetProductQuality`,

    GetWtBridgeMasterList: `${SERVER_PATH}/WtBridge.php?action=GetWtBridgeMasterList`,
    AddEditWtBridgeMaster: `${SERVER_PATH}/WtBridge.php?action=AddEditWtBridgeMaster`,
    DeleteWtBridgeMaster: `${SERVER_PATH}/WtBridge.php?action=DeleteWtBridgeMaster`,
    GetWtBridgeMasterbyId: `${SERVER_PATH}/WtBridge.php?action=GetWtBridgeMasterbyId`,
    GetLocationList: `${SERVER_PATH}/WtBridge.php?action=GetLocationList`,

    AddUpdatePersonalizedStatus: `${SERVER_PATH}/Order.php?action=AddUpdatePersonalizedStatus`,
    GetPersonalizedStatus: `${SERVER_PATH}/Order.php?action=GetPersonalizedStatus`,

    // AddUpdateGeneralVessel: `${SERVER_PATH}/Vessel.php?action=AddUpdateGeneralVessel`,
    // GetGeneralVesselById: `${SERVER_PATH}/Vessel.php?action=GetGeneralVesselById`,

    AddUpdateLocationMaster: `${SERVER_PATH}/location/AddUpdateLocationMaster`,
    DeleteLocationMaster: `${SERVER_PATH}/location/DeleteLocationMaster`,
    GetLocationMasterByID: `${SERVER_PATH}/location/GetLocationMasterByID`,
    GetLocationMasterList: `${SERVER_PATH}/location/GetLocationMasterList`,
    GetLocationBySearchItem: `${SERVER_PATH}/location/GetLocationBySearchItem`,

    AddUpdateBerthMaster: `${SERVER_PATH}/BerthMaster.php?action=AddUpdateBerthMaster`,
    DeleteBerthMaster: `${SERVER_PATH}/BerthMaster.php?action=DeleteBerthMaster`,
    GetBerthMasterById: `${SERVER_PATH}/BerthMaster.php?action=GetBerthMasterById`,
    GetBerthMaster: `${SERVER_PATH}/BerthMaster.php?action=GetBerthMaster`,
    getJettyPostionList: `${SERVER_PATH}/BerthMaster.php?action=getJettyPostionList`,

    AddUpdateCategoryMaster: `${SERVER_PATH}/categorymaster/AddUpdateCategoryMaster`,
    DeleteCategoryMaster: `${SERVER_PATH}/categorymaster/DeleteCategoryMaster`,
    GetCategoryMasterById: `${SERVER_PATH}/categorymaster/GetCategoryMasterById`,
    GetCategoryMaster: `${SERVER_PATH}/categorymaster/GetCategoryMaster`,

    AddUpdateSubCategoryMaster: `${SERVER_PATH}/categorymaster/AddUpdateSubCategoryMaster`,
    DeleteSubCategoryMaster: `${SERVER_PATH}/categorymaster/DeleteSubCategoryMaster`,
    GetSubCategoryMasterById: `${SERVER_PATH}/categorymaster/GetSubCategoryMasterById`,
    GetSubCategoryMaster: `${SERVER_PATH}/categorymaster/GetSubCategoryMaster`,

    AddUpdateMakeMaster: `${SERVER_PATH}/makemodelmaster/AddUpdateMakeMaster`,
    DeleteMakeMaster: `${SERVER_PATH}/makemodelmaster/DeleteMakeMaster`,
    GetMakeMasterById: `${SERVER_PATH}/makemodelmaster/GetMakeMasterById`,
    GetMakeMaster: `${SERVER_PATH}/makemodelmaster/GetMakeMaster`,

    AddUpdateModelMaster: `${SERVER_PATH}/makemodelmaster/AddUpdateModelMaster`,
    DeleteModelMaster: `${SERVER_PATH}/makemodelmaster/DeleteModelMaster`,
    GetModelMasterById: `${SERVER_PATH}/makemodelmaster/GetModelMasterById`,
    GetModelMaster: `${SERVER_PATH}/makemodelmaster/GetModelMaster`,
    GetModelMasterByMakeId: `${SERVER_PATH}/makemodelmaster/GetModelMasterByMakeId`,

    AddUpdateTypeMaster: `${SERVER_PATH}/typesubtypemaster/AddUpdateTypeMaster`,
    DeleteTypeMaster: `${SERVER_PATH}/typesubtypemaster/DeleteTypeMaster`,
    GetTypeMasterById: `${SERVER_PATH}/typesubtypemaster/GetTypeMasterById`,
    GetTypeMaster: `${SERVER_PATH}/typesubtypemaster/GetTypeMaster`,

    AddUpdateSubTypeMaster: `${SERVER_PATH}/typesubtypemaster/AddUpdateSubTypeMaster`,
    DeleteSubTypeMaster: `${SERVER_PATH}/typesubtypemaster/DeleteSubTypeMaster`,
    GetSubTypeMasterByTypeId: `${SERVER_PATH}/typesubtypemaster/GetSubTypeMasterByTypeId`,
    GetSubTypeMaster: `${SERVER_PATH}/typesubtypemaster/GetSubTypeMaster`,
    GetSubTypeMasterById: `${SERVER_PATH}/typesubtypemaster/GetSubTypeMasterById`,

    AddUpdateReasonMaster: `${SERVER_PATH}/ReasonMaster.php?action=AddUpdateReasonMaster`,
    DeleteReasonMaster: `${SERVER_PATH}/ReasonMaster.php?action=DeleteReasonMaster`,
    GetReasonMasterById: `${SERVER_PATH}/ReasonMaster.php?action=GetReasonMasterById`,
    GetReasonMaster: `${SERVER_PATH}/ReasonMaster.php?action=GetReasonMaster`,

    AddUpdateEquipmentMaster: `${SERVER_PATH}/Master/AddUpdateEquipmentMaster`,
    DeleteEquipmentMaster: `${SERVER_PATH}/Master/DeleteEquipmentMaster`,
    GetEquipmentMasterById: `${SERVER_PATH}/Master/GetEquipmentMasterById`,
    GetEquipmentMaster: `${SERVER_PATH}/Master/GetEquipmentMaster`,

    AddUpdateProcessMaster: `${SERVER_PATH}/ProcessMaster/AddUpdateProcessMaster`,
    DeleteProcessMaster: `${SERVER_PATH}/ProcessMaster/DeleteProcessMaster`,
    GetProcessMasterById: `${SERVER_PATH}/ProcessMaster/GetProcessMasterById`,
    GetProcessMaster: `${SERVER_PATH}/ProcessMaster/GetProcessMaster`,

    AddUpdateShipCard: `${SERVER_PATH}/ShipCard.php?action=AddUpdateShipCard`,
    GetShipCardList: `${SERVER_PATH}/ShipCard.php?action=GetShipCardList`,
    DeleteShipCard: `${SERVER_PATH}/ShipCard.php?action=DeleteShipCard`,
    GetShipCardDetailById: `${SERVER_PATH}/ShipCard.php?action=GetShipCardDetailById`,
    GetShipCardDashboardList: `${SERVER_PATH}/ShipCard.php?action=GetShipCardDashboardList`,
    GetShipCardLog: `${SERVER_PATH}/ShipCard.php?action=GetShipCardLog`,
    GetShipCardReport: `${SERVER_PATH}/ShipCard.php?action=GetShipCardReport`,
    GetVcnNoListByUserId: `${SERVER_PATH}/ShipCard.php?action=GetVcnNoListByUserId`,

    AddUpdateParticularMaster: `${SERVER_PATH}/Master.php?action=AddUpdateParticularMaster`,
    DeleteParticularMaster: `${SERVER_PATH}/Master.php?action=DeleteParticularMaster`,
    GetParticularMasterById: `${SERVER_PATH}/Master.php?action=GetParticularMasterById`,
    GetParticularMaster: `${SERVER_PATH}/Master.php?action=GetParticularMaster`,
    GetParticularMasterByRole: `${SERVER_PATH}/Master.php?action=GetParticularMasterByRole`,

    AddUpdateCountryMaster: `${SERVER_PATH}/Master/AddUpdateCountryMaster`,
    DeleteCountryMaster: `${SERVER_PATH}/Master/DeleteCountryMaster`,
    GetCountryMasterById: `${SERVER_PATH}/Master/GetCountryMasterById`,
    GetCountryMaster: `${SERVER_PATH}/Master/GetCountryMaster`,
    GetCountryNameByPort: `${SERVER_PATH}/Master/GetCountryNameByPort`,

    AddUpdateTerminalMaster: `${SERVER_PATH}/Master.php?action=AddUpdateTerminalMaster`,
    DeleteTerminalMaster: `${SERVER_PATH}/Master.php?action=DeleteTerminalMaster`,
    GetTerminalMasterById: `${SERVER_PATH}/Master.php?action=GetTerminalMasterById`,
    GetTerminalMaster: `${SERVER_PATH}/Master.php?action=GetTerminalMaster`,

    getUserAccessEquator: `${SERVER_PATH}/access/getUserAccessEquator`,
    getPageAccess: `${SERVER_PATH}/Access.php?action=getPageAccess`,
    getGlobalMasterAccess: `${SERVER_PATH}/Access.php?action=getGlobalMasterAccess`,

    GetShipCardReportByType: `${SERVER_PATH}/AdminNew.php?action=GetShipCardReportByType`,
    GetVCNNoList: `${SERVER_PATH}/AdminNew.php?action=GetVCNNoList`,
    UpdateVesselDeclarationStatus: `${SERVER_PATH}/AdminNew.php?action=UpdateVesselDeclarationStatus`,
    AddUpdateNotification: `${SERVER_PATH}/AdminNew.php?action=AddUpdateNotification`,
    GetVesselDeclarationById: `${SERVER_PATH}/AdminNew.php?action=GetVesselDeclarationById`,

    AddUpdateVesselDeclaration: `${SERVER_PATH}/VesselDeclaration.php?action=AddUpdateVesselDeclaration`,
    DeleteVesselDeclaration: `${SERVER_PATH}/VesselDeclaration.php?action=DeleteVesselDeclaration`,
    GetVesselDeclarationById: `${SERVER_PATH}/VesselDeclaration.php?action=GetVesselDeclarationById`,
    GetVesselDeclarationList: `${SERVER_PATH}/VesselDeclaration.php?action=GetVesselDeclarationList`,
    UpdateVesselDeclarationStatus: `${SERVER_PATH}/VesselDeclaration.php?action=UpdateVesselDeclarationStatus`,
    GetBerthingMeetingList: `${SERVER_PATH}/VesselDeclaration.php?action=GetBerthingMeetingList`,
    AddBerthMeetingSchedule: `${SERVER_PATH}/VesselDeclaration.php?action=AddBerthMeetingSchedule`,

    AddUpdateOperatorMaster: `${SERVER_PATH}/Master.php?action=AddUpdateOperatorMaster`,
    DeleteOperatorMaster: `${SERVER_PATH}/Master.php?action=DeleteOperatorMaster`,
    GetOperatorMasterById: `${SERVER_PATH}/Master.php?action=GetOperatorMasterById`,
    GetOperatorMaster: `${SERVER_PATH}/Master.php?action=GetOperatorMaster`,
    GetOperatorMasterbyLine: `${SERVER_PATH}/Master.php?action=GetOperatorMasterbyLine`,

    AddUpdateLineMaster: `${SERVER_PATH}/Master.php?action=AddUpdateLineMaster`,
    DeleteLineMaster: `${SERVER_PATH}/Master.php?action=DeleteLineMaster`,
    GetLineMasterById: `${SERVER_PATH}/Master.php?action=GetLineMasterById`,
    GetLineMaster: `${SERVER_PATH}/Master.php?action=GetLineMaster`,

    GetCISFTruckList: `${SERVER_PATH}/CISF.php?action=GetCISFTruckList`,
    UpdateCISFStatus: `${SERVER_PATH}/CISF.php?action=UpdateCISFStatus`,

    AddUpdateWaymentSlip: `${SERVER_PATH}/WaymentSlip.php?action=AddUpdateWaymentSlip`,
    DeleteWaymentSlip: `${SERVER_PATH}/WaymentSlip.php?action=DeleteWaymentSlip`,
    GetWaymentSlipbyId: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentSlipbyId`,
    GetWaymentSlipList: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentSlipList`,
    GetWaymentSlipByRSTNo: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentSlipByRSTNo`,
    GetWaymentSlipByWaymentNo: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentSlipByWaymentNo`,
    GetCOMPortByUserId: `${SERVER_PATH}/WaymentSlip.php?action=GetCOMPortByUserId`,
    GetPreviousData: `${SERVER_PATH}/WaymentSlip.php?action=GetPreviousData`,
    AddUpdateWaymentSlipReportAccess: `${SERVER_PATH}/WaymentSlip.php?action=AddUpdateWaymentSlipReportAccess`,
    GetWaymentSlipReportAccess: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentSlipReportAccess`,
    GetWaymentRateConfiguration: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentRateConfiguration`,
    AddUpdateWaymentRateConfiguration: `${SERVER_PATH}/WaymentSlip.php?action=AddUpdateWaymentRateConfiguration`,
    GetSlipRateConfigurationById: `${SERVER_PATH}/WaymentSlip.php?action=GetSlipRateConfigurationById`,
    GetWaymentRateAccess: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentRateAccess`,
    AddUpdateAddWaymentSlipConfig: `${SERVER_PATH}/WaymentSlip.php?action=AddUpdateAddWaymentSlipConfig`,
    GetAddWaymentSlipConfig: `${SERVER_PATH}/WaymentSlip.php?action=GetAddWaymentSlipConfig`,
    GetWaymentDashboard: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentDashboard`,
    GetSingleWaymentReport: `${SERVER_PATH}/WaymentSlip.php?action=GetSingleWaymentReport`,
    GetWaymentVehicleList: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentVehicleList`,
    GetWeighBridgeById: `${SERVER_PATH}/WaymentSlip.php?action=GetWeighBridgeById`,
    GetWBSlipReport: `${SERVER_PATH}/WaymentSlip.php?action=GetWBSlipReport`,
    GetTareWeightbyVehicleNo: `${SERVER_PATH}/WaymentSlip.php?action=GetTareWeightbyVehicleNo`,
    UpdateWaymentSlip: `${SERVER_PATH}/WaymentSlip.php?action=UpdateWaymentSlip`,
    CancelledWaymentSlip: `${SERVER_PATH}/WaymentSlip.php?action=CancelledWaymentSlip`,
    GetWaymentRstNo: `${SERVER_PATH}/WaymentSlip.php?action=GetWaymentRstNo`,

    AddUpdateVesselOperationStatus: `${SERVER_PATH}/VOS.php?action=AddUpdateVesselOperationStatus`,
    GetVesselOperationStatus: `${SERVER_PATH}/VOS.php?action=GetVesselOperationStatus`,
    DeleteVesselOperationStatus: `${SERVER_PATH}/VOS.php?action=DeleteVesselOperationStatus`,
    GetVesselOperationStatusById: `${SERVER_PATH}/VOS.php?action=GetVesselOperationStatusById`,

    // New API Structure Changes
    GetUserDetailsById: `${SERVER_PATH}/user/GetUserDetailsById`,
    GetEditUserDetails: `${SERVER_PATH}/user/GetEditUserDetails`,
    EditProfile: `${SERVER_PATH}/user/EditProfile`,
    GetPhoneEmail: `${SERVER_PATH}/user/GetPhoneEmail`,
    GetGSTVerifyData: `${SERVER_PATH}/user/GetGSTVerifyData`,
    GetPanVerifyData: `${SERVER_PATH}/user/GetPanVerifyData`,
    VerifyGST: `${SERVER_PATH}/user/VerifyGST`,
    VerifyPAN: `${SERVER_PATH}/user/VerifyPAN`,
    VerifyEmail: `${SERVER_PATH}/user/VerifyEmail`,
    SentGstAdminRequest: `${SERVER_PATH}/user/SentGstAdminRequest`,
    ChangeEmailPhone: `${SERVER_PATH}/user/ChangeEmailPhone`,
    VerifiedEmail: `${SERVER_PATH}/user/VerifiedEmail`,
    VerifiedPhone: `${SERVER_PATH}/user/VerifiedPhone`,
    GetRequestServicelist: `${SERVER_PATH}/user/GetRequestServicelist`,
    AddServiceNotification: `${SERVER_PATH}/user/AddServiceNotification`,

    AddUpdateWareHouse: `${SERVER_PATH}/warehousemaster/AddUpdateWareHouse`,
    GetWareHouseById: `${SERVER_PATH}/warehousemaster/GetWareHouseById`,
    GetWareHouseList: `${SERVER_PATH}/warehousemaster/GetWareHouseList`,
    DeleteWareHouse: `${SERVER_PATH}/warehousemaster/DeleteWareHouse`,
    GetWareHouseDetailById: `${SERVER_PATH}/warehousemaster/GetWareHouseDetailById`,
    GetSubWareHouseList: `${SERVER_PATH}/warehousemaster/GetSubWareHouseList`,
    GetAddressTypeList: `${SERVER_PATH}/warehousemaster/GetAddressTypeList`,
    GetWarehouseListByUserId: `${SERVER_PATH}/warehousemaster/GetWarehouseListByUserId`,
    GetGodownNoList: `${SERVER_PATH}/warehousemaster/GetGodownNoList`,

    AddUpdateMakeMyLocation: `${SERVER_PATH}/MakeMyLocation.php?action=AddUpdateMakeMyLocation`,
    GetMyLocation: `${SERVER_PATH}/MakeMyLocation.php?action=GetMyLocation`,
    GetLoadingPersonPhoneEmail: `${SERVER_PATH}/MakeMyLocation.php?action=GetLoadingPersonPhoneEmail`,
    GetMyGroupList: `${SERVER_PATH}/MakeMyLocation.php?action=GetMyGroupList`,
    SaveLoadingPerson: `${SERVER_PATH}/MakeMyLocation.php?action=SaveLoadingPerson`,
    DeletePendingLocation: `${SERVER_PATH}/MakeMyLocation.php?action=DeletePendingLocation`,
    GetVesselByVesselId: `${SERVER_PATH}/MakeMyLocation.php?action=GetVesselByVesselId`,

    AddUpdateRoute: `${SERVER_PATH}/Route.php?action=AddUpdateRoute`,
    DeleteRoute: `${SERVER_PATH}/Route.php?action=DeleteRoute`,
    GetRoute: `${SERVER_PATH}/Route.php?action=GetRoute`,
    // Driver Api
    GetDriverList: `${SERVER_PATH}/Driver.php?action=GetDriverList`,
    searchLicenceDetails: `${SERVER_PATH}/Driver.php?action=searchLicenceDetails`,
    getTruckslist: `${SERVER_PATH}/Driver.php?action=getTruckslist`,
    getTruckRequest: `${SERVER_PATH}/Driver.php?action=getTruckRequest`,
    GetTripStatus: `${SERVER_PATH}/Driver.php?action=GetTripStatus`,
    AddUpdateTripManual: `${SERVER_PATH}/Driver.php?action=AddUpdateTripManual`,
    GetTransporter: `${SERVER_PATH}/Driver.php?action=GetTransporter`,
    GetTripMannualByID: `${SERVER_PATH}/Driver.php?action=GetTripMannualByID`,
    GetVehicleLiveStatus: `${SERVER_PATH}/Driver.php?action=GetVehicleLiveStatus`,
    sentTruckAdminRequest: `${SERVER_PATH}/Driver.php?action=sentTruckAdminRequest`,
    GetTransporterDashboard: `${SERVER_PATH}/Driver.php?action=GetTransporterDashboard`,
    AddUpdateDashboard: `${SERVER_PATH}/Driver.php?action=AddUpdateDashboard`,
    GetDashboard: `${SERVER_PATH}/Driver.php?action=GetDashboard`,
    GetTripMonthWise: `${SERVER_PATH}/Driver.php?action=GetTripMonthWise`,
    GetTransporterGraphDashboard: `${SERVER_PATH}/Driver.php?action=GetTransporterGraphDashboard`,
    GetUserList: `${SERVER_PATH}/Driver.php?action=GetUserList`,
    UpdateTruckStatus: `${SERVER_PATH}/Driver.php?action=UpdateTruckStatus`,
    AddUpdateSingleTrip: `${SERVER_PATH}/Driver.php?action=AddUpdateSingleTrip`,
    GetSingleTripById: `${SERVER_PATH}/Driver.php?action=GetSingleTripById`,
    GetTruckOrders: `${SERVER_PATH}/Driver.php?action=GetTruckOrders`,
    GetSingleTripReportById: `${SERVER_PATH}/Driver.php?action=GetSingleTripReportById`,
    GetMultiTripReportById: `${SERVER_PATH}/Driver.php?action=GetMultiTripReportById`,
    GetTripNo: `${SERVER_PATH}/Driver.php?action=GetTripNo`,
    GetSingleTripReport: `${SERVER_PATH}/Driver.php?action=GetSingleTripReport`,
    GetSingleTripReportFile: `${SERVER_PATH}/Driver.php?action=GetSingleTripReportFile`,

    AddUpdateTollPlaza: `${SERVER_PATH}/TollPlaza.php?action=AddUpdateTollPlaza`,
    GetTollPlazaList: `${SERVER_PATH}/TollPlaza.php?action=GetTollPlazaList`,
    DeleteTollPlaza: `${SERVER_PATH}/TollPlaza.php?action=DeleteTollPlaza`,
    GetTollPlazaById: `${SERVER_PATH}/TollPlaza.php?action=GetTollPlazaById`,

    GetTableDataOrder: `${SERVER_PATH}/TableDataOrder.php?action=GetTableDataOrder`,
    AddUpdateTableDataOrder: `${SERVER_PATH}/TableDataOrder.php?action=AddUpdateTableDataOrder`,

    getPageAccess: `${SERVER_PATH}/Access.php?action=getPageAccess`,
    getGlobalMasterAccess: `${SERVER_PATH}/Access.php?action=getGlobalMasterAccess`,

    GetMemberRoleForOwner: `${SERVER_PATH}/Auth.php?action=GetMemberRoleForOwner`,
    /* All Weigh Bridge API */
    GetAllWeighBridge: `${SERVER_PATH}/AllWeighBridge.php?action=GetAllWeighBridge`,
    AddUpdateAllWeighBridge: `${SERVER_PATH}/AllWeighBridge.php?action=AddUpdateAllWeighBridge`,
    DeleteAllWeighBridge: `${SERVER_PATH}/AllWeighBridge.php?action=DeleteAllWeighBridge`,
    GetWeighBridgeUserList: `${SERVER_PATH}/AllWeighBridge.php?action=GetWeighBridgeUserList`,
    CheckWeighOperatorExist: `${SERVER_PATH}/AllWeighBridge.php?action=CheckWeighOperatorExist`,

    GetMemberPlanList: `${SERVER_PATH}/Plan.php?action=GetMemberPlanList`,

    //Parking API
    GetParkingRateList: `${SERVER_PATH}/ParkingOperator.php?action=GetParkingRateList`,
    GetParkingRateByID: `${SERVER_PATH}/ParkingOperator.php?action=GetParkingRateByID`,
    AddUpdateParkingRate: `${SERVER_PATH}/ParkingOperator.php?action=AddUpdateParkingRate`,
    DeleteParkingRate: `${SERVER_PATH}/ParkingOperator.php?action=DeleteParkingRate`,

    // VehicleWhiteList API
    GetVehicleWhitelistList: `${SERVER_PATH}/VehicleWhitelist.php?action=GetVehicleWhitelistList`,
    GetVehicleWhitelistByID: `${SERVER_PATH}/VehicleWhitelist.php?action=GetVehicleWhitelistByID`,
    AddUpdateVehicleWhitelist: `${SERVER_PATH}/VehicleWhitelist.php?action=AddUpdateVehicleWhitelist`,
    DeleteVehicleWhitelist: `${SERVER_PATH}/VehicleWhitelist.php?action=DeleteVehicleWhitelist`,

    // All ParkingRate API
    GetAllParkingRate: `${SERVER_PATH}/AllParkingRate.php?action=GetAllParkingRate`,
    AddUpdateAllParkingRate: `${SERVER_PATH}/AllParkingRate.php?action=AddUpdateAllParkingRate`,
    DeleteAllParkingRate: `${SERVER_PATH}/AllParkingRate.php?action=DeleteAllParkingRate`,
    GetParkingRateUserList: `${SERVER_PATH}/AllParkingRate.php?action=GetParkingRateUserList`,
    CheckParkingOperatorExist: `${SERVER_PATH}/AllParkingRate.php?action=CheckParkingOperatorExist`,

    GetFixedRateMaster: `${SERVER_PATH}/Master.php?action=GetFixedRateMaster`,
    DeleteFixedRateMaster: `${SERVER_PATH}/Master.php?action=DeleteFixedRateMaster`,
    AddUpdateFixedRateMaster: `${SERVER_PATH}/Master.php?action=AddUpdateFixedRateMaster`,

    AddUpdateWBHelpCode: `${SERVER_PATH}/WBHelpCode.php?action=AddUpdateWBHelpCode`,
    GetWBHelpCode: `${SERVER_PATH}/WBHelpCode.php?action=GetWBHelpCode`,
    DeleteWBHelpCode: `${SERVER_PATH}/WBHelpCode.php?action=DeleteWBHelpCode`,
    GetWBHelpCodeById: `${SERVER_PATH}/WBHelpCode.php?action=GetWBHelpCodeById`,
    GetDataByWBHelpCode: `${SERVER_PATH}/WBHelpCode.php?action=GetDataByWBHelpCode`,

    ClearNotification: `${SERVER_PATH}/Notification.php?action=ClearNotification`,

    //Node API PAth

    generateUniqueJobNo: `${NODE_SERVER_PATH}/jobManagement/generateUniqueJobNo`,
    getJobManagement: `${NODE_SERVER_PATH}/jobManagement/getJobManagement`,
    getJobManagementById: `${NODE_SERVER_PATH}/jobManagement/getJobManagementById`,
    addUpdateJobManagement: `${NODE_SERVER_PATH}/jobManagement/addUpdateJobManagement`,
    deleteJobManagement: `${NODE_SERVER_PATH}/jobManagement/deleteJobManagement`,
    updateJobManagementStatus: `${NODE_SERVER_PATH}/jobManagement/updateJobManagementStatus`,

    getAttachmentMaster: `${NODE_SERVER_PATH}/master/getAttachmentMaster`,
    getAttachmentMasterById: `${NODE_SERVER_PATH}/master/getAttachmentMasterById`,
    addUpdateAttachmentMaster: `${NODE_SERVER_PATH}/master/addUpdateAttachmentMaster`,
    deleteAttachmentMaster: `${NODE_SERVER_PATH}/master/deleteAttachmentMaster`,

    getCheckListMaster: `${NODE_SERVER_PATH}/master/getCheckListMaster`,
    getCheckListMasterById: `${NODE_SERVER_PATH}/master/getCheckListMasterById`,
    addUpdateCheckListMaster: `${NODE_SERVER_PATH}/master/addUpdateCheckListMaster`,
    deleteCheckListMaster: `${NODE_SERVER_PATH}/master/deleteCheckListMaster`,

    GetCompanyInitials: `${NODE_SERVER_PATH}/configuration/GetCompanyInitials`,
    GetCompanyInitialsById: `${NODE_SERVER_PATH}/configuration/GetCompanyInitialsById`,
    AddUpdateCompanyInitials: `${NODE_SERVER_PATH}/configuration/AddUpdateCompanyInitials`,
    DeleteCompanyInitials: `${NODE_SERVER_PATH}/configuration/DeleteCompanyInitials`,
    AddUpdateConfiguration: `${NODE_SERVER_PATH}/configuration/AddUpdateConfiguration`,
    GetConfigurationList: `${NODE_SERVER_PATH}/configuration/GetConfigurationList`,
    GetConfigurationById: `${NODE_SERVER_PATH}/configuration/GetConfigurationById`,
    DeleteConfiguration: `${NODE_SERVER_PATH}/configuration/DeleteConfiguration`,

    getCompanyName: `${NODE_SERVER_PATH}/configuration/getCompanyName`,
    getCompanyNameById: `${NODE_SERVER_PATH}/configuration/getCompanyNameById`,
    addUpdateCompanyName: `${NODE_SERVER_PATH}/configuration/addUpdateCompanyName`,
    deleteCompanyName: `${NODE_SERVER_PATH}/configuration/deleteCompanyName`,

    searchLicenceDetails: `${NODE_SERVER_PATH}/ulipapi/searchLicenceDetails`,

    addUpdateDriver: `${NODE_SERVER_PATH}/driverapi/addUpdateDriver`,
    sentDriverAdminRequest: `${NODE_SERVER_PATH}/driverapi/sentDriverAdminRequest`,
    DeleteDriver: `${NODE_SERVER_PATH}/driverapi/DeleteDriver`,

    GenerateUniqueStockNo: `${SERVER_PATH}/stock/GenerateUniqueStockNo`,
    AddUpdateStock: `${SERVER_PATH}/stock/AddUpdateStock`,
    GetStockList: `${SERVER_PATH}/stock/GetStockList`,
    GetStockById: `${SERVER_PATH}/stock/GetStockById`,
    DeleteStock: `${SERVER_PATH}/stock/DeleteStock`,

    AddUpdateReminder: `${SERVER_PATH}/reminder/AddUpdateReminder`,
    GetReminderList: `${SERVER_PATH}/reminder/GetReminderList`,
    GetReminderById: `${SERVER_PATH}/reminder/GetReminderById`,
    DeleteReminder: `${SERVER_PATH}/reminder/DeleteReminder`,

    GetNotificationDetails: `${SERVER_PATH}/notification/GetNotificationDetails`,
    UpdateNewNotification: `${SERVER_PATH}/notification/UpdateNewNotification`,
    DeleteNotification: `${SERVER_PATH}/notification/DeleteNotification`,

    AddUpdateReasonMaster: `${SERVER_PATH}/reason/AddUpdateReasonMaster`,
    DeleteReasonMaster: `${SERVER_PATH}/reason/DeleteReasonMaster`,
    GetReasonMasterById: `${SERVER_PATH}/reason/GetReasonMasterById`,
    GetReasonMaster: `${SERVER_PATH}/reason/GetReasonMaster`,

    GenerateUniqueReturnId: `${SERVER_PATH}/stock/GenerateUniqueReturnId`,
    AddReturnedItems: `${SERVER_PATH}/stock/AddReturnedItems`,
    UpdateStockItems: `${SERVER_PATH}/stock/UpdateStockItems`,
    CheckUnitConversion: `${SERVER_PATH}/stock/CheckUnitConversion`,

    GetAuditOrderList: `${SERVER_PATH}/auditOrder/GetAuditOrderList `,
    FilterAuditOrderList: `${SERVER_PATH}/auditOrder/FilterAuditOrderList `,
    GenerateAuditNumber: `${SERVER_PATH}/auditOrder/GenerateAuditNumber`,
    GenerateOrderNumber: `${SERVER_PATH}/auditOrder/GenerateOrderNumber`,
    AddUpdateAuditItems: `${SERVER_PATH}/auditOrder/AddUpdateAuditItems`,
    GetAuditItems: `${SERVER_PATH}/auditOrder/GetAuditItems`,
    GetAuditItemsList: `${SERVER_PATH}/auditOrder/GetAuditItemsList`,
    CompareOrderData: `${SERVER_PATH}/auditOrder/CompareOrderData`,
    AddUpdateOrderItems: `${SERVER_PATH}/auditOrder/AddUpdateOrderItems`,
    GetOrderItems: `${SERVER_PATH}/auditOrder/GetOrderItems`,

    GetSubwarehouseItemList: `${SERVER_PATH}/master/GetSubwarehouseItemList`,

    GetTotalStocks: `${SERVER_PATH}/dashboard/GetTotalStocks`,
    GetReturnedStocks: `${SERVER_PATH}/dashboard/GetReturnedStocks`,
    GetOrdersToBePlaced: `${SERVER_PATH}/dashboard/GetOrdersToBePlaced`,
    GetItemGoodsCount: `${SERVER_PATH}/dashboard/GetItemGoodsCount`,
    GetTotalWarehouse: `${SERVER_PATH}/dashboard/GetTotalWarehouse`,
    MostUsedItems: `${SERVER_PATH}/dashboard/MostUsedItems`,
    MajorReasons: `${SERVER_PATH}/dashboard/MajorReasons`,
    ItemsReturned: `${SERVER_PATH}/dashboard/ItemsReturned`,
    GetRefillItems: `${SERVER_PATH}/dashboard/GetRefillItems`,
    GetTotalRepairingCost: `${SERVER_PATH}/dashboard/GetTotalRepairingCost`,
    GetTotalItemsUsed: `${SERVER_PATH}/dashboard/GetTotalItemsUsed`,
    GetTotalEquipments: `${SERVER_PATH}/dashboard/GetTotalEquipments`,
    GetRepairingCosts: `${SERVER_PATH}/dashboard/GetRepairingCosts`,

    AddUpdateUnitMaster: `${SERVER_PATH}/master/AddUpdateUnitMaster`,
    GetUnitMaster: `${SERVER_PATH}/master/GetUnitMaster`,
    GetUnitMasterById: `${SERVER_PATH}/master/GetUnitMasterById`,
    DeleteUnitMaster: `${SERVER_PATH}/master/DeleteUnitMaster`,

    AddUpdateUnitConfiguration: `${SERVER_PATH}/master/AddUpdateUnitConfiguration`,
    GetUnitConfiguration: `${SERVER_PATH}/master/GetUnitConfiguration`,
    GetUnitConfigurationById: `${SERVER_PATH}/master/GetUnitConfigurationById`,
    DeleteUnitConfiguration: `${SERVER_PATH}/master/DeleteUnitConfiguration`,

    AddUpdateInsuranceCompany: `${SERVER_PATH}/warehousemaster/AddUpdateInsuranceCompany`,
    GetInsuranceCompanyMaster: `${SERVER_PATH}/warehousemaster/GetInsuranceCompanyMaster`,
  };
}
