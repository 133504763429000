/*eslint-disable*/
import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import { makeStyles } from "@material-ui/core/styles";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "@material-ui/core/Button";
import api from "../../utils/api";
import { apiBase } from "../../config";
import yes from "../../assets/images/yesmarkblank.png";
import pending from "../../assets/images/pendingicon.png";
import { CommonConfig } from "../../CommonConfig.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import cogoToast from "cogo-toast";
import Checkbox from "@material-ui/core/Checkbox";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { individual_profile_image_link } from "../../config";
import User from "../../assets/images/user.jpg";
import SimpleBackdrop from "../../utils/general";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ProgressBar } from "primereact/progressbar";
import successCircle from "../../assets/equator-img/success-circle.svg";
import APIConstant from "../../utils/constant";
import Loader from "components/Shared/Loader/Loader";
import moment from "moment";
import Axios from "axios";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

const useStyles = makeStyles((theme) => ({
  inputRoot: { color: "inherit" },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
}));

class EditProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserId: "",
      token: "",
      showPassword: false,
      edit_name: "",
      edit_nameErr: false,
      edit_namehelperText: "",
      openKYCDone: false,
      otp: "",
      otpcheck: "",
      isKYCVerified: false,
      otpErr: false,
      otphelperText: "",
      show: "",
      edit_email: "",
      edit_emailErr: false,
      edit_emailhelperText: "",
      isMailVerified: false,
      KycPhoneNumber: "",
      KycPhoneNumberErr: false,
      KycPhoneNumberhelperText: "",
      edit_phonenumber: "",
      edit_phonenumberErr: false,
      edit_phonenumberhelperText: "",
      isPhoneVerified: "",

      UserType: "",
      usertypeerr: false,
      image: "",
      loading: false,

      edit_lastname: "",
      edit_website: "",

      edit_companyname: "",
      edit_companynameErr: false,
      dit_companyname_helperText: "",
      selectedFile: "",
      edit_companyphone: "",
      edit_companyphoneErr: false,
      edit_companyphonehelperText: "",

      edit_companyEmail: "",
      edit_companyEmailErr: false,
      edit_companyEmailhelperText: "",

      edit_companycity: "",
      edit_companycityErr: false,
      edit_companycityhelperText: "",

      edit_companystate: "",
      edit_companystateErr: false,
      edit_companystatehelperText: "",

      edit_companypincode: "",
      edit_companypincodeErr: false,
      edit_companypincodehelperText: "",
      zipShareCodeErr: false,
      zipShareCodehelperText: "",

      pan: "",
      panErr: "",
      panhelperText: "",
      panVerifybtn: false,
      ispanVerified: false,
      zipShareCode: "",
      gst: "",
      gstErr: "",
      gsthelperText: "",
      gstVerifybtn: false,
      isgstVerified: false,

      aadhar: "",
      aadharErr: false,
      aadharhelperText: "",
      aadharVerifybtn: false,
      isaadharVerified: false,

      getUserType: [],
      getServices: [],
      usertypehelperText: "",

      edit_companyaddress: "",
      edit_dob: "",
      image1: "",
      isChecked: false,
      isCheckedErr: false,
      isCheckedhelperText: "",
      isimageErr: false,
      isimagehelperText: "",
      openNotification: null,
      checkedItems: [],
      DriverDLNo: "",
      click: 0,
      imgSrc: "",
      profilePic: "",
      old_email: "",
      old_phonenumber: "",
      phone: 0,
      GSTVerifyData: {},
      open: false,
      gstAddress1: "",
      gstAddress1Err: false,
      gstAddress1ErrText: "",
      gstAddress2: "",
      gstAddress2Err: false,
      gstAddress2ErrText: "",
      gstState: "",
      gstStateErr: false,
      gstStateErrText: "",
      gstCompanyName: "",
      gstCompanyNameErr: false,
      gstCompanyNameErrText: "",
      gstTradeName: "",
      gstTradeNameErr: false,
      gstTradeNameErrText: "",
      PANVerifyData: {},
      openGstRequest: false,
      openPan: false,
      panNo: "",
      panNoErr: false,
      panNoErrText: "",
      panName: "",
      panNameErr: false,
      panNameErrText: "",
      oldKycPhoneNumber: "",
      progressBarCount: 0,
      oldgst: "",
      oldpan: "",
      openUserKyc: false,
      openDialogue: false,
      uploadKycZip: [],
      openKycSucess: false,
      uploadKycZipName: "",
      isKycStatus: true,
      isPhoneStatus: false,
      AccountType: "",
      shiftName: "",
      shiftStartTime: "",
      shiftEndTime: "",
      shiftHour: "",
      accountType: "",
      planName: "",
      comPortList: [
        {
          label: "COM1",
          value: "COM1",
        },
        {
          label: "COM2",
          value: "COM2",
        },
        {
          label: "COM3",
          value: "COM3",
        },
        {
          label: "COM4",
          value: "COM4",
        },
        {
          label: "COM5",
          value: "COM5",
        },
        {
          label: "COM6",
          value: "COM6",
        },
        {
          label: "COM7",
          value: "COM7",
        },
        {
          label: "COM8",
          value: "COM8",
        },
        {
          label: "COM9",
          value: "COM9",
        },
        {
          label: "COM10",
          value: "COM10",
        },
        {
          label: "COM11",
          value: "COM11",
        },
        {
          label: "COM12",
          value: "COM12",
        },
        {
          label: "COM13",
          value: "COM13",
        },
        {
          label: "COM14",
          value: "COM14",
        },
        {
          label: "COM15",
          value: "COM15",
        },
      ],

      comPort: "",
      comPortErr: false,
      comPortErrText: "",
      planStartDate: "",
      planEndDate: "",
      isAadhaarOtpSend: false,
      aadharRefId: "",
      aadhaarData: {},
      aadhaarOTP: "",
      aadhaarOTPErr: false,
      aadhaarOTPErrText: "",
      verifyAadhaarClick: true,

      isCompanyEmailVerified: false,
      verifyCompanyEmailClick: true,
      companyEmailVerifyDisable: false,
      isCompanyEmailResendEnable: false,
      isCompanyEmailResendButton: false,

      companyEmailOTP: "",
      companyEmailOTPErr: false,
      companyEmailOTPErrText: "",

      isCompanyPhoneVerified: false,
      verifyCompanyPhoneClick: true,
      companyPhoneVerifyDisable: false,
      isCompanyPhoneResendEnable: false,
      isCompanyPhoneResendButton: false,

      companyPhoneOTP: "",
      companyPhoneOTPErr: false,
      companyPhoneOTPErrText: "",
      isNewPhoneNumberVerify: false,
      isExpiring: false,
      isExpiringStatus: "",
      isExpiringDate: "",
      showIsRenewModel: false,
      planList: [],
      plan: "",
      planErr: false,
      planErrText: "",
      discRate: "",
      planDesc: "",
      planRate: "",
      discountpercentage: "",
      startDate: "",
      endDate: "",
      isSubmitEnable: false,
    };
  }
  componentDidMount = () => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      let userrole = JSON.parse(localStorage.getItem("Userdata"));
      this.getServices();
      this.GetEditUserDetails(data, token);
      this.setState({ UserId: data, AccountType: userrole.AccountType });
      this.getUserKyc(data);
      // this.GetComPort(data);

      // }
    } else {
      this.props.history.push("/login");
    }
    // } else if (lockstatus == null) {
    //     this.props.history.push('/login')
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  };

  GetComPort = async (ID) => {
    try {
      Loader.show();
      let params = {
        ID: ID,
      };
      await api
        .post(APIConstant.path.GetCOMPortByUserId, params)
        .then(async (res) => {
          if (res.Success === 1) {
            Loader.hide();
            if (res.Info?.WaymentSlip?.COMPort) {
              this.setState({ comPort: res.Info?.WaymentSlip?.COMPort });
              this.setPort(res.Info?.WaymentSlip?.COMPort);
            }
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          console.log(err);
          Loader.hide();
        });
    } catch (err) {
      console.error(err);
    }
  };

  getProgressBar = () => {
    let count = 0;

    if (this.state.profilePic != "" && this.state.profilePic != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (this.state.UserType != "" && this.state.UserType != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (this.state.edit_name != "" && this.state.edit_name != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_phonenumber != "" &&
      this.state.edit_phonenumber != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (this.state.edit_email != "" && this.state.edit_email != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (this.state.edit_website != "" && this.state.edit_website != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_companyname != "" &&
      this.state.edit_companyname != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_companyEmail != "" &&
      this.state.edit_companyEmail != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_companyaddress != "" &&
      this.state.edit_companyaddress != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_companyphone != "" &&
      this.state.edit_companyphone != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_companycity != "" &&
      this.state.edit_companycity != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_companystate != "" &&
      this.state.edit_companystate != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (
      this.state.edit_companypincode != "" &&
      this.state.edit_companypincode != null
    ) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (this.state.pan != "" && this.state.pan != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (this.state.gst != "" && this.state.gst != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
    if (this.state.aadhar != "" && this.state.aadhar != null) {
      count = count + 6.25;
      this.setState({ progressBarCount: count });
    }
  };

  handleClickNotification = (event) => {
    if (
      this.state.openNotification &&
      this.state.openNotification.contains(event.target)
    ) {
      this.setState({ openNotification: null });
    } else {
      this.setState({ openNotification: event.currentTarget });
    }
  };

  handleCloseNotification = () => {
    this.setState({ openNotification: null, checkedItems: [] });
  };

  handleServicesChange = (e) => {
    const { checkedItems } = this.state;
    var item = [];
    item = e.target.name;
    checkedItems.push(item);
    this.setState({ checkedItems: checkedItems, isChecked: true });
  };

  handleClose = (type) => {
    if (type == "openGstRequest") {
      this.setState({ openGstRequest: false, loading: false, phoneclick: 0 });
    }
    if (type == "profile") {
      // this.setState({
      //     openDialogue: false
      //     //  firstName: '', secondName: '',
      //     //     emailId: '', contactNum: '', profilePic: ''
      // })
    }
    if (type == "gst") {
      this.setState({
        open: false,
      });
    }
    if (type == "pan") {
      this.setState({
        openPan: false,
      });
    }
    if (type == "userKyc") {
      this.setState({
        openUserKyc: false,
        uploadKycZip: "",
        zipShareCode: "",
        KycPhoneNumber: "",
        oldKycPhoneNumber: "",
        isKycStatus: "",
      });
    }
    if (type == "KycDone") {
      this.setState({
        openKYCDone: false,
      });
    } else {
      this.setState({
        openotp: false,
        otp: "",
        otpErr: false,
        otphelperText: "",
      });
    }
  };

  validatechecked = () => {
    let formIsValid = true;
    if (this.state.isChecked === false) {
      this.setState({
        isCheckedErr: true,
        isCheckedhelperText: "Please Select atleast one module",
      });
      formIsValid = false;
    } else {
      this.setState({ isCheckedErr: false, isCheckedhelperText: "" });
    }
    return formIsValid;
  };

  handleRequest = (event) => {
    event.preventDefault();
    if (this.validatechecked()) {
      this.setState({ loading: true });
      const { checkedItems } = this.state;
      var params = {
        Service: checkedItems,
        UserId: this.state.UserId,
      };
      api
        .post(APIConstant.path.AddServiceNotification, params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ loading: false });
            cogoToast.success("Module Request sent successfully");
            this.props.history.push("/admin/VesselInfo");
          } else {
            this.setState({ loading: false });
            cogoToast.error("Something bad happened");
          }
        })
        .catch(() => {});
    }
  };

  getServices() {
    var params = {
      UserId: localStorage.getItem("Infodata"),
    };
    api
      .post(APIConstant.path.GetRequestServicelist, params)
      .then((res) => {
        if (res.Success === 1) {
          var getList = [];
          for (let i = 0; i < res.Data.length; i++) {
            getList.push({
              label: res.Data[i]._id,
              value: res.Data[i].ServiceName,
            });
          }
          this.setState({ getServices: getList });
        }
      })
      .catch(() => {});
  }

  GetEditUserDetails = (UserId) => {
    var params = {
      UserId: UserId,
    };
    api
      .post(APIConstant.path.GetEditUserDetails, params)
      .then((res) => {
        if (res.success) {
          // if (res.data.IsProfileCompleted == 1) {
          //   localStorage.removeItem("IsProfileCompleted");
          //   localStorage.setItem("IsProfileCompleted", 1, true);
          // } else {
          //   localStorage.removeItem("IsProfileCompleted");
          //   localStorage.setItem("IsProfileCompleted", 0, true);
          // }
          localStorage.setItem(
            "ProfilePicture",
            individual_profile_image_link + res.data.ProfilePic
          );
          this.setState({
            UserType: res.data.UserRole,
            edit_email: res.data.Email,
            old_email: res.data.Email,
            edit_name: res.data.Name,
            DriverDLNo: res.data.DriverDLNo,
            edit_lastname: res.data.last_name,
            edit_website: res.data.WebsiteUrl,
            edit_companyname: res.data.CompanyName,
            edit_companyphone: res.data.CompanyPhone,
            edit_companyEmail: res.data.CompanyEmail,
            edit_companycity: res.data.CompanyCity,
            edit_companystate: res.data.CompanyState,
            isMailVerified: res.data.isMailVerified,
            isPhoneVerified: res.data.isPhoneVerified,
            edit_companypincode: res.data.CompanyPincode,
            edit_companyaddress: res.data.CompanyAddress,
            edit_phonenumber: res.data.PhoneNumber,
            old_phonenumber: res.data.PhoneNumber,
            profilePic: res.data.ProfilePic,
            image: res.data.ProfilePic,
            pan: res.data.PanNumber,
            gst: res.data.GstNumber,
            oldgst: res.data.GstNumber,
            oldpan: res.data.PanNumber,
            loading: false,
            shiftName: res.data?.ShiftName,
            shiftStartTime: res.data?.StartTime,
            shiftEndTime: res.data?.EndTime,
            shiftHour: res.data?.ShiftHour,
            accountType: res.data?.AccountType,
            planName:
              CommonConfig.dateFormat(res.data?.PlanStartDate) +
              " To " +
              CommonConfig.dateFormat(res.data?.PlanEndDate),
            planStartDate: moment(res.data?.PlanStartDate).format("DD-MM-YYYY"),
            planEndDate: moment(res.data?.PlanEndDate).format("DD-MM-YYYY"),
            isCompanyEmailVerified:
              res.data?.IsCompanyEmailVerify == 1 ? true : false,
            isCompanyPhoneVerified:
              res.data?.IsCompanyPhoneVerify == 1 ? true : false,
          });

          if (res.data.IsPanVerified && res.data.IsPanVerified == "1") {
            this.setState({ ispanVerified: true });
          }
          if (res.data.IsGstVerified && res.data.IsGstVerified == "1") {
            this.setState({ isgstVerified: true });
          }
          this.getProgressBar();
        } else if (!res.success) {
          localStorage.clear();
          const { history } = props;
          history.push("/login");
        }
      })
      .catch(() => {});
  };

  onImageChange = (event) => {
    event.preventDefault();
    var file = event.target.files[0];
    var reader = new FileReader();
    if (Number(file.size) < 1024000 || Number(file.size) == 1024000) {
      this.setState({ image: file, isimageErr: false, isimagehelperText: "" });
      reader.onloadend = () => {
        this.setState({
          imgSrc: [reader.result],
          click: 1,
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({ image: this.state.profilePic });
      cogoToast.error("Image size should not be more than 1 MB");
    }
  };

  handleChange_value1 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    let panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    let aadharRegex = /^\d{4}\s\d{4}\s\d{4}$/;
    let aadharRegex1 = /^\d{4}\d{4}\d{4}$/;
    let phoneRegExp = /^([0-9]+\s?)*$/g;
    if (e.target.name === "edit_name") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_name: e.target.value,
          edit_nameErr: true,
          edit_namehelperText: "Please full Name",
        });
      } else {
        this.setState({
          edit_name: e.target.value,
          edit_nameErr: false,
          edit_namehelperText: "",
        });
      }
    }

    if (e.target.name === "otp") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          otp: e.target.value,
          otpErr: true,
          otphelperText: "Please enter otp",
        });
      } else {
        this.setState({
          otp: e.target.value,
          otpErr: false,
          otphelperText: "",
        });
      }
    }

    if (e.target.name === "edit_email") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_email: e.target.value,
          edit_emailErr: true,
          edit_emailhelperText: "Please Enter Email",
        });
      } else {
        this.setState({
          edit_email: e.target.value,
          edit_emailErr: false,
          edit_emailhelperText: "",
        });
      }
    }

    if (e.target.name === "edit_lastname") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_lastname: e.target.value,
          edit_lastnameErr: true,
          edit_lastnamehelperText: "Please Enter last name",
        });
      } else {
        this.setState({
          edit_lastname: e.target.value,
          edit_lastnameErr: false,
          edit_lastnamehelperText: "",
        });
      }
    }

    if (e.target.name === "KycPhoneNumber") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          KycPhoneNumber: e.target.value,
          KycPhoneNumberErr: true,
          KycPhoneNumberhelperText: "Please enter Mobile number",
        });
      } else if (
        !e.target.value.match(CommonConfig.RegExp.phoneRegExp) ||
        e.target.value.length > 10
      ) {
        this.setState({ KycPhoneNumber: this.state.KycPhoneNumber });
      } else {
        this.setState({
          KycPhoneNumber: e.target.value.replace(/\D/, ""),
          KycPhoneNumberErr: false,
          KycPhoneNumberhelperText: "",
        });
      }
    }
    if (e.target.name === "edit_phonenumber") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_phonenumber: e.target.value,
          edit_phonenumberErr: true,
          edit_phonenumberhelperText: "Please enter Mobile number",
        });
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(phoneRegExp)
      ) {
        this.setState({ edit_phonenumber: e.target.value });
      } else if (e.target.value.length !== 10) {
        this.setState({ edit_phonenumber: e.target.value });
      } else {
        this.setState({
          edit_phonenumber: e.target.value.replace(/\D/, ""),
          edit_phonenumberErr: false,
          edit_phonenumberhelperText: "",
          isNewPhoneNumberVerify: false,
        });
      }
      // if (e.target.value === "" || e.target.value === null) {
      //     this.setState({ edit_phonenumber: e.target.value, edit_phonenumberErr: true, edit_phonenumberhelperText: 'Please Enter Phone Number' });
      // }
      // else {
      //     this.setState({ edit_phonenumber: e.target.value.replace(/\D/, ''), edit_phonenumberErr: false, edit_phonenumberhelperText: '' });
      // }
    }

    if (e.target.name === "edit_website") {
      this.setState({ edit_website: e.target.value });
    }

    if (e.target.name === "edit_companyname") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_companyname: e.target.value,
          edit_companynameErr: true,
          edit_companynamehelperText: "Please Enter Company Name",
        });
      } else {
        this.setState({
          edit_companyname: e.target.value,
          edit_companynameErr: false,
          edit_companynamehelperText: "",
        });
      }
    }

    if (e.target.name === "edit_companyEmail") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_companyEmail: e.target.value,
          edit_companyEmailErr: true,
          edit_companyEmailhelperText: "Please Enter Company Email",
        });
      } else {
        this.setState({
          edit_companyEmail: e.target.value.toLowerCase(),
          edit_companyEmailErr: false,
          edit_companyEmailhelperText: "",
        });
      }
    }

    if (e.target.name === "edit_companyphone") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_companyphone: e.target.value,
          edit_companyphoneErr: true,
          edit_companyphonehelperText: "Please enter Mobile number",
        });
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(phoneRegExp)
      ) {
        this.setState({ edit_companyphone: this.state.edit_companyphone });
      } else if (e.target.value.length > 10) {
        this.setState({ edit_companyphone: this.state.edit_companyphone });
      } else {
        this.setState({
          edit_companyphone: e.target.value.replace(/\D/, ""),
          edit_companyphoneErr: false,
          edit_companyphonehelperText: "",
        });
      }
      // else {
      //     var clonePhoneid = this.state.isAlreadyRegisterPhone.filter(x => x === e.target.value);
      //     if (e.target.value === clonePhoneid[0]) {
      //         this.setState({ edit_companyphone: e.target.value, edit_companyphoneErr: true, edit_companyphonehelperText: 'Mobile number already registered' });
      //         formIsValid = false;
      //     }
      //     else {
      //         this.setState({ edit_companyphoneErr: false, edit_companyphonehelperText: '' });
      //     }
      // }
      // if (e.target.value === "" || e.target.value === null) {
      //     this.setState({ edit_companyphone: e.target.value, edit_companyphoneErr: true, edit_companyphonehelperText: 'Please Enter Phone Number' });
      // }
      // else {
      //     this.setState({ edit_companyphone: e.target.value.replace(/\D/, ''), edit_companyphoneErr: false, edit_companyphonehelperText: '' });
      // }
    }

    if (e.target.name === "edit_companycity") {
      this.setState({
        edit_companycity: e.target.value,
        edit_companycityErr: false,
        edit_companycityhelperText: "",
      });
    }

    if (e.target.name === "edit_companystate") {
      this.setState({
        edit_companystate: e.target.value,
        edit_companystateErr: false,
        edit_companystatehelperText: "",
      });
    }

    if (e.target.name === "edit_companypincode") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          edit_companypincode: e.target.value,
          edit_companypincodeErr: true,
          edit_companypincodehelperText: "Please Enter Phone Number",
        });
      } else {
        this.setState({
          edit_companypincode: e.target.value,
          edit_companypincodeErr: false,
          edit_companypincodehelperText: "",
        });
      }
    }

    if (e.target.name === "pan") {
      if (e.target.value == "") {
        this.setState({
          pan: e.target.value?.toUpperCase(),
          panErr: true,
          panhelperText: "PAN No is Required",
        });
      } else if (e.target.value.length > 10) {
        this.setState({ pan: this.state.pan?.toUpperCase() });
      } else if (!e.target.value?.toUpperCase().match(panRegex)) {
        this.setState({
          pan: e.target.value?.toUpperCase(),
          panErr: true,
          panhelperText: "Please enter valid PAN No.",
          ispanVerified: false,
        });
      } else if (e.target.value == this.state.oldpan) {
        this.setState({
          pan: e.target.value?.toUpperCase(),
          panErr: false,
          panhelperText: "",
          ispanVerified: true,
        });
      } else if (e.target.value != this.state.oldpan) {
        this.setState({
          pan: e.target.value?.toUpperCase(),
          panErr: false,
          panhelperText: "",
          ispanVerified: false,
        });
      } else {
        this.setState({
          pan: e.target.value?.toUpperCase(),
          panErr: false,
          panhelperText: "",
        });
      }
      // if (e.target.value.length > 0) {
      //     this.setState({ panVerifybtn: true })
      // }
      // else {
      //     this.setState({ panVerifybtn: false })
      // }
    }

    if (e.target.name === "zipShareCode") {
      if (e.target.value == "") {
        this.setState({
          zipShareCode: e.target.value,
          zipShareCodeErr: true,
          zipShareCodehelperText: "zipShareCode  is Required",
        });
      } else if (e.target.value.length > 4) {
        this.setState({ zipShareCode: this.state.zipShareCode });
      } else {
        this.setState({
          zipShareCode: e.target.value,
          zipShareCodeErr: false,
          zipShareCodehelperText: "",
        });
      }
    }
    if (e.target.name === "gst") {
      if (e.target.value == "") {
        this.setState({
          gst: e.target.value?.toUpperCase(),
          gstErr: true,
          gsthelperText: "GST No is Required",
        });
      } else if (e.target.value.length > 15) {
        this.setState({ gst: this.state.gst?.toUpperCase() });
      } else if (!e.target.value?.toUpperCase().match(gstRegex)) {
        this.setState({
          gst: e.target.value?.toUpperCase(),
          gstErr: true,
          gsthelperText: "Please enter valid GST No.",
          isgstVerified: false,
        });
      } else if (e.target.value == this.state.oldgst) {
        this.setState({
          gst: e.target.value?.toUpperCase(),
          gstErr: false,
          gsthelperText: "",
          isgstVerified: true,
        });
      } else if (e.target.value != this.state.oldgst) {
        this.setState({
          gst: e.target.value?.toUpperCase(),
          gstErr: false,
          gsthelperText: "",
          isgstVerified: false,
        });
      } else {
        this.setState({
          gst: e.target.value?.toUpperCase(),
          gstErr: false,
          gsthelperText: "",
        });
      }
      // if (e.target.value.length > 0) {
      //     this.setState({ gstVerifybtn: true })
      // }
      // else {
      //     this.setState({ gstVerifybtn: false })
      // }
    }
    if (e.target.name === "aadhar") {
      if (e.target.value == "") {
        this.setState({
          aadhar: e.target.value,
          aadharErr: true,
          aadharhelperText: "AADHAR No is Required",
        });
      } else if (
        !e.target.value.match(aadharRegex) &&
        !e.target.value.match(aadharRegex1)
      ) {
        this.setState({
          aadhar: e.target.value,
          aadharErr: true,
          aadharhelperText: "Please enter valid Aadhar No.",
        });
      } else {
        this.setState({
          aadhar: e.target.value,
          aadharErr: false,
          aadharhelperText: "",
        });
      }
      if (e.target.value.length > 0) {
        this.setState({ aadharVerifybtn: true });
      } else {
        this.setState({ aadharVerifybtn: false });
      }
    }
    if (e.target.name === "gstAddress1") {
      if (e.target.value == "") {
        this.setState({
          gstAddress1: e.target.value,
          gstAddress1Err: true,
          gstAddress1ErrText: "GST Address1 is Required",
        });
      } else {
        this.setState({
          gstAddress1: e.target.value,
          gstAddress1Err: false,
          gstAddress1ErrText: "",
        });
      }
    }
    if (e.target.name === "gstAddress2") {
      if (e.target.value == "") {
        this.setState({
          gstAddress2: e.target.value,
          gstAddress2Err: true,
          gstAddress2ErrText: "GST Address2 is Required",
        });
      } else {
        this.setState({
          gstAddress2: e.target.value,
          gstAddress2Err: false,
          gstAddress2ErrText: "",
        });
      }
    }
    if (e.target.name === "gstState") {
      if (e.target.value == "") {
        this.setState({
          gstState: e.target.value,
          gstStateErr: true,
          gstStateErrText: "GST State is Required",
        });
      } else {
        this.setState({
          gstState: e.target.value,
          gstStateErr: false,
          gstStateErrText: "",
        });
      }
    }
    if (e.target.name === "gstCompanyName") {
      if (e.target.value == "") {
        this.setState({
          gstCompanyName: e.target.value,
          gstCompanyNameErr: true,
          gstCompanyNameErrText: "GST Company Name is Required",
        });
      } else {
        this.setState({
          gstCompanyName: e.target.value,
          gstCompanyNameErr: false,
          gstCompanyNameErrText: "",
        });
      }
    }
    if (e.target.name === "gstTradeName") {
      if (e.target.value == "") {
        this.setState({
          gstTradeName: e.target.value,
          gstTradeNameErr: false,
          gstTradeNameErrText: "",
        });
      } else {
        this.setState({
          gstTradeName: e.target.value,
          gstTradeNameErr: false,
          gstTradeNameErrText: "",
        });
      }
    }

    if (e.target.name === "panNo") {
      if (e.target.value == "") {
        this.setState({
          panNo: e.target.value?.toUpperCase(),
          panNoErr: true,
          panNoErrText: "PAN NO is Required",
        });
      } else {
        this.setState({
          panNo: e.target.value?.toUpperCase(),
          panNoErr: false,
          panNoErrText: "",
        });
      }
    }
    if (e.target.name === "aadhaarOTP") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          aadhaarOTP: e.target.value,
          aadhaarOTPErr: true,
          aadhaarOTPErrText: "Aadhaar otp is Required",
        });
      } else {
        this.setState({
          aadhaarOTP: e.target.value,
          aadhaarOTPErr: false,
          aadhaarOTPErrText: "",
        });
      }
    }

    if (e.target.name === "panName") {
      if (e.target.value == "") {
        this.setState({
          panName: e.target.value,
          panNameErr: true,
          panNameErrText: "PAN Name is Required",
        });
      } else {
        this.setState({
          panName: e.target.value,
          panNameErr: false,
          panNameErrText: "",
        });
      }
    }
  };

  validate(e) {
    let formIsValid = true;
    this.setState({ [e.target.name]: e.target.value });

    let EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let zipregex = /^[1-9][0-9]{5}$/g;
    let phoneRegExp = /^[0-9]{10}$/;

    if (CommonConfig.isEmpty(this.state.UserType)) {
      formIsValid = false;
      this.setState({
        usertypeerr: true,
        usertypehelperText: "Please select User Type",
      });
    } else if (this.state.UserType === 0) {
      this.setState({
        usertypeerr: true,
        usertypehelperText: "Please select User Type",
      });
      formIsValid = false;
    } else {
      this.setState({ usertypeerr: false, usertypehelperText: "" });
    }

    if (this.state.edit_name === "" || this.state.edit_name === null) {
      this.setState({
        edit_nameErr: true,
        edit_namehelperText: "Please full Name",
      });
      formIsValid = false;
    } else if (this.state.edit_name.length < 3) {
      this.setState({ edit_nameErr: true, Name: this.state.Name });
      this.setState({
        edit_nameErr: true,
        edit_namehelperText: "Enter atleast 3 Character",
      });
      formIsValid = false;
    } else {
      this.setState({ edit_nameErr: false, edit_namehelperText: "" });
    }

    if (this.state.edit_email === "" || this.state.edit_email === null) {
      this.setState({
        edit_emailErr: true,
        edit_emailhelperText: "Please Enter Email",
      });
      formIsValid = false;
    } else if (!this.state.edit_email.match(EmailRegex)) {
      this.setState({
        edit_emailErr: true,
        edit_emailhelperText: "Email is not valid",
      });
      formIsValid = false;
    } else {
      this.setState({ edit_emailErr: false, edit_emailhelperText: "" });
    }

    if (!this.state.AccountType == "NewMember") {
      if (CommonConfig.isEmpty(this.state.edit_companyname)) {
        this.setState({
          edit_companynameErr: true,
          edit_companynamehelperText: "Please enter Company name",
        });
        formIsValid = false;
      } else {
        this.setState({
          edit_companynameErr: false,
          edit_companynamehelperText: "",
        });
      }

      if (CommonConfig.isEmpty(this.state.edit_companystate)) {
        this.setState({
          edit_companystateErr: false,
          edit_companystatehelperText: "",
        });
      } else if (!isNaN(this.state.edit_companystate)) {
        this.setState({
          edit_companystateErr: true,
          edit_companystatehelperText: "Please enter valid State",
        });
        formIsValid = false;
      } else {
        this.setState({
          edit_companystateErr: false,
          edit_companystatehelperText: "",
        });
      }

      if (CommonConfig.isEmpty(this.state.edit_companycity)) {
        this.setState({
          edit_companycityErr: false,
          edit_companycityhelperText: "",
        });
      } else if (!isNaN(this.state.edit_companycity)) {
        this.setState({
          edit_companycityErr: true,
          edit_companycityhelperText: "Please enter valid City",
        });
        formIsValid = false;
      } else {
        this.setState({
          edit_companycityErr: false,
          edit_companycityhelperText: "",
        });
      }

      if (CommonConfig.isEmpty(this.state.edit_companyEmail)) {
        this.setState({
          edit_companyEmailErr: true,
          edit_companyEmailhelperText: "Please enter Company email",
        });
        formIsValid = false;
      } else if (
        this.state.edit_companyEmail.trim() !== this.state.edit_companyEmail ||
        !this.state.edit_companyEmail.match(EmailRegex)
      ) {
        this.setState({
          edit_companyEmailErr: true,
          edit_companyEmailhelperText: "Email is not valid",
        });
        formIsValid = false;
      } else {
        this.setState({
          edit_companyEmailErr: false,
          edit_companyEmailhelperText: "",
        });
      }

      if (CommonConfig.isEmpty(this.state.edit_companyphone)) {
        this.setState({
          edit_companyphoneErr: true,
          edit_companyphone: this.state.edit_companyphone,
        });
        this.setState({
          edit_companyphoneErr: true,
          edit_companyphonehelperText: "Please enter Mobile number",
        });
        formIsValid = false;
      } else if (
        this.state.edit_companyphone?.trim() !== this.state.edit_companyphone ||
        !this.state.edit_companyphone?.match(phoneRegExp)
      ) {
        this.setState({
          edit_companyphoneErr: true,
          edit_companyphone: this.state.edit_companyphone,
        });
        this.setState({
          edit_companyphoneErr: true,
          edit_companyphonehelperText: "Mobile number not valid",
        });
        formIsValid = false;
      } else if (this.state.edit_companyphone?.length !== 10) {
        this.setState({
          edit_companyphoneErr: true,
          edit_companyphone: this.state.edit_companyphone,
        });
        this.setState({
          edit_companyphoneErr: true,
          edit_companyphonehelperText:
            "Mobile number length should be 10 digit only",
        });
        formIsValid = false;
      } else {
        this.setState({
          edit_companyphoneErr: false,
          edit_companyphonehelperText: "",
        });
      }

      if (CommonConfig.isEmpty(this.state.edit_companypincode)) {
        this.setState({
          edit_companypincodeErr: true,
          edit_companypincodehelperText: "Please enter the Company pincode",
        });
        formIsValid = false;
      } else if (
        this.state.edit_companypincode.trim() !==
          this.state.edit_companypincode ||
        !this.state.edit_companypincode.match(zipregex)
      ) {
        this.setState({
          edit_companypincodeErr: true,
          edit_companypincodehelperText: "Please enter valid Company pincode",
        });
        formIsValid = false;
      } else {
        this.setState({
          edit_companypincodeErr: false,
          edit_companypincodehelperText: "",
        });
      }
    }

    if (
      this.state.edit_phonenumber !== this.state.old_phonenumber &&
      !this.state.isNewPhoneNumberVerify
    ) {
      this.setState({
        edit_phonenumberErr: true,
        edit_phonenumberhelperText: "Verify new phone number.",
      });
      formIsValid = false;
    } else {
      this.setState({
        edit_phonenumberErr: false,
        edit_phonenumberhelperText: "",
      });
    }
    return formIsValid;
  }

  EditProfile = (event) => {
    event.preventDefault();
    if (this.validate(event)) {
      var formData = new FormData();
      formData.append("UserId", this.state.UserId);
      formData.append("Name", this.state.edit_name);
      formData.append("Email", this.state.edit_email);
      formData.append("PhoneNumber", this.state.edit_phonenumber);
      formData.append("last_name", this.state.edit_lastname);
      formData.append("cmpny_name", this.state.edit_companyname);
      formData.append("website", this.state.edit_website);
      formData.append("UserRole", this.state.UserType);
      formData.append("Company_email_id", this.state.edit_companyEmail);
      formData.append("Company_phone", this.state.edit_companyphone);
      formData.append("company_address", this.state.edit_companyaddress);
      formData.append("company_city", this.state.edit_companycity);
      formData.append("company_state", this.state.edit_companystate);
      formData.append("Company_pincode", this.state.edit_companypincode);
      formData.append("ProfilePic", this.state.image);
      formData.append("BirthDate", this.state.edit_dob);
      formData.append("token", this.state.token);
      formData.append("PanNumber", this.state.pan);
      formData.append("GstNumber", this.state.gst);
      formData.append("AadharNumber", this.state.aadhar);
      formData.append(
        "IsCompanyEmailVerify",
        this.state.isCompanyEmailVerified == true ? 1 : 0
      );
      formData.append(
        "IsCompanyPhoneVerify",
        this.state.isCompanyPhoneVerified == true ? 1 : 0
      );

      //   this.setState({ loading: true });
      api
        .post(APIConstant.path.EditProfile, formData, {
          headers: {
            "Content-Type": "mulitipart/form-data",
          },
        })
        .then((res) => {
          if (res.Success === 1) {
            // window.location.reload();

            this.setState({ profilePic: res.Info.UserDetail.ProfilePic });
            // localStorage.setItem('Userdata', JSON.stringify(res.Info, true));
            this.GetEditUserDetails(this.state.UserId, this.state.token);
            cogoToast.success("Profile updated successfully");

            this.props.history.push({
              state: {
                UserName: this.state.edit_name,
              },
            });
            this.props.history.push({
              state: {
                IsProfileUpdated: true,
                profilePic:
                  this.state.click === 0
                    ? individual_profile_image_link + this.state.profilePic
                    : this.state.imgSrc,
              },
            });
          } else if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
            //     }
            // })
          } else if (res.Success === 2) {
            cogoToast.success(res.Message);
            this.props.history.push("/login");
          } else {
            this.setState({ loading: false });
            cogoToast.error(res.Message);
          }
        })
        .catch(() => {});
    } else {
      this.setState({ loading: false });
      return;
    }
  };

  verifyEmail = (type) => {
    if (type == "verify") {
      var params = {
        UserId: this.state.UserId,
        Email: this.state.edit_email,
        token: this.state.token,
      };
      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(APIConstant.path.VerifyEmail, params)
          .then((res) => {
            if (res.Success === 1) {
              // window.location.reload();
              this.setState({ loading: false });
              this.GetEditUserDetails(this.state.UserId, this.state.token);
              cogoToast.success(res.Message);
            } else if (res.Success == -1) {
              localStorage.clear();
              const { history } = this.props;
              history.push("/login");
            } else {
              this.setState({ loading: false });
              cogoToast.error(res.Message);
            }
          })
          .catch(() => {});
      }
    } else if (type == "otpverify") {
      if (this.state.otp !== "") {
        if (this.state.otp == this.state.otpcheck) {
          this.setState({ otpErr: false, otphelperText: "" });
          var params = {
            UserId: this.state.UserId,
            email_phone: this.state.edit_email,
            Type: "email",
            token: this.state.token,
          };
          if (params !== null) {
            this.setState({ loading: true });
            api
              .post(APIConstant.path.ChangeEmailPhone, params)
              .then((res) => {
                if (res.Success === 1) {
                  cogoToast.success("Email updated successfully");
                  this.setState({
                    loading: false,
                    otp: "",
                    otpcheck: "",
                    otpErr: false,
                    otphelperText: "",
                    old_email: "",
                    openotp: false,
                  });
                  this.GetEditUserDetails(this.state.UserId, this.state.token);
                } else if (res.Success == -1) {
                  // let data = localStorage.getItem('Infodata');
                  // var params = {
                  //     UserId: data
                  // }
                  // // setUserName: data });
                  // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                  //     if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                  //     }
                  // })
                }
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          }
        } else {
          this.setState({
            loading: false,
            otpErr: true,
            otphelperText: "Please enter valid otp",
          });
        }
      } else {
        this.setState({
          loading: false,
          otpErr: true,
          otphelperText: "Please enter  otp",
        });
      }
    } else if (type == "otpverifyphone") {
      if (this.state.otp !== "") {
        if (this.state.otp == this.state.otpcheck) {
          this.setState({ otpErr: false, otphelperText: "" });
          var params = {
            UserId: this.state.UserId,
            email_phone: this.state.edit_phonenumber,
            Type: "phone",
            token: this.state.token,
            OldPhoneNumber: this.state.old_phonenumber,
          };
          if (params !== null) {
            this.setState({ loading: true });
            api
              .post(APIConstant.path.ChangeEmailPhone, params)
              .then((res) => {
                if (res.Success === 1) {
                  cogoToast.success("Mobile number updated successfully");
                  this.setState({
                    loading: false,
                    otp: "",
                    otpcheck: "",
                    otpErr: false,
                    otphelperText: "",
                    old_phonenumber: "",
                    openotp: false,
                    phone: 0,
                    isNewPhoneNumberVerify: true,
                  });
                  this.GetEditUserDetails(this.state.UserId, this.state.token);
                } else if (res.Success == -1) {
                  // let data = localStorage.getItem('Infodata');
                  // var params = {
                  //     UserId: data
                  // }
                  // // setUserName: data });
                  // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                  //     if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                  //     }
                  // })
                }
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          }
        } else {
          this.setState({
            loading: false,
            otpErr: true,
            otphelperText: "Please enter valid otp",
          });
        }
      } else {
        this.setState({
          loading: false,
          otpErr: true,
          otphelperText: "Please enter otp",
        });
      }
    } else if (type == "changephone") {
      if (this.state.edit_phonenumber !== this.state.old_phonenumber) {
        this.setState({
          edit_phonenumberErr: false,
          edit_phonenumberhelperText: "",
        });
        var params = {
          UserId: this.state.UserId,
          email_phone: this.state.edit_phonenumber,
          token: this.state.token,
        };
        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(apiBase + "/ajax/player.php?action=SendOTPForDriver", params)
            .then((res) => {
              if (res.Success === 1) {
                // window.location.reload();
                this.setState({
                  loading: false,
                  openotp: true,
                  otpcheck: res.Info.SecurityCode,
                  phone: 1,
                });

                // this.GetEditUserDetails("")
                cogoToast.success(
                  "OTP has been sent to entered mobile number "
                );

                // this.props.history.push("/admin/VesselInfo");
              } else if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
                //     }
                // })
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch(() => {});
        }
      } else {
        this.setState({
          edit_phonenumberErr: true,
          edit_phonenumberhelperText:
            "Please enter new mobile number to change",
        });
      }
    } else {
      if (this.state.edit_email !== this.state.old_email) {
        this.setState({ edit_emailErr: false, edit_emailhelperText: "" });
        var params = {
          UserId: this.state.UserId,
          email_phone: this.state.edit_email,
          token: this.state.token,
        };
        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(apiBase + "/ajax/player.php?action=SendOTPForDriver", params)
            .then((res) => {
              if (res.Success === 1) {
                // window.location.reload();
                this.setState({
                  loading: false,
                  openotp: true,
                  otpcheck: res.Info.SecurityCode,
                  phone: 0,
                });

                // this.GetEditUserDetails("")
                cogoToast.success("OTP has been sent to entered email ");

                // this.props.history.push("/admin/VesselInfo");
              } else if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
                //     }
                // })
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch(() => {});
        }
      } else {
        this.setState({
          edit_emailErr: true,
          edit_emailhelperText: "Please enter new email to change",
        });
      }
    }
  };

  getPanVerifyData = (type) => {
    if (type == "getPanVerifyData") {
      if (this.state.pan) {
        this.setState({ panErr: false, panhelperText: "" });
        var params = {
          UserId: this.state.UserId,
          pan: this.state.pan,
          token: this.state.token,
        };
        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(APIConstant.path.GetPanVerifyData, params)
            .then((res) => {
              if (
                res.Success === 1 &&
                res.Info.VerifyPAN &&
                res.Info.VerifyPAN.code &&
                res.Info.VerifyPAN.code == 200
              ) {
                console.log("PAN Response", res);
                // this.setState({ loading: false, ispanVerified: true });
                // this.GetEditUserDetails(this.state.UserId, this.state.token)
                this.setState({
                  PANVerifyData: res.Info.VerifyPAN,
                  loading: false,
                  openPan: true,
                  panNo: res.Info.VerifyPAN.response.pan_no,
                  panName: res.Info.VerifyPAN.response.registered_name,
                });
                cogoToast.success(res.Message);
              } else if (
                (res.Info.VerifyPAN &&
                  (res.Info.VerifyPAN.code &&
                    res.Info.VerifyPAN.code != 200)) ||
                res.Info.VerifyPAN.code == undefined
              ) {
                cogoToast.error("Verification failed. Please check PAN No.");
                this.setState({
                  loading: false,
                });
              } else if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }

                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
                //     }
                // })
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch(() => {});
        }
      } else {
        this.setState({ panErr: true, panhelperText: "PAN No. is required." });
      }
    }
  };

  verifyPAN = (type) => {
    this.setState((prevState) => {
      let PANVerifyData = Object.assign({}, prevState.PANVerifyData); // creating copy of state variable GSTVerifyData
      PANVerifyData.response.registered_name = this.state.panName;
      return { PANVerifyData };
    });

    if (type == "verifyPAN") {
      if (this.state.pan) {
        this.setState({ panErr: false, panhelperText: "" });
        var params = {
          UserId: this.state.UserId,
          panData: this.state.PANVerifyData,
          token: this.state.token,
        };
        console.log("Pan Verify Data", this.state.PANVerifyData);

        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(APIConstant.path.VerifyPAN, params)
            .then((res) => {
              if (res.Success === 1) {
                // window.location.reload();
                this.setState({
                  loading: false,
                  ispanVerified: true,
                  openPan: false,
                });
                this.GetEditUserDetails(this.state.UserId, this.state.token);
                cogoToast.success(res.Message);
              } else if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }

                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
                //     }
                // })
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch(() => {});
        }
      } else {
        this.setState({ panErr: true, panhelperText: "PAN No. is required." });
      }
    }
  };

  sentGstAdminRequest = () => {
    this.setState({ loading: true });
    var params = {
      UserId: this.state.UserId,
      gst: this.state.gst,
    };
    if (params !== null) {
      api.post(APIConstant.path.SentGstAdminRequest, params).then((res) => {
        if (res.Success === 1) {
          cogoToast.success(res.Message);
          this.setState({
            openGstRequest: false,
            loading: false,
            phoneclick: 0,
          });
          this.GetEditUserDetails(this.state.UserId, this.state.token);
        } else if (res.Success == -1) {
          // let data = localStorage.getItem('Infodata');
          // var params = {
          //     UserId: data
          // }
          // // setUserName: data });
          // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
          //     if (res.Success == 1) {
          localStorage.clear();
          const { history } = this.props;
          history.push("/login");
          //     }
          // })
        }
      });
    }
  };

  getGSTVerifyData = (type) => {
    if (type == "getGSTVerifyData") {
      if (this.state.gst) {
        this.setState({ gstErr: false, gsthelperText: "" });
        var params = {
          UserId: this.state.UserId,
          // PhoneNumber:this.state.edit_phonenumber,
          gst: this.state.gst,
          token: this.state.token,
        };

        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(APIConstant.path.GetGSTVerifyData, params)
            .then((res) => {
              if (
                res.Success === 1 &&
                res.Info.VerifyGST &&
                res.Info.VerifyGST.code &&
                res.Info.VerifyGST.code == 200
              ) {
                // this.setState({ loading: false, isgstVerified: true });
                // this.GetEditUserDetails(this.state.UserId, this.state.token)
                this.setState({
                  GSTVerifyData: res.Info.VerifyGST,
                  loading: false,
                  open: true,
                  gstAddress1: res.Info.VerifyGST.response.address.addr1,
                  gstAddress2: res.Info.VerifyGST.response.address.addr2,
                  gstState: res.Info.VerifyGST.response.address.state,
                  gstCompanyName: res.Info.VerifyGST.response.legal_name,
                  gstTradeName: res.Info.VerifyGST.response.trade_name,
                });
                cogoToast.success(res.Message);

                console.log("GSTVerifyData", this.state.GSTVerifyData);
              } else if (
                (res.Info.VerifyGST &&
                  (res.Info.VerifyGST.code &&
                    res.Info.VerifyGST.code != 200)) ||
                res.Info.VerifyGST.code == undefined
              ) {
                // cogoToast.error("Verification failed. Please check GST No.");
                this.setState({ openGstRequest: true });
                this.setState({
                  loading: false,
                });
              } else if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }

                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
                //     }
                // })
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch(() => {});
        }
      } else {
        this.setState({ gstErr: true, gsthelperText: "GST No. is required." });
      }
    }
  };

  verifyGST = (type) => {
    this.setState((prevState) => {
      let GSTVerifyData = Object.assign({}, prevState.GSTVerifyData); // creating copy of state variable GSTVerifyData
      GSTVerifyData.response.address.addr1 = this.state.gstAddress1;
      GSTVerifyData.response.address.addr2 = this.state.gstAddress2;
      GSTVerifyData.response.address.state = this.state.gstState;
      GSTVerifyData.response.legal_name = this.state.gstCompanyName;
      GSTVerifyData.response.trade_name = this.state.gstTradeName;
      return { GSTVerifyData };
    });

    console.log("GSTVerifyData from Verify GST", this.state.GSTVerifyData);

    if (type == "verifyGST") {
      if (this.state.gst) {
        this.setState({ gstErr: false, gsthelperText: "" });
        var params = {
          UserId: this.state.UserId,
          gstData: this.state.GSTVerifyData,
          token: this.state.token,
          AccountType: this.state.AccountType,
        };
        console.log("params from Verify GST", params);

        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(APIConstant.path.VerifyGST, params)
            .then((res) => {
              if (res.Success === 1) {
                console.log("GST res", res);
                this.setState({ isgstVerified: true, open: false });
                this.GetEditUserDetails(this.state.UserId, this.state.token);
                cogoToast.success(res.Message);
              } else if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }

                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = this.props;
                history.push("/login");
                //     }
                // })
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch(() => {});
        }
      } else {
        this.setState({ gstErr: true, gsthelperText: "GST No. is required." });
      }
    }
  };

  getUserKyc = (ID) => {
    var params = { UserId: ID };
    api.post(APIConstant.path.GetUserKyc, params).then((res) => {
      if (res.Success === 1) {
        this.setState({
          aadhar: CommonConfig.replaceFirst8DigitsWithAsterisks(
            res.Data?.AadharNo
          ),
          isKYCVerified: res.Data?.IsVerify == 1 ? true : false,
        });
      }
    });
  };

  modalOpen = () => {
    this.setState({ openUserKyc: true });
    this.getUserKyc(this.state.UserId);
  };

  updateUserKYCDetials = async (item) => {
    try {
      Loader.show();
      const payload = {
        UserId: this.state?.UserId,
        Aadhaar: this.state?.aadhar,
        AadhaarRefId: this.state?.aadharRefId,
        AadhaarName: item?.name,
        AadhaarCarePerson: item?.care,
        AadhaarProfilePicture: item?.image,
        AadhaarDOB: item?.dob,
        AadhaarGender: item?.gender,
        AadhaarAddress: !CommonConfig.isObjectEmpty(item)
          ? item?.address?.house +
            " " +
            item?.address?.landmark +
            " " +
            item?.address?.loc +
            " " +
            item?.address?.street +
            " " +
            item?.address?.vtc +
            " " +
            item?.address?.subdist +
            " " +
            item?.address?.dist +
            " " +
            item?.address?.state +
            " " +
            item?.address?.country
          : "",
        AadhaarShareCode: item?.share_code,
        AadhaarZipFileUrl: item?.zip_file,
      };

      // const fileName = 'profilePic.jpeg';
      // // Replace 'image/png' with the appropriate content type
      // const contentType = 'image/jpeg';
      // let ProfilePicture = CommonConfig.base64toFileNew(item?.image, fileName, contentType);
      // let formData = new FormData();

      // formData.append('Data', JSON.stringify(payload));
      // formData.append('AadhaarProfilePicture', ProfilePicture.length ? ProfilePicture[0]?.file : []);

      api.post(APIConstant.path.AddUpdateUserKyc, payload).then((res) => {
        if (res.Success === 1) {
          this.getUserKyc(this.state.UserId);
          Loader.hide();
          this.setState({ isKycStatus: true });
          cogoToast.success(res.Message);
        } else {
          Loader.hide();
          cogoToast.error(res.Message);
        }
      });
    } catch (error) {
      console.error("error", error);
      Loader.hide();
    }
  };

  // Send OTP on Aadhaar No
  handleSendAadharOTP = async (event) => {
    event.preventDefault();
    try {
      Loader.show();
      if (
        CommonConfig.isEmpty(this.state.UserId) &&
        CommonConfig.isEmpty(this.state.aadhar)
      ) {
        cogoToast.error("Aadhaar no and user id is required.");
        return true;
      }

      var params = {
        UserId: this.state.UserId,
        AadhaarNo: this.state.aadhar,
      };
      await api
        .post(APIConstant.path.verifyAadhaar, params)
        .then(async (res) => {
          if (res.Success == 1) {
            Loader.hide();
            this.setState({
              verifyAadhaarClick: false,
              isAadhaarOtpSend: true,
              aadharRefId: res.Info.ref_id,
            });
            cogoToast.success(res.Message);
          } else {
            Loader.hide();
            cogoToast.error(res.Message);
          }
        });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      cogoToast.error("Some error occurred");
    } finally {
      Loader.hide();
      this.setState({ loading: false });
    }
  };

  // Verify Aadhaar OTP
  verifyAadhaarOTP = async (event) => {
    event.preventDefault();
    try {
      Loader.show();
      let data = {
        RefId: this.state.aadharRefId,
        OTP: this.state.aadhaarOTP,
      };
      await api
        .post(APIConstant.path.verifyAadhaarOTP, data)
        .then(async (response) => {
          const res = await response;
          if (res.Success == 1) {
            this.setState({ aadhaarData: res.Info, edit_name: res.Info.name });
            cogoToast.success(res.Message);
            Loader.hide();
            await this.updateUserKYCDetials(res.Info);
          } else {
            cogoToast.error(res.Message);
            Loader.hide();
          }
        })
        .catch((err) => {
          console.error("err", err);
          Loader.hide();
        });
    } catch (error) {
      console.error("error", error);
      Loader.hide();
    }
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShow = () => {
    this.setState((state) => ({ show: !state.show }));
  };

  setPort = async (value) => {
    this.setState({ comPort: value });
    try {
      this.setState({ loading: true });
      let params = {
        PortName: value,
        UserId: this.state.UserId,
      };
      await Axios.post("http://localhost:3005/setPortName", params)
        .then(async (response) => {
          let res = await response.data;
          if (res.Success == 1) {
            this.setState({ loading: false });

            cogoToast.success(res.Message);
          } else {
            this.setState({ loading: false });
            cogoToast.error(res.Message);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  onDeleteProfileImage = (event) => {
    event.preventDefault();
    this.setState({
      imgSrc: "",
      image: "",
      profilePic: "",
      click: 0,
      // Add any other necessary state updates here
    });
  };

  handleChange = async (e, type) => {
    if (type === "aadhaarOTP") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          aadhaarOTP: "",
          aadhaarOTPErr: true,
          aadhaarOTPErrText: "Aadhaar Otp is required",
        });
      } else {
        this.setState({
          aadhaarOTP: e.target.value,
          aadhaarOTPErr: false,
          aadhaarOTPErrText: "",
        });
      }
    } else if (type === "companyEmailOTP") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          companyEmailOTP: "",
          companyEmailOTPErr: true,
          companyEmailOTPErrText: "Company email otp is required",
        });
      } else {
        this.setState({
          companyEmailOTP: e.target.value,
          companyEmailOTPErr: false,
          companyEmailOTPErrText: "",
        });
      }
    } else if (type === "companyPhoneOTP") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          companyPhoneOTP: "",
          companyPhoneOTPErr: true,
          companyPhoneOTPErrText: "Company phone otp is required",
        });
      } else {
        this.setState({
          companyPhoneOTP: e.target.value,
          companyPhoneOTPErr: false,
          companyPhoneOTPErrText: "",
        });
      }
    }
  };

  isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  sendOtpOnCompanyEmail = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
      companyEmailVerifyDisable: true,
      isCompanyEmailResendEnable: false,
    });

    const data = {
      SendData: this.state.edit_companyEmail,
    };
    api
      .post(APIConstant.path.SendOTPPhoneAndEmail, data)
      .then((res) => {
        if (res.Success === 1) {
          this.setState({
            loading: false,
            verifyCompanyEmailClick: false,
            isCompanyEmailResendButton: res.Info.SendCount < 3 ? true : false,
          });
          setTimeout(() => {
            this.setState({ isCompanyEmailResendEnable: true });
          }, 30000);
          cogoToast.success("OTP sent to your email");
        } else if (res.Success === 2) {
          this.setState({ loading: false, companyEmailVerifyDisable: false });
          cogoToast.error(res.Message);
        } else {
          this.setState({ loading: false, companyEmailVerifyDisable: false });
          cogoToast.error("Error");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, companyEmailVerifyDisable: false });
        console.log("Err", err);
      });
  };

  sendOtpOnCompanyPhone = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
      companyPhoneVerifyDisable: true,
      isCompanyPhoneResendEnable: false,
    });

    const data = {
      SendData: this.state.edit_companyphone,
    };
    api
      .post(APIConstant.path.SendOTPPhoneAndEmail, data)
      .then((res) => {
        if (res.Success === 1) {
          this.setState({
            loading: false,
            verifyCompanyPhoneClick: false,
            isCompanyPhoneResendButton: res.Info.SendCount < 3 ? true : false,
          });
          setTimeout(() => {
            this.setState({ isCompanyPhoneResendEnable: true });
          }, 30000);
          cogoToast.success("OTP sent to your phone");
        } else if (res.Success === 2) {
          this.setState({ loading: false, companyPhoneVerifyDisable: false });
          cogoToast.error(res.Message);
        } else {
          this.setState({ loading: false, companyPhoneVerifyDisable: false });
          cogoToast.error("Error");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, companyPhoneVerifyDisable: false });
        console.log("Err", err);
      });
  };

  handleVerifyOTP = async (entity, otp) => {
    try {
      const response = await api.post(APIConstant.path.VerifyOTP, {
        Entity: entity,
        OTP: otp,
      });

      // Assuming your API response structure has 'Success', 'Message', and 'Info' fields
      return response;
    } catch (error) {
      // Handle specific errors or provide a generic message
      if (error?.response) {
        // The request was made, but the server responded with an error status
        console.error("API Error:", error);
      } else if (error?.request) {
        // The request was made, but no response was received
        console.error("No response received from the server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error);
      }

      // Optionally rethrow the error if you want to propagate it further
      throw error;
    }
  };

  verifyCompanyEmailOTP = async (e) => {
    e.preventDefault();
    await this.handleVerifyOTP(
      this.state.edit_companyEmail,
      this.state.companyEmailOTP
    )
      .then(async (res) => {
        const response = await res;
        if (response.Success == 1) {
          this.setState({
            isCompanyEmailVerified: true,
            companyEmailOTPErr: false,
            companyEmailOTPErrText: "",
            isCompanyEmailResendButton: false,
            isCompanyEmailResendEnable: false,
            edit_companyEmailErr: false,
            edit_companyEmailhelperText: "",
          });
        } else {
          this.setState({
            isCompanyEmailVerified: false,
            companyEmailOTPErr: true,
            companyEmailOTPErrText: response.Message,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  verifyCompanyPhoneOTP = async (e) => {
    e.preventDefault();
    await this.handleVerifyOTP(
      this.state.edit_companyphone,
      this.state.companyPhoneOTP
    )
      .then(async (res) => {
        const response = await res;
        if (response.Success == 1) {
          this.setState({
            isCompanyPhoneVerified: true,
            companyPhoneOTPErr: false,
            companyPhoneOTPErrText: "",
            isCompanyPhoneResendButton: false,
            isCompanyPhoneResendEnable: false,
            edit_companyPhoneErr: false,
            edit_companyPhonehelperText: "",
          });
        } else {
          this.setState({
            isCompanyPhoneVerified: false,
            companyPhoneOTPErr: true,
            companyPhoneOTPErrText: response.Message,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  render() {
    const classes = useStyles;
    return (
      <div>
        <form onSubmit={(e) => this.EditProfile(e)}>
          <Card className="card-lg-section bs-none p-m-0">
            <CardBody className="p-px-0">
              <GridContainer>
                <GridItem xs={12} md={4} className="p-p-0 p-pr-md-2 ">
                  <div className="customize-card p-m-0 bs-none">
                    <div className="input-control p-position-relative p-p-0">
                      {this.state.click === 0 ? (
                        <div>
                          <img
                            className="edit-profile-image"
                            src={
                              this.state.profilePic == "" ||
                              this.state.profilePic == null ||
                              this.state.profilePic == undefined
                                ? User
                                : individual_profile_image_link +
                                  this.state.profilePic
                            }
                          />
                          <label
                            className="edit-profile-editImage p-d-flex p-jc-center p-ai-center"
                            htmlFor="edit_profile"
                          >
                            <i className="icon-editImage"></i>
                          </label>
                          <input
                            type="file"
                            onChange={(e) => this.onImageChange(e)}
                            style={{ visibility: "hidden" }}
                            className="filetype"
                            id="edit_profile"
                            accept="image/jpeg"
                          />
                          <button
                            onClick={(e) => this.onDeleteProfileImage(e)}
                            className="edit-profile-deleteImage p-d-flex p-jc-center p-ai-center"
                          >
                            <i className="icon-delete"></i>
                          </button>
                        </div>
                      ) : (
                        <div>
                          <img
                            src={this.state.imgSrc}
                            className="edit-profile-image"
                          />

                          <label
                            htmlFor="edit_profile"
                            className="edit-profile-editImage p-d-flex p-jc-center p-ai-center"
                          >
                            <i className="icon-editImage"></i>
                          </label>
                          <input
                            type="file"
                            onChange={(e) => this.onImageChange(e)}
                            style={{ visibility: "hidden" }}
                            className="filetype"
                            id="edit_profile"
                            accept="image/jpeg"
                          />
                          <button
                            onClick={(e) => this.onDeleteProfileImage(e)}
                            className="edit-profile-deleteImage p-d-flex p-jc-center p-ai-center"
                          >
                            <i className="icon-delete"></i>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="p-px-4 user-profile-section">
                      <div className="p-d-flex p-jc-between p-ai-center">
                        <div className="user-profile">
                          {this.state.UserType}
                        </div>
                        <div>
                          {this.state.isKYCVerified ? (
                            <div className="kyc-tag">
                              <img src={successCircle} /> Verified
                            </div>
                          ) : (
                            <div className="no-kyc-tag">
                              <Button onClick={(e) => this.modalOpen(e)}>
                                {" "}
                                <i className="icon-warning-circle"></i> KYC Not
                                Verified
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="input-control ipt-with-icon">
                        <CustomInput
                          labelText="Full Name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => this.handleChange_value1(e),
                            type: "text",
                            id: "edit_name",
                            name: "edit_name",
                            value: this.state.edit_name,
                            disabled: this.state.isKYCVerified ? true : false,
                          }}
                          helperText={this.state.edit_namehelperText}
                          id="outlined-required"
                          error={this.state.edit_nameErr}
                        />
                      </div>

                      <div className="input-control ipt-with-icon">
                        <CustomInput
                          labelText="Aadhar No"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => this.handleChange_value1(e),
                            type: "text",
                            id: "aadhar",
                            name: "aadhar",
                            value: this.state.aadhar,
                            disabled: this.state.isKYCVerified ? true : false,
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.state.isKYCVerified ? (
                                  <img
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      marginInlineEnd: "2px",
                                    }}
                                    src={yes}
                                  />
                                ) : (
                                  <div className="p-inline">
                                    <div
                                      className="login-btn p-grid p-jc-between"
                                      style={{
                                        display:
                                          this.state.verifyAadhaarClick &&
                                          this.state.aadhar.length == 12
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <span>
                                        <img
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            marginInlineEnd: "2px",
                                          }}
                                          src={pending}
                                        />
                                        &nbsp;
                                        <Button
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            color: "white",
                                            fontSize: "12px",
                                          }}
                                          onClick={(e) =>
                                            this.handleSendAadharOTP(e)
                                          }
                                          className="buyVessel-btn"
                                        >
                                          verify
                                        </Button>
                                      </span>
                                    </div>
                                    <div
                                      className="login-input"
                                      style={{
                                        display: this.state.verifyAadhaarClick
                                          ? "none"
                                          : "block",
                                        paddingLeft: "10px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      <div
                                        className="login-btn"
                                        style={{ display: "flex" }}
                                      >
                                        <InputText
                                          id="aadhaarOTP"
                                          type="text"
                                          placeholder="Enter OTP*"
                                          value={this.state.aadhaarOTP}
                                          onChange={(e) =>
                                            this.handleChange(e, "aadhaarOTP")
                                          }
                                          keyfilter={"num"}
                                          maxLength={6}
                                        />
                                        {this.state.aadhaarOTPErr &&
                                          CommonConfig.showErrorMsg(
                                            this.state.aadhaarOTPErrText
                                          )}
                                        <Button
                                          style={{
                                            marginTop: "13px",
                                            marginLeft: "10px",
                                            height: "35px",
                                          }}
                                          className="register-submit"
                                          type=""
                                          variant="contained"
                                          color="primary"
                                          onClick={(e) =>
                                            this.verifyAadhaarOTP(e)
                                          }
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          helperText={this.state.aadharhelperText}
                          id="outlined-required"
                          error={this.state.aadharErr}
                        />
                      </div>

                      <div className="input-control ipt-with-icon">
                        <CustomInput
                          labelText="Your Email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: true,
                            onChange: (e) => this.handleChange_value1(e),
                            type: "text",
                            id: "edit_email",
                            name: "edit_email",
                            value: this.state.edit_email,
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.state.isMailVerified ? (
                                  <img
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      marginInlineEnd: "2px",
                                    }}
                                    src={yes}
                                  />
                                ) : (
                                  <>
                                    <img
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        marginInlineEnd: "2px",
                                      }}
                                      src={pending}
                                    />
                                    &nbsp;
                                    <Button
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                      className="buyVessel-btn"
                                      onClick={() => this.verifyEmail("verify")}
                                    >
                                      Verify
                                    </Button>
                                  </>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          helperText={this.state.edit_emailhelperText}
                          id="outlined-required"
                          error={this.state.edit_emailErr}
                        />
                      </div>

                      <div className="input-control ipt-with-icon">
                        <CustomInput
                          labelText="Your Phone Number"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            maxLength: 10,
                            onChange: (e) => this.handleChange_value1(e),
                            type: "text",
                            id: "edit_phonenumber",
                            name: "edit_phonenumber",
                            value: this.state.edit_phonenumber,
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.state.isPhoneVerified === "1" ? (
                                  <>
                                    {this.state.edit_phonenumber !==
                                      this.state.old_phonenumber &&
                                    !this.state.isNewPhoneNumberVerify ? (
                                      <Button
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          color: "white",
                                          fontSize: "12px",
                                        }}
                                        className="buyVessel-btn"
                                        onClick={() =>
                                          this.verifyEmail("changephone")
                                        }
                                      >
                                        Verify
                                      </Button>
                                    ) : (
                                      <img
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          marginInlineEnd: "2px",
                                        }}
                                        src={yes}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        marginInlineEnd: "2px",
                                      }}
                                      src={pending}
                                    />
                                    <Button
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                      className="buyVessel-btn"
                                      onClick={() =>
                                        this.verifyEmail("changephone")
                                      }
                                    >
                                      Change
                                    </Button>
                                  </>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          helperText={this.state.edit_phonenumberhelperText}
                          id="outlined-required"
                          error={this.state.edit_phonenumberErr}
                        />
                      </div>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  md={8}
                  className="p-p-0 p-pl-md-2 p-mt-3 p-mt-md-0"
                >
                  <div className="customize-card bs-none p-h-100 p-position-relative p-m-0">
                    <div className="mb-5px">
                      <ProgressBar
                        value={this.state.progressBarCount.toFixed(2)}
                      ></ProgressBar>
                    </div>
                    <div className="p-px-3 p-py-2 company-profile-section">
                      <div className="user-profile">Company Profile</div>
                      {/* Upgrade Plan */}
                      <div>
                        <p>
                          {" "}
                          Account Type :{" "}
                          {this.state.accountType == "NewMember"
                            ? "CompanyMember"
                            : this.state.accountType}
                        </p>
                      </div>
                      <div>
                        <p> Plan : {this.state.planName}</p>
                        {/* <p> Plan :  {this.state.planStartDate}  To  {this.state.planEndDate} </p> */}
                      </div>
                      <div>
                        <span style={{ padding: "10px" }}>
                          Shift Name:{" "}
                          {!CommonConfig.isEmpty(this.state.shiftName)
                            ? this.state.shiftName
                            : "-"}
                        </span>
                        <span style={{ padding: "10px" }}>
                          Shift Start Time:{" "}
                          {!CommonConfig.isEmpty(this.state.shiftStartTime)
                            ? moment(this.state.shiftStartTime).format(
                                "HH:mm:ss"
                              )
                            : "-"}
                        </span>
                        <span style={{ padding: "10px" }}>
                          Shift End Time:{" "}
                          {!CommonConfig.isEmpty(this.state.shiftEndTime)
                            ? moment(this.state.shiftEndTime).format("HH:mm:ss")
                            : "-"}
                        </span>
                      </div>
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company Name"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_companyname",
                                  name: "edit_companyname",
                                  value: this.state.edit_companyname,

                                  disabled: !CommonConfig.isEmpty(
                                    this.state.isgstVerified
                                  )
                                    ? this.state.isgstVerified ||
                                      this.state.AccountType == "NewMember"
                                      ? true
                                      : false
                                    : false,
                                }}
                                helperText={
                                  this.state.edit_companynamehelperText
                                }
                                id="outlined-required"
                                error={this.state.edit_companynameErr}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company Website"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_website",
                                  name: "edit_website",
                                  disabled:
                                    this.state.AccountType == "NewMember"
                                      ? true
                                      : false,
                                  value: this.state.edit_website,
                                }}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company Phone"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_companyphone",
                                  name: "edit_companyphone",
                                  value: this.state.edit_companyphone,
                                  disabled:
                                    this.state.AccountType == "NewMember" ||
                                    this.state.isCompanyPhoneVerified
                                      ? true
                                      : false,
                                  pattern: "[1-9]{1}[0-9]{9}",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {this.state.isCompanyPhoneVerified ? (
                                        <img
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            marginInlineEnd: "2px",
                                          }}
                                          src={yes}
                                        />
                                      ) : (
                                        <div className="p-inline">
                                          <div
                                            className="login-btn p-grid p-jc-between"
                                            style={{
                                              display:
                                                this.state
                                                  .verifyCompanyPhoneClick &&
                                                this.state.edit_companyphone
                                                  ?.length == 10
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            <span>
                                              <img
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  marginInlineEnd: "2px",
                                                }}
                                                src={pending}
                                              />
                                              &nbsp;
                                              <Button
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  color: "white",
                                                  fontSize: "12px",
                                                }}
                                                onClick={(e) =>
                                                  this.sendOtpOnCompanyPhone(e)
                                                }
                                                className="buyVessel-btn"
                                              >
                                                verify
                                              </Button>
                                            </span>
                                          </div>
                                          <div
                                            className="login-input"
                                            style={{
                                              display: this.state
                                                .verifyCompanyPhoneClick
                                                ? "none"
                                                : "block",
                                              paddingLeft: "10px",
                                              marginBottom: "0px",
                                            }}
                                          >
                                            <div
                                              className="login-btn"
                                              style={{ display: "flex" }}
                                            >
                                              <InputText
                                                id="companyPhoneOTP"
                                                type="text"
                                                placeholder="Enter OTP*"
                                                value={
                                                  this.state.companyPhoneOTP
                                                }
                                                onChange={(e) =>
                                                  this.handleChange(
                                                    e,
                                                    "companyPhoneOTP"
                                                  )
                                                }
                                                keyfilter={"num"}
                                                maxLength={6}
                                              />
                                              {this.state.companyPhoneOTPErr &&
                                                CommonConfig.showErrorMsg(
                                                  this.state
                                                    .companyPhoneOTPErrText
                                                )}
                                              <Button
                                                style={{
                                                  marginTop: "13px",
                                                  marginLeft: "10px",
                                                  height: "35px",
                                                }}
                                                className="register-submit"
                                                type=""
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) =>
                                                  this.verifyCompanyPhoneOTP(e)
                                                }
                                              >
                                                Submit
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                helperText={
                                  this.state.edit_companyphonehelperText
                                }
                                id="outlined-required"
                                error={this.state.edit_companyphoneErr}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company EmailId"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_companyEmail",
                                  name: "edit_companyEmail",
                                  disabled:
                                    this.state.AccountType == "NewMember" ||
                                    this.state.isCompanyEmailVerified
                                      ? true
                                      : false,
                                  value: this.state.edit_companyEmail,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {this.state.isCompanyEmailVerified ? (
                                        <img
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            marginInlineEnd: "2px",
                                          }}
                                          src={yes}
                                        />
                                      ) : (
                                        <div className="p-inline">
                                          <div
                                            className="login-btn p-grid p-jc-between"
                                            style={{
                                              display:
                                                this.state
                                                  .verifyCompanyEmailClick &&
                                                this.isEmailValid(
                                                  this.state.edit_companyEmail
                                                )
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            <span>
                                              <img
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  marginInlineEnd: "2px",
                                                }}
                                                src={pending}
                                              />
                                              &nbsp;
                                              <Button
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  color: "white",
                                                  fontSize: "12px",
                                                }}
                                                onClick={(e) =>
                                                  this.sendOtpOnCompanyEmail(e)
                                                }
                                                className="buyVessel-btn"
                                              >
                                                verify
                                              </Button>
                                            </span>
                                          </div>
                                          <div
                                            className="login-input"
                                            style={{
                                              display: this.state
                                                .verifyCompanyEmailClick
                                                ? "none"
                                                : "block",
                                              paddingLeft: "10px",
                                              marginBottom: "0px",
                                            }}
                                          >
                                            <div
                                              className="login-btn"
                                              style={{ display: "flex" }}
                                            >
                                              <InputText
                                                id="companyEmailOTP"
                                                type="text"
                                                placeholder="Enter OTP*"
                                                value={
                                                  this.state.companyEmailOTP
                                                }
                                                onChange={(e) =>
                                                  this.handleChange(
                                                    e,
                                                    "companyEmailOTP"
                                                  )
                                                }
                                                keyfilter={"num"}
                                                maxLength={6}
                                              />
                                              {this.state.companyEmailOTPErr &&
                                                CommonConfig.showErrorMsg(
                                                  this.state
                                                    .companyEmailOTPErrText
                                                )}
                                              <Button
                                                style={{
                                                  marginTop: "13px",
                                                  marginLeft: "10px",
                                                  height: "35px",
                                                }}
                                                className="register-submit"
                                                type=""
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) =>
                                                  this.verifyCompanyEmailOTP(e)
                                                }
                                              >
                                                Submit
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                helperText={
                                  this.state.edit_companyEmailhelperText
                                }
                                id="outlined-required"
                                error={this.state.edit_companyEmailErr}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company Address"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_companyaddress",
                                  name: "edit_companyaddress",
                                  disabled:
                                    this.state.AccountType == "NewMember"
                                      ? true
                                      : false,
                                  value: this.state.edit_companyaddress,
                                }}
                                helperText={
                                  this.state.edit_companyaddresshelperText
                                }
                                id="outlined-required"
                                error={this.state.edit_companyaddressErr}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company City"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_companycity",
                                  name: "edit_companycity",
                                  value: this.state.edit_companycity,
                                  disabled:
                                    this.state.AccountType == "NewMember"
                                      ? true
                                      : false,
                                }}
                                helperText={
                                  this.state.edit_companycityhelperText
                                }
                                id="outlined-required"
                                error={this.state.edit_companycityErr}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company State"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_companystate",
                                  disabled:
                                    this.state.AccountType == "NewMember"
                                      ? true
                                      : false,
                                  name: "edit_companystate",
                                  value: this.state.edit_companystate,
                                }}
                                helperText={
                                  this.state.edit_companystatehelperText
                                }
                                id="outlined-required"
                                error={this.state.edit_companystateErr}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="Company Pincode"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "edit_comapnypincode",
                                  name: "edit_companypincode",
                                  disabled:
                                    this.state.AccountType == "NewMember"
                                      ? true
                                      : false,
                                  value: this.state.edit_companypincode,
                                }}
                                helperText={
                                  this.state.edit_companypincodehelperText
                                }
                                id="outlined-required"
                                error={this.state.edit_companypincodeErr}
                              />
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="PAN No."
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "pan",
                                  name: "pan",
                                  value: this.state.pan,

                                  disabled: !CommonConfig.isEmpty(
                                    this.state.ispanVerified
                                  )
                                    ? this.state.ispanVerified ||
                                      this.state.AccountType == "NewMember"
                                      ? true
                                      : false
                                    : false,

                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {this.state.ispanVerified ? (
                                        <img
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            marginInlineEnd: "2px",
                                          }}
                                          src={yes}
                                        />
                                      ) : (
                                        <span>
                                          <img
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                              marginInlineEnd: "2px",
                                            }}
                                            src={pending}
                                          />
                                          &nbsp;
                                          <Button
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                              color: "white",
                                              fontSize: "12px",
                                            }}
                                            onClick={() =>
                                              this.getPanVerifyData(
                                                "getPanVerifyData"
                                              )
                                            }
                                            className="buyVessel-btn"
                                          >
                                            verify
                                          </Button>
                                        </span>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                helperText={this.state.panhelperText}
                                id="outlined-required"
                                error={this.state.panErr}
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <div className="input-control ipt-with-icon">
                              <CustomInput
                                labelText="GSTIN"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => this.handleChange_value1(e),
                                  type: "text",
                                  id: "gst",
                                  name: "gst",
                                  value: this.state.gst,

                                  disabled: !CommonConfig.isEmpty(
                                    this.state.isgstVerified
                                  )
                                    ? this.state.isgstVerified ||
                                      this.state.AccountType == "NewMember"
                                      ? true
                                      : false
                                    : false,

                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {this.state.isgstVerified ? (
                                        <img
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            marginInlineEnd: "2px",
                                          }}
                                          src={yes}
                                        />
                                      ) : (
                                        <span>
                                          <img
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                              marginInlineEnd: "2px",
                                            }}
                                            src={pending}
                                          />
                                          &nbsp;
                                          <Button
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                              color: "white",
                                              fontSize: "12px",
                                            }}
                                            onClick={() =>
                                              this.getGSTVerifyData(
                                                "getGSTVerifyData"
                                              )
                                            }
                                            className="buyVessel-btn"
                                          >
                                            verify
                                          </Button>
                                        </span>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                helperText={this.state.gsthelperText}
                                id="outlined-required"
                                error={this.state.gstErr}
                              />
                            </div>
                          </GridItem>
                          {/* <GridItem xs={12} sm={6} md={6}>
                                                        <div className="input-control ipt-with-icon">
                                                            <CustomInput
                                                                labelText="Aadhar No"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                inputProps={{
                                                                    onChange: (e) => this.handleChange_value1(e),
                                                                    type: "text",
                                                                    id: "aadhar",
                                                                    name: "aadhar",
                                                                    value: this.state.aadhar,
                                                                    disabled: true,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">

                                                                            {
                                                                                this.state.isKYCVerified ?
                                                                                    <img style={{ height: "20px", width: "20px", marginInlineEnd: "2px" }} src={yes} />
                                                                                    :
                                                                                    <span><img style={{ height: "20px", width: "20px", marginInlineEnd: "2px" }} src={pending} />&nbsp;<Button style={{ height: "20px", width: "20px", color: "white", fontSize: "12px" }}
                                                                                        onClick={() => this.modalOpen()}
                                                                                        className="buyVessel-btn"
                                                                                    >verify</Button></span>
                                                                            }
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                helperText={this.state.aadharhelperText} id="outlined-required"
                                                                error={this.state.aadharErr}
                                                            />
                                                        </div>
                                                    </GridItem> */}
                          {CommonConfig.isEmpty(
                            this.state.DriverDLNo
                          ) ? null : (
                            <GridItem xs={12} sm={6} md={6}>
                              <div className="input-control ipt-with-icon">
                                <CustomInput
                                  labelText="DL No."
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    id: "DriverDLNo",
                                    name: "DriverDLNoDriverDLNo",
                                    value: this.state.DriverDLNo,
                                    disabled: true,
                                  }}
                                />
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      </div>
                      <div className="custom-card-ftr">
                        <div className="p-pl-3 p-d-flex p-ai-center p-jc-center p-jc-md-between p-flex-wrap ">
                          <Button
                            className="primary-btn1 p-px-2"
                            onClick={(e) => this.handleClickNotification(e)}
                          >
                            Request Additional modules
                          </Button>
                          <Button
                            className="primary-btn2 p-mt-2 p-mt-md-0"
                            disableElevation
                            variant="contained"
                            type="submit"
                          >
                            Update
                          </Button>
                        </div>
                      </div>

                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <div className="noti-dropdown-outer">
                            <Popper
                              open={Boolean(this.state.openNotification)}
                              anchorEl={this.state.openNotification}
                              transition
                              disablePortal
                              placement="bottom-start"
                              className={classNames({
                                [classes.popperClose]: !this.state
                                  .openNotification,
                                [classes.popperResponsive]: true,
                                [classes.popperNav]: true,
                              })}
                            >
                              {({ TransitionProps }) => (
                                <Grow
                                  {...TransitionProps}
                                  id="notification-menu-list"
                                  style={{ width: "max-content" }}
                                >
                                  <Paper className={classes.dropdown}>
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        this.handleCloseNotification()
                                      }
                                    >
                                      <div>
                                        <MenuList
                                          role="menu"
                                          style={{
                                            height: "300px",
                                            overflow: "auto",
                                          }}
                                        >
                                          <div className="input-control select ipt-with-icon-right">
                                            {this.state.getServices.map(
                                              (item, key) => (
                                                <div key={key}>
                                                  <Checkbox
                                                    name={item.value}
                                                    // checked={this.state.isChecked}

                                                    helpertext={
                                                      this.state
                                                        .isCheckedhelperText
                                                    }
                                                    onChange={(e) =>
                                                      this.handleServicesChange(
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {item.value}
                                                </div>
                                              )
                                            )}
                                            <FormControl
                                              helpertext={
                                                this.state.isCheckedhelperText
                                              }
                                              id="outlined-required"
                                              error={this.state.isCheckedErr}
                                            >
                                              {this.state.isCheckedErr && (
                                                <FormHelperText>
                                                  Please select atleast one
                                                  module
                                                </FormHelperText>
                                              )}
                                            </FormControl>
                                          </div>
                                        </MenuList>
                                        <MenuList
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                            textAlign: "center",
                                            backgroundColor: "#0098ff",
                                          }}
                                        >
                                          <Button
                                            style={{ color: "white" }}
                                            onClick={(e) =>
                                              this.handleRequest(e)
                                            }
                                          >
                                            {" "}
                                            Request
                                          </Button>
                                        </MenuList>
                                      </div>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                        </GridItem>
                      </GridContainer>

                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {this.state.loading === true ? (
                          <div className="loading">
                            <SimpleBackdrop />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
            <div>
              <Dialog
                open={this.state.openotp}
                onClose={() => this.handleClose("otp")}
                aria-labelledby="form-dialog-title"
              >
                <form method="post">
                  <DialogTitle
                    className="custom-dialog-header"
                    id="form-dialog-title"
                  >
                    <div>Verify OTP</div>
                    <div
                      className="dialog-close"
                      onClick={() => this.handleClose("otp")}
                    >
                      <i className="icon-modal-close"></i>
                    </div>
                  </DialogTitle>
                  <DialogContent className="dialog-content-area">
                    <TextField
                      margin="dense"
                      name="otp"
                      id="otp"
                      label="Enter OTP"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.otp}
                      error={this.state.otpErr}
                      helperText={this.state.otphelperText}
                      onChange={(e) => this.handleChange_value1(e)}
                    />
                  </DialogContent>
                  <DialogActions className="p-p-0 dialog-footer1">
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {this.state.loading === true ? (
                        <div className="loading">
                          <SimpleBackdrop />
                        </div>
                      ) : null}
                    </div>

                    {/* <Button variant="contained" className="primary-btn1" onClick={() => this.verifyEmail("otp")} disabled={this.state.loading}>Cancle</Button> : */}
                    {this.state.phone == 0 ? (
                      <Button
                        variant="contained"
                        className="primary-btn"
                        onClick={() => this.verifyEmail("otpverify")}
                        disabled={this.state.loading}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="primary-btn"
                        onClick={() => this.verifyEmail("otpverifyphone")}
                        disabled={this.state.loading}
                      >
                        Submit
                      </Button>
                    )}
                  </DialogActions>
                </form>
              </Dialog>

              <Dialog
                open={this.state.open}
                onClose={() => this.handleClose("gst")}
                aria-labelledby="form-dialog-title"
              >
                <form method="post">
                  <DialogTitle
                    className="custom-dialog-header"
                    id="form-dialog-title"
                  >
                    <div>GST Details</div>
                    <div
                      className="dialog-close"
                      onClick={() => this.handleClose("gst")}
                    >
                      <i className="icon-modal-close"></i>
                    </div>
                  </DialogTitle>

                  <DialogContent className="dialog-content-area">
                    <TextField
                      margin="dense"
                      name="gstAddress1"
                      id="gstAddress1"
                      label=" Address1"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.gstAddress1}
                      error={this.state.gstAddress1Err}
                      helperText={this.state.gstAddress1ErrText}
                      onChange={(e) => this.handleChange_value1(e)}
                    />
                    <TextField
                      margin="dense"
                      name="gstAddress2"
                      id="gstAddress2"
                      label=" Address2"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.gstAddress2}
                      error={this.state.gstAddress2Err}
                      helperText={this.state.gstAddress2ErrText}
                      onChange={(e) => this.handleChange_value1(e)}
                    />
                    <TextField
                      margin="dense"
                      name="gstState"
                      id="gstState"
                      label=" State"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.gstState}
                      error={this.state.gstStateErr}
                      helperText={this.state.gstStateErrText}
                      onChange={(e) => this.handleChange_value1(e)}
                    />
                    <TextField
                      margin="dense"
                      name="gstCompanyName"
                      id="gstCompanyName"
                      label=" Company Name"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.gstCompanyName}
                      error={this.state.gstCompanyNameErr}
                      helperText={this.state.gstCompanyNameErrText}
                      onChange={(e) => this.handleChange_value1(e)}
                      disabled={true}
                    />
                    {this.state.gstTradeName &&
                    this.state.gstTradeName != "" ? (
                      <TextField
                        margin="dense"
                        name="gstTradeName"
                        id="gstTradeName"
                        label=" Trade Name"
                        type="text"
                        // defaultValue={this.state.quantity}
                        className=""
                        fullWidth
                        value={this.state.gstTradeName}
                        error={this.state.gstTradeNameErr}
                        helperText={this.state.gstTradeNameErrText}
                        onChange={(e) => this.handleChange_value1(e)}
                      />
                    ) : null}
                  </DialogContent>
                  <DialogActions className="p-p-0 dialog-footer1">
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {this.state.loading === true ? (
                        <div className="loading">
                          <SimpleBackdrop />
                        </div>
                      ) : null}
                    </div>

                    <Button
                      className="primary-btn1"
                      onClick={() => this.handleClose("gst")}
                      color="primary"
                    >
                      Close
                    </Button>
                    <Button
                      className="primary-btn"
                      variant="contained"
                      onClick={() => this.verifyGST("verifyGST")}
                      disabled={this.state.loading}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
              <Dialog
                open={this.state.openPan}
                onClose={() => this.handleClose("gst")}
                aria-labelledby="form-dialog-title"
              >
                <form method="post">
                  <DialogTitle
                    className="custom-dialog-header"
                    id="form-dialog-title"
                  >
                    <div>PAN Details</div>
                    <div
                      className="dialog-close"
                      onClick={() => this.handleClose("pan")}
                    >
                      <i className="icon-modal-close"></i>
                    </div>
                  </DialogTitle>
                  <DialogContent className="dialog-content-area ">
                    <TextField
                      margin="dense"
                      name="panNo"
                      id="panNo"
                      label="PAN No"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.panNo}
                      error={this.state.panNoErr}
                      helperText={this.state.panNoErrText}
                      onChange={(e) => this.handleChange_value1(e)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      margin="dense"
                      name="panName"
                      id="panName"
                      label="Registered Name"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.panName}
                      error={this.state.panNameErr}
                      helperText={this.state.panNameErrText}
                      onChange={(e) => this.handleChange_value1(e)}
                    />
                  </DialogContent>
                  <DialogActions className="p-p-0 dialog-footer1">
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {this.state.loading === true ? (
                        <div className="loading">
                          <SimpleBackdrop />
                        </div>
                      ) : null}
                    </div>
                    <Button
                      className="primary-btn1"
                      onClick={() => this.handleClose("pan")}
                      color="primary"
                    >
                      Close
                    </Button>
                    <Button
                      className="primary-btn"
                      variant="contained"
                      onClick={() => this.verifyPAN("verifyPAN")}
                      disabled={this.state.loading}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
              <Dialog
                open={this.state.openGstRequest}
                onClose={() => this.handleClose("openGstRequest")}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  className="custom-dialog-header"
                  id="form-dialog-title"
                >
                  <div>GST Admin Request</div>
                  <div
                    className="dialog-close"
                    onClick={() => this.handleClose("openGstRequest")}
                  >
                    <i className="icon-modal-close"></i>
                  </div>
                </DialogTitle>
                <React.Fragment>
                  <DialogContent className="dialog-content-area">
                    <div className="vib-header">
                      Are you sure you want to send the request Gst Verification
                      ?
                    </div>
                    <TextField
                      margin="dense"
                      name="gstin"
                      id="gstin"
                      label="GSTIN"
                      type="text"
                      // defaultValue={this.state.quantity}
                      className=""
                      fullWidth
                      value={this.state.gst}
                      error={this.state.gstErr}
                      helperText={this.state.gsthelperText}
                      onChange={(e) => this.handleChange_value1(e)}
                    />
                  </DialogContent>
                  <DialogActions className="p-p-0 dialog-footer1">
                    <Button
                      className="primary-btn1"
                      onClick={() => this.handleClose("openGstRequest")}
                      color="primary"
                      disabled={this.state.loading}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="primary-btn"
                      onClick={() => this.sentGstAdminRequest()}
                      color="primary"
                      disabled={this.state.loading}
                    >
                      Sent
                    </Button>
                  </DialogActions>
                </React.Fragment>
              </Dialog>

              <Dialog
                open={this.state.showIsRenewModel}
                onClose={() => this.handleClose("showIsRenewModel")}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
              >
                <DialogTitle
                  className="custom-dialog-header"
                  id="form-dialog-title"
                >
                  <div>Renew Plan</div>
                  <div
                    className="dialog-close"
                    onClick={() => this.handleClose("showIsRenewModel")}
                  >
                    <i className="icon-modal-close"></i>
                  </div>
                </DialogTitle>

                <DialogContent className="dialog-content-area">
                  <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="p-w-100 ">
                      <div className="custom-inputs">
                        <label htmlFor="plan">Select Plan</label>
                        <Dropdown
                          className="p-w-100 p-mt-2"
                          inputId="plan"
                          placeholder="Select Plan"
                          value={this.state.plan}
                          onChange={(e) => this.handleChange(e, "plan")}
                          options={this.state.planList}
                          optionLabel="label"
                          filter
                        />
                      </div>
                      <span className="error-msg">
                        {this.state.planErr ? this.state.planErrText : null}
                      </span>
                    </div>
                    {!CommonConfig.isEmpty(this.state.plan) ? (
                      <>
                        <div className="login-input">
                          <div>
                            <div className="p-grid p-ai-center p-jc-between">
                              <div className="p-mb-3">Membership Period</div>
                              <div className="p-grid primary-border p-ai-center b-radius-10 p-p-1">
                                <div className="p-pr-2 border-right p-py-1">
                                  Price:{" "}
                                  <strong>₹ {this.state.discRate}</strong>
                                </div>

                                <div className="p-pl-2">
                                  <strike>₹ {this.state.planRate}</strike>
                                  <br />
                                  <label>
                                    {this.state.discountpercentage} % off
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="p-grid primary-border p-ai-center b-radius-10 p-p-1">
                              <div className="p-pr-2 p-py-1">
                                Plan Description:{" "}
                                <strong>₹ {this.state.planDesc}</strong>
                              </div>
                            </div>

                            <div className="p-d-flex p-jc-between p-mt-3">
                              <div>
                                <div>
                                  <strong>Starts</strong>
                                </div>
                                <div>
                                  <strong>
                                    {moment(this.state.startDate).format(
                                      "DD MMMM YYYY hh:mm A"
                                    )}{" "}
                                  </strong>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <strong>Ends</strong>
                                </div>
                                <div>
                                  <strong>
                                    {moment(this.state.endDate).format(
                                      "DD MMMM YYYY hh:mm A"
                                    )}{" "}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </DialogContent>
                <DialogActions className="p-p-0 dialog-footer1">
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {this.state.loading === true ? (
                      <div className="loading">
                        <SimpleBackdrop />
                      </div>
                    ) : null}
                  </div>

                  <Button
                    className="primary-btn1"
                    onClick={() => this.handleClose("showIsRenewModel")}
                    color="primary"
                  >
                    Close
                  </Button>
                  <Button
                    className="primary-btn"
                    variant="contained"
                    onClick={(e) => this.handleSubmitRenewPlan(e)}
                    disabled={this.state.loading}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Card>
        </form>
      </div>
    );
  }
}

export default EditProfilePage;
