/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import login from '../src/views/Login/Login';
import { PrivateRoute } from '../src/utils/authenticate'
import "assets/scss/material-dashboard-pro-react.scss";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 575,
      md: 767,
      lg: 919,
      xl: 1536,
    },
  },
});


const hist = createBrowserHistory();
ReactDOM.render(
  <Router history={hist} >
    <Switch theme={theme}>

      <PrivateRoute exact path="/" component={AdminLayout} />
      <Route path="/login" component={login} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
