/*eslint-disable*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import imagine1 from "assets/img/sidebar-1.jpg";
import imagine2 from "assets/img/sidebar-2.jpg";
import imagine3 from "assets/img/sidebar-3.jpg";
import imagine4 from "assets/img/sidebar-4.jpg";

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function Support(props) {
    const [classes, setClasses] = React.useState("dropdown show");
    const [bg_checked, setBg_checked] = React.useState(true);
    const [bgImage, setBgImage] = React.useState(props.bgImage);
    const [showImage, setShowImage] = React.useState(true);
    const handleClick = () => {
        props.handleHoverClick();
    };
    const handleContactClick = () => {
        props.handleContactClick();
    };
    const handleChange = name => event => {

        switch (name) {
            case "miniActive":
                props.sidebarMinimize();
                break;

            case "image":
                if (event.target.checked) {
                    props.handleImageClick(bgImage);
                } else {
                    props.handleImageClick();
                }
                setShowImage(event.target.checked);
                break;
            default:
                break;
        }
    };
    const classesObj = useStyles();
    return (
        <>
            {/* {props.IsHover == false ?
                <div
                    className={"fixed-plugin" + (props.rtlActive ? " fixed-plugin-rtl" : "")}
                    style={{ top: "240px" }}
                >

                    <div id="fixedPluginClasses" >
                        <a href='https://mail.google.com/mail/?view=cm&fs=1&to=portlinksindia@gmail.com' target='_blank'>
                            <div onMouseOver={handleClick}>
                                <i className="fa fa-envelope fa-2x" style={{
                                    color: "#ffffff",
                                    padding: "10px",
                                    borderRadius: "0 0 6px 6px",
                                    width: "auto",
                                }} />
                            </div>
                        </a>
                    </div>
                </div>
                :
                <div
                    className={"fixed-plugin" + (props.rtlActive ? " fixed-plugin-rtl" : "")}
                    style={{ top: "240px", width: "12%" }}
                >

                    <div id="fixedPluginClasses">
                        <a href='https://mail.google.com/mail/?view=cm&fs=1&to=portlinksindia@gmail.com' target='_blank'>
                            <div onMouseOut={handleClick}  style={{ display: "flex" }}>
                                <div>
                                    <i className="fa fa-envelope fa-2x" style={{
                                        color: "#ffffff",
                                        padding: "10px",
                                        borderRadius: "0 0 6px 6px",
                                        width: "auto",
                                    }} />
                                </div>
                                <div style={{
                                    color: "rgb(255, 255, 255)",
                                    padding: "0px",
                                    borderRadius: "0px 0px 6px 6px",
                                    width: "auto",
                                    fontWeight: "900",
                                    top: "13px",
                                    fontSize: "19px",
                                    position: "relative"
                                }}>
                                    Contact Us
                        </div>

                            </div>
                        </a>
                    </div>
                </div>
                } */}
            {/* {props.IsContactHover == false ?
                <div
                    className={"fixed-plugin" + (props.rtlActive ? " fixed-plugin-rtl" : "")}
                    style={{ top: "300px" }}
                >

                    <div id="fixedPluginClasses" >

                        <div onMouseOver={handleContactClick}>
                            <i className="fa fa-phone fa-2x" style={{
                                color: "#ffffff",
                                padding: "10px",
                                borderRadius: "0 0 6px 6px",
                                width: "auto",
                            }} />
                        </div>
                    </div>
                </div>
                :
                <div
                    className={"fixed-plugin" + (props.rtlActive ? " fixed-plugin-rtl" : "")}
                    style={{ top: "300px", width: "18%" }}
                >
                    <div id="fixedPluginClasses">
                        <div onMouseOut={handleContactClick} style={{ display: "flex" }}>
                            <div>
                                <i className="fa fa-phone fa-2x" style={{
                                    color: "#ffffff",
                                    padding: "10px",
                                    borderRadius: "0 0 6px 6px",
                                    width: "auto",
                                }} />
                            </div>
                            <div style={{
                                color: "rgb(255, 255, 255)",
                                padding: "0px",
                                borderRadius: "0px 0px 6px 6px",
                                width: "auto",
                                fontWeight: "900",
                                top: "13px",
                                fontSize: "19px",
                                position: "relative"
                            }}>
                                <p style={{ width: "max-content" }}>+91-8160627581</p>
                                <p style={{
                                    width: "120%",
                                    position: "relative",
                                    margin: "0px 0px 20px",
                                    right: "40px"
                                }}>Telephonic support for any query available 24*7</p>
                            </div>
                        </div>
                    </div>
                </div>
            } */}
        </>
    );
}

Support.propTypes = {
    bgImage: PropTypes.string,
    handleFixedClick: PropTypes.func,
    miniActive: PropTypes.bool,
    fixedClasses: PropTypes.string,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose"
    ]),
    handleBgColorClick: PropTypes.func,
    handleColorClick: PropTypes.func,
    handleImageClick: PropTypes.func,
    sidebarMinimize: PropTypes.func,
    rtlActive: PropTypes.bool
};
