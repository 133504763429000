import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from '../../utils/general';
import api from '../../utils/api';
import cogoToast from 'cogo-toast';
import APIConstant from "../../utils/constant";
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Loader from 'components/Shared/Loader/Loader';
import CustomInput from "components/CustomInput/CustomInput.js";
import { Tooltip } from "primereact/tooltip";
class HSNCode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            SearchListSACCode: [],
            SACMasterID: '',
            loading: false,
            UserId: '',
            Token: '',
            GetSACMasterList: [],
            rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
            rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
            search: null,
            MasterSACCode: '',
            MasterSACCodeErr: false,
            MasterSACCodeErrText: '',
            SACMasterLabelName: '',
            SACMasterLabelNameErr: false,
            SACMasterLabelNameErrText: '',
            AddSACMasterModal: false,
            EditSACMasterTypeModal: false,
            DeleteSACMasterTypeModal: false,
            userPageAccess: []
        };
    }

    componentDidMount = async () => {
        let data = localStorage.getItem('Infodata');
        let Userrole = JSON.parse(localStorage.getItem('Userdata'));
        let token = localStorage.getItem('Token');
        let userPageAccess = localStorage.getItem('PageAccess');
        let lockstatus = localStorage.getItem('LockStatus');
        // if (lockstatus == 0) {
        if (token !== null) {
            this.setState({ UserId: data, Token: token, userPageAccess: userPageAccess });
            await this.GetSACMasterListByType(data);
        } else {
            this.props.history.push('/login')
        }
        // } else {
        //     this.props.history.push('/auth/lockscreen')
        // }
    };

    actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    }

    actionTemplate = (rowData, props) => {
        return <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
            {this.state.userPageAccess.includes('Edit Service Code') ?
                <div onClick={(e) => this.handleOpenSAC(rowData, "EditMasterType")}> <i class="icon-editImage"></i> </div>
                : null}
            {this.state.userPageAccess.includes('Delete Service Code') ?
                <div onClick={(e) => this.handleOpenSAC(rowData, "DeleteMasterType")}> <i class="icon-delete"></i> </div>
                : null}
        </div>

    }

    GetSACMasterListByType = async (UserId) => {
        let params = {
            ID: UserId,
        }
        api.post(APIConstant.path.GetHSCodeList, params).then(async res => {
            let response = await res;
            if (response.Success === 1) {
                let filterArry = response.Info.Master.filter(x => x.UserID != 'Admin');
                this.setState({ GetSACMasterList: filterArry });
            }
        }).catch(err => { });
    };

    handleOpenSAC = (item, type) => {
        if (type === "AddSACMaster") {
            this.setState({ AddSACMasterModal: true });
        }
        else if (type === "EditMasterType") {

            this.setState({ AddSACMasterModal: true, MasterSACCode: item.product_hsn_code, SACMasterID: item.MasterHSNCodeID, });
            this.SearchSACCodeList(item, 'Edit');
        }
        else if (type === "DeleteMasterType") {
            this.setState({ DeleteSACMasterTypeModal: true, SACMasterID: item.MasterHSNCodeID, });
        }
    }

    handleCloseSAC = (type) => {
        if (type === "AddSACMaster") {
            this.setState({ AddSACMasterModal: false, MasterSACCode: '', MasterSACCodeErr: false, MasterSACCodeErrText: '', SACMasterID: '', SearchListSACCode: [] });
        }
        else if (type === "EditMasterType") {
            this.setState({ EditSACMasterTypeModal: false });
        }
        else if (type === "DeleteMasterType") {
            this.setState({ DeleteSACMasterTypeModal: false, SACMasterID: '' });
        }
    }

    handleChangeSAC = (e, type) => {
        if (type === 'SACMasterLabelName') {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ SACMasterLabelName: e.target.value, SACMasterLabelNameErr: true, SACMasterLabelNameErrText: `Please enter Type.` });
            } else {
                this.setState({ SACMasterLabelName: e.target.value, SACMasterLabelNameErr: false, SACMasterLabelNameErrText: '' });
            }
        } else if (type === 'MasterSACCode') {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ MasterSACCode: e.target.value, MasterSACCodeErr: true, MasterSACCodeErrText: `Please enter Item HsCode.` });
            } else {
                this.setState({ MasterSACCode: e.target.value, MasterSACCodeErr: false, MasterSACCodeErrText: '' });
            }
        }
    }

    validationSAC = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(this.state.MasterSACCode)) {
            this.setState({ MasterSACCode: '', MasterSACCodeErr: true, MasterSACCodeErrText: 'Item HsCode is required.' })
            formIsValid = false;
        }
        else {
            this.setState({ MasterSACCodeErr: false, MasterSACCodeErrText: '' });
        }
        return formIsValid;
    }

    AddSACMaster = () => {
        if (this.validationSAC()) {
            this.setState({ loading: true });
            Loader.show();


            let Selected = this.state.SearchListSACCode.filter(x => x.IsActiveTabSAC == '1').length ? this.state.SearchListSACCode.filter(x => x.IsActiveTabSAC == '1') : [];
            if (!CommonConfig.isObjectEmpty(Selected)) {
                if (!CommonConfig.isEmpty(Selected[0].chapter_name)) {

                    let params = {

                        chapter_name: Selected[0].chapter_name,
                        product_description: Selected[0].product_description,
                        product_hsn_code: Selected[0].product_hsn_code,
                        product_rate: Selected[0].product_rate == 'Nil' ? '0%' : Selected[0].product_rate,
                        MasterHSNCodeID: this.state.SACMasterID,
                        product_cess: CommonConfig.isEmpty(Selected[0].product_cess) ? 0 : Selected[0].product_cess,
                        UserID: this.state.UserId,

                    }
                    api.post(APIConstant.path.AddEditHSCode, params).then(res => {
                        if (res.Success == 1) {
                            this.setState({ AddSACMasterModal: false, MasterSACCode: '', MasterSACCodeErr: false, MasterSACCodeErrText: '', SACMasterID: '', SearchListSACCode: [] });
                            this.GetSACMasterListByType(this.state.UserId);
                            cogoToast.success(res.Message);
                            Loader.hide();
                        }
                        else {
                            this.setState({ loading: false });
                            cogoToast.error(res.Message);
                            Loader.hide();
                        }
                    });
                }
                else {
                    cogoToast.error('Please Enter Name ');
                    Loader.hide();
                }
            }
            else {
                cogoToast.error('Please Select one Option from the below list');
                Loader.hide();
            }
        }
    };

    SearchSACCodeList = (MasterSACCode, Type) => {
        if (!CommonConfig.isEmpty(MasterSACCode)) {
            this.setState({ loading: true });
            Loader.show();

            let params = {

                ID: Type == 'Edit' ? MasterSACCode.product_hsn_code : MasterSACCode,

            }
            api.post(APIConstant.path.GetSearchHSCode, params).then(res => {
                if (res.Success == 1) {
                    let Array = [];
                    console.log('res.Info.Master', res.Info.Master)
                    Array = [...res.Info.Master.map(x => { return { ...x, IsActiveTabSAC: '0' } })];
                    if (Type == 'Edit') {

                        for (var i = 0; i < Array.length; i++) {

                            if (Array[i]['product_hsn_code'] == MasterSACCode.product_hsn_code) {

                                Array[i]['IsActiveTabSAC'] = '1';
                                Array[i]['chapter_name'] = MasterSACCode.chapter_name;
                                Array[i]['product_rate'] = MasterSACCode.product_rate;

                            } else { Array[i]['IsActiveTabSAC'] = '0'; }
                        }

                    }

                    Loader.hide();
                    this.setState({ loading: false, SearchListSACCode: Array })

                }
                else {

                    cogoToast.error(res.Message);
                    Loader.hide();
                }
            });
        } else { this.setState({ MasterSACCodeErr: true, MasterSACCodeErrText: 'Please Enter Item HSCode', }) }
    };

    DeleteMasterType = () => {
        Loader.show();
        let params = {
            ID: this.state.SACMasterID,
            UserID: this.state.UserId,
        }
        api.post(APIConstant.path.DeleteHSCode, params).then(res => {
            if (res.Success == 1) {
                this.setState({ loading: false, DeleteSACMasterTypeModal: false, SACMasterID: '' })
                this.GetSACMasterListByType(this.state.UserId);
                cogoToast.success(res.Message);
                Loader.hide();
            }
            else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
                Loader.hide();
            }
        });
    }
    ActiveTabSAC = (value, idx) => {

        let tempList = this.state.SearchListSACCode;
        if (value == '0') {
            tempList[idx]['IsActiveTabSAC'] = '1';
            for (var i = 0; i < tempList.length; i++) {

                if (idx != i) {

                    tempList[i]['IsActiveTabSAC'] = '0';

                }
            }

        } else { tempList[idx]['IsActiveTabSAC'] = ' 0 '; }



        console.log('tempList', tempList);
        this.setState({ SearchListSACCode: tempList })

    };
    SrNoBody = (data, props) => {
        return props.rowIndex + 1;
    }

    RateBody = (data, props) => {
        return <>{data.product_rate + "%"}</>;
    }
    DescriptionBody = (data, props) => {
        return <>
            <Tooltip target=".custom-tooltip"></Tooltip>
            <div className="card">
                <span className="custom-tooltip" data-pr-tooltip={data.product_description}>
                    {data.product_description}
                </span>
            </div>

        </>
    }

    render() {

        const { userPageAccess, UserId, search, GetSACMasterList, SACMasterLabelName, SACMasterLabelNameErr, SearchListSACCode, MasterSACCodeErrText, MasterSACCode, MasterSACCodeErr, SACMasterLabelNameErrText, AddSACMasterModal, EditSACMasterTypeModal, DeleteSACMasterTypeModal, SACMasterID } = this.state;

        let filterGetSACMasterList = [];
        if (GetSACMasterList.length) {
            filterGetSACMasterList = GetSACMasterList;
            if (search) {
                filterGetSACMasterList = filterGetSACMasterList.filter((x) => {
                    return (
                        x.label.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1
                    );
                });
            }
        };

        return (
            <div className="wayment-owner-dashboard e-weighment-dashoard">
                <div className="heading-area p-d-flex p-ai-center p-jc-between">
                    <div className="p-d-flex p-ai-center">
                        <i className="pi pi-box"></i>
                        <h2>Service Master</h2>
                    </div>
                    <div className="p-d-flex p-ai-center wb-filter-btn">
                        <div className="searchbar-area p-as-center">
                            <InputText type="search" onInput={(e) => this.setState({ search: e.target.value })} placeholder="Global Search" size="30" style={{ marginRight: 10 }} />
                            <i className="icon-search"></i>
                        </div>
                        {userPageAccess.includes('Add Service Code') ?
                            <div>
                                <div className="add-btn1" onClick={(e) => this.handleOpenSAC(e, "AddSACMaster")}>
                                    <i className="icon-add"></i> Add Service
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
                <hr />
                <div className="main-access-card-master">
                    {userPageAccess.includes('View Service Code') ?
                        <DataTable className='main-table table-td' value={filterGetSACMasterList} editMode="row" dataKey="id" responsiveLayout="scroll" paginator={true} rows={this.state.rowsPerPage} totalRecords={filterGetSACMasterList.length} rowsPerPageOptions={this.state.rowsPerPageOptions} globalFilter={search}
                            emptyMessage="No records found" responsive={false} resizableColumns={true} columnResizeMode="fit" scrollable={true} scrollWidth="auto" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                            {/* <Column field="Index" header="Sr.No" body={this.actionBodyTemplateSrNo} style={{ width: '20%' }}></Column> */}
                            <Column field="product_hsn_code" header="SAC Code" style={{ width: '20%' }}></Column>
                            <Column field="chapter_name" header="Chapter Name " style={{ width: '20%' }}></Column>
                            {/* <Column field="product_description" header="Product Description" style={{ width: '20%' }}></Column> */}
                            <Column body={this.DescriptionBody} header="Product Description" style={{ width: '20%' }}></Column>
                            <Column body={this.RateBody} header="Rate(%)" style={{ width: '20%' }}></Column>
                            {/* <Column body={this.CESSBody} header="CESS(%)" style={{ width: '20%' }}></Column> */}
                            <Column field="Action" header="Action" body={this.actionTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        </DataTable>
                        : null}
                    {/* Add Edit BL Type */}
                    <Dialog open={AddSACMasterModal} className="custom-hs-master">
                        <DialogTitle id="cha-form-dialog-title" className="custom-dialog-header">
                            <div>{SACMasterID !== '' ? 'Edit' : "Add"} Service </div>
                            <div className="dialog-close" onClick={(e) => this.handleCloseSAC("AddSACMaster")}><i className="icon-modal-close"></i></div>
                        </DialogTitle>
                        <DialogContent className="HSCode-dialog-content-area">
                            <GridContainer>
                                <GridItem md={4}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name=" HsCode"
                                        // id="chaNo"
                                        label="HS Code *"
                                        type="text"
                                        value={MasterSACCode}
                                        onChange={(e) => this.handleChangeSAC(e, "MasterSACCode")}
                                        error={MasterSACCodeErr}
                                        helperText={MasterSACCodeErrText}
                                        fullWidth
                                    />
                                </GridItem>
                                <GridItem md={1}>
                                    <div className=''>
                                        <button
                                            className="filter-btn table-add-btn p-mt-3 cursor-pointer"
                                            onClick={(e) => {
                                                this.SearchSACCodeList(MasterSACCode, 'Add');
                                            }}>

                                            <i className="icon-search"></i>{" "}
                                        </button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem md={12}>
                                    {/* <DataTable className='main-table table-td' value={SearchListSACCode} editMode="row" dataKey="id" responsiveLayout="scroll">
                                        <Column header="SrNo." body={this.SrNoBody} ></Column>
                                        <Column header="ItemName" field="chapter_name" ></Column>
                                        <Column header="Description" field="product_description" style={{ width: '30%' }}></Column>
                                        <Column header="Service  Code" field="product_hsn_code" style={{ width: '30%' }}></Column>
                                        <Column header="Rate (%)" body={this.RateBody} style={{ width: '30%' }}></Column>
                                        <Column header="CESS (%)" body={this.CESSBody} style={{ width: '30%' }}></Column>

                                    </DataTable> */}
                                    {SearchListSACCode.length != 0 ? <table>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Service Code</th>
                                            <th>Rate (%)</th>
                                            {/* <th>Action</th> */}
                                        </tr>

                                        {SearchListSACCode.length != 0 ?
                                            SearchListSACCode.map((x, idx) => {
                                                return (<tr className={x.IsActiveTabSAC == '1' ? 'active cursor-pointer ' : 'cursor-pointer'} >
                                                    <td>{x.IsActiveTabSAC == '1' ? <CustomInput
                                                        className="input-no-cont"
                                                        labelText="Name"

                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        inputProps={{
                                                            onChange: (e) => {
                                                                e.stopPropagation();
                                                                let tempList = SearchListSACCode;
                                                                tempList[idx]['chapter_name'] = e.target.value.trim();



                                                                this.setState({ SearchListSACCode: tempList });


                                                            },
                                                            type: "text",
                                                            id: "chapter_name",
                                                            name: "chapter_name",
                                                            value: x.chapter_name,
                                                        }}
                                                    /> : x.chapter_name}</td>
                                                    <td onClick={(e) => this.ActiveTabSAC(x.IsActiveTabSAC, idx)}>{x.product_description}</td>
                                                    <td onClick={(e) => this.ActiveTabSAC(x.IsActiveTabSAC, idx)}>{x.product_hsn_code}</td>
                                                    <td>{x.IsActiveTabSAC == '1' ? <CustomInput
                                                        className="input-no-cont"
                                                        labelText="Rate (%)"

                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        inputProps={{
                                                            onChange: (e) => {
                                                                e.stopPropagation();
                                                                let tempList = SearchListSACCode;
                                                                tempList[idx]['product_rate'] = e.target.value.trim();



                                                                this.setState({ SearchListSACCode: tempList });


                                                            },
                                                            type: "number",
                                                            id: "product_rate",
                                                            name: "product_rate",
                                                            value: x.product_rate,
                                                        }}
                                                    /> : x.product_rate} </td>
                                                    {/* <td>{x.IsActiveTabSAC == '1' ? <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
                                                        <div onClick={(e) => e.stopPropagation()}> <i class="icon-editImage"></i> </div>
                                                    </div> : '-'}</td> */}
                                                </tr>)
                                            }) : <div>No Records Available</div>}

                                    </table> : null}
                                </GridItem>


                            </GridContainer>
                        </DialogContent>
                        <DialogActions className="p-p-0 dialog-footer1">
                            <Button className="primary-btn1" onClick={(e) => this.handleCloseSAC("AddSACMaster")}>
                                Close
                            </Button>
                            {!CommonConfig.isObjectEmpty(SearchListSACCode) && SearchListSACCode.length > 0 ? <Button className="primary-btn" onClick={(e) => this.AddSACMaster(e)} type="submit" >
                                Submit
                            </Button> : null}
                        </DialogActions>
                    </Dialog>

                    {/* Modal For Delete */}
                    <Dialog open={DeleteSACMasterTypeModal} onClose={() => this.handleCloseSAC("DeleteMasterType")} aria-labelledby="cha-form-dialog-title">
                        <DialogTitle id="cha-form-dialog-title" className="custom-dialog-header">
                            <div>Delete Service</div>
                            <div className="dialog-close" onClick={(e) => this.handleCloseSAC("DeleteMasterType")}><i className="icon-modal-close"></i></div>
                        </DialogTitle>
                        <DialogContent className="CHA-dialog-content-area">
                            <h6>Are you sure want to delete?</h6>
                        </DialogContent>
                        <DialogActions className="p-p-0 dialog-footer1">
                            <Button className="primary-btn1" onClick={(e) => this.handleCloseSAC("DeleteMasterType")}>
                                Close
                            </Button>
                            <Button className="primary-btn" onClick={(e) => this.DeleteMasterType(e)} type="submit" >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}
export default HSNCode;