import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import { Autocomplete } from "@mui/material";
class PortMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MasterID: "",
      loading: false,
      UserId: "",
      Token: "",
      GetMasterList: [],
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      search: null,

      portName: "",
      portNameErr: false,
      portNameErrText: "",

      portCode: "",
      portCodeErr: false,
      portCodeErrText: "",

      portCountry: "",
      portCountryErr: false,
      portCountryErrText: "",

      latitude: "",
      latitudeErr: false,
      latitudeErrText: "",

      longitude: "",
      longitudeErr: false,
      longitudeErrText: "",

      AddMasterModal: false,
      EditMasterTypeModal: false,
      DeleteMasterTypeModal: false,
      portCountryList: [],
      AddCountryMasterModal: false,

      countryName: "",
      countryNameErr: false,
      countryNameErrText: "",
      userPageAccess: [],
    };
  }

  componentDidMount = async () => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      this.setState({
        UserId: data,
        Token: token,
        userPageAccess: userPageAccess,
      });
      await this.GetMasterListByType(data);
      await this.GetCountryMasterList(data);
    } else {
      this.props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  };
  GetCountryMasterList = async (UserId) => {
    let params = {
      SecurityUserID: UserId,
    };
    api
      .post(APIConstant.path.GetCountryMaster, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserID != "Admin");
          this.setState({ portCountryList: filterArry });
        }
      })
      .catch((err) => { });
  };

  actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {this.state.userPageAccess.includes("Edit Port") ? (
          <div onClick={(e) => this.handleOpen(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {this.state.userPageAccess.includes("Delete Port") ? (
          <div onClick={(e) => this.handleOpen(rowData, "DeleteMasterType")}>
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  GetMasterListByType = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetPortMaster, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.CreatedBy != "Admin");

          this.setState({ GetMasterList: filterArry });
        }
      })
      .catch((err) => { });
  };
  GetPortMasterByID = async (MasterId) => {
    Loader.show();
    let params = {
      ID: MasterId,
    };
    api
      .post(APIConstant.path.GetPortMasterByID, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          Loader.hide();

          this.setState({
            portName: response.Data.PortName,
            portCode: response.Data.PortCode,
            latitude: response.Data.Latitude,
            longitude: response.Data.Longitude,
            portCountry: response.Data.PortCountry,
            MasterID: response.Data._id,
            plotNumber: response.Data.PlotNumber,
          });
        } else {
          Loader.hide();
        }
      })
      .catch((err) => {
        Loader.hide();
      });
  };

  handleOpen = (item, type) => {
    console.log(item);
    if (type === "AddMaster") {
      this.setState({ AddMasterModal: true });
    } else if (type === "EditMasterType") {
      this.GetPortMasterByID(item._id);
      this.setState({
        AddMasterModal: true,
        MasterLabelName: item.label,
        MasterID: item._id,
      });
    } else if (type === "DeleteMasterType") {
      this.setState({
        DeleteMasterTypeModal: true,
        MasterID: item._id,
      });
    }
  };

  handleClose = (type) => {
    if (type === "AddMaster") {
      this.setState({
        AddMasterModal: false,
        portName: "",
        portNameErr: false,
        portNameErrText: "",

        portCode: "",
        portCodeErr: false,
        portCodeErrText: "",

        portCountry: "",
        portCountryErr: false,
        portCountryErrText: "",

        latitude: "",
        latitudeErr: false,
        latitudeErrText: "",

        plotNumber: "",
        plotNumberErr: false,
        plotNumberErrText: "",

        longitude: "",
        longitudeErr: false,
        longitudeErrText: "",
        MasterID: "",
      });
    } else if (type === "EditMasterType") {
      this.setState({ EditMasterTypeModal: false });
    } else if (type === "DeleteMasterType") {
      this.setState({ DeleteMasterTypeModal: false, MasterID: "" });
    } else if (type == "AddUpdateCountryMaster") {
      this.setState({
        AddCountryMasterModal: false,
        countryName: "",
        countryNameErr: false,
        countryNameErrText: "",
        countryMasterId: "",
      });
    }
  };

  handleChange = (e, type) => {
    if (type === "portName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          portName: e.target.value,
          portNameErr: true,
          portNameErrText: `Please enter port name.`,
        });
      } else {
        this.setState({
          portName: e.target.value,
          portNameErr: false,
          portNameErrText: "",
        });
      }
    } else if (type === "portCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          portCode: e.target.value,
          portCodeErr: true,
          portCodeErrText: `Please enter port code.`,
        });
      } else {
        this.setState({
          portCode: e.target.value,
          portCodeErr: false,
          portCodeErrText: "",
        });
      }
    } else if (type === "portCountry") {
      if (CommonConfig.isObjectEmpty(e)) {
        this.setState({
          portCountry: "",
          portCountryErr: true,
          portCountryErrText: `Please select country name.`,
        });
      } else {
        this.setState({
          portCountry: e.value,
          portCountryErr: false,
          portCountryErrText: "",
        });
      }
    } else if (type === "latitude") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          latitude: e.target.value,
          latitudeErr: true,
          latitudeErrText: `Please enter latitude.`,
        });
      } else if (e.target.value.match(CommonConfig.RegExp.onlyNumber)) {
        this.setState({
          latitude: "",
          latitudeErr: true,
          latitudeErrText: "Please enter only number.",
        });
      } else {
        this.setState({
          latitude: e.target.value,
          latitudeErr: false,
          latitudeErrText: "",
        });
      }
    } else if (type === "longitude") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          longitude: e.target.value,
          longitudeErr: true,
          longitudeErrText: `Please enter longitude.`,
        });
      } else if (e.target.value.match(CommonConfig.RegExp.onlyNumber)) {
        this.setState({
          longitude: "",
          longitudeErr: true,
          longitudeErrText: "Please enter only number.",
        });
      } else {
        this.setState({
          longitude: e.target.value,
          longitudeErr: false,
          longitudeErrText: "",
        });
      }
    } else if (type === "countryName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          countryName: e.target.value,
          countryNameErr: true,
          countryNameErrText: `Please enter country name.`,
        });
      } else {
        this.setState({
          countryName: e.target.value,
          countryNameErr: false,
          countryNameErrText: "",
        });
      }
    }
  };

  validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.portName)) {
      this.setState({
        portName: "",
        portNameErr: true,
        portNameErrText: "Port name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ portNameErr: false, portNameErrText: "" });
    }
    if (CommonConfig.isEmpty(this.state.portCode)) {
      this.setState({
        portCode: "",
        portCodeErr: true,
        portCodeErrText: "Port code is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ portCodeErr: false, portCodeErrText: "" });
    }
    if (CommonConfig.isEmpty(this.state.latitude)) {
      this.setState({
        latitude: "",
        latitudeErr: true,
        latitudeErrText: "Latitude is required.",
      });
      formIsValid = false;
    } else if (this.state.latitude.match(CommonConfig.RegExp.onlyNumber)) {
      this.setState({
        latitude: "",
        latitudeErr: true,
        latitudeErrText: "Please enter only number.",
      });
    } else {
      this.setState({ latitudeErr: false, latitudeErrText: "" });
    }
    if (CommonConfig.isEmpty(this.state.longitude)) {
      this.setState({
        longitude: "",
        longitudeErr: true,
        longitudeErrText: "Longtude is required.",
      });
      formIsValid = false;
    } else if (this.state.longitude.match(CommonConfig.RegExp.onlyNumber)) {
      this.setState({
        longitude: "",
        longitudeErr: true,
        longitudeErrText: "Please enter only number.",
      });
    } else {
      this.setState({ longitudeErr: false, longitudeErrText: "" });
    }
    if (CommonConfig.isEmpty(this.state.portCountry)) {
      this.setState({
        portCountry: "",
        portCountryErr: true,
        portCountryErrText: "Port country name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ portCountryErr: false, portCountryErrText: "" });
    }
    // if (CommonConfig.isEmpty(this.state.plotNumber)) {
    //     this.setState({ plotNumber: '', plotNumberErr: true, plotNumberErrText: 'Plot number is  required.' })
    //     formIsValid = false;
    // }
    // else {
    //     this.setState({ plotNumberErr: false, plotNumberErrText: '' });
    // }
    return formIsValid;
  };

  AddMaster = () => {
    if (this.validation()) {
      this.setState({ loading: true });

      let params = {
        PortName: this.state.portName,
        PortCode: this.state.portCode,
        Latitude: this.state.latitude,
        Longitude: this.state.longitude,
        UserID: this.state.UserId,
        PortMasterID: this.state.MasterID,
        PortCountry: this.state.portCountry,
        PlotNumber: this.state.plotNumber,
      };
      api.post(APIConstant.path.AddUpdatePortMaster, params).then((res) => {
        if (res.Success == 1) {
          this.setState({ loading: false });
          this.handleClose("AddMaster");
          this.GetMasterListByType(this.state.UserId);
          cogoToast.success(res.Message);
        } else {
          this.setState({ loading: false });
          cogoToast.error(res.Message);
        }
      });
    }
  };

  DeleteMasterType = () => {
    this.setState({ loading: true });
    let params = {
      ID: this.state.MasterID,
    };
    api.post(APIConstant.path.DeletePortMaster, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          DeleteMasterTypeModal: false,
          MasterID: "",
        });
        this.GetMasterListByType(this.state.UserId);
        cogoToast.success(res.Message);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  validateCountryMaster = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.countryName)) {
      this.setState({
        countryName: "",
        countryNameErr: true,
        countryNameErrText: "Country name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ countryNameErr: false, countryNameErrText: "" });
    }
    return formIsValid;
  };

  AddUpdateCountryMaster = () => {
    if (this.validateCountryMaster()) {
      this.setState({ loading: true });

      let params = {
        CountryName: this.state.countryName,
        UserID: this.state.UserId,
        CountryMasterId: this.state.countryMasterId,
      };
      api.post(APIConstant.path.AddUpdateCountryMaster, params).then((res) => {
        if (res.Success == 1) {
          this.setState({
            loading: false,
            AddCountryMasterModal: false,
            countryName: "",
            countryNameErr: false,
            countryNameErrText: "",
            countryMasterId: "",
          });
          this.GetCountryMasterList(this.state.UserId);
          cogoToast.success(res.Message);
        } else {
          this.setState({ loading: false });
          cogoToast.error(res.Message);
        }
      });
    }
  };

  render() {
    const {
      UserId,
      search,
      AddCountryMasterModal,
      userPageAccess,
      GetMasterList,
      MasterLabelName,
      MasterLabelNameErr,
      MasterLabelNameErrText,
      AddMasterModal,
      EditMasterTypeModal,
      DeleteMasterTypeModal,
      MasterID,
      portCountryList,
      portName,
      portNameErr,
      portNameErrText,
      portCode,
      portCodeErr,
      portCodeErrText,
      portCountry,
      portCountryErr,
      portCountryErrText,
      latitude,
      latitudeErr,
      latitudeErrText,
      longitude,
      longitudeErr,
      longitudeErrText,
    } = this.state;

    let filterGetMasterList = [];
    if (GetMasterList.length) {
      filterGetMasterList = GetMasterList;
      if (search) {
        filterGetMasterList = filterGetMasterList.filter((x) => {
          return (
            x.PortName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.PortCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.CountryName.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        });
      }
    }

    return (
      <div className="wayment-owner-dashboard e-weighment-dashoard">
        <div className="heading-area p-d-flex p-ai-center p-jc-between">
          <div className="p-d-flex p-ai-center">
            <i className="pi pi-map"></i>
            <h2>Port Master</h2>
          </div>
          <div className="p-d-flex p-ai-center wb-filter-btn">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                onInput={(e) => this.setState({ search: e.target.value })}
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            {userPageAccess.includes("Add Port") ? (
              <div>
                <div
                  className="e-add-btn"
                  onClick={(e) => this.handleOpen(e, "AddMaster")}
                >
                  <i className="icon-add"></i>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="main-access-card-master">
          {this.state.loading === true ? (
            <div className="loading">
              <SimpleBackdrop />
            </div>
          ) : null}

          <DataTable
            className="main-table table-td"
            value={filterGetMasterList}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={true}
            rows={this.state.rowsPerPage}
            totalRecords={filterGetMasterList.length}
            rowsPerPageOptions={this.state.rowsPerPageOptions}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={this.actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="PortName"
              header="Port Name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="PortCode"
              header="Port Code"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="CountryName"
              header="Port Country"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Latitude"
              header="Latitude"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Longitude"
              header="Longitude"
              style={{ width: "20%" }}
            ></Column>
            {/* <Column field="PlotNumber" header="Plot Number" style={{ width: '20%' }}></Column> */}
            <Column
              field="Action"
              header="Action"
              body={this.actionTemplate}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
          </DataTable>

          <Dialog
            open={AddCountryMasterModal}
            onClose={() => this.handleClose("AddUpdateCountryMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Add Country </div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("AddUpdateCountryMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <GridContainer>
                <GridItem sm={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="CountryName"
                    // id="chaNo"
                    label="Country Name*"
                    type="text"
                    value={this.state.countryName}
                    onChange={(e) => this.handleChange(e, "countryName")}
                    error={this.state.countryNameErr}
                    helperText={this.state.countryNameErrText}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("AddUpdateCountryMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.AddUpdateCountryMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          {/* Add Edit BL Type */}
          <Dialog
            open={AddMasterModal}
            onClose={() => this.handleClose("AddMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>{MasterID !== "" ? "Edit" : "Add"} Port Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <GridContainer>
                <GridItem sm={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Location"
                    // id="chaNo"
                    label="Port Name*"
                    type="text"
                    value={portName}
                    onChange={(e) => this.handleChange(e, "portName")}
                    error={portNameErr}
                    helperText={portNameErrText}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                  />
                </GridItem>
                <GridItem sm={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Location"
                    // id="chaNo"
                    label="Port Code*"
                    type="text"
                    value={portCode}
                    onChange={(e) => this.handleChange(e, "portCode")}
                    error={portCodeErr}
                    helperText={portCodeErrText}
                    inputProps={{ maxLength: 16 }}
                    fullWidth
                  />
                </GridItem>
                <GridItem sm={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Location"
                    // id="chaNo"
                    label="Latitude*"
                    type="text"
                    value={latitude}
                    onChange={(e) => this.handleChange(e, "latitude")}
                    error={latitudeErr}
                    helperText={latitudeErrText}
                    fullWidth
                    inputProps={{ maxLength: 10, pattern: "d*" }}
                  />
                </GridItem>
                <GridItem sm={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Location"
                    // id="chaNo"
                    label="Longitude*"
                    type="text"
                    value={longitude}
                    onChange={(e) => this.handleChange(e, "longitude")}
                    error={longitudeErr}
                    helperText={longitudeErrText}
                    inputProps={{ maxLength: 10, pattern: "d*" }}
                    fullWidth
                  />
                </GridItem>
                <GridItem sm={12}>
                  <div className="input-control select p-d-flex">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="portCountry"
                        name="portCountry"
                        value={
                          portCountry && this.state.portCountryList.length
                            ? this.state.portCountryList.filter(
                              (y) => y.value == portCountry
                            )[0]
                            : ""
                        }
                        options={this.state.portCountryList}
                        getOptionLabel={(option) =>
                          option.label ? option.label : option
                        }
                        onChange={(e, value) =>
                          this.handleChange(value, "portCountry")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Country Name"
                            error={portCountryErr}
                            helperText={portCountryErrText}
                            label="Select Country Name"
                          />
                        )}
                      />
                    </FormControl>
                    <div className="p-d-flex p-pt-2">
                      <button
                        className="filter-btn cursor-pointer"
                        onClick={(e) => {
                          this.setState({ AddCountryMasterModal: true });
                        }}
                      >
                        {" "}
                        <i className="icon-add"></i>{" "}
                      </button>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.AddMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal For Delete */}
          <Dialog
            open={DeleteMasterTypeModal}
            onClose={() => this.handleClose("DeleteMasterType")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Delete Port Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <h6>Are you sure want to delete?</h6>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.DeleteMasterType(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default PortMaster;
