/*eslint-disable*/
import React, { Component } from "react";
import logo from "../../assets/equator-img/logo/portlinkLogo.svg";

import TextField from "@material-ui/core/TextField";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
//import 'firebase/auth';
import { Link } from "@material-ui/core";
import APIConstant from "utils/constant";

let UserEmail;
let UserId;

class verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      userid: "",
      emailvalue: "",
      idvalue: "",
      count: 10,
      message: "",
      isExpired: false,
      setvalue: {},
    };
    var MainUrl = window.location.href;
    // var Url = MainUrl.replace("http://localhost:3000/auth/verification?", "");
    var Url = MainUrl.replace(
      process.env.REACT_APP_VERIFICATION_LINK + "/auth/verification?",
      ""
    );
    // var str = Url;
    var url = new URL(Url);
    // var list = str.split('&');
    // var email = list[0];
    var Email = url.searchParams.get("email");

    // UserEmail = email.replace("email=", "");
    UserEmail = Email;

    // var id = list[1];
    UserId = url.searchParams.get("id");
  }

  componentWillUnmount() {
    clearInterval(this.inter);
  }

  handleVerificationOfEmail() {
    var params = {
      email: UserEmail,
      userid: UserId,
    };

    api
      .post(APIConstant.path.VerifiedEmail, params)
      .then((res) => {
        if (res.Success === 1) {
          cogoToast.success("Email verified successfully");
          this.props.history.push("/");
        } else {
          this.setState({ IsExpired: true });
          cogoToast.error("Your Link is expired");
        }
      })
      .catch((err) => {});
  }

  componentDidMount() {
    this.setState({ emailvalue: UserEmail });
    this.handleVerificationOfEmail();

    this.inter = setInterval(() => {
      if (this.state.count <= 0) {
        clearInterval(this.inter);
        this.setState({
          message: "Login",
        });
      } else {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      }
    }, 1000);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onLogin() {
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="container-fluid">
        <div id="logo" style={{ textAlign: "center" }}>
          <div className="col text-center">
            <img
              src={logo}
              className="img-thumbnail rounded-circle"
              style={{ borderRadius: "50%" }}
              alt=""
              width="10%"
              height="10%"
            />
          </div>
        </div>
        <div className="loginwith-btns"></div>
        <div className="login-form">
          <div className="loginform-inner">
            {this.state.IsExpired == false ? (
              <div className="login-input">
                <p>
                  Your account is verified and you will be redirected to the
                  Login page:
                  {this.state.message ? this.state.message : this.state.count}
                  <Link onClick={() => this.onLogin()}></Link>
                </p>
              </div>
            ) : (
              <div className="login-input">
                <p>
                  Your account Verification Link is expired , Please Log In and
                  Verify your Email
                  <Link onClick={() => this.onLogin()}></Link>
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <TextField
            type="hidden"
            className="input-with-icon-grid"
            id="email"
            name="email"
            value={this.state.emailvalue}
          />
        </div>
        <div>
          <TextField
            type="hidden"
            className="input-with-icon-grid"
            id="userid"
            name="userid"
            value={this.state.idvalue}
          />
        </div>
      </div>
    );
  }
}

export default verification;
