import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import api from "utils/api";
import APIConstant from "utils/constant";
import { CommonConfig } from "CommonConfig";

const AuditListing = (props) => {
  const [userId, setUserId] = useState("");
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [search, setSearch] = useState("");
  const [auditOrderList, setAuditOrderList] = useState([]);
  const [auditItems, setAuditItems] = useState([]);
  const [auditItemDialog, setAuditItemDialog] = useState(false);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    CommonConfig.dataTableConfig.rowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonConfig.dataTableConfig.rowsPerPage
  );

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
       getAuditOrderList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getAuditOrderList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetAuditItemsList, params)
      .then((response) => {
        if (response.Success === 1) {
          setAuditOrderList(response.Data);
        }
      })
      .catch((err) => {});
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        <div onClick={(e) => handleOpen(rowData)}>
          <i class="icon-view"></i>
        </div>
      </div>
    );
  };

  const handleOpen = (data) => {
    setAuditItems(data.Items);
    setAuditItemDialog(true);
  };

  const handleClose = () => {
    setAuditItems([]);
    setAuditItemDialog(false);
  };

  return (
    <div className="main-access-contianer">
      <div className="heading-area">
        <h3>Audit List</h3>
        <div className="heading-area-right">
          <div className="searchbar-area p-as-center">
            <InputText
              type="search"
              placeholder="Global Search"
              size="30"
              style={{ marginRight: 10 }}
            />
            <i className="icon-search"></i>
          </div>
        </div>
      </div>

      <div className="main-access-card-master">
        <DataTable
          className="main-table table-td"
          value={auditOrderList}
          editMode="row"
          dataKey="id"
          responsiveLayout="scroll"
          paginator={true}
          rows={rowsPerPage}
          totalRecords={auditOrderList.length}
          rowsPerPageOptions={rowsPerPageOptions}
          globalFilter={search}
          emptyMessage="No records found"
          responsive={false}
          resizableColumns={true}
          columnResizeMode="fit"
          scrollable={true}
          scrollWidth="auto"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="AuditNumber"
            header="Audit Number"
            style={{ width: "20%" }}
          ></Column>

          <Column
            field="Remark"
            header="Remark"
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="Items"
            header="Items"
            style={{ width: "20%" }}
            body={actionTemplate}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        className="stock-modal"
        open={auditItemDialog}
        onClose={(e) => handleClose()}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Items</div>

          <div className="dialog-close" onClick={(e) => handleClose()}>
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <div className="main-access-card-master">
          <DataTable
            className="main-table table-td"
            value={auditItems}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={false}
            totalRecords={auditItems.length}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Item"
              header="Item Name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="CurrentStock"
              header="Current Stock"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="ActualStock"
              header="Actual Stock"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Unit"
              header="Unit"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Godown"
              header="Godown"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Remark"
              header="Remark"
              style={{ width: "20%" }}
            ></Column>
          </DataTable>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button className="primary-btn1" onClick={(e) => handleClose("")}>
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AuditListing;
