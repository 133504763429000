import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CommonConfig } from "../../../CommonConfig.js";
import SimpleBackdrop from '../../../utils/general.js';
import api from '../../../utils/api.js';
import cogoToast from 'cogo-toast';
import APIConstant from "../../../utils/constant.js";
import { DialogContent, DialogTitle, DialogActions, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { Dialog } from 'primereact/dialog';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Loader from 'components/Shared/Loader/Loader';
import CustomInput from "components/CustomInput/CustomInput";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "react-datetime";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import { MultiSelect } from "primereact/multiselect";
import "date-fns";
import { apiBase } from "config.js";
import { ColorPicker } from 'primereact/colorpicker';
import Checkbox from '@material-ui/core/Checkbox';
import { Calendar } from 'primereact/calendar';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

// Import the FilePond plugins you need
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

const SolvencyDetails = (props) => {

    let [userId, setUserId] = useState('');
    let [wareHouseList, setWareHouseList] = useState([]);

    useEffect(() => {

    }, []);

    return (

        <div className="p-mt-2">
            <GridContainer>

                <GridItem xs={12} sm={6} md={4}>
                    <div className="custom-input p-mt-3">
                        <Calendar placeholder="Select validity Date" className="p-w-100" dateFormat="dd-mm-yy" id="calendar-24h" value={props?.values?.issuingDate} onChange={(e) => props?.handleChange(e, 'issuingDate')} showIcon />

                    </div>
                    {props?.values?.stateissuingDateErr && (<FormHelperText>{props?.values?.issuingDateErrText}</FormHelperText>)}
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                    <div className="custom-input p-mt-3">
                        <Calendar placeholder="Select validity Date" className="p-w-100" dateFormat="dd-mm-yy" id="calendar-24h" value={props?.values?.expiryDate} onChange={(e) => props?.handleChange(e, 'expiryDate')} showIcon />
                    </div>
                    {props?.values?.expiryDateErr && (<FormHelperText>{props?.values?.expiryDateErrText}</FormHelperText>)}
                </GridItem>

            </GridContainer>


            <div className="p-d-flex p-jc-center p-mt-4">
                <div className="p-w-50">
                    <FilePond
                        files={props?.values?.solvencyDoc}
                        allowMultiple={false}
                        acceptedFileTypes={['application/pdf', 'jpg']}
                        maxFileSize="2MB"
                        onupdatefiles={(fileItems) =>
                            props?.handleFile(fileItems, 'solvencyDoc')
                        }
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                </div>
            </div>


        </div>
    )

}

export default SolvencyDetails;