import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import api from "utils/api";
import APIConstant from "utils/constant";
import { CommonConfig } from "CommonConfig";
import GridItem from "components/Grid/GridItem";
import { Autocomplete } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import cogoToast from "cogo-toast";

const initialValueState = {
  value: "",
  error: false,
  errorText: "",
};

const UnitConfiguration = (props) => {
  const [userId, setUserId] = useState("");
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [search, setSearch] = useState("");
  const [configurationId, setConfigurationId] = useState("");
  const [unitConfigurationList, setUnitConfigurationList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [unit, setUnit] = useState(initialValueState);
  const [convertedUnit, setConvertedUnit] = useState(initialValueState);
  const [qty, setQty] = useState(initialValueState);
  const [readOnly, setReadOnly] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    CommonConfig.dataTableConfig.rowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonConfig.dataTableConfig.rowsPerPage
  );

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      GetUnitConfigurationList(data);
      getUnitList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     props.history.push('/auth/lockscreen')
    // }
  }, []);

  const GetUnitConfigurationList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetUnitConfiguration, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          setUnitConfigurationList(response.Data);
        }
      })
      .catch((err) => {});
  };

  const getUnitList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetUnitMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data;

          setUnitList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const addUpdateUnitConfiguration = () => {
    if (validate()) {
      let params = {
        ConfigurationId: configurationId,
        UnitId: unit.value,
        UnitName: unitList.find((data) => data.value === unit.value)?.label,
        ConvertedUnitId: convertedUnit.value,
        ConvertedUnitName: unitList.find(
          (data) => data.value === convertedUnit.value
        )?.label,
        Quantity: qty.value,
        UserID: userId,
      };
      api
        .post(APIConstant.path.AddUpdateUnitConfiguration, params)
        .then((res) => {
          if (res.Success == 1) {
            handleClose("Add");
            GetUnitConfigurationList(userId);
            cogoToast.success(res.Message);
          } else {
            cogoToast.error(res.Message);
          }
        });
    }
  };

  const getUnitConfigurationById = (id) => {
    let params = {
      ConfigurationId: id,
      UserID: userId,
    };
    api.post(APIConstant.path.GetUnitConfigurationById, params).then((res) => {
      if (res.Success == 1) {
        let unitData = res.Data;
        setUnit((prevState) => ({ ...prevState, value: unitData.UnitId }));
        setConvertedUnit((prevState) => ({
          ...prevState,
          value: unitData.ConvertedUnitId,
        }));
        setQty((prevState) => ({ ...prevState, value: unitData.Quantity }));
      } else {
        cogoToast.error(res.Message);
      }
    });
  };

  const deleteUnitConfiguration = () => {
    let params = {
      ConfigurationId: configurationId,
      UserID: userId,
    };
    api.post(APIConstant.path.DeleteUnitConfiguration, params).then((res) => {
      if (res.Success == 1) {
        handleClose("Delete");
        GetUnitConfigurationList(userId);
        cogoToast.success(res.Message);
      } else {
        cogoToast.error(res.Message);
      }
    });
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(unit.value)) {
      setUnit({
        value: "",
        error: true,
        errorText: "Unit is required.",
      });
      formIsValid = false;
    } else {
      setUnit((prevState) => ({
        ...prevState,
        error: false,
        errorText: "",
      }));
    }
    if (CommonConfig.isEmpty(convertedUnit.value)) {
      setConvertedUnit({
        value: "",
        error: true,
        errorText: "Unit is required.",
      });
      formIsValid = false;
    } else {
      setConvertedUnit((prevState) => ({
        ...prevState,
        error: false,
        errorText: "",
      }));
    }

    if (CommonConfig.isEmpty(qty.value)) {
      setQty({
        value: "",
        error: true,
        errorText: "Quantity is required.",
      });
      formIsValid = false;
    } else {
      setQty((prevState) => ({
        ...prevState,
        error: false,
        errorText: "",
      }));
    }

    return formIsValid;
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        <div onClick={(e) => handleOpen(rowData, "View")}>
          <i class="icon-view"></i>
        </div>
        <div onClick={(e) => handleOpen(rowData, "Edit")}>
          <i class="icon-editImage"></i>
        </div>
        <div onClick={(e) => handleOpen(rowData, "Delete")}>
          <i class="icon-delete"></i>
        </div>
      </div>
    );
  };

  const handleOpen = (e, type) => {
    if (type == "Add") {
      setAddDialog(true);
    } else if (type === "Edit") {
      setAddDialog(true);
      setConfigurationId(e._id);
      getUnitConfigurationById(e._id);
    } else if (type === "View") {
      setAddDialog(true);
      setConfigurationId(e._id);
      getUnitConfigurationById(e._id);
      setReadOnly(true);
    } else if (type === "Delete") {
      setConfigurationId(e._id);
      setDeleteDialog(true);
    }
  };

  const handleClose = (type) => {
    if (type == "Add") {
      setUnit("");
      setConvertedUnit("");
      setConfigurationId("");
      setQty("");
      setReadOnly(false);
      setAddDialog(false);
    } else if (type === "Delete") {
      setConfigurationId("");
      setDeleteDialog(false);
    }
  };

  const handleChange = (e, type) => {
    if (type === "unit") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setUnit({
          value: "",
          error: true,
          errorText: "Please enter unit",
        });
      } else {
        setUnit({
          value: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "convertedUnit") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setConvertedUnit({
          value: "",
          error: true,
          errorText: "Please enter unit",
        });
      } else {
        setConvertedUnit({
          value: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "qty") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setQty({
          value: e.target.value,
          error: true,
          errorText: "Please enter quantity",
        });
      } else if (/^\d*$/i.test(e.target.value)) {
        setQty({
          value: e.target.value,
          error: false,
          errorText: "",
        });
      }
    }
  };

  return (
    <div className="main-access-contianer">
      <div className="heading-area">
        <h3>Unit Configuration</h3>
        <div className="heading-area-right">
          <div className="p-d-flex p-ai-center wb-filter-btn">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            <div>
              <div className="e-add-btn" onClick={(e) => handleOpen(e, "Add")}>
                <i className="icon-add"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-access-card-master">
        <DataTable
          className="main-table table-td"
          value={unitConfigurationList}
          editMode="row"
          dataKey="id"
          responsiveLayout="scroll"
          paginator={true}
          rows={rowsPerPage}
          totalRecords={unitConfigurationList.length}
          rowsPerPageOptions={rowsPerPageOptions}
          globalFilter={search}
          emptyMessage="No records found"
          responsive={false}
          resizableColumns={true}
          columnResizeMode="fit"
          scrollable={true}
          scrollWidth="auto"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ width: "20%" }}
          ></Column>
          <Column field="Unit" header="Unit" style={{ width: "20%" }}></Column>

          <Column
            field="ConvertedUnit"
            header="Converted Unit"
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="Actions"
            header="Action"
            style={{ width: "20%" }}
            body={actionTemplate}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        className="stock-modal"
        open={addDialog}
        onClose={(e) => handleClose("Add")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Unit Conversion</div>

          <div className="dialog-close" onClick={(e) => handleClose("Add")}>
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="CHA-dialog-content-area">
          <GridContainer>
            <GridItem xs={12}>
              <div className="input-control select p-d-flex">
                <FormControl fullWidth>
                  <Autocomplete
                    id="unit"
                    name="unit"
                    disabled={readOnly}
                    value={
                      unit.value && unitList.length
                        ? unitList.filter((y) => y.value == unit.value)[0]
                        : ""
                    }
                    options={unitList}
                    getOptionLabel={(option) =>
                      option.label ? option.label : option
                    }
                    onChange={(e, value) => handleChange(value, "unit")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Unit Name"
                        error={unit.error}
                        helperText={unit.errorText}
                        label="Unit Name"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={12}>
              <h4>Converted Unit</h4>
            </GridItem>
            <GridItem xs={6}>
              <div className="input-control select p-d-flex">
                <FormControl fullWidth>
                  <TextField
                    autoFocus
                    disabled={readOnly}
                    margin="dense"
                    name="Qty"
                    label="Quantity*"
                    type="text"
                    value={qty.value}
                    onChange={(e) => handleChange(e, "qty")}
                    error={qty.error}
                    helperText={qty.errorText}
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                  />
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <div className="input-control select p-d-flex">
                <FormControl fullWidth>
                  <Autocomplete
                    id="unit"
                    name="unit"
                    disabled={readOnly}
                    value={
                      convertedUnit.value && unitList.length
                        ? unitList.filter(
                            (y) => y.value == convertedUnit.value
                          )[0]
                        : ""
                    }
                    options={unitList}
                    getOptionLabel={(option) =>
                      option.label ? option.label : option
                    }
                    onChange={(e, value) =>
                      handleChange(value, "convertedUnit")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Unit Name"
                        error={convertedUnit.error}
                        helperText={convertedUnit.errorText}
                        label="Unit Name"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>

        <DialogActions className="p-p-0 dialog-footer1">
          <Button className="primary-btn1" onClick={(e) => handleClose("Add")}>
            Close
          </Button>
          {!readOnly && (
            <Button
              className="primary-btn"
              onClick={(e) => addUpdateUnitConfiguration(e)}
              type="submit"
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialog}
        onClose={() => handleClose("Delete")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Delete Unit Configuration</div>
          <div className="dialog-close" onClick={(e) => handleClose("Delete")}>
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="CHA-dialog-content-area">
          <h6>Are you sure want to delete?</h6>
        </DialogContent>
        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("Delete")}
          >
            Close
          </Button>
          <Button
            className="primary-btn"
            onClick={(e) => deleteUnitConfiguration()}
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnitConfiguration;
