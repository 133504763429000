import axios from 'axios';

import {apiBase} from '../config';
// import { getAccessToken } from './authenticate';
// import { format } from 'path';

const instance = axios.create({
  baseURL: apiBase,
  // timeout: 60000
});

var headers = {
  'Authorization': 'JWT fefege...' ,
  'Access-Control-Allow-Origin':'*',
  'Accept': 'application/json',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': '*',
  "Content-type": "application/json; charset=UTF-8",
}


const request = (method, url, data) => {
  headers['token'] = localStorage.getItem('Token');
  headers['sessionid'] = localStorage.getItem('Infodata');
  // console.log("headers",headers)
  return new Promise((resolve, reject) => {
    (() => {
      if (method === 'get') {
          return instance.request({
            url, method, params: data, headers: headers
          });
      } else {
          return instance.request({
            url, method, data, headers: headers
          });
      }
    })()
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      
      reject(err.response);
    })
  ;
  });
};

export default {
  get: (endpoint, data) => {
    return request('get', endpoint, data);
  },
  post: (endpoint, data) => {
    return request('post', endpoint, data);
  },  
  put: (endpoint, data) => {
    return request('put', endpoint, data);
  },
  del: (endpoint, data) => {
    return request('delete', endpoint, data);
  },
};
