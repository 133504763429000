import moment from "moment";
import React from 'react';
export class CommonConfig {
    static loginData = function () {
        let data = {};
        if (localStorage.getItem('Userdata')) {
            data = JSON.parse(localStorage.getItem('Userdata'));
        }
        return data;
    }

    static getPageAccessData = function () {
        let tempdata = {};
        if (localStorage.getItem('PageAccess')) {
            tempdata = JSON.parse(localStorage.getItem('PageAccess'));
        }
        return tempdata;
    }

    static isEmpty = function (value) {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            if (typeof value === 'string') {
                return value.trim() === "";
            } else {
                return false;
            }
        }
    }

    static checkPathName = function () {
        let pathname = window.location.pathname;
        let pathnameStartIdx = pathname.lastIndexOf('/');
        return pathname.slice(pathnameStartIdx, pathname.length)
    }

    static getFileExtension(filename) {
        // get file extension
        const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
        return extension.toString();
    }

    static isObjectEmpty = function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    static dataTableConfig = {
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10, 20, 30, 50],
        // columnToggleIcon: <i className="fa fa-bars"></i>
    }

    static hexToRgb = function (hex) {
        if (hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        }
        else {
            return null;
        }
    }

    static getContrast = function (rgb) {
        if (rgb) {
            // Randomly update colours
            rgb[0] = Math.round(Math.random() * 255);
            rgb[1] = Math.round(Math.random() * 255);
            rgb[2] = Math.round(Math.random() * 255);

            // http://www.w3.org/TR/AERT#color-contrast
            const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                (parseInt(rgb[1]) * 587) +
                (parseInt(rgb[2]) * 114)) / 1000);
            return (brightness > 125) ? 'black' : 'white';
        }
        else {
            return 'black';
        }
    }

    static convertImgToBase64 = async function (url) {
        return fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
    }

    static percDiff = function (originalValue, currentValue) {
        let diffValue = currentValue - originalValue;
        let returnVal = 0;
        if (originalValue != 0) {
            returnVal = ((diffValue * 100) / originalValue).toFixed(2)
        }
        // return (100 * Math.abs((a - b) / ((a + b) / 2))).toFixed(2);
        return parseFloat(returnVal).toFixed(2);
    }


    static base64toFile = function (dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    

    static replaceFirst8DigitsWithAsterisks = function (number) {
        // Convert the number to a string
        let numberStr = number.toString();

        // Check if the number has at least 8 digits
        if (numberStr.length >= 8) {
            // Replace the first 8 digits with asterisks
            let replacedNumberStr = '********' + numberStr.slice(8);
            return replacedNumberStr;
        } else {
            // If the number has less than 8 digits, return the original number
            return numberStr;
        }
    }

    static base64toFileNew = function (base64String, filename, contentType) {
        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const file = new File(byteArrays, filename, { type: contentType });
        return file;
    };

    static findFileNameFromURL = function (urlPath) {
        var url = urlPath;
        var filename = url.substring(url.lastIndexOf('/') + 1);
        return filename;
    }

    static findExtesionFromURL = function (urlPath) {
        const types = new Map([["jpg", "img"], ["jpeg", "img"], ["png", "img"], ["gif", "img"], ["mp4", "video"], ["3gp", "video"]])

        const url = new URL(urlPath)
        return url.pathname.split(".")[1]
    }

    static slickSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true
    };

    static RegExp = {
        Pos: /^(0|[1-9][0-9]{0,9})$/,
        decimalNumber: /^(\$|)([1-9]\d{0,2}(\.\d{2})*|([1-9]\d*))(\.\d{2})?$/,
        NonNegativeNumber: /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/,
        onlyNumber: /[a-zA-Z~`!@#$%^&*()_+=-{}|:"<>?,;']+$/,
        nameRegex: /^[a-zA-Z]+[a-zA-Z-\s']*$/,
        // EmailId: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/ig,
        EmailId: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        companyRegex: /^[a-zA-Z]+[a-zA-Z-\s']*$/,
        companyEmail: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/ig,
        zipregex: /^[1-9][0-9]{5}$/g,
        phoneRegExp: /^([0-9]+\s?)*$/g,
        numberWithDecimal: /^\d*\.?\d*$/
        // phoneRegExp : /^[0-9]{10}$/,
    }

    static generateRandomString = function (len) {
        const alpha = Array.from(Array(26)).map((e, i) => i + 65);
        let alphabet = alpha.map((x) => String.fromCharCode(x));
        alphabet = alphabet.concat(Array.from(Array(10).keys()));
        const arr = alphabet;
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    static getIndianFormatAmount = function (amount) {
        var x = amount;
        x = x.toString();
        var afterPoint = '';
        if (x.indexOf('.') > 0)
            afterPoint = x.substring(x.indexOf('.'), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    }

    static dateFormatUsingDefaultJS(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;



        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year, strTime].join('-');
    }
    static dateFormat(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        // var hours = d.getHours();
        // var minutes = d.getMinutes();
        // var ampm = hours >= 12 ? 'pm' : 'am';
        // hours = hours % 12;
        // hours = hours ? hours : 12; // the hour '0' should be '12'
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        // var strTime = hours + ':' + minutes + ' ' + ampm;



        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    static getInitials(value) {
        const fullName = value;
        let initials = null;
        if (fullName.length > 1) {
            // initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
            initials = fullName.substring(0, 2);
        } else {
            initials = fullName.substring(0, 2);
        }

        return initials.toUpperCase();
    }

    static twoDigits = (value) => {
        return parseFloat(value).toFixed(2);
    }

    static thousandsSeparators = (amount) => {
        var x = amount;
        x = x.toString();
        var afterPoint = '';
        if (x.indexOf('.') > 0)
            afterPoint = x.substring(x.indexOf('.'), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    }

    static timeDiff = (start, end) => {
        start = start ? start.split(":") : 0;
        end = end ? end.split(":") : 0;
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        if (hours < 0)
            hours = hours + 24;

        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }

    static checkShiftExist = (shiftList, startTime, endTime) => {
        let listData = shiftList;
        listData = listData.length > 1 ? (listData.slice(0, -1)) : listData;
        for (const shift of listData) {
            debugger

            // if (moment(shift.reasonToTime).format("HH:mm:ss") <= moment(startTime).format("HH:mm:ss")
            //     &&
            //     // moment(startTime).format("HH:mm:ss") >= moment(shift.reasonFromTime).format("HH:mm:ss") 
            //     // &&
            //     moment(endTime).format("HH:mm:ss") >= moment(shift.reasonToTime).format("HH:mm:ss")
            //     // &&
            //     // moment(endTime).format("HH:mm:ss") == moment(shift.reasonToTime).format("HH:mm:ss")
            // ) {
            //     return true;
            // }

            // console.log(moment(endTime).format('HH:mm:ss'))
            // return (startTime  shift.reasonFromTime), moment(shift.reasonToTime))) ||
            //     ((endTime)) shift.reasonFromTime) shift.reasonToTime)));
        }
        // return false;
    }

    static insertObjectAtIndex = (array, objectToInsert, index) => {
        if (index >= 0 && index <= array.length) {
            array.splice(index, 0, objectToInsert);
        } else {
            array.push(objectToInsert);
        }
        return array;
    }

    static generateRandomId = (date) => {
        const now = date;
        const year = now
            .getFullYear()
            .toString()
            .substr(-2);
        const month = now.getMonth() + 1;
        const day = now.getDate();

        const uniquePart = `${day < 10 ? "0" + day : day}`;

        return `${uniquePart}/${month < 10 ? "0" + month : month}${year}`;
    };

    static getDateDifference = (dateString1, dateString2) => {

        dateString2 = new Date(dateString2);
        dateString1 = new Date(dateString1);

        const dateDifference = dateString2 - dateString1;
        const hoursDifference = dateDifference / (1000 * 60 * 60);
        let totalHours = (hoursDifference / 24).toFixed(2);
        return totalHours;

    }

    static showErrorMsg = function (msg) {
        return (
            <span className="text-danger" style={{ color: 'red' }}>
                <span className="field-validation-error">{msg}</span>
            </span>
        )
    }
    static getRandomColor = function () {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

}
