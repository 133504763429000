import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import { Autocomplete } from "@material-ui/lab";
class SubCategoryMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MasterID: "",
      loading: false,
      UserId: "",
      Token: "",
      GetMasterList: [],
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      search: null,

      categoryName: "",
      categoryNameErr: false,
      categoryNameErrText: "",

      category: "",
      categoryErr: false,
      categoryErrText: "",

      AddMasterModal: false,
      EditMasterTypeModal: false,
      DeleteMasterTypeModal: false,
      cargoTypeList: [],
      categoryList: [],
      userPageAccess: [],
    };
  }

  componentDidMount = async () => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      this.setState({
        UserId: data,
        Token: token,
        userPageAccess: userPageAccess,
      });
      await this.GetMasterListByType(data);
      await this.GetCategoryMaster(data);
    } else {
      this.props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  };

  actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {this.state.userPageAccess.includes("Add") ? (
          <div onClick={(e) => this.handleOpen(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {this.state.userPageAccess.includes("Delete") ? (
          <div onClick={(e) => this.handleOpen(rowData, "DeleteMasterType")}>
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  GetMasterListByType = async (UserId) => {
    let params = {
      ID: UserId,
    };
    api
      .post(APIConstant.path.GetSubCategoryMaster, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          // let filterArry = response.Info.CategoryMaster.filter(x => x.UserID != 'Admin');

          this.setState({ GetMasterList: response.Data });
        }
      })
      .catch((err) => {});
  };

  GetSubCategoryMasterById = async (MasterId) => {
    Loader.show();
    let params = {
      ID: MasterId,
    };
    api
      .post(APIConstant.path.GetSubCategoryMasterById, params)
      .then((response) => {
        if (response.Success === 1) {
          Loader.hide();

          this.setState({
            categoryName: response.Data.SubCategoryName,
            category: response.Data.Category,
            MasterID: response.Data.SubCategoryMasterId,
          });
        } else {
          Loader.hide();
        }
      })
      .catch((err) => {
        Loader.hide();
      });
  };

  GetCategoryMaster = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetCategoryMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserID != "Admin");

          this.setState({ categoryList: filterArry });
        }
      })
      .catch((err) => {});
  };
  
  handleOpen = (item, type) => {
    if (type === "AddMaster") {
      this.setState({ AddMasterModal: true });
    } else if (type === "EditMasterType") {
      this.GetSubCategoryMasterById(item.SubCategoryMasterId);
      this.setState({
        AddMasterModal: true,
        MasterLabelName: item.label,
        MasterID: item.SubCategoryMasterId,
      });
    } else if (type === "DeleteMasterType") {
      this.setState({
        DeleteMasterTypeModal: true,
        MasterID: item.SubCategoryMasterId,
      });
    }
  };

  handleClose = (type) => {
    if (type === "AddMaster") {
      this.setState({
        AddMasterModal: false,

        categoryName: "",
        categoryNameErr: false,
        categoryNameErrText: "",

        category: "",
        categoryErr: false,
        categoryErrText: "",

        MasterID: "",
      });
    } else if (type === "EditMasterType") {
      this.setState({ EditMasterTypeModal: false });
    } else if (type === "DeleteMasterType") {
      this.setState({ DeleteMasterTypeModal: false, MasterID: "" });
    }
  };

  handleChange = (e, type) => {
    if (type === "categoryName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          categoryName: e.target.value,
          categoryNameErr: true,
          categoryNameErrText: `Please enter categoryName.`,
        });
      } else {
        this.setState({
          categoryName: e.target.value,
          categoryNameErr: false,
          categoryNameErrText: "",
        });
      }
    } else if (type === "category") {
      if (CommonConfig.isObjectEmpty(e)) {
        this.setState({
          category: "",
          categoryErr: true,
          categoryErrText: `Please select category.`,
        });
      } else {
        this.setState({
          category: e.value,
          categoryErr: false,
          categoryErrText: "",
        });
      }
    }
  };

  validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.categoryName)) {
      this.setState({
        categoryName: "",
        categoryNameErr: true,
        categoryNameErrText: "Category is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ categoryNameErr: false, categoryNameErrText: "" });
    }

    if (CommonConfig.isEmpty(this.state.category)) {
      this.setState({
        category: "",
        categoryErr: true,
        categoryErrText: "category is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ categoryErr: false, categoryErrText: "" });
    }
    return formIsValid;
  };

  AddMaster = () => {
    if (this.validation()) {
      this.setState({ loading: true });

      let params = {
        CategoryName: this.state.categoryName,
        Category: this.state.category,
        CategoryMasterID: this.state.MasterID,
        UserID: this.state.UserId,
      };
      api
        .post(APIConstant.path.AddUpdateSubCategoryMaster, params)
        .then((res) => {
          if (res.Success == 1) {
            this.setState({ loading: false });
            this.handleClose("AddMaster");
            this.GetMasterListByType(this.state.UserId);
            cogoToast.success(res.Message);
          } else {
            this.setState({ loading: false });
            cogoToast.error(res.Message);
          }
        });
    }
  };

  DeleteMasterType = () => {
    this.setState({ loading: true });
    let params = {
      ID: this.state.MasterID,
      UserId: this.state.UserId,
    };
    api.post(APIConstant.path.DeleteSubCategoryMaster, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          DeleteMasterTypeModal: false,
          MasterID: "",
        });
        this.GetMasterListByType(this.state.UserId);
        cogoToast.success(res.Message);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };
  GetCargoTypeList = (UserId) => {
    let params = {
      Type: "CARGOTYPE",
      ID: UserId,
    };
    api.post(APIConstant.path.GetMasterListByType, params).then(async (res) => {
      if (res.Success === 1) {
        this.setState({ cargoTypeList: res.Info.Master });
      }
    });
  };

  render() {
    const {
      UserId,
      categoryList,
      search,
      userPageAccess,
      GetMasterList,
      MasterLabelName,
      MasterLabelNameErr,
      MasterLabelNameErrText,
      AddMasterModal,
      EditMasterTypeModal,
      DeleteMasterTypeModal,
      MasterID,
      portMasterList,

      categoryName,
      categoryNameErr,
      categoryNameErrText,
      category,
      categoryErr,
      categoryErrText,
    } = this.state;

    let filterGetMasterList = [];
    if (GetMasterList.length) {
      filterGetMasterList = GetMasterList;
      if (search) {
        filterGetMasterList = filterGetMasterList.filter((x) => {
          return (
            x.CategoryName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.SubCategoryName.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        });
      }
    }

    return (
      <div className="wayment-owner-dashboard e-weighment-dashoard">
      <div className="heading-area p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-ai-center">
          <i className="pi pi-ticket"></i>
          <h2>Sub Category Master</h2>
          </div>
          <div className="p-d-flex p-ai-center wb-filter-btn">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                onInput={(e) => this.setState({ search: e.target.value })}
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            {userPageAccess.includes("Add") ? (
              <div>
                <div
                  className="e-add-btn"
                  onClick={(e) => this.handleOpen(e, "AddMaster")}
                >
                  <i className="icon-add"></i>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="main-access-card-master">
          {this.state.loading === true ? (
            <div className="loading">
              <SimpleBackdrop />
            </div>
          ) : null}
          {userPageAccess.includes("View Sub Category Master") ? (
            <DataTable
              className="main-table table-td"
              value={filterGetMasterList}
              editMode="row"
              dataKey="id"
              responsiveLayout="scroll"
              paginator={true}
              rows={this.state.rowsPerPage}
              totalRecords={filterGetMasterList.length}
              rowsPerPageOptions={this.state.rowsPerPageOptions}
              globalFilter={search}
              emptyMessage="No records found"
              responsive={false}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            >
              <Column
                field="Index"
                header="Sr.No"
                body={this.actionBodyTemplateSrNo}
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="SubCategoryName"
                header="Sub Category Name"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="CategoryName"
                header="Category Name"
                style={{ width: "20%" }}
              ></Column>

              <Column
                field="Action"
                header="Action"
                body={this.actionTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          ) : null}

          {/* Add Edit BL Type */}
          <Dialog
            open={AddMasterModal}
            onClose={() => this.handleClose("AddMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>{MasterID !== "" ? "Edit" : "Add"} Sub Category Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <GridContainer>
                <GridItem md={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="SubCategoryName"
                    // id="chaNo"
                    label="Sub Category Name*"
                    type="text"
                    value={categoryName}
                    onChange={(e) => this.handleChange(e, "categoryName")}
                    error={categoryNameErr}
                    helperText={categoryNameErrText}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <div className="input-control select p-d-flex">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="category"
                        name="category"
                        value={
                          category && categoryList.length
                            ? categoryList.filter((y) => y.value == category)[0]
                            : ""
                        }
                        options={categoryList}
                        getOptionLabel={(option) =>
                          option.label ? option.label : option
                        }
                        onChange={(e, value) =>
                          this.handleChange(value, "category")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Category Name"
                            error={categoryErr}
                            helperText={categoryErrText}
                            label="Category Name"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.AddMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal For Delete */}
          <Dialog
            open={DeleteMasterTypeModal}
            onClose={() => this.handleClose("DeleteMasterType")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Delete Sub Category Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <h6>Are you sure want to delete?</h6>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.DeleteMasterType(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default SubCategoryMaster;
