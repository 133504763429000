import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../../CommonConfig.js";
import SimpleBackdrop from "../../../utils/general.js";
import api from "../../../utils/api.js";
import cogoToast from "cogo-toast";
import APIConstant from "../../../utils/constant.js";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
} from "@material-ui/core";
import { Dialog } from "primereact/dialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import CustomInput from "components/CustomInput/CustomInput";
import "date-fns";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

// Import the FilePond plugins you need
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { Autocomplete } from "@mui/material";
import InsuranceDetails from "./InsuranceDetails.js";
import SolvencyDetails from "./SolvencyDetails.js";
import GodownDetails from "./GodownDetails.js";
import FireFightingDetails from "./FireFightingDetails.js";
// Register the plugins
registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);
const AddWareHouse = (props) => {
  let [eidNo, setEidNo] = useState("");
  let [eidNoErr, setEidNoErr] = useState(false);
  let [eidNoErrText, setEidNoErrText] = useState("");

  let [wareHouseFile, setWareHouseFile] = useState([]);
  let [wareHouseFileErr, setWareHouseFileErr] = useState(false);
  let [wareHouseFileErrText, setWareHouseFileErrText] = useState("");

  let [wareHouseName, setWareHouseName] = useState("");
  let [wareHouseNameErr, setWareHouseNameErr] = useState(false);
  let [wareHouseNameErrText, setWareHouseNameErrText] = useState("");

  const [area, setArea] = useState("");

  let [address, setAddress] = useState("");
  let [addressErr, setAddressErr] = useState(false);
  let [addressErrText, setAddressErrText] = useState("");

  let [wareHourseType, setWareHourseType] = useState("");
  let [wareHourseTypeErr, setWareHourseTypeErr] = useState(false);
  let [wareHourseTypeErrText, setWareHourseTypeErrText] = useState("");

  let [type, setType] = useState("");
  let [typeErr, setTypeErr] = useState(false);
  let [typeErrText, setTypeErrText] = useState("");
  //Section: Insurance Details:
  let [policyNo, setPolicyNo] = useState("");
  let [policyNoErr, setPolicyNoErr] = useState(false);
  let [policyNoErrText, setPolicyNoErrText] = useState("");

  let [company, setCompany] = useState("");
  let [companyErr, setCompanyErr] = useState(false);
  let [companyErrText, setCompanyErrText] = useState("");

  let [validityFrom, setValidityFrom] = useState("");
  let [validityFromErr, setValidityFromErr] = useState(false);
  let [validityFromErrText, setValidityFromErrText] = useState("");

  let [validityTo, setValidityTo] = useState("");
  let [validityToErr, setValidityToErr] = useState(false);
  let [validityToErrText, setValidityToErrText] = useState("");

  let [amount, setAmount] = useState("");
  let [amountErr, setAmountErr] = useState(false);
  let [amountErrText, setAmountErrText] = useState("");

  let [policyCopy, setPolicyCopy] = useState([]);
  let [policyCopyErr, setPolicyCopyErr] = useState(false);
  let [policyCopyErrText, setPolicyCopyErrText] = useState("");
  let [companyTypeList, setCompanyTypeList] = useState([]);

  let insuranceData = {
    companyTypeList,
    policyNo,
    policyNoErr,
    policyNoErrText,
    company,
    companyErr,
    companyErrText,
    validityFrom,
    validityFromErr,
    validityFromErrText,
    validityTo,
    validityToErr,
    validityToErrText,
    amount,
    amountErr,
    amountErrText,
    policyCopy,
    policyCopyErr,
    policyCopyErrText,
  };

  // Solvency Doc:

  let [solvencyDoc, setSolvencyDoc] = useState([]);
  let [solvencyDocErr, setSolvencyDocErr] = useState(false);
  let [solvencyDocErrText, setSolvencyDocErrText] = useState("");

  let [issuingDate, setIssuingDate] = useState("");
  let [issuingDateErr, setIssuingDateErr] = useState(false);
  let [issuingDateErrText, setIssuingDateErrText] = useState("");

  let [expiryDate, setExpiryDate] = useState("");
  let [expiryDateErr, setExpiryDateErr] = useState(false);
  let [expiryDateErrText, setExpiryDateErrText] = useState("");
  let solvencyData = {
    solvencyDoc,
    solvencyDocErr,
    solvencyDocErrText,
    issuingDate,
    issuingDateErr,
    issuingDateErrText,
    expiryDate,
    expiryDateErr,
    expiryDateErrText,
  };
  // Fire Fighting NOC:

  let [fireFightingDoc, setFireFightingDoc] = useState([]);
  let [fireFightingDocErr, setFireFightingDocErr] = useState(false);
  let [fireFightingDocErrText, setFireFightingDocErrText] = useState("");

  let [firingDocIssuingDate, setFiringDocIssuingDate] = useState("");
  let [firingDocIssuingDateErr, setFiringDocIssuingDateErr] = useState(false);
  let [firingDocIssuingDateErrText, setFiringDocIssuingDateErrText] = useState(
    ""
  );

  let [firingDocExpiryDate, setFiringDocExpiryDate] = useState("");
  let [firingDocExpiryDateErr, setFiringDocExpiryDateErr] = useState(false);
  let [firingDocExpiryDateErrText, setFiringDocExpiryDateErrText] = useState(
    ""
  );

  let fireFightingData = {
    fireFightingDoc,
    fireFightingDocErr,
    fireFightingDocErrText,
    firingDocIssuingDate,
    firingDocIssuingDateErr,
    firingDocIssuingDateErrText,
    firingDocExpiryDate,
    firingDocExpiryDateErr,
    firingDocExpiryDateErrText,
  };

  let [typeList, setTypeList] = useState([]);
  let [cargoTypeList, setCargoTypeList] = useState([]);
  let [warehouseTypeList, setWarehouseTypeList] = useState([]);
  let [userPageAccess, setUserPageAccess] = useState([]);
  // Godown Detals:

  let [godownArray, setGodownArray] = useState([
    {
      WareHouseGodownDetailId: "",
      godownNo: "",
      godownNoErr: false,
      godownNoErrText: "",

      cargoType: "",
      cargoTypeErr: false,
      cargoTypeErrText: "",

      area: "",
      areaErr: false,
      areaErrText: "",

      capacity: "",
      capacityErr: false,
      capacityErrText: "",

      isNew: true,
      isEdit: true,
    },
  ]);

  let [duplicateGodownArray, setDuplicateGodownArray] = useState([]);
  let [addressTypeList, setAddressTypeList] = useState([]);
  let [userId, setUserId] = useState("");
  let [wareHouseMasterId, setWareHouseMasterId] = useState("");
  let [wareHouseTypeLabel, setWareHouseTypeLabel] = useState("");
  let [addMasterModal, setAddMasterModal] = useState(false);

  let [masterLabelName, setMasterLabelName] = useState("");
  let [masterLabelNameErr, setMasterLabelNameErr] = useState(false);
  let [masterLabelNameErrText, setMasterLabelNameErrText] = useState("");
  let [masterId, setMasterId] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      getCargoTypeList(data);
      getHazardousTypeList(data);
      //   getCompanyTypeList(data);
      getWarehouseTypeList(data);
      getAddressTypeList(data);
      getInsuranceCompanyTypeList(data);

      if (props?.location?.state && props?.location?.state?.WareHouseId) {
        getWareHouseById(props?.location?.state?.WareHouseId);
      }
    } else {
      props.history.push("/login");
    }
    // } else {
    //     props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getWareHouseById = async (ID) => {
    try {
      Loader.show();
      let data = {
        ID: ID,
      };
      await api
        .post(APIConstant.path.GetWareHouseById, data)
        .then((response) => {
          if (response.Success == 1) {
            Loader.hide();
            let data = response?.Data;
            setWareHouseMasterId(data?._id);
            setEidNo(data?.EdiNo);
            setArea(data.Area);
            setWareHouseName(data?.WareHouseName);
            setAddress(data?.Address);
            setWareHourseType(data?.WareHouseType);
            setType(data?.Type);
            setPolicyNo(data?.PolicyNo);
            setCompany(data?.InsuranceCompany);
            setValidityFrom(new Date(data?.InsuranceValidityFrom));
            setValidityTo(new Date(data?.InsuranceValidityTo));
            setAmount(Number(data?.InsuranceAmount));
            setIssuingDate(new Date(data?.SolvencyDoc?.ValidityFrom));
            setExpiryDate(new Date(data?.SolvencyDoc?.ValidityTo));
            setFiringDocIssuingDate(
              new Date(data?.FireFightingDoc?.ValidityFrom)
            );
            setFiringDocExpiryDate(new Date(data?.FireFightingDoc?.ValidityTo));

            let tempGodownArr = [];
            if (
              data?.WareHouseGodownDetails.filter((x) => x.godownNo != "")
                .length
            ) {
              tempGodownArr = [
                ...data?.WareHouseGodownDetails.map((x) => {
                  return { ...x, isEdit: false, isNew: false };
                }),
                {
                  WareHouseGodownDetailId: "",
                  godownNo: "",
                  godownNoErr: false,
                  godownNoErrText: "",
                  cargoType: "",
                  cargoTypeErr: false,
                  cargoTypeErrText: "",
                  area: "",
                  areaErr: false,
                  areaErrText: "",
                  capacity: "",
                  capacityErr: false,
                  capacityErrText: "",
                  mt: "",
                  mtErr: false,
                  mtErrText: "",
                  isNew: true,
                  isEdit: true,
                },
              ];
            } else {
              tempGodownArr = godownArray;
            }

            setGodownArray([...tempGodownArr]);

            let tempWarehouseFile = data?.WareHouseFile
              ? data?.WareHouseFile
              : [];
            if (tempWarehouseFile) {
              let fileData = CommonConfig.base64toFile(
                tempWarehouseFile,
                CommonConfig.findFileNameFromURL(data?.WareHouseFileName)
              );
              var reader = new FileReader();
              var url = reader.readAsDataURL(fileData);
              reader.onloadend = function (e) { }.bind(this);
              if (fileData) {
                setWareHouseFile([fileData]);
              } else {
                setWareHouseFile([]);
              }
            }
            let tempInsuranceFile = data?.InsuranceFile
              ? data.InsuranceFile
              : [];
            if (tempInsuranceFile) {
              let fileData = CommonConfig.base64toFile(
                tempInsuranceFile,
                CommonConfig.findFileNameFromURL(data?.InsuranceFileName)
              );
              var reader = new FileReader();
              var url = reader.readAsDataURL(fileData);
              reader.onloadend = function (e) { }.bind(this);
              if (fileData) {
                setPolicyCopy([fileData]);
              } else {
                setPolicyCopy([]);
              }
            }
            let tempSolvencyDoc = data?.SolvencyDoc?.File
              ? data.SolvencyDoc?.File
              : [];
            if (tempSolvencyDoc) {
              let fileData = CommonConfig.base64toFile(
                tempSolvencyDoc,
                CommonConfig.findFileNameFromURL(data?.SolvencyDoc?.FileName)
              );
              var reader = new FileReader();
              var url = reader.readAsDataURL(fileData);
              reader.onloadend = function (e) { }.bind(this);
              if (fileData) {
                setSolvencyDoc([fileData]);
              } else {
                setSolvencyDoc([]);
              }
            }
            let tempFireFightingDoc = data?.FireFightingDoc?.File
              ? data.FireFightingDoc?.File
              : [];
            if (tempFireFightingDoc) {
              let fileData = CommonConfig.base64toFile(
                tempFireFightingDoc,
                CommonConfig.findFileNameFromURL(
                  data?.FireFightingDoc?.FileName
                )
              );
              var reader = new FileReader();
              var url = reader.readAsDataURL(fileData);
              reader.onloadend = function (e) { }.bind(this);
              if (fileData) {
                setFireFightingDoc([fileData]);
              } else {
                setFireFightingDoc([]);
              }
            }
          } else {
            Loader.hide();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const getAddressTypeList = async (UserId) => {
    try {
      let params = {
        UserId: UserId ? UserId : userId,
      };
      await api
        .post(APIConstant.path.GetLocationMasterList, params)
        .then(async (res) => {
          if (res.Success == 1) {
            setAddressTypeList(res?.Data);
          } else {
            setAddressTypeList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  //   const getCompanyTypeList = async (UserId) => {
  //     try {
  //       let params = {
  //         Type: "COMPANYTYPE",
  //         ID: UserId ? UserId : userId,
  //       };
  //       await api
  //         .post(APIConstant.path.GetMasterListByType, params)
  //         .then(async (res) => {
  //           if (res.Success === 1) {
  //             setTypeList(res?.Data);
  //             setType(res?.Data.filter((x) => x.label == "Private")[0]?.value);
  //           } else {
  //             setTypeList([]);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  const getInsuranceCompanyTypeList = async (UserId) => {
    try {
      let params = {
        UserId: UserId || userId,
      };
      await api
        .post(APIConstant.path.GetInsuranceCompanyMaster, params)
        .then(async (res) => {
          if (res.Success === 1) {
            setCompanyTypeList(res?.Data);
          } else {
            setCompanyTypeList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getHazardousTypeList = async (UserId) => {
    try {
      let params = {
        Type: "HAZARDOUSTYPE",
        ID: UserId ? UserId : userId,
      };
      await api
        .post(APIConstant.path.GetMasterListByType, params)
        .then(async (res) => {
          if (res.Success === 1) {
            setTypeList(
              res?.Data?.map((data) => ({
                ...data,
                label: data.name,
                value: data._id,
              }))
            );
          } else {
            setTypeList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const getWarehouseTypeList = async (UserId) => {
    try {
      let params = {
        Type: "WAREHOUSETYPE",
        ID: UserId ? UserId : userId,
      };
      await api
        .post(APIConstant.path.GetMasterListByType, params)
        .then(async (res) => {
          if (res.Success === 1) {
            setWarehouseTypeList(
              res?.Data.map((data) => ({
                ...data,
                label: data.name,
                value: data._id,
              }))
            );
          } else {
            setWarehouseTypeList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const getCargoTypeList = async (UserId) => {
    try {
      let params = {
        Type: "CARGOTYPE",
        ID: UserId ? UserId : userId,
      };
      await api
        .post(APIConstant.path.GetMasterListByType, params)
        .then(async (res) => {
          if (res.Success === 1) {
            setCargoTypeList(
              res?.Data.map((data) => ({
                ...data,
                label: data.name,
                value: data._id,
              }))
            );
          } else {
            setCargoTypeList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e, type) => {
    if (type === "eidNo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setEidNo("");
        setEidNoErr(true);
        setEidNoErrText("EID no is required");
      } else {
        setEidNo(e.target.value);
        setEidNoErr(false);
        setEidNoErrText("");
      }
    } else if (type === "wareHouseName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setWareHouseName("");
        setWareHouseNameErr(true);
        setWareHouseNameErrText("Ware house name is required");
      } else {
        setWareHouseName(e.target.value);
        setWareHouseNameErr(false);
        setWareHouseNameErrText("");
      }
    } else if (type === "area") {
      setArea(e.target.value);
    } else if (type === "address") {
      if (CommonConfig.isObjectEmpty(e)) {
        setAddress("");
        setAddressErr(true);
        setAddressErrText("Ware house name is required");
      } else {
        setAddress(e.value);
        setAddressErr(false);
        setAddressErrText("");
      }
    } else if (type === "wareHourseType") {
      if (CommonConfig.isObjectEmpty(e)) {
        setWareHourseType("");
        setWareHourseTypeErr(true);
        setWareHourseTypeErrText("Ware house type is required");
      } else {
        setWareHourseType(e.value);
        setWareHouseTypeLabel(e?.label);
        setWareHourseTypeErr(false);
        setWareHourseTypeErrText("");

        if (e?.label == "Normal") {
          setEidNo("PLIW" + CommonConfig.generateRandomString(5));
        } else {
          setEidNo("");
        }
      }
    } else if (type === "type") {
      if (CommonConfig.isObjectEmpty(e)) {
        setType("");
        setTypeErr(true);
        setTypeErrText("Type is required");
      } else {
        setType(e.value);
        setTypeErr(false);
        setTypeErrText("");
      }
    } else if (type === "policyNo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPolicyNo("");
        setPolicyNoErr(true);
        setPolicyNoErrText("Policy no is required");
      } else if (/^\d*$/i.test(e.target.value)) {
        setPolicyNo(e.target.value);
        setPolicyNoErr(false);
        setPolicyNoErrText("");
      }
    } else if (type === "company") {
      if (CommonConfig.isObjectEmpty(e)) {
        setCompany("");
        setCompanyErr(true);
        setCompanyErrText("Company name is required");
      } else {
        setCompany(e.value);
        setCompanyErr(false);
        setCompanyErrText("");
      }
    } else if (type === "validityFrom") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setValidityFrom("");
        setValidityFromErr(true);
        setValidityFromErrText("Validity from date is required");
      } else {
        setValidityFrom(e.target.value);
        setValidityFromErr(false);
        setValidityFromErrText("");
      }
    } else if (type === "validityTo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setValidityTo("");
        setValidityToErr(true);
        setValidityToErrText("Validity to date is required");
      } else {
        setValidityTo(e.target.value);
        setValidityToErr(false);
        setValidityToErrText("");
      }
    } else if (type === "amount") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setAmount("");
        setAmountErr(true);
        setAmountErrText("Amount is required");
      } else if (/^\d*\.?\d*$/.test(e.target.value)) {
        setAmount(e.target.value);
        setAmountErr(false);
        setAmountErrText("");
      }
    } else if (type === "issuingDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setIssuingDate("");
        setIssuingDateErr(true);
        setIssuingDateErrText("Issuing date is required");
      } else {
        setIssuingDate(e.target.value);
        setIssuingDateErr(false);
        setIssuingDateErrText("");
      }
    } else if (type === "expiryDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setExpiryDate("");
        setExpiryDateErr(true);
        setExpiryDateErrText("Expiry date is required");
      } else {
        setExpiryDate(e.target.value);
        setExpiryDateErr(false);
        setExpiryDateErrText("");
      }
    } else if (type === "firingDocissuingDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setFiringDocIssuingDate("");
        setFiringDocIssuingDateErr(true);
        setFiringDocIssuingDateErrText("Issuing document date is required");
      } else {
        setFiringDocIssuingDate(e.target.value);
        setFiringDocIssuingDateErr(false);
        setFiringDocIssuingDateErrText("");
      }
    } else if (type === "firingDocIssuingDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setFiringDocIssuingDate("");
        setFiringDocIssuingDateErr(true);
        setFiringDocIssuingDateErrText("Document issuing  date is required");
      } else {
        setFiringDocIssuingDate(e.target.value);
        setFiringDocIssuingDateErr(false);
        setFiringDocIssuingDateErrText("");
      }
    } else if (type === "firingDocExpiryDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setFiringDocExpiryDate("");
        setFiringDocExpiryDateErr(true);
        setFiringDocExpiryDateErrText("Document expiry date is required");
      } else {
        setFiringDocExpiryDate(e.target.value);
        setFiringDocExpiryDateErr(false);
        setFiringDocExpiryDateErrText("");
      }
    } else if (type === "masterLabelName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setMasterLabelName("");
        setMasterLabelNameErr(true);
        setMasterLabelNameErrText("Company name is required");
      } else {
        setMasterLabelName(e.target.value);
        setMasterLabelNameErr(false);
        setMasterLabelNameErrText("");
      }
    }
  };

  const validate = () => {
    let IsFormValid = true;
    if (CommonConfig.isEmpty(eidNo)) {
      IsFormValid = false;
      setEidNoErr(true);
      setEidNoErrText("EID no is required");
    } else {
      setEidNoErr(false);
      setEidNoErrText(";");
    }
    if (CommonConfig.isEmpty(wareHouseName)) {
      IsFormValid = false;
      setWareHouseNameErr(true);
      setWareHouseNameErrText("Ware house name is required");
    } else {
      setWareHouseNameErr(false);
      setWareHouseNameErrText("");
    }

    if (CommonConfig.isEmpty(address)) {
      IsFormValid = false;
      setAddressErr(true);
      setAddressErrText("Address is required");
    } else {
      setAddressErr(false);
      setAddressErrText("");
    }

    if (CommonConfig.isEmpty(wareHourseType)) {
      IsFormValid = false;
      setWareHourseTypeErr(true);
      setWareHourseTypeErrText("Ware house type is required");
    } else {
      setWareHourseTypeErr(false);
      setWareHourseTypeErrText("");
    }

    if (CommonConfig.isEmpty(type)) {
      IsFormValid = false;
      setTypeErr(true);
      setTypeErrText("Type is required");
    } else {
      setTypeErr(false);
      setTypeErrText("");
    }
    if (CommonConfig.isEmpty(policyNo)) {
      IsFormValid = false;
      setPolicyNoErr(true);
      setPolicyNoErrText("Policy no is required");
    } else {
      setPolicyNoErr(false);
      setPolicyNoErrText("");
    }
    if (CommonConfig.isEmpty(company)) {
      IsFormValid = false;
      setCompanyErr(true);
      setCompanyErrText("Company name is required");
    } else {
      setCompanyErr(false);
      setCompanyErrText("");
    }
    if (CommonConfig.isEmpty(validityFrom)) {
      IsFormValid = false;
      setValidityFromErr(true);
      setValidityFromErrText("Validity start date is required");
    } else {
      setValidityFromErr(false);
      setValidityFromErrText("");
    }
    if (CommonConfig.isEmpty(validityTo)) {
      IsFormValid = false;
      setValidityToErr(true);
      setValidityToErrText("Validity end date is required");
    } else {
      setValidityToErr(false);
      setValidityToErrText("");
    }
    if (CommonConfig.isEmpty(amount)) {
      IsFormValid = false;
      setAmountErr(true);
      setAmountErrText("Amount is required");
    } else {
      setAmountErr(false);
      setAmountErrText("");
    }
    if (CommonConfig.isEmpty(issuingDate)) {
      IsFormValid = false;
      setIssuingDateErr(true);
      setIssuingDateErrText("Issuing date is required");
    } else {
      setIssuingDateErr(false);
      setIssuingDateErrText("");
    }
    if (CommonConfig.isEmpty(expiryDate)) {
      IsFormValid = false;
      setExpiryDateErr(true);
      setExpiryDateErrText("Expiry date is required");
    } else {
      setExpiryDateErr(false);
      setExpiryDateErrText("");
    }
    if (CommonConfig.isEmpty(firingDocIssuingDate)) {
      IsFormValid = false;
      setFiringDocIssuingDateErr(true);
      setFiringDocIssuingDateErrText("Issuing date is required");
    } else {
      setFiringDocIssuingDateErr(false);
      setFiringDocIssuingDateErrText("");
    }
    if (CommonConfig.isEmpty(firingDocExpiryDate)) {
      IsFormValid = false;
      setFiringDocExpiryDateErr(true);
      setFiringDocExpiryDateErrText("Expiry date is required");
    } else {
      setFiringDocExpiryDateErr(false);
      setFiringDocExpiryDateErrText("");
    }
    return IsFormValid;
  };

  const handleFile = (fileItems, type) => {
    if (type == "wareHouseFile") {
      if (wareHouseFile.length < 2) {
        let files = fileItems[0];
        if (files?.fileSize < 2048000 || files?.fileSize == 2048000) {
          setWareHouseFileErr(false);
          setWareHouseFileErrText("");
        } else {
          if (files) {
            cogoToast.error("File size should be less than 2 MB");
          }
          setWareHouseFile(wareHouseFile);
          setWareHouseFileErr(true);
          // setWareHouseFileErrText("File size should be less than 2 MB");
        }
        setWareHouseFile(wareHourseTypeErr ? wareHouseFile : fileItems);
      } else {
        cogoToast.error("Maximum 1 File is allowed.");
        setWareHouseFile(wareHouseFile);
      }
    } else if (type == "policyCopy") {
      if (policyCopy.length < 2) {
        let files = fileItems[0];
        if (files?.fileSize < 5120000 || files?.fileSize == 5120000) {
          setPolicyCopyErr(false);
          setPolicyCopyErrText("");
        } else {
          if (files) {
            cogoToast.error("File size should be less than 5 MB");
          }
          setPolicyCopy(policyCopy);
          setPolicyCopyErr(true);
          // setPolicyCopyErrText("File size should be less than 5 MB");
        }
        setPolicyCopy(policyCopyErr ? policyCopy : fileItems);
      } else {
        cogoToast.error("Maximum 1 file is allowed.");
        setPolicyCopy(policyCopy);
      }
    } else if (type == "solvencyDoc") {
      if (solvencyDoc.length < 2) {
        let files = fileItems[0];
        if (files?.fileSize < 2048000 || files?.fileSize == 2048000) {
          setSolvencyDocErr(false);
          setSolvencyDocErrText("");
        } else {
          if (files) {
            cogoToast.error("File size should be less than 2 MB");
          }
          setSolvencyDocErr(true);
          // setSolvencyDocErrText("File size should be less than 2 MB");
          setSolvencyDoc(solvencyDoc);
        }
        setSolvencyDoc(solvencyDocErr ? solvencyDoc : fileItems);
      } else {
        cogoToast.error("Maximum 1 file is allowed.");
        setSolvencyDoc(solvencyDoc);
      }
    } else if (type == "fireFightingDoc") {
      if (fireFightingDoc.length < 2) {
        let files = fileItems[0];
        if (files?.fileSize < 2048000 || files?.fileSize == 2048000) {
          setFireFightingDocErr(false);
          setFireFightingDocErrText("");
        } else {
          if (files) {
            cogoToast.error("File size should be less than 2 MB");
          }
          setFireFightingDocErr(true);
          // setFireFightingDocErrText("File size should be less than 2 MB");
          setFireFightingDoc(fireFightingDoc);
        }
        setFireFightingDoc(fireFightingDocErr ? fireFightingDoc : fileItems);
      } else {
        cogoToast.error("Maximum 1 file is allowed.");
        setFireFightingDoc(fireFightingDoc);
      }
    }
  };

  const godownNoTemplate = (rowData, props) => {
    return rowData.isEdit ? (
      <div className="input-control ipt-with-icon ship-reason-input">
        <CustomInput
          // labelText='CBM'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => {
              let tempList = godownArray;
              tempList[props.rowIndex]["godownNo"] = e.target.value;
              setGodownArray([...tempList]);
            },
            type: "text",
            id: "godownNo",
            name: "godownNo",
            value: rowData.godownNo,
          }}
        />
      </div>
    ) : (
      <div className="input-control ipt-with-icon ship-reason-input p-mt-2 p-pt-2">
        <CustomInput
          // labelText='CBM'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => {
              let tempList = godownArray;
              tempList[props.rowIndex]["godownNo"] = e.target.value;
              setGodownArray([...tempList]);
            },
            type: "text",
            id: "godownNo",
            name: "godownNo",
            value: rowData.godownNo,
            disabled: !rowData.isEdit,
          }}
        />
      </div>
    );
  };
  const cargoTypeTemplate = (rowData, props) => {
    return rowData.isEdit ? (
      <div className="p-d-flex p-ai-end p-pt-2">
        <Autocomplete
          id="tags-standard"
          value={
            rowData.cargoType && cargoTypeList.length
              ? cargoTypeList.filter((x) => x.value == rowData.cargoType)[0]
              : ""
          }
          options={cargoTypeList}
          getOptionLabel={(option) => (option.label ? option.label : "")}
          onChange={(e, value) => {
            let tempList = godownArray;
            tempList[props.rowIndex]["cargoType"] = value?.value;
            setGodownArray([...tempList]);
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </div>
    ) : (
      <div className="p-d-flex p-ai-end p-mt-2 p-pt-1">
        <Autocomplete
          id="tags-standard"
          disabled={!rowData.isEdit}
          value={
            rowData.cargoType && cargoTypeList.length
              ? cargoTypeList.filter((x) => x.value == rowData.cargoType)[0]
              : ""
          }
          options={cargoTypeList}
          getOptionLabel={(option) => (option.label ? option.label : "")}
          onChange={(e, value) => {
            let tempList = godownArray;
            tempList[props.rowIndex]["cargoType"] = value?.value;
            setGodownArray([...tempList]);
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </div>
    );
  };
  const areaTemplate = (rowData, props) => {
    return rowData.isEdit ? (
      <div className="input-control ipt-with-icon ship-reason-input">
        <CustomInput
          // labelText='CBM'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => {
              let tempList = godownArray;
              tempList[props.rowIndex]["area"] = e.target.value;
              setGodownArray([...tempList]);
            },
            type: "text",
            id: "area",
            name: "area",
            value: rowData.area,
          }}
        />
      </div>
    ) : (
      <div className="input-control ipt-with-icon ship-reason-input p-mt-2 p-pt-2">
        <CustomInput
          // labelText='CBM'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => {
              let tempList = godownArray;
              tempList[props.rowIndex]["area"] = e.target.value;
              setGodownArray([...tempList]);
            },
            type: "text",
            id: "area",
            name: "area",
            value: rowData.area,
            disabled: !rowData.isEdit,
          }}
        />
      </div>
    );
  };
  const capacityTemplate = (rowData, props) => {
    return rowData.isEdit ? (
      <div className="input-control ipt-with-icon ship-reason-input">
        <CustomInput
          // labelText='CBM'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => {
              let tempList = godownArray;
              tempList[props.rowIndex]["capacity"] = e.target.value;
              setGodownArray([...tempList]);
            },
            type: "text",
            id: "capacity",
            name: "capacity",
            value: rowData.capacity,
          }}
        />
      </div>
    ) : (
      <div className="input-control ipt-with-icon ship-reason-input p-mt-2 p-pt-2">
        <CustomInput
          // labelText='CBM'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => {
              let tempList = godownArray;
              tempList[props.rowIndex]["capacity"] = e.target.value;
              setGodownArray([...tempList]);
            },
            type: "text",
            id: "capacity",
            name: "capacity",
            value: rowData.capacity,
            disabled: !rowData.isEdit,
          }}
        />
      </div>
    );
  };
  const SrNoBody = (data, props) => {
    return props.rowIndex + 1;
  };
  const ActionBody = (rowData, props) => {
    return (
      <>
        {rowData.isEdit ? (
          <div className="p-d-flex p-ai-end p-mt-2 p-pt-2">
            {rowData.isNew ? (
              <button
                className="action-button cursor-pointer"
                onClick={(e) => {
                  addEditGodownDetails(props.rowIndex);
                }}
              >
                <i className="icon-add"></i>{" "}
              </button>
            ) : (
              <>
                <button
                  className="action-button cursor-pointer"
                  onClick={(e) => {
                    addEditGodownDetails(props.rowIndex);
                  }}
                >
                  <i className="icon-correct"></i>{" "}
                </button>
                <button
                  className="action-button p-ml-2 cursor-pointer"
                  onClick={(e) => {
                    resetGodownDetails(props.rowIndex);
                  }}
                >
                  <i className="icon-cancel"></i>{" "}
                </button>
              </>
            )}
          </div>
        ) : (
          <div className="p-d-flex p-ai-end p-pt-2">
            <button
              className="action-button cursor-pointer"
              onClick={(e) => {
                editGodownDetails(e, props.rowIndex);
              }}
            >
              <i className="icon-editImage"></i>{" "}
            </button>
            <button
              className="action-button p-ml-2 cursor-pointer"
              onClick={(e) => {
                deleteGodownDetails(props.rowIndex);
              }}
            >
              <i className="icon-delete"></i>{" "}
            </button>
          </div>
        )}
      </>
    );
  };

  const addEditGodownDetails = (idx) => {
    if (godownArray[idx].godownNo != "") {
      let isEdit = godownArray[idx].isEdit;
      let isNew = godownArray[idx].isNew;
      if (isNew && isEdit) {
        let tempList = godownArray;
        tempList[idx]["isNew"] = false;
        tempList[idx]["isEdit"] = false;
        let newArray = [
          ...tempList.map((x) => {
            return { ...x };
          }),
          {
            godownNo: "",
            godownNoErr: false,
            godownNoErrText: "",
            cargoType: "",
            cargoTypeErr: false,
            cargoTypeErrText: "",
            area: "",
            areaErr: false,
            areaErrText: "",
            capacity: "",
            capacityErr: false,
            capacityErrText: "",
            isNew: true,
            isEdit: true,
          },
        ];

        let tempIEList = duplicateGodownArray;
        tempIEList[idx] = tempList[idx];

        setGodownArray(newArray);
        setDuplicateGodownArray([
          ...tempIEList.map((x) => {
            return { ...x };
          }),
          {
            godownNo: "",
            godownNoErr: false,
            godownNoErrText: "",
            cargoType: "",
            cargoTypeErr: false,
            cargoTypeErrText: "",
            area: "",
            areaErr: false,
            areaErrText: "",
            capacity: "",
            capacityErr: false,
            capacityErrText: "",
            isNew: true,
            isEdit: true,
          },
        ]);
      } else {
        let tempList = godownArray;
        tempList[idx]["isEdit"] = false;
        let newArray = [
          ...tempList.map((x) => {
            return { ...x };
          }),
        ];
        let tempIEList = duplicateGodownArray;
        tempIEList[idx] = tempList[idx];
        setGodownArray(newArray);
        setDuplicateGodownArray([
          ...tempIEList.map((x) => {
            return { ...x };
          }),
        ]);
      }
    } else {
      cogoToast.info("Please fill the fields ");
    }
  };
  const resetGodownDetails = (idx) => {
    let tempGodownList = duplicateGodownArray;
    let tempList = godownArray;

    tempList[idx] = tempGodownList[idx];
    tempList[idx]["isEdit"] = false;
    setGodownArray(tempList);
  };
  const editGodownDetails = (e, idx) => {
    let tempList = godownArray;
    tempList = tempList.map((x, indx) => {
      return { ...x, isEdit: indx == idx ? true : false };
    });
    setGodownArray(tempList);
  };
  const deleteGodownDetails = (idx) => {
    let List = godownArray;
    let tempArr = List.splice(idx, 1);
    setGodownArray(List);
  };

  const handleWareHouse = (e) => {
    try {
      if (validate()) {
        // Loader.show();
        let data = {
          WareHouseMasterId: wareHouseMasterId,
          EdiNo: eidNo,
          WareHouseName: wareHouseName,
          Area: area,
          Address: address,
          WareHouseType: wareHourseType,
          Type: type,
          PolicyNo: policyNo,
          Company: company,
          ValidityFrom: validityFrom,
          ValidityTo: validityTo,
          Amount: amount,
          IssuingDate: issuingDate,
          ExpiryDate: expiryDate,
          FiringDocIssuingDate: firingDocIssuingDate,
          FiringDocExpiryDate: firingDocExpiryDate,
          UserId: userId,
          GodownArray: godownArray.filter((x) => x.isNew != true).length
            ? godownArray.filter((x) => x.isNew != true)
            : [],
        };
        var formData = new FormData();
        formData.append("Data", JSON.stringify(data));
        for (let i = 0; i < wareHouseFile.length; i++) {
          let file = wareHouseFile[i].file;
          formData.append("WareHouseFile", file);
        }
        for (let i = 0; i < policyCopy.length; i++) {
          let file = policyCopy[i].file;
          formData.append("PolicyCopy", file);
        }
        for (let i = 0; i < solvencyDoc.length; i++) {
          let file = solvencyDoc[i].file;
          formData.append("SolvencyDoc", file);
        }
        for (let i = 0; i < fireFightingDoc.length; i++) {
          let file = fireFightingDoc[i].file;
          formData.append("FireFightingDoc", file);
        }

        api
          .post(APIConstant.path.AddUpdateWareHouse, formData, {
            headers: {
              "Content-Type": "mulitipart/form-data",
            },
          })
          .then((res) => {
            if (res.Success) {
              Loader.hide();
              handleClose("AddWareHose");
              props.history.push("/admin/ware-house");
              cogoToast.success(res.Message);
            } else {
              Loader.hide();
              cogoToast.error(res.Message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleClose = (type) => {
    if (type == "AddWareHouse") {
      setEidNo("");
      setEidNoErr(false);
      setEidNoErrText("");

      setWareHouseFile("");
      setWareHouseFileErr(false);
      setWareHouseFileErrText("");

      setWareHouseName("");
      setWareHouseNameErr(false);
      setWareHouseNameErrText("");

      setAddress("");
      setAddressErr(false);
      setAddressErrText("");

      setWareHourseType("");
      setWareHourseTypeErr(false);
      setWareHourseTypeErrText("");

      setType("");
      setTypeErr(false);
      setTypeErrText("");

      setPolicyNo("");
      setPolicyNoErr(false);
      setPolicyNoErrText("");

      setCompany("");
      setCompanyErr(false);
      setCompanyErrText("");

      setValidityFrom("");
      setValidityFromErr(false);
      setValidityFromErrText("");

      setValidityTo("");
      setValidityToErr(false);
      setValidityToErrText("");

      setAmount("");
      setAmountErr(false);
      setAmountErrText("");

      setPolicyCopy("");
      setPolicyCopyErr(false);
      setPolicyCopyErrText("");

      setSolvencyDoc("");
      setSolvencyDocErr(false);
      setSolvencyDocErrText("");

      setIssuingDate("");
      setIssuingDateErr(false);
      setIssuingDateErrText("");

      setExpiryDate("");
      setExpiryDateErr(false);
      setExpiryDateErrText("");

      setFireFightingDoc("");
      setFireFightingDocErr(false);
      setFireFightingDocErrText("");

      setFiringDocIssuingDate("");
      setFiringDocIssuingDateErr(false);
      setFiringDocIssuingDateErrText("");

      setFiringDocExpiryDate("");
      setFiringDocExpiryDateErr(false);
      setFiringDocExpiryDateErrText("");

      setDuplicateGodownArray("");
      setWareHouseMasterId("");
    } else if (type == "AddMaster") {
      setAddMasterModal(false);
      setMasterLabelName("");
      setMasterLabelNameErr(false);
      setMasterLabelNameErrText("");
      setMasterId("");
    }
  };
  const handleOpen = (e, type) => {
    if (type == "addMasterModal") {
      setAddMasterModal(true);
    }
  };
  const validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(masterLabelName)) {
      formIsValid = false;
      setMasterLabelNameErr(true);
      setMasterLabelNameErrText("Company name is required");
    } else {
      setMasterLabelNameErr(false);
      setMasterLabelNameErrText(";");
    }
    return formIsValid;
  };

  const AddMaster = async () => {
    if (validation()) {
      Loader.show();

      let params = {
        CompanyName: masterLabelName,
        UserID: userId,
        MasterId: masterId,
      };
      await api
        .post(APIConstant.path.AddUpdateInsuranceCompany, params)
        .then(async (res) => {
          if (res.Success == 1) {
            Loader.hide();
            handleClose("AddMaster");
            getInsuranceCompanyTypeList(userId);
            cogoToast.success(res.Message);
          } else {
            Loader.hide();
            cogoToast.error(res.Message);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="wayment-owner-dashboard e-weighment-dashoard">
      <div className="heading-area p-d-flex p-ai-center p-jc-between p-mb-2">
        <div
          className="p-d-flex p-ai-center p-jc-between"
          onClick={() => {
            props.history.push("/admin/ware-house");
          }}
        >
          <i className="pi pi-chevron-left" />
          <h2>Add Warehouse Master</h2>
        </div>

        <div className="heading-area-right">
          {/* {userPageAccess.includes('Add Vessel Master') ? */}
          <div>
            <div className="add-btn1" onClick={(e) => handleWareHouse(e)}>
              <i className="icon-add"></i> Submit
            </div>
          </div>
          {/* : null} */}
        </div>
      </div>
      <hr />
      <div className="main-access-card-master">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <div className="input-control select p-d-flex p-pt-2">
              <FormControl fullWidth>
                <Autocomplete
                  id="tags-standard"
                  value={
                    wareHourseType && warehouseTypeList.length
                      ? warehouseTypeList.filter(
                        (x) => x.value == wareHourseType
                      )[0]
                      : ""
                  }
                  options={warehouseTypeList}
                  // getOptionLabel={option => option.label ? option.label : option}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  onChange={(e, value) => handleChange(value, "wareHourseType")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Ware House Type"
                      error={wareHourseTypeErr}
                      helperText={wareHourseTypeErrText}
                      label="Select Ware House Type"
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <TextField
              className="p-mt-1 p-pt-1"
              autoFocus
              margin="dense"
              name="Area"
              label="Area"
              type="text"
              value={area}
              onChange={(e) => handleChange(e, "area")}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <TextField
              disabled={wareHouseTypeLabel == "Normal" ? true : false}
              autoFocus
              margin="dense"
              name="Unit"
              // id="chaNo"
              label="EDI No*"
              type="text"
              value={eidNo}
              onChange={(e) => handleChange(e, "eidNo")}
              error={eidNoErr}
              helperText={eidNoErrText}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <TextField
              autoFocus
              margin="dense"
              name="Unit"
              // id="chaNo"
              label="Ware House Name*"
              type="text"
              value={wareHouseName}
              onChange={(e) => handleChange(e, "wareHouseName")}
              error={wareHouseNameErr}
              helperText={wareHouseNameErrText}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </GridItem>
          {/* <GridItem xs={12} sm={6} md={3}>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="Unit"
                            // id="chaNo"
                            label="Address*"
                            type="text"
                            value={address}
                            onChange={(e) => handleChange(e, "address")}
                            error={addressErr}
                            helperText={addressErrText}
                            fullWidth
                        />
                    </GridItem> */}
          <GridItem xs={12} sm={6} md={4}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <Autocomplete
                  id="tags-standard"
                  value={
                    address && addressTypeList?.length
                      ? addressTypeList?.filter((x) => x.value == address)[0]
                      : ""
                  }
                  options={addressTypeList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  onChange={(e, value) => handleChange(value, "address")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Address"
                      error={addressErr}
                      helperText={addressErrText}
                      label="Select Address"
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <Autocomplete
                  id="tags-standard"
                  value={
                    type && typeList.length
                      ? typeList.filter((x) => x.value == type)[0]
                      : ""
                  }
                  options={typeList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  onChange={(e, value) => handleChange(value, "type")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Type"
                      error={typeErr}
                      helperText={typeErrText}
                      label="Select Type"
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>

        </GridContainer>
        <div className="p-d-flex p-jc-center p-mt-4">
          <div className="p-w-50">
            <FilePond
              files={wareHouseFile}
              allowMultiple={false}
              acceptedFileTypes={["application/pdf", "jpg"]}
              maxFileSize="2MB"
              onupdatefiles={(fileItems) => {
                handleFile(fileItems, "wareHouseFile");
              }}
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
          </div>
        </div>

        <div>
          <div className="heading-area">
            <h2>Insurance Details</h2>
          </div>
          <hr />
          <InsuranceDetails
            values={insuranceData}
            handleChange={handleChange}
            handleFile={handleFile}
            handleOpen={handleOpen}
          />
        </div>

        <div>
          <div className="heading-area">
            <h2>Solvency Doc</h2>
          </div>
          <hr />
          <SolvencyDetails
            values={solvencyData}
            handleChange={handleChange}
            handleFile={handleFile}
          />
        </div>

        <div>
          <div className="heading-area">
            <h2>Fire Fighting Doc</h2>
          </div>
          <hr />
          <FireFightingDetails
            values={fireFightingData}
            handleChange={handleChange}
            handleFile={handleFile}
          />
        </div>

        <div>
          <div className="heading-area">
            <h2>Godown Details</h2>
          </div>
          <hr />
          <GodownDetails
            values={godownArray}
            godownNoTemplate={godownNoTemplate}
            cargoTypeTemplate={cargoTypeTemplate}
            areaTemplate={areaTemplate}
            capacityTemplate={capacityTemplate}
            SrNoBody={SrNoBody}
            ActionBody={ActionBody}
          />
        </div>
      </div>
      <Dialog
        visible={addMasterModal}
        onHide={() => handleClose("AddMaster")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Add Company</div>
        </DialogTitle>
        <DialogContent className="CHA-dialog-content-area">
          <GridContainer>
            <GridItem md={12}>
              <TextField
                autoFocus
                margin="dense"
                name="Company"
                // id="chaNo"
                label="Company*"
                type="text"
                value={masterLabelName}
                onChange={(e) => handleChange(e, "masterLabelName")}
                error={masterLabelNameErr}
                helperText={masterLabelNameErrText}
                inputProps={{ maxLength: 50 }}
                fullWidth
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("AddMaster")}
          >
            Close
          </Button>
          <Button
            className="primary-btn"
            onClick={(e) => AddMaster(e)}
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddWareHouse;
