import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../../CommonConfig.js";
import SimpleBackdrop from "../../../utils/general.js";
import api from "../../../utils/api.js";
import cogoToast from "cogo-toast";
import APIConstant from "../../../utils/constant.js";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { Dialog } from "primereact/dialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import CustomInput from "components/CustomInput/CustomInput";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "react-datetime";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { MultiSelect } from "primereact/multiselect";
import "date-fns";
import { apiBase } from "config.js";
import { ColorPicker } from "primereact/colorpicker";
import Checkbox from "@material-ui/core/Checkbox";
import { Calendar } from "primereact/calendar";

const ViewWarehouse = (props) => {
  let [userId, setUserId] = useState("");
  let [wareHouseId, setWareHouseId] = useState("");
  let [wareHouseList, setWareHouseList] = useState([]);
  let [userPageAccess, setUserPageAccess] = useState([]);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      if (props?.location?.state && props?.location?.state?.WareHouseId) {
        getWareHouseById(props?.location?.state?.WareHouseId);
      }
    } else {
      props.history.push("/login");
    }
    // } else {
    //     props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getWareHouseById = async (ID) => {
    try {
      Loader.show();
      let data = {
        ID: ID,
      };

      await api
        .post(APIConstant.path.GetWareHouseDetailById, data)
        .then((response) => {
          if (response.Success == 1) {
            Loader.hide();
            setWareHouseList(response?.Data);
          } else {
            Loader.hide();
            setWareHouseList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="wayment-owner-dashboard e-weighment-dashoard p-px-4">
      <div className="heading-area p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-ai-center"  onClick={() => {
            props.history.push("/admin/ware-house");
          }}>
          <i className="pi pi-chevron-left"></i>
          <h2>Warehouse Detail</h2>
        </div>
        <hr />
        {/* <div className="heading-area-right">
                    <div className="searchbar-area p-as-center">
                        <InputText type="search" onInput={(e) => setSearch(e.target.value)} placeholder="Global Search" size="30" style={{ marginRight: 10 }} />
                        <i className="icon-search"></i>
                    </div>
                    {userPageAccess.includes('Add Ware House') ?
                        <div>
                            <div className="add-btn1" onClick={(e) => props.history.push("add-ware-house")}>
                                <i className="icon-add"></i> Add WareHouse Master
                            </div>
                        </div>
                        : null}
                </div> */}
      </div>
      <hr />
      <div className="shipping-info p-mt-3">
        {/* {loading === true ?
                    <div className="loading">
                        <SimpleBackdrop />
                    </div>
                    : null} */}

        <GridContainer>

          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">EDI No</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.EdiNo)
                  ? "-"
                  : wareHouseList?.EdiNo}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Warehouse Doc</p>
              <a className="e-main-detail-value"
                href={
                  wareHouseList?.WareHouseFile
                    ? apiBase + wareHouseList.WareHouseFile
                    : "#"
                }
                without
                rel="noopener noreferrer"
                target="_blank"
              >
                <button type="button" p="View File" />
              </a>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Warehouse Name</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.WareHouseName)
                  ? "-"
                  : wareHouseList?.WareHouseName}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Warehouse Type</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.WareHouseTypeName)
                  ? "-"
                  : wareHouseList?.WareHouseTypeName}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Type</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.TypeName)
                  ? "-"
                  : wareHouseList?.TypeName}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Address</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.Address)
                  ? "-"
                  : wareHouseList?.Address}
              </p>
            </div>
          </GridItem>
          {/* file */}
          {/* Insurance details */}
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Policy No</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.PolicyNo)
                  ? "-"
                  : wareHouseList?.PolicyNo}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Insurance Doc</p>
              <a className="e-main-detail-value"
                href={
                  wareHouseList?.InsuranceFile
                    ? apiBase + wareHouseList?.InsuranceFile
                    : "#"
                }
                without
                rel="noopener noreferrer"
                target="_blank"
              >
                <button type="button" p="View File" />
              </a>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Company</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.InsuranceCompanyName)
                  ? "-"
                  : wareHouseList?.InsuranceCompanyName}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Insurance Validity From</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.InsuranceValidityFrom)
                  ? "-"
                  : wareHouseList?.InsuranceValidityFrom}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Insurance Validity To</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.InsuranceValidityTo)
                  ? "-"
                  : wareHouseList?.InsuranceValidityTo}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Amount test</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.InsuranceAmount)
                  ? "-"
                  : wareHouseList?.InsuranceAmount}
              </p>
            </div>
          </GridItem>

          {/* file */}
          {/* solvency details  */}
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Solvency Doc</p>
              <a className="e-main-detail-value"
                href={
                  wareHouseList?.SolvencyDoc?.File
                    ? apiBase + wareHouseList.SolvencyDoc.File
                    : "#"
                }
                without
                rel="noopener noreferrer"
                target="_blank"
              >
                <button type="button" p="View File" />
              </a>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Solvency Validity From</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.SolvencyValidityFrom)
                  ? "-"
                  : wareHouseList?.SolvencyValidityFrom}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Solvency Validity To</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.SolvencyValidityTo)
                  ? "-"
                  : wareHouseList?.SolvencyValidityTo}
              </p>
            </div>
          </GridItem>
          {/* Fire fighting details  */}
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Fire Fighting Doc</p>
              <a className="e-main-detail-value"
                href={
                  wareHouseList?.FireFightingDoc?.File
                    ? apiBase + wareHouseList.FireFightingDoc.File
                    : "#"
                }
                without
                rel="noopener noreferrer"
                target="_blank"
              >
                <button type="button" p="View File" />
              </a>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Fire Fighting Validity From</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.FireFightingValidityFrom)
                  ? "-"
                  : wareHouseList?.FireFightingValidityFrom}
              </p>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <div className="e-main-detail">
              <p className="e-main-detail-label">Fire Fighting Validity To</p>
              <p className="e-main-detail-value">
                {CommonConfig.isEmpty(wareHouseList?.FireFightingValidityTo)
                  ? "-"
                  : wareHouseList?.FireFightingValidityTo}
              </p>
            </div>
          </GridItem>

        </GridContainer>
        {/* file  */}

        <div>
          <div className="heading-area p-d-flex p-ai-center p-jc-between p-pb-2 p-mt-3">
            <h2 className="p-m-0">Godown details</h2>
          </div>
          <hr />
          <div className="e-ship-pdf-table">
            <div className="e-warehouse-table">
                <p><strong>Godown No</strong></p>
                <p><strong>Cargo Type</strong></p>
                <p><strong>Area</strong></p>
                <p><strong>Capacity</strong></p>
            </div>

            {wareHouseList?.WareHouseGodownDetails?.length
              ? wareHouseList?.WareHouseGodownDetails?.map((x) => {
                return (
                  <div className="e-contain-pdf-table">
                      <p>{x.godownNo}</p>
                      <p>{x.CargoType}</p>
                      <p>{x.area}</p>
                      <p>{x.capacity}</p>
                   
                  </div>
                );
              })
              : null}
          </div>

        </div>
      </div>
    </div>

  );
};

export default ViewWarehouse;
