import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";

class CountryMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryMasterId: "",
      loading: false,
      UserId: "",
      Token: "",
      GetCountryMasterList: [],
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      search: null,

      countryName: "",
      countryNameErr: false,
      countryNameErrText: "",

      AddCountryMasterModal: false,
      EditShiftMasterTypeModal: false,
      DeleteCountryMasterModal: false,
      userPageAccess: [],
    };
  }

  componentDidMount = async () => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      this.setState({
        UserId: data,
        Token: token,
        userPageAccess: userPageAccess,
      });
      await this.GetCountryMasterList(data);
    } else {
      this.props.history.push("/login");
    }
    // } else {
    //   this.props.history.push("/auth/lockscreen");
    // }
  };

  actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {this.state.userPageAccess.includes("Edit Country") ? (
          <div onClick={(e) => this.handleOpen(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {this.state.userPageAccess.includes("Delete Country") ? (
          <div onClick={(e) => this.handleOpen(rowData, "DeleteCountryMaster")}>
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  GetCountryMasterList = async (UserId) => {
    let params = {
      SecurityUserID: UserId,
    };
    api
      .post(APIConstant.path.GetCountryMaster, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin");
          this.setState({ GetCountryMasterList: filterArry });
        }
      })
      .catch((err) => {});
  };

  handleOpen = (item, type) => {
    if (type === "AddUpdateCountryMaster") {
      this.setState({ AddCountryMasterModal: true });
    } else if (type === "EditMasterType") {
      this.setState({
        AddCountryMasterModal: true,
        countryName: item.CountryName,
        countryMasterId: item.CountryMasterId,
      });
    } else if (type === "DeleteCountryMaster") {
      this.setState({
        DeleteCountryMasterModal: true,
        countryMasterId: item.CountryMasterId,
      });
    }
  };

  handleClose = (type) => {
    if (type === "AddUpdateCountryMaster") {
      this.setState({
        AddCountryMasterModal: false,
        countryName: "",
        countryNameErr: false,
        countryNameErrText: "",
        countryMasterId: "",
      });
    } else if (type === "EditMasterType") {
      this.setState({ EditShiftMasterTypeModal: false });
    } else if (type === "DeleteCountryMaster") {
      this.setState({ DeleteCountryMasterModal: false, countryMasterId: "" });
    }
  };

  handleChange = (e, type) => {
    if (type === "countryName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          countryName: e.target.value,
          countryNameErr: true,
          countryNameErrText: `Please enter country name.`,
        });
      } else {
        this.setState({
          countryName: e.target.value,
          countryNameErr: false,
          countryNameErrText: "",
        });
      }
    }
  };

  validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.countryName)) {
      this.setState({
        countryName: "",
        countryNameErr: true,
        countryNameErrText: "Country name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ countryNameErr: false, countryNameErrText: "" });
    }
    return formIsValid;
  };

  AddUpdateCountryMaster = () => {
    if (this.validation()) {
      this.setState({ loading: true });

      let params = {
        CountryName: this.state.countryName,
        UserID: this.state.UserId,
        CountryMasterId: this.state.countryMasterId,
      };
      api.post(APIConstant.path.AddUpdateCountryMaster, params).then((res) => {
        if (res.Success == 1) {
          this.setState({
            loading: false,
            AddCountryMasterModal: false,
            countryName: "",
            countryNameErr: false,
            countryNameErrText: "",
            countryMasterId: "",
          });
          this.GetCountryMasterList(this.state.UserId);
          cogoToast.success(res.Message);
        } else {
          this.setState({ loading: false, AddCountryMasterModal: false });
          cogoToast.error(res.Message);
        }
      });
    }
  };

  DeleteCountryMaster = () => {
    this.setState({ loading: true });
    let params = {
      ID: this.state.countryMasterId,
      UserID: this.state.UserId,
    };
    api.post(APIConstant.path.DeleteCountryMaster, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          DeleteCountryMasterModal: false,
          countryMasterId: "",
        });
        this.GetCountryMasterList(this.state.UserId);
        cogoToast.success(res.Message);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  render() {
    const {
      UserId,
      search,
      userPageAccess,
      GetCountryMasterList,
      MasterLabelName,
      MasterLabelNameErr,
      MasterLabelNameErrText,
      AddCountryMasterModal,
      EditShiftMasterTypeModal,
      DeleteCountryMasterModal,
      countryMasterId,
    } = this.state;

    let filterGetCountryMasterList = [];
    if (GetCountryMasterList.length) {
      filterGetCountryMasterList = GetCountryMasterList;
      if (search) {
        filterGetCountryMasterList = filterGetCountryMasterList.filter((x) => {
          return (
            x.CountryName.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        });
      }
    }

    return (
      <div className="wayment-owner-dashboard e-weighment-dashoard">
        <div className="heading-area p-d-flex p-ai-center p-jc-between">
          <div className="p-d-flex p-ai-center">
            <i className="pi pi-box"></i>
            <h2>Country Master</h2>
          </div>
          <div className="p-d-flex p-ai-center wb-filter-btn">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                onInput={(e) => this.setState({ search: e.target.value })}
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            {userPageAccess.includes("Add Country") ? (
              <div>
                <div
                  className="add-btn1"
                  onClick={(e) => this.handleOpen(e, "AddUpdateCountryMaster")}
                >
                  <i className="icon-add"></i> Add Country
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="main-access-card-master">
          {this.state.loading === true ? (
            <div className="loading">
              <SimpleBackdrop />
            </div>
          ) : null}

          <DataTable
            className="main-table table-td"
            value={filterGetCountryMasterList}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={true}
            rows={this.state.rowsPerPage}
            totalRecords={filterGetCountryMasterList.length}
            rowsPerPageOptions={this.state.rowsPerPageOptions}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={this.actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="CountryName"
              header="Country Name"
              style={{ width: "20%" }}
            ></Column>

            <Column
              field="Action"
              header="Action"
              body={this.actionTemplate}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
          </DataTable>

          {/* Add Edit BL Type */}
          <Dialog
            open={AddCountryMasterModal}
            onClose={() => this.handleClose("AddUpdateCountryMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>{countryMasterId !== "" ? "Edit" : "Add"} Country </div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("AddUpdateCountryMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <GridContainer>
                <GridItem md={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="CountryName"
                    // id="chaNo"
                    label="Country Name*"
                    type="text"
                    value={this.state.countryName}
                    onChange={(e) => this.handleChange(e, "countryName")}
                    error={this.state.countryNameErr}
                    helperText={this.state.countryNameErrText}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("AddUpdateCountryMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.AddUpdateCountryMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal For Delete */}
          <Dialog
            open={DeleteCountryMasterModal}
            onClose={() => this.handleClose("DeleteCountryMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Delete Country Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("DeleteCountryMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <h6>Are you sure want to delete?</h6>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("DeleteCountryMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.DeleteCountryMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default CountryMaster;
