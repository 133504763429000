const apiBase = process.env.REACT_APP_API_ENDPOINT;
const nodeApiBase = process.env.REACT_APP_NODE_API_ENDPOINT;
const group_image_link = process.env.REACT_APP_GROUP_IMAGE_LINK;
const individual_profile_image_link = process.env.REACT_APP_INDIVIDUAL_PROFILE_IMAGE_LINK;
const individual_rc_image_link = process.env.REACT_APP_INDIVIDUAL_RC_IMAGE_LINK;
const pdf_file_link = process.env.REACT_APP_PDF_FILE_LINK;
const ServerErrorMessage = "Server error, Please try agian later";


const dateFormat = 'ddd MMM DD, YYYY'
export { apiBase, nodeApiBase, ServerErrorMessage, dateFormat, pdf_file_link, group_image_link, individual_profile_image_link, individual_rc_image_link };
